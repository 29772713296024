import { useMemo } from "react";
import { useSelector } from "react-redux";

interface sectionProps {
    section?: string,
    subsection?: string,
    fieldkey?: string
}

export function useSectionList(params: sectionProps) {
    const insurance = useSelector((state: any) => state.insurance)
    //eslint-disable-next-line
    const sectionList = useMemo(() => JSON.parse(JSON.stringify(insurance.sectionList)), [insurance.sectionList, insurance.formConfigAgency])

    const fetchItem = (key: string, searchObj: Array<any>) => {
        let item: any = null
        for (let obj of searchObj) {
            if (obj.key === key || obj.name === key) {
                item = obj
                break
            }
        }
        return item
    }

    const fetchSectionItem = (section: string, subsection?: string, fieldkey?: string) => {
        const mainSection: any = fetchItem(section, sectionList)
        if (!subsection) {
            return mainSection
        }

        const subItem: any = fetchItem(subsection, mainSection?.blocks || [])
        if (!fieldkey) return subItem

        const fieldItem = fetchItem(fieldkey, subItem?.fields || [])
        return fieldItem

    }

    const setSectionItem = (section: string, subsection: string, fieldkey: string, newFieldItem: any) => {
        const mainSection: any = fetchItem(section, sectionList)
        const subItem: any = fetchItem(subsection, mainSection.blocks)
        let fieldItem = fetchItem(fieldkey, subItem.fields)
        fieldItem = newFieldItem
        return fieldItem
    }

    // const sectionItem = fetchSectionItem(section, subsection, fieldkey)
    return {
        fetchSectionItem,
        setSectionItem,
        fetchItem
    }
}