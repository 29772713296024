import { useEffect, useState } from 'react';
import Modal from "@src/common/ModalPopup";
import dateFormat from "dateformat";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import { getLeadRemarks, addLeadRemark, updateLeadDetailsInStore } from '@src/store/slices/insurance';
import { STATUS_SUB_STATUS, ROLE_FEATURES } from '@src/config/constant';
import * as HELPER from '@src/config/helper';

function LeadRemarksModal(props: any) {

    let { remarkHistory, selectedRemarkLead } = props;

    const [loading, setLoading] = useState(false);

    const [remarHistorykList, setRemarHistorykList] = useState([]);

    const [remarkValue, setRemarkValue] = useState("");

    const [refreshList, setRefreshList] = useState<number>(0);

    // Redux hooks to dispatch actions and access state
    const dispatch: any = useDispatch()
    const insuranceState = useSelector((state: any) => state.insurance)

    useEffect(() => {

        if (remarkHistory || refreshList) {
            setLoading(true);
            getLeadRemarks({ lead_id: selectedRemarkLead }).then((res: any) => {
                setLoading(false);

                if (res.data && res.data.length) {
                    setRemarHistorykList(res.data)
                }
            }).catch(err => setLoading(false));
        }
    // eslint-disable-next-line
    }, [remarkHistory, refreshList]);


    const handleRemarkTextArea = (e: any) => {
        setRemarkValue(e.target.value);
    }

    const addRemarkHistory = () => {
        if (remarkValue.trim()) {

            if (remarkValue.length > 500) {
                toast.error("Remark should not be more than 500 character long");

            } else {

                addLeadRemark({ comment: remarkValue, lead_id: selectedRemarkLead }).then((res: any) => {
                    if (res.status === 200) {
                        setRemarkValue("");
                        toast.success(res.message);
                        setRefreshList(new Date().getTime());

                        dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, "leadRemarkCount": (insuranceState.leadDetail.leadRemarkCount+1) }));
                    }
                });
            }
        } else {
            toast.error("Please enter remark");
        }
    }

    return (


        <div className="view-timeline-popup remarks-history-popup">
            <Modal show={remarkHistory} handleClose={() => { props.handleRemarkTimeline(false, 0); }} >
                <div className="modal-header">
                    <h2>Add Remarks & History</h2>
                </div>
                <div className="modal-body" >
                    <div className="timeline-history-tabel">
                        <table>
                            <tbody>
                                {
                                    !loading && remarHistorykList.length
                                        ?
                                        remarHistorykList.map((remark: any) =>
                                            <tr>
                                                <td>
                                                    <span className="date">{dateFormat(remark.created_at, 'd mmm')}</span>
                                                    <span className="time">{dateFormat(remark.created_at, 'hh:MM tt')}</span>
                                                </td>
                                                <td>
                                                    <span className="heading overflow-wrap">{remark.comment}</span>
                                                    <span className="subheading">By {remark.created_by_name}</span>
                                                </td>
                                                {/* <td>{remark.created_by_name || 'Admin'}</td> */}
                                            </tr>
                                        )
                                        :
                                        loading ? <p style={{ textAlign: "center" }}>Loading...</p> : <p style={{ textAlign: "center" }}>No data found</p>

                                }

                            </tbody>
                        </table>
                    </div>
                    <fieldset className="form-filed add-remarks-filed">
                        <span className="remark-heading-txt">Add Remark</span>
                        <div className="material">
                            <textarea placeholder=" " className="form-input" rows={3} onChange={(e) => handleRemarkTextArea(e)} value={remarkValue}></textarea>
                            <label data-label="Add Remarks *" className="form-label"></label>
                        </div>
                    </fieldset>
                    {
                        HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['dashboard_page'], accessType: 'edit' })
                        &&(

                       ( insuranceState?.leadDetail?.status_id === STATUS_SUB_STATUS['status']['lost'] || props?.selectedRemarkLeadStatus === STATUS_SUB_STATUS['status']['lost']) ?
                        <button className="btn-primary m-md-t"
                        disabled = {true}
                        >Submit</button>
                        :
                        <button className="btn-primary m-md-t" onClick={addRemarkHistory}
                        >Submit</button>
                        )
                    }
                </div>
            </Modal>
        </div>

    )
}

export default LeadRemarksModal;