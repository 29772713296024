import { useState } from 'react';
import Modal from "@src/common/ModalPopup";
import { toast } from 'react-toastify';
import { approveAgencyDocument } from '@src/store/slices/agency';

function DocResubmitModal(props: any) {

    const { showModal, agency_id } = props;
    const [remarkValue, setRemarkValue] = useState("");

    const handleRemarkTextArea = (e: any) => {
        setRemarkValue(e.target.value);
    }

    const adaddComment = () => {
        if (remarkValue.trim()) {

            if (remarkValue.length > 500) {
                toast.error("Remark should not be more than 500 character long");

            } else {
                approveAgencyDocument({ comment: remarkValue, agency_id, type: props.handleType , source: 2}).then((res: any) => {
                    if (res.status === 200) {
                        setRemarkValue("");
                        toast.success(res.message);
                        props.triggerApi();
                       
                    } else {

                    }
                });
            }
        } else {
            toast.error("Please enter remark");
        }
    }

    return (


        <div className="view-timeline-popup remarks-history-popup">
            <Modal show={showModal} handleClose={() => { props.closeResubmitModal(false, 0); }} >
                <div className="modal-header">
                    <h2>Request for TIN document resubmission</h2>
                </div>
                <div className="modal-body" >
                    
                    <fieldset className="form-filed add-remarks-filed">
                        {/* <span className="remark-heading-txt">Comment</span> */}
                        <div className="material">
                            <textarea placeholder=" " className="form-input" rows={3} onChange={(e) => handleRemarkTextArea(e)} value={remarkValue}></textarea>
                            <label data-label="Comment*" className="form-label"></label>
                        </div>
                    </fieldset>
                    {
                       
                        <button className="btn-primary m-md-t" onClick={adaddComment}
                        >Submit</button>
                    }
                </div>
            </Modal>
        </div>

    )
}

export default DocResubmitModal;