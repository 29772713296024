import Modal from "@src/common/ModalPopup"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { ROLE } from '@src/config/constant';
import { shareLoanQuoteDetail } from "@src/store/slices/insurance";
import { toast } from 'react-toastify';

export default function ShareQuotesDetail(props: any) {
    let { show, handleClose } = props
    let [selectedRoles, setSelectedRoles] = useState<any>({ customer: false, so: false, agent: false, aro: false, sm: false })

    let insuranceState = useSelector((state: any) => state.insurance)
    insuranceState = (insuranceState && insuranceState.leadDetail) ? insuranceState.leadDetail : null
    const insuranceQuotesState = (insuranceState) ? insuranceState.quotes : null;

    let isShareBtnActive = insuranceState?.email || insuranceState?.so_id || insuranceState?.agent_id || insuranceState?.aro_id || insuranceState?.sm_id
    isShareBtnActive = isShareBtnActive ? true : false

    const handleBtnClick = async () => {
        props.setLoader(true)

        let params: any = {}
        let share_with_roles = []
        let roleIds = []
        let rolesSelected = selectedRoles
        for (let key in rolesSelected) {
            if (rolesSelected[key]) {
                if (key === 'customer') {
                    params.customer_email = insuranceState.email
                }
                else {
                    params[`${key}_id`] = insuranceState[`${key}_id`]
                    roleIds.push(+insuranceState[`${key}_id`])
                    let allRoles: any = ROLE
                    let roleId = Object.keys(ROLE).find((e: string) => allRoles[e] === `${key}_id`)
                    if (roleId) share_with_roles.push(+roleId)
                }
            }
        }


        if (Object.keys(params).length) {
            let apiParams: any = {}

            apiParams.lead_type = insuranceState?.lead_type
            apiParams.user_id = insuranceState?.user_id
            apiParams.role_ids = roleIds
            apiParams.share_with_roles = share_with_roles
            apiParams.data = {
                vehicle: `${insuranceState?.make_name} ${insuranceState?.model_name} ${insuranceState?.version_name}`,
                tenure: insuranceState?.tenure,
                financier: insuranceState?.financier,
                vehicle_usage: insuranceState?.vehicle_usage,
                fmv: insuranceState?.proposed_fmv > 0 ? insuranceState.proposed_fmv : insuranceState?.total_fmv_car,
                partner_id: insuranceState?.partner_id,
                gross_premium: insuranceState?.gross_premium,
                own_theft_damage: insuranceState?.own_theft_damage,
                lead_id: insuranceState?.hashId,
                aon_rate: insuranceState?.aon_rate
            }

            let addonDetails = insuranceState && insuranceState.addon_detail
            if (addonDetails) {
                for (let data of addonDetails) {
                    let name = data.name.trim().replaceAll(' ', '_').toLowerCase()
                    let key = name
                    apiParams.data[key] = data.coverage_amount

                    if (data.id === 3) apiParams.data.seat_capacity = data.seat_capacity + data.extra_seat_capacity

                }
            }

            if (params.customer_email) apiParams.customer_email = params.customer_email

            let sendMail: any = await shareLoanQuoteDetail(apiParams)

            if (sendMail && sendMail.status && sendMail.status === 200) {
                toast.success("Email Sent Successfully")
                handleClose()
            }

        }

        props.setLoader(false)

    }

    const handleRoleClick = (key: string) => {
        setSelectedRoles((prev: any) => {
            return {
                ...prev,
                [key]: !selectedRoles[key]
            }
        })
    }

    useEffect(() => { }, [insuranceState])

    return (
        <div className="view-timeline-popup image-timeline-popup financer-email-popup share-popup">
            <Modal show={show} handleClose={handleClose} >
                <div className="modal-header">
                    <h2>Share Loan Quotation</h2>
                </div>
                <div className="modal-body" >
                    <div className="financer-email-data">
                        <ul>
                            <li>
                                <label>Model</label>
                                <span>{`${insuranceState?.make_name} ${insuranceState?.model_name} ${insuranceState?.version_name}`}</span>
                            </li>
                            <li>
                                <label>Period Of Insurance</label>
                                <span>{insuranceState?.tenure}</span>
                            </li>
                            <li>
                                <label>Mortgagee</label>
                                <span>{insuranceState?.is_under_financing === 1 ? insuranceState?.financier : ''}</span>
                            </li>
                            <li>
                                <label>Vehicle Use</label>
                                <span>{insuranceState?.vehicle_usage}</span>
                            </li>
                            <li>
                                <label>Coverage</label>
                                <span>{"PHP " + (insuranceState?.proposed_fmv > 0 ? insuranceState.proposed_fmv : insuranceState?.total_fmv_car)}</span>
                            </li>
                            <li>
                                <label>Insurance Partner</label>
                                <span>{insuranceState?.insurance_partner}</span>
                            </li>

                            <li>
                                <label>Gross Premium</label>
                                <span>{"PHP " + insuranceQuotesState?.gross_premium}</span>
                            </li>
                            <li className="align-top">
                                <label>Coverage</label>
                               <span className="">  {
                                    insuranceState && insuranceState.addon_detail ?
                                        insuranceState.addon_detail.map((e: any, idx: number) => {
                                            let seatCapacity = e.id === 3 ? `(${(e.seat_capacity || 0) + (e.extra_seat_capacity || 0)})` : ''
                                            //for add
                                            if (e.id === 6) {
                                               
                                                return (
                                                    <span className="share-data-lisy" key={idx}>{(e.name ? e.name + " : Rate " : '') + insuranceState?.aon_rate ?? 0 + '%'}</span>
                                                )
                                            }
                                            else {

                                                return (
                                                    <span className="share-data-lisy" key={idx}>{(e.name ? e.name + " : PHP " : '') + (e.coverage_amount ? e.coverage_amount : 0) + seatCapacity}</span>
                                                )
                                            }

                                            
                                        }) : null
                                }</span>
                            </li>


                            <li>
                                <label>Casa Eligibility (car's age based on registration date on CR)</label>
                                <span>{"10 years old and below"}</span>
                            </li>
                        </ul>
                    </div>
                    {
                        isShareBtnActive
                            ?
                            <>
                                <div className="email-attech-doc-list">
                                    <h3>Share With</h3>
                                    <ul>
                                        {
                                            insuranceState?.email ?
                                                <li className={selectedRoles.customer ? 'active' : ''} onClick={() => handleRoleClick('customer')}>Customer</li>
                                                : null
                                        }
                                        {
                                            insuranceState?.so_id ?
                                                <li className={selectedRoles.so ? 'active' : ''} onClick={() => handleRoleClick('so')}>Sales Officer</li>
                                                : null
                                        }
                                        {
                                            insuranceState?.agent_id ?
                                                <li className={selectedRoles.agent ? 'active' : ''} onClick={() => handleRoleClick('agent')}>Agent</li>
                                                : null
                                        }
                                        {
                                            insuranceState?.aro_id ?
                                                <li className={selectedRoles.aro ? 'active' : ''} onClick={() => handleRoleClick('aro')}>ARO</li>
                                                : null
                                        }
                                        {
                                            insuranceState?.sm_id ?
                                                <li className={selectedRoles.sm ? 'active' : ''} onClick={() => handleRoleClick('sm')}>Sales Manager</li>
                                                : null
                                        }
                                    </ul>
                                </div>
                                <div className="btn-save-remarks m-xl-t">
                                    <button type="button" className="btn-primary" onClick={() => handleBtnClick()}>Share</button>
                                </div>
                            </>
                            : null
                    }
                </div>
            </Modal>
        </div>
    )
}