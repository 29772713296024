import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DocumentGridView from '@src/component/insurance/common/DocumentGridView';
import DocumentView from '@src/component/insurance/common/DocumentView';
import ReactSelect from '@common/ReactSelect';
import { DOCUMENT_CATEGORY, INBOUND_PAYMENT_FROM_BROKER } from '@src/config/constant';



export default function ListGridView(props: any): ReturnType<React.FC> {
    let { docType } = props
    let [gridView, setGridView] = useState<boolean>(false)
    let [ListView, setListView] = useState<boolean>(true)
    let [leadDetail, setLeadDetail] = useState<any>(null)
    let [categoryList, setCategoryList] = useState<any>([])
    let [subCategoryList, setSubCategoryList] = useState<any>([])
    let [inputField, setInputField] = useState<any>({})
    let [selectedCategoryImage, setSelectedCategoryImage] = useState<any>([])
    let [activeImageIndex, setActiveImageIndex] = useState<number>(0)
    let [allImages, setAllImages] = useState<any>()

    let commonState = useSelector((state: any) => state.common)
    let insuranceState = useSelector((state: any) => state.insurance)

    const toggleswitchgrid = (type: any) => {
        if (type === 'list') {
            setGridView(true)
            setListView(false)
        }
        else {
            setGridView(false)
            setListView(true)
        }
    }

    const handleChange = (name: string, value: any) => {
        //if category has subcategories 
        if (name === 'category') {
            if (value.child.length) {
                let subCategory = value.child
                let firstChild = subCategory && subCategory[0]
                setSubCategoryList(subCategory)
                setInputField((prev: any) => {
                    return {
                        ...prev,
                        subcategory: firstChild
                    }
                })
            }
            //for resetting subcategory list
            else {
                setSubCategoryList(null)
                setInputField((prev: any) => {
                    return {
                        ...prev,
                        subcategory: null
                    }
                })
            }
        }
        setInputField((prev: any) => {
            return {
                ...prev,
                [name]: value
            }
        })

    }

    const fetchAllDocuments = useCallback(() => {
        //filtering images with selected doc type (first submit, disbursal, post-disbursal)
        let leadDetail = (insuranceState && insuranceState.leadDetail)
        setLeadDetail(leadDetail)

        let images = (leadDetail && leadDetail.images) || []

        if (images.length) {
            let currentDocTypeImages = []
            for (let img of images) {
                if (img?.doc_type_id === docType) {
                    currentDocTypeImages.push(img)
                }
            }

            setAllImages(currentDocTypeImages)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceState])

    useEffect(() => {
        if (commonState && commonState.documentList) {
            let documentList = JSON.parse(JSON.stringify(commonState.documentList))
            let updatedList = documentList.filter((e: any) => {
                if (e?.value === DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_inbound'] && props?.formType === 'inbound_payment' && (props?.payment_from === INBOUND_PAYMENT_FROM_BROKER || !insuranceState?.leadDetail?.payment_tenure_id)) e.child = []
                
                return e?.doc_type_id === docType
            })

            let category = (props.category && updatedList) ? (updatedList.find((e: any) => e.value === props.category) || updatedList[0]) : updatedList[0]
            let subcat: any = []            
            
            if (category?.child?.length) {
                subcat = category.child
            }

            setCategoryList(updatedList)
            setSubCategoryList(subcat.length ? subcat : null)

            setInputField((prev: any) => {
                return {
                    ...prev,
                    category,
                    subcategory: subcat[0] ? subcat[0] : null
                }
            })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonState, docType, props.category, props.formType, props.payment_from])

    useEffect(() => {
        if (inputField.category) {
            if (leadDetail) {
                let images = leadDetail && leadDetail.images
                console.log("image", images)
                //if subcategory selected, then fetch images of subcategory
                if (inputField.subcategory) {

                    let selectedSubCategoryDoc = (images && images.filter((e: any) => e.doc_id === inputField.subcategory.value)) || []

                    setSelectedCategoryImage(selectedSubCategoryDoc)

                    let activeImage = (selectedSubCategoryDoc && selectedSubCategoryDoc[activeImageIndex]) ? selectedSubCategoryDoc[activeImageIndex] : null
                    if (!activeImage) {
                        setActiveImageIndex(0)
                    }

                }
                else {
                    let selectedCategoryDoc = (images && images.filter((e: any) => inputField.category.value === e.doc_id )) || []
                    setSelectedCategoryImage(selectedCategoryDoc)

                    let activeImage = (selectedCategoryDoc && selectedCategoryDoc[activeImageIndex]) ? selectedCategoryDoc[activeImageIndex] : null
                    if (!activeImage) {
                        setActiveImageIndex(0)
                    }
                }
            }
        }
    }, [inputField.category, inputField.subcategory, leadDetail, activeImageIndex])


    useEffect(() => {
        fetchAllDocuments()
    }, [fetchAllDocuments])

    useEffect(() => {
        return (() => {
            setInputField(null)
            setCategoryList(null)
            setSubCategoryList(null)
            setSelectedCategoryImage(null)
            setActiveImageIndex(0)
        })
    }, [])
    return (
        <div className="doument-view-list-gallery">
            {ListView ?
                <div className="file-submit-sec list-view">
                    <div className="file-submit-selet-list">

                        <ReactSelect options={categoryList} value={inputField.category} handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)} placeholder="Category" fieldKey="category" />

                        {
                            subCategoryList && subCategoryList.length ?

                                <ReactSelect options={subCategoryList} value={inputField.subcategory} handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)} placeholder="Sub-category" fieldKey="subcategory" />
                                : ''
                        }

                        {
                            allImages && allImages.length
                                ?
                                <div className="grid-icn" onClick={() => toggleswitchgrid('list')}>
                                    <i className="ic-gridview">
                                        <i className="path1"></i>
                                        <i className="path2"></i>
                                        <i className="path3"></i>
                                        <i className="path4"></i>
                                        <i className="path5"></i>
                                        <i className="path6"></i>
                                        <i className="path7"></i>
                                        <i className="path8"></i>

                                    </i>
                                </div>
                                :
                                ''
                        }
                    </div>

                    <DocumentView callFrom="DocGalleryView" allImages={selectedCategoryImage} setLoader={props.setLoader} updateSelectedImage={() => { }} showImages={selectedCategoryImage && (selectedCategoryImage.filter((e: any) => e.path)).length ? true : false} selectedCategoryImage={selectedCategoryImage} selectedCategory={inputField} docType={docType} categoryForResubmission={inputField.subcategory && inputField.subcategory.value ? { ...inputField.subcategory } : { ...inputField.category }} />


                </div>
                : null}
            {/* <--------- grid view -------> */}

            {gridView ?
                <DocumentGridView callFrom="DocGalleryView" allImages={allImages} toggleswitchgrid={toggleswitchgrid} setLoader={props.setLoader} docType={docType} />
                : null}

        </div>
    )

}