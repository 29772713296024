import React, { useEffect, useState } from "react";
import { Tab } from 'react-bootstrap'; 
import PaymentListTable from "./PaymentListTable"; 
import { useNavigate } from 'react-router-dom';
import { getBulkPaymentList } from '../../store/slices/bulkPayment';
import { useDispatch } from "react-redux"; 


function BulkPaymentList() {  
    let [searchText, setSearchText] = useState('');
    const [bulkPaymentList, setBulkPaymentList] = useState<any>([]); 
    const [paginationData, setPaginationData] = useState<any>({});
    const [loading, setLoading] = useState(false); 
    const dispatch: any = useDispatch();
    const navigate = useNavigate();  

    const searchUser = (event: any) => { 
        let value = event.target.value;
        setSearchText(value); 
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            let text = event?.target?.value?.trim()
            setSearchText(text)
            getBulkListDetails({searchKey: text || undefined, page_number: 1})
        } 
    } 

    useEffect(() => { 
        getBulkListDetails({searchKey: searchText || undefined, page_number: 1});
        // eslint-disable-next-line
    }, [])

    const getBulkListDetails = async (params: any) => {  
        setLoading(true); 
        dispatch(getBulkPaymentList.getBulkPayData(params)).then((res: any) => {
            setLoading(false)
            if (res && res.status === 200) { 
                setPaginationData({ ...res?.pagination });
                setBulkPaymentList([...res?.data]);
            } 
        }).catch((err: Error) => {  setLoading(false);  })
    };
    return (
        <React.Fragment>
            <div className="container-fluid"> 
                <div className="top-heading financer-lead-heding">
                    <h1>Bulk Insurance Payment</h1>
                    <div className="d-flex"> 
                    <div className="search-user-add-btn">
                    <fieldset className="search-bx">
                        <div className="material m-t-0">
                            <input id="Search" type="text" title="Search by reference no, paid to whom" placeholder="Search by reference no, paid to whom" onChange={(e: any) => searchUser(e)} value={searchText} className="form-input" onKeyDown={handleKeyDown} />
                            <i className="ic-search"></i>
                        </div>
                    </fieldset>
                    </div>
                 
                    <button className="btn-line m-md-l" onClick={() => navigate('/payment/add')}>
                    <i className="ic-add"></i>
                        <span>Add Bulk Payment</span>
                    </button>
                    </div> 
                </div>
                <div className="loan-filter-bg managment-tabs tab-chips">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="p-rel">
                        <div className="role-management-top-sec"> 
                        <PaymentListTable searchText={searchText} bulkPaymentList={bulkPaymentList} paginationData={paginationData} getBulkListDetails={(e:any) => getBulkListDetails(e)} loading={loading}/> 
                        </div>
                        </div>
                        
                    </Tab.Container>

                </div>
              
            </div>
        </React.Fragment>
    )
}

export default BulkPaymentList