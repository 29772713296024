import React,{ useState,useEffect } from 'react';
import { ADD_ONS_TYPE, APP_TYPE_RENEWAL, APP_TYPE_WITH_UCF } from 'config/constant';
import DynamicFormField from './DynamicFormField'; 
import InsuranceService from 'services/InsuranceService';
import ReactSelect from '@common/ReactSelect';
import { saveInsurance, updateRenewalState } from "@src/store/slices/insurance";
import { useSelector,useDispatch } from 'react-redux';
import * as HELPER from "../../../config/helper";
import { updateInsuranceForm } from './FunctionList';


const InsuranceForm = (props: any) => {
    const [value, setValue] = useState(3)
    const dispatch: any = useDispatch();
    const allState = useSelector((state: any) => state);
    const [reload, setreload] = useState<boolean>(false)

    const insuranceState =  (allState.insurance && allState.insurance.createInsurance) ? allState.insurance.createInsurance: null;
    const isComprensive = (insuranceState && insuranceState.hasOwnProperty('insurance_type')) ? insuranceState.insurance_type: true; 
    // Validate input field of allow two decimal value 
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;     

    const getFieldItem = (key: any) => {
        let filterItem = props?.sectionItem?.blocks?.[0].fields?.filter((item: any) => item.name === key)?.[0]
        return filterItem
    } 
    
    const onValueChange = (fieldItem: any) => {
        if (fieldItem.name === "car_fmv" || fieldItem.name === "acc_price") {
            //reset referrer mark up & net premium on fmv change
            if(props?.extraField) {
                let newFields = {...props.extraField}
                const keysToRemove = ['referrer_mark_up', 'net_premium']

                keysToRemove.forEach((key) => {
                    if(props.extraField[key]) delete newFields[key]
                })

                props.setExtraField(newFields)
            }

            setValue(value + 1)
        }else if (fieldItem.name === "vehicle_type" || fieldItem.name === "insurance_type" || fieldItem.name === "make_year") {
            fetchAddons()
            if (fieldItem.name === "insurance_type") {  
                dispatch(saveInsurance({
                    insurance_type: (fieldItem?.value?.value === 2) ? false: true
                }));
                
                props.handlePaymentTermTenure(fieldItem?.value?.value)
            }
        }else if(fieldItem.name === "car_under_finance"){
            SetActNature();
        }  
    }

    const SetActNature = async () => {
        let carfinanceTypeItem = getFieldItem('car_under_finance');
        let insuranceTypeItem = getFieldItem('insurance_type'); 
        let is_required=(insuranceTypeItem.value.value===1 && +carfinanceTypeItem.value.value===1)?true:false;
        let fieldItem = getFieldItem('addon_detail');
        let originalList = fieldItem.original_list;
        if(originalList?.length>0){
            originalList=originalList.map((ob:any)=>{
                return {
                    ...ob,is_required:(ob.id===6)?is_required:ob.is_required,is_selected:(ob.id===6)?is_required:ob.is_selected
                }
            })
            fieldItem['original_list'] = originalList;
            setValue(value + 1)
        }
        
    }

    const fetchAddons = async () => {
        let vehicleTypeItem = getFieldItem('vehicle_type')
        let insuranceTypeItem = getFieldItem('insurance_type') 
        let makeYearItem = getFieldItem('make_year') 
        if (vehicleTypeItem?.value && insuranceTypeItem?.value) {
            props.setLoading(true)
            let params = { rule_engine: props.applicationType, vehicle_type: vehicleTypeItem.value.value, apptype: props.apptype ?? 2, insurance_type: insuranceTypeItem.value.value, make_year: makeYearItem.value.value }
            let result = await InsuranceService.getAddonList(params)
            if (result?.data?.data) {
                let originalList = result.data.data;
                let fieldItem = getFieldItem('addon_detail');
                let carfinanceTypeItem = getFieldItem('car_under_finance');

                let is_required=(carfinanceTypeItem && +carfinanceTypeItem?.value?.value===1)?true:false;
                //for renewal application, check if pa selected in last lead
                let renewalParentAddons = allState?.insurance?.leadDetail?.addon_detail
                
                if(is_required && originalList.length>0){
                    originalList=originalList.map((ob:any)=>{
                        return {
                            ...ob,is_required:(ob.id===6)?is_required:ob.is_required,is_selected:(ob.id===6)?is_required:ob.is_selected
                        }
                    })
                }
                
                //pre-fill renewal addons
                if(props.apptype === APP_TYPE_RENEWAL) {
                    originalList = originalList.map((addon: any) => {
                        let parentAddon = renewalParentAddons.find((e: any) => e.id === addon.id)
                        if(parentAddon?.premium_amount && !(isRuleEngine())) addon['premium_amount'] = parentAddon.premium_amount
                        if(parentAddon?.premium_amount && addon.type === "pa") addon['premium_amount'] = parentAddon.premium_amount
                
                        //prefill pa
                        if (parentAddon?.id && addon.id === 3) {
                            return {
                                ...addon,
                                is_selected: true,
                                coverage_amt: parentAddon?.coverage_amount,
                                seat_capacity: parentAddon?.seat_capacity,
                                extra_seat_value: parentAddon?.extra_seat_capacity,
                            }
                        }

                        return {
                            ...addon,
                            coverage_amt: parentAddon?.coverage_amount || addon.converage_amt,
                            is_selected: parentAddon?.id ? true : false
                        }
                    })
                }

                fieldItem['original_list'] = originalList;
                setValue((value)=> value + 1)
            }
            props.setLoading(false)

        }
    }


    const onValueSelect = (addonItem: any, event: any) => {
        addonItem['coverage_amt'] = event.value
        setValue(value + 1)
    }

    const isRuleEngine = () => {
        if (props?.applicationType === 1) return true
        else return false
    }

    const getPremiumAmount = (item: any) => {

        if (isComprensive && isRuleEngine()) {
            if (ADD_ONS_TYPE.PERSONAL_ACCIDENT === item.type) {
                return item.premium_amount || ''
            }
            let filterItem = item?.coverage_list?.filter((option: any) => option.value === item.coverage_amt)?.[0]
            if (filterItem)
                return filterItem?.premium_amount || ''
            else
                return 0
        }
        else {
            return item.premium_amount ? item.premium_amount : ''
        }
    }

    const getPremiumTitle = (item: any, key: string) => { 
        if (ADD_ONS_TYPE.PERSONAL_ACCIDENT === item.type && isComprensive && isRuleEngine()) {
            return ''
        }
        else if (ADD_ONS_TYPE.ACTS_OF_NATURE === item.type || ADD_ONS_TYPE.RIOTS_STRIKES_CIVIL === item.type || ADD_ONS_TYPE.TOWING_ROWDSIDE === item.type) {
            return ''
        } else if (isComprensive && isRuleEngine()) {
            if(key === "title"){
                return "Premium Amount :"
            }else{
                return HELPER.addCurrencyFormatting(getPremiumAmount(item))
            }
        }
        return ''
    }

    const isShowAlreadyIncluded = (addonItem: any) => {
        if ((ADD_ONS_TYPE.RIOTS_STRIKES_CIVIL === addonItem.type || ADD_ONS_TYPE.TOWING_ROWDSIDE === addonItem.type) && !addonItem?.is_editable) return true;

        return false
    }

    const calculateTotalPremiumValue = () => {
        let total = 0
        let filterList = addonList()?.filter((item: any) => item?.is_selected)
        if (filterList?.length) {
            filterList?.forEach((item: any) => {
                let premiumAmount = getPremiumAmount(item)
                if (ADD_ONS_TYPE.ACTS_OF_NATURE !== item.type)
                    if (premiumAmount)
                        total += parseFloat(premiumAmount ?? '0')
            })
        }
        return HELPER.addCurrencyFormatting(total)
    }

    const onChangeCheckbox = (addonItem: any, event: any) => {
        if (addonItem.is_required) {
            addonItem.is_selected = true
        } else {
            addonItem['is_selected'] = !addonItem.is_selected
        }
        setValue(value + 1)
    }

    const isShowPremiumInputBox = (addonItem: any) => {
        if (((!isComprensive && !isRuleEngine()) || (!isRuleEngine() && isComprensive)) && ADD_ONS_TYPE.RIOTS_STRIKES_CIVIL !== addonItem.type && ADD_ONS_TYPE.TOWING_ROWDSIDE !== addonItem.type){
            return true
        }
        else{
            if(addonItem.is_editable && !isRuleEngine()){ //ONLY IN CASE OF TRA WITHOUT RULE ENGINE
                return true
            }else{
                //FOR OONA & MERCENTILE IF COVERAGE AMT IS MATCHED FROM API THEN SHOW INPUT BOX
                if(addonItem.type === ADD_ONS_TYPE.PERSONAL_ACCIDENT){
                    if(addonItem.coverage_amt === addonItem.oona_coverage_amount_limit || addonItem.coverage_amt === addonItem.milestone_coverage_amount_limit){
                        addonItem.premium_amount = ''
                        return false
                    }else{
                        return (addonItem.coverage_amt) ? true : false //MANUAL PREMIUM INPUT ALLOW 
                    }

                }else{
                    return false
                } 
            }
        }
    }

    const onChangePremiumAmount = (addonItem: any, text: any) => {
        // validate two decimal digit value
        if (regex.test(text) || text === '') {
            if (!isComprensive && isRuleEngine())
                addonItem.coverage_amt = text ? text : ''
            else
                addonItem.premium_amount = text ? text : ''
                setValue(value + 1)
        }
    }

    const addonList = () => {
        let addonFieldItem = getFieldItem('addon_detail'); 
        return addonFieldItem?.original_list || []
    }

    const resetAddonList = () => {
        setValue(value + 1)
    }

    const getSeatingCapacityList = (item: any) => {
        let versionSeat = props?.versionSeat
        if (versionSeat) {
            item.seat_capacity = versionSeat
            return [{ value: versionSeat, label: versionSeat }]
        }
        if (!versionSeat) {
            versionSeat = item.extra_seat_capacity
        }
        if (versionSeat) {
            versionSeat = +versionSeat
            var tempList = []
            for (var index = 1; index <= versionSeat; index++) {
                const optionParam = {
                    "value": index,
                    "label": index
                }
                tempList.push(optionParam)
            }
            return tempList
        }
        return []
    }

    const getExtraSeatingCapacityList = (item: any) => {
        let versionSeat = item.extra_seat_capacity

        if (versionSeat) {
            versionSeat = +versionSeat
            var tempList = []
            for (var index = 1; index <= versionSeat; index++) {
                const optionParam = {
                    "value": index,
                    "label": index
                }
                tempList.push(optionParam)
            }
            return tempList
        }
        return []
    }

    const saveExtraSeatingValue = (item: any, event: any) => {
        item.extra_seat_value = event.value  
        setValue(value + 1)

    } 
    
    const saveSeatingValue = (item: any, event: any) => {
        item.seat_capacity = event.value
        setValue(value + 1)
    };

    const calculateTotalFMV = ()=>{
        let carFMVItem = getFieldItem('car_fmv')
        let accPriceItem = getFieldItem('acc_price')
        let totalValue = 0
        if (carFMVItem.value)
            totalValue = parseFloat(carFMVItem.value)

        if (accPriceItem.value)
            totalValue += parseFloat(accPriceItem.value) 
        return HELPER.addCurrencyFormatting(totalValue);
    }

    useEffect(() => {
        let insuranceFormSection: Array<any> = props.sectionItem?.['blocks']?.[0]?.['fields']

        if (props.apptype === APP_TYPE_WITH_UCF) {
            if (allState.insurance.activeLoanID && Object.keys(allState.insurance.activeLoanID).length) {
                updateInsuranceForm(allState.insurance.activeLoanID, insuranceFormSection)
                props.onVersionSelect({})
            } else updateInsuranceForm({}, insuranceFormSection, [], true)

            setreload(!reload)
        }

       // eslint-disable-next-line
    }, [props.apptype, allState.insurance.activeLoanID, props.sectionItem])


    useEffect(() => {
        let insuranceFormSection: Array<any> = props.sectionItem?.['blocks']?.[0]?.['fields']

        if (props.apptype === APP_TYPE_RENEWAL && !allState?.insurance?.renewalUpdateFlag?.insuranceForm) {
            if (allState.insurance.leadDetail && Object.keys(allState.insurance.leadDetail).length) {
                updateInsuranceForm({ ...allState.insurance.leadDetail, type: 'renewal' }, insuranceFormSection).then(() => {
                    //update insurance_type to update isComprehensive flag
                    let { insurance_type } = allState.insurance.leadDetail
                    dispatch(saveInsurance({
                        insurance_type: (insurance_type === 2) ? false: true
                    }));
                    
                    props.handlePaymentTermTenure(insurance_type)
                })
                props.onVersionSelect({})
                fetchAddons()
                dispatch(updateRenewalState({ ...allState?.insurance?.renewalUpdateFlag, insuranceForm: true }))

            } else updateInsuranceForm({}, insuranceFormSection, [], true)

            setreload(!reload)
        }
        // eslint-disable-next-line
    }, [props.apptype, allState.insurance.leadDetail, props.sectionItem])
    

    return (
        <div className="loan-filed-sec">
            <div className="form-group-bx br-bottom">
                <div className="row">
                    <DynamicFormField
                        fields={props?.sectionItem?.blocks?.[0].fields}
                        applicationType={props.applicationType}
                        onValueChange={onValueChange}
                        onVersionSelect={props.onVersionSelect}
                        resetAddonList={resetAddonList}
                        hideFieldType={["select_addon"]}
                        apptype={props.apptype}
                        setLoading={props.setLoading}
                    />
                </div>
            </div>

            {
                addonList()?.length  ?
                    <div className="form-group-bx ">
                        <div className='br-bottom'>
                        {
                            addonList()?.map((addonItem: any, index: number) =>
                                <div className="row" key={index}>
                                    <fieldset className={"form-filed common-application-checkbox "+( ([ADD_ONS_TYPE.RIOTS_STRIKES_CIVIL,ADD_ONS_TYPE.TOWING_ROWDSIDE].includes(addonItem.type) || (isRuleEngine() && addonItem.type === ADD_ONS_TYPE.ACTS_OF_NATURE ) ) ? "col-md-6":"col-md-12") }>
                                        <div className="custom-control custom-checkbox check-box-small col-md-12">
                                            <input checked={addonItem.is_selected} id="Individual" type="checkbox" className="custom-control-input " name="Buyer Type" onChange={(event) => {
                                                onChangeCheckbox(addonItem, event)
                                            }} disabled={addonItem.id === 3 && allState?.insurance?.leadDetail?.addon_detail?.find((e: any) => e.id === 3 && e.seat_capacity)}/>
                                            <label className="custom-control-label checkbox-label-txt"><span>{addonItem.name}</span></label>
                                        </div>
                                    </fieldset>
                                    {
                                        addonItem?.is_selected  &&
                                        <>
                                            <div className='addon-box-parent'>

                                                {addonItem.coverage_list?.length > 0 &&
                                                    <ReactSelect 
                                                        options={addonItem.coverage_list || []} 
                                                        value={addonItem.coverage_amt ? { label: addonItem.coverage_amt, value: addonItem.coverage_amt } : null} 
                                                        handleChange={(e: any, fieldKey: string) => onValueSelect(addonItem, e)} placeholder="Choose Coverage Amount" 
                                                        fieldKey="" 
                                                        isDisabled={!isComprensive ? true : false} fieldSetClassName="col-md-4"
                                                    />
                                                }

                                                {addonItem.type === 'pa' && <>
                                                    <ReactSelect 
                                                        options={getSeatingCapacityList(addonItem)}
                                                        placeholder="Seating Capacity"
                                                        isDisabled={(props.versionSeat || allState?.insurance?.leadDetail?.addon_detail?.find((e: any) => e.id === 3 && e.seat_capacity))}
                                                        value={props.versionSeat ? { label: props.versionSeat, value: props.versionSeat } : addonItem.seat_capacity ? { label: addonItem.seat_capacity, value: addonItem.seat_capacity } : null}
                                                        handleChange={(e: any) => saveSeatingValue(addonItem, e) } fieldSetClassName="col-md-4"
                                                    />

                                                    <ReactSelect 
                                                        options={getExtraSeatingCapacityList(addonItem)}
                                                        placeholder="Extra Seating Capacity"
                                                        isDisabled={allState?.insurance?.leadDetail?.pa_extra_seating_capacity}
                                                        value={addonItem?.extra_seat_value ? { label: addonItem.extra_seat_value, value: addonItem.extra_seat_value } : null}
                                                        handleChange={(e: any) => saveExtraSeatingValue(addonItem, e)} fieldSetClassName="col-md-4"
                                                    />
                                                </>
                                                }

    
                                                {
                                                    getPremiumTitle(addonItem, 'title')?.length > 0 &&
                                                    <React.Fragment>
                                                    <fieldset className="form-filed">
                                                            <span>{getPremiumTitle(addonItem, 'title')}</span>
                                                            <span>{getPremiumTitle(addonItem, 'value')}</span>
                                                    </fieldset>
                                                    </React.Fragment>
                                                }
                                            </div>


                                            {
                                                isShowPremiumInputBox(addonItem) && <fieldset className="form-filed col-md-4">
                                                    <div className="material">
                                                        <input id="" type="text" placeholder="" name="name" className="form-input" value={getPremiumAmount(addonItem)} onChange={(event) => {
                                                            onChangePremiumAmount(addonItem, event.target.value);
                                                        }} />
                                                        <label data-label={"Premium Amount"} className="form-label"></label>
                                                    </div>
                                                </fieldset>
                                            }  

                                            {
                                                addonItem.aon_rate ?
                                                    <div className="addon-already-included">
                                                        <span> Already Included</span>
                                                    </div> : null
                                            }
                                            {
                                                isShowAlreadyIncluded(addonItem) ?
                                                    <div className="addon-already-included">
                                                        <span>{"Already Included"}</span>
                                                    </div> : null
                                            }

                                        </>
                                    }
                                </div>
                            )
                        }
                        </div>
                        <div className="form-group-bx">
                            <div className="row">
                                <fieldset className="form-filed col-md-12 culcated-premimum-txt">
                                    <div className="col-md-6">
                                        <label>Total FMV</label>
                                        <span>{calculateTotalFMV()}</span>
                                    </div>
                                    {
                                        isComprensive ?
                                        <div className="col-md-6">
                                            <label>Total Add on Premium</label>
                                            <label>{calculateTotalPremiumValue()}</label>
                                        </div>
                                        : null 
                                    }
                                   
                                </fieldset>
                            </div>
                        </div>
                        
                    </div> : null
            }
        </div >
    )
}

export default InsuranceForm