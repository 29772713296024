import React, { useEffect, useState } from 'react';
import { Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getDashboardCount } from '@src/store/slices/common';
import { useDispatch } from 'react-redux'
import secureStorage from "@src/config/encrypt";
import { toast } from 'react-toastify';
import FullPageLoader from '@src/common/FullPageLoader';
const InsurancePaymentIcon = require('../styles/webroot/images/insurance-payment.svg');

type CommonCountType = {
    count: number,
    name: string,
    status: number,
    substatus: number
}
type DashboardCountType = {
    pending_app: CommonCountType,
    new_app: CommonCountType,
    ins_broker: CommonCountType,
    soft_approval: CommonCountType,
    ins_policy: CommonCountType,
    ins_payment: CommonCountType,
    incentive_payment: CommonCountType,
    lost: CommonCountType,
}


export default function Dashboard(props: any): ReturnType<React.FC> {
    let [dashboardCount, setDashboardCount] = useState<DashboardCountType>();
    let [loading, setLoading] = useState<boolean>(false)
    const [name, setName] = useState("");
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [redirect, setRedirect] = useState(false);
    const [role, setRole] = useState("");
    /* eslint-enable @typescript-eslint/no-unused-vars */
    let [searchValue, setSearchValue] = useState<string>()
    const navigate = useNavigate();
    const dispatch: any = useDispatch()

    useEffect(() => {
        let result = secureStorage.getItem("authUserInfo");

        if (!result) {
            setRedirect(true)
        } else {
            let userInfo = result.user_data;
            setName((userInfo && userInfo.name) || '');
            setRole(userInfo && userInfo.role_title);

        }
    }, []);

    const setNewState = () => {
        //     dispatch(userNameSet("Insurance Box"))
    }

    const countClickHandler = (status: any, substatus: any, count: any, filterType: any="") => {
        substatus = substatus.toString();
        if (count !== 0) {
            navigate({
                pathname: '/insurance-list',
                search: `?status_id_arr=${status}&sub_status_id_arr=${substatus}${filterType? `&filterType=${filterType}` : '' }`,
            });
        }

    }

    const DashboardCount = () => {
        setLoading(true);
        dispatch(getDashboardCount()).then((res: any) => {
            setLoading(false)
            if (res && res.status === 200) {
                setDashboardCount(res.data);
            }
        }).catch((err: Error) => {
            setLoading(false)
        })
    }

    const searchClickHandler = () => {
        if (searchValue) {
            if (searchValue.length <= 10) {
                navigate({
                    pathname: '/insurance-list',
                    search: `?searchKeyword=${searchValue}`,
                });
            } else {
                toast.error("Search value should not be greater than 10 digit");
            }
        } else {
            toast.error("Please enter insurance id or mobile number");
        }
    }

    useEffect(() => {
        DashboardCount();
        // eslint-disable-next-line
    }, [])

    const onChangeHandler = (value: string) => {
        setSearchValue("");
        let pattern = /^[0-9]*$/;

        if (value.trim() && pattern.test(value)) {
            setSearchValue(value);
        }
    }

    return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <div className="dashboard-bg">
                <div className="dashboard-outer">
                    <div className="heading">
                        <span className="subheading">Hello {name}</span>
                        <h1 onClick={setNewState}>Welcome to Insurance Box</h1>
                    </div>
                    <div className="search-bx">
                        <input name="search" value={searchValue} onChange={(e: any) => onChangeHandler(e.target.value)} type="text" placeholder="Search by Insurance Id or Mobile" maxLength={10} />
                        <button className="search-btn" onClick={searchClickHandler}>Search</button>
                        <i className="ic-search"></i>
                    </div>
                    <div className="dashboard-toggle-tab">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="dashboard-card">
                                        <div className="card color-one" >
                                            <div className="roung-bg">
                                                <i className="ic-pending-application">
                                                    <i className="path1"></i>
                                                    <i className="path2"></i>
                                                    <i className="path3"></i>
                                                    <i className="path4"></i>
                                                    <i className="path5"></i>
                                                    <i className="path6"></i>
                                                    <i className="path7"></i>
                                                    <i className="path8"></i>
                                                    <i className="path9"></i>
                                                    <i className="path10"></i>
                                                </i>
                                            </div>
                                            <span className="count-txt" onClick={() => countClickHandler(dashboardCount?.pending_app?.status, dashboardCount?.pending_app?.substatus, dashboardCount?.pending_app?.count, "pending")}>{dashboardCount?.pending_app?.count}</span>
                                            <span className="card-txt">{dashboardCount?.pending_app?.name}</span>
                                        </div>
                                        <div className="card color-two">
                                            <div className="roung-bg">
                                                <i className="ic-application"></i>
                                            </div>
                                            <span className="count-txt" onClick={() => countClickHandler(dashboardCount?.new_app?.status, dashboardCount?.new_app?.substatus, dashboardCount?.new_app?.count)}>{dashboardCount?.new_app?.count}</span>
                                            <span className="card-txt">{dashboardCount?.new_app?.name}</span>
                                        </div>

                                        <div className="card color-three">
                                            <div className="roung-bg">
                                                <i className="ic-financier">
                                                    <i className="path1"></i>
                                                    <i className="path2"></i>
                                                    <i className="path3"></i>
                                                    <i className="path4"></i>
                                                    <i className="path5"></i>
                                                    <i className="path6"></i>
                                                    <i className="path7"></i>
                                                    <i className="path8"></i>
                                                    <i className="path9"></i>
                                                    <i className="path10"></i>
                                                </i>
                                            </div>
                                            <span className="count-txt " onClick={() => countClickHandler(dashboardCount?.ins_broker?.status, dashboardCount?.ins_broker?.substatus, dashboardCount?.ins_broker?.count)}>{dashboardCount?.ins_broker?.count}</span>
                                            <span className="card-txt">{dashboardCount?.ins_broker?.name}</span>
                                        </div>

                                        <div className="card color-four">
                                            <div className="roung-bg">
                                                <i className="ic-softapproved">
                                                    <i className="path1"></i>
                                                    <i className="path2"></i>
                                                    <i className="path3"></i>
                                                    <i className="path4"></i>
                                                    <i className="path5"></i>
                                                    <i className="path6"></i>
                                                    <i className="path7"></i>
                                                    <i className="path8"></i>
                                                </i>
                                            </div>
                                            <span className="count-txt " onClick={() => countClickHandler(dashboardCount?.soft_approval?.status, dashboardCount?.soft_approval?.substatus, dashboardCount?.soft_approval?.count)}>{dashboardCount?.soft_approval?.count}</span>
                                            <span className="card-txt">{dashboardCount?.soft_approval?.name}</span>
                                        </div>

                                        <div className="card color-five">
                                            <div className="roung-bg">
                                                <i className="ic-softapproved">
                                                    <i className="path1"></i>
                                                    <i className="path2"></i>
                                                    <i className="path3"></i>
                                                    <i className="path4"></i>
                                                    <i className="path5"></i>
                                                    <i className="path6"></i>
                                                    <i className="path7"></i>
                                                    <i className="path8"></i>
                                                </i>
                                            </div>
                                            <span className="count-txt " onClick={() => countClickHandler(dashboardCount?.ins_policy?.status, dashboardCount?.ins_policy?.substatus, dashboardCount?.ins_policy?.count)}>{dashboardCount?.ins_policy?.count}</span>
                                            <span className="card-txt">{dashboardCount?.ins_policy?.name}</span>
                                        </div>

                                        <div className="card color-two">
                                            <div className="roung-bg insurance-payment-icon">
                                                <img src={InsurancePaymentIcon.default} alt=""/>
                                            </div>
                                            <span className="count-txt " onClick={() => countClickHandler(dashboardCount?.ins_payment?.status, dashboardCount?.ins_payment?.substatus, dashboardCount?.ins_payment?.count)}>{dashboardCount?.ins_payment?.count}</span>
                                            <span className="card-txt">{dashboardCount?.ins_payment?.name}</span>
                                        </div>

                                        <div className="card color-three">
                                            <div className="roung-bg">
                                                <i className="ic-disbursed">
                                                    <i className="path1"></i>
                                                    <i className="path2"></i>
                                                    <i className="path3"></i>
                                                    <i className="path4"></i>
                                                </i>
                                            </div>
                                            <span className="count-txt " onClick={() => countClickHandler(dashboardCount?.incentive_payment?.status, dashboardCount?.incentive_payment?.substatus, dashboardCount?.incentive_payment?.count)}>{dashboardCount?.incentive_payment?.count}</span>
                                            <span className="card-txt">{dashboardCount?.incentive_payment?.name}</span>
                                        </div>

                                        <div className="card color-four">
                                            <div className="roung-bg">
                                                <i className="ic-lost">
                                                    <i className="path1"></i>
                                                    <i className="path2"></i>
                                                    <i className="path3"></i>
                                                    <i className="path4"></i>
                                                </i>
                                            </div>
                                            <span className="count-txt " onClick={() => countClickHandler(dashboardCount?.lost?.status, dashboardCount?.lost?.substatus, dashboardCount?.lost?.count)}>{dashboardCount?.lost?.count}</span>
                                            <span className="card-txt">{dashboardCount?.lost?.name}</span>
                                        </div>


                                    </div>
                                </Tab.Pane>

                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

