import Modal from "@src/common/ModalPopup"
import { useState } from "react"

export default function ResubmissionModal(props: any) {
    let { show, handleClose, handleBtnClick, setResubmissionContent } = props
    let [comment, setComment] = useState<any>('')

    const handleInputChange = (value: any) => {
        setComment(value)
        setResubmissionContent(value)
    }

    return (
        <div className="mark-lost-popup">
            <Modal show={show} handleClose={handleClose} >
                <div className="modal-header">
                    <h2>Request for Re-submission</h2>
                </div>
                <div className="modal-body" >
                    <fieldset className="form-filed">
                        <div className="material">
                            <textarea placeholder=" " className="form-input" rows={4} onChange={(e) => handleInputChange(e.target.value)}></textarea>
                            <label data-label="Comment" className="form-label"></label>
                        </div>
                    </fieldset>
                    {
                        comment ?
                            <button className="btn-primary" onClick={() => handleBtnClick('resubmission')}>Submit</button>
                            : null
                    }
                </div>
            </Modal>
        </div>
    )
}