import FullPageLoader from '@src/common/FullPageLoader';
import { useState } from 'react';
import DocumentTypeTab from '@src/component/insurance/common/DocumentTypeTab';


const DocumentUpload = () => {
    let [loading, setLoading] = useState<boolean>(false)

    const setLoader = (loader: boolean) => {
        setLoading(loader)
    }

    return (
        <>
            <FullPageLoader show={loading} />
            <div className="document-upload-tabs" >
                <div className="doument-upload-left-panel">
                    <div className="heading-txt">
                        <h2>Upload Documents</h2>
                    </div>
                    <DocumentTypeTab callFrom='docUpload' setLoader={setLoader} />
                </div >
            </div >
        </>

    )

}
export default DocumentUpload;