import React from 'react';
import DynamicFormField from './DynamicFormField';
import { useEffect ,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  getDealesList } from '@src/store/slices/common';
import { APP_TYPE_RENEWAL, APP_TYPE_STANDALONE } from '@src/config/constant';

const OtherDetails = (props:any) => {
    const dispatch: any = useDispatch();
    const commonState = useSelector((state: any) => state.common);
    // eslint-disable-next-line
    const [dealerData, setDealerData] = useState(undefined) 

    useEffect(() => {
        // For standalone dealer list show only
        if ([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(+props?.apptype)) {
            if (commonState?.getDealerList?.length) {
                setDealerData(commonState?.getDealerList)
                fetchFormConfig({ dealerData: commonState?.getDealerList });

            } else {
                dispatch(getDealesList()).then((resp: any) => {
                    if (resp?.status === 200) {
                        setDealerData(resp.data);
                        fetchFormConfig({ dealerData: resp?.data });
                    }
                });
            }
        }
        // eslint-disable-next-line
    }, [props?.apptype]);

    const fetchFormConfig = async (params: any) => {
        let tempList: any = props?.sectionItemAll;
        // eslint-disable-next-line
        tempList.map((sectionItem: any) => {
            // eslint-disable-next-line
            sectionItem?.blocks?.map((blockItem: any) => {
                // eslint-disable-next-line
                blockItem?.fields?.map((fieldItem: any) => {
                    if (fieldItem.name === 'other_detail_dealer_id') {
                        fieldItem['options'] = params.dealerData
                    }
                })
            })
        })
        props.updateSectionList([...tempList]);
    }

    return (
        <div className="loan-filed-sec">
            <div className="form-group-bx">
                <div className="row">
                    {
                        props?.sectionItem?.blocks?.map((blockItem:any,index:number) =>
                            <React.Fragment key={index}>
                                <div className="col-md-12">
                                    <h3>{blockItem.title}</h3>
                                </div>
                                <DynamicFormField
                                    fields={blockItem?.fields}
                                    applicationType={props.applicationType}
                                    apptype={props.apptype}
                                />
                            </React.Fragment>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default OtherDetails