import React from 'react';
import { useSelector } from 'react-redux';
import InputField from "@src/common/InputField"
import LeadRemarksButton from '../common/LeadRemarksButton';
import {INS_TYPE_CTPL} from '@src/config/constant';

export default function LoanExpectedRightPanel(props: any): ReturnType<React.FC>{   
    const commonState = useSelector((state: any) => state.insurance);  
    let {make_name,model_name,version_name,make_year,tenure,car_fmv,color,plate_number,machine_number,chassis_number,vehicle_seat,fuel_type,transmission,vehicle_type,mv_number,insurance_type_id,vehicle_usage
    } = commonState && commonState?.leadDetail
    let disable  = true;

    return (
        <div className="loan-form">


            <div className="form-group-bx">
                <h2>Vehicle Details</h2>
                <div className="row">
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"Make",
                                    name:"Make",
                                    className:"form-input",
                                    type:"text",
                                    value:make_name || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Make" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"Model",
                                    name:"Model",
                                    className:"form-input",
                                    type:"text",
                                    value:model_name || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Model" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"Variant",
                                    name:"Variant",
                                    className:"form-input",
                                    type:"text",
                                    value:version_name || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Variant" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"mfg_year",
                                    name:"mfg_year",
                                    className:"form-input",
                                    type:"text",
                                    value:make_year || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Manufacturing Year" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"vehicle_usage",
                                    name:"vehicle_usage",
                                    className:"form-input",
                                    type:"text",
                                    value:vehicle_usage || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Vehicle Usage" className="form-label"></label>
                        </div>
                    </fieldset>
                    {
                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <InputField
                                    inputProps={{
                                        id: "tenure",
                                        name: "tenure",
                                        className: "form-input",
                                        type: "text",
                                        value: tenure || '',
                                        maxLength: '',
                                        disabled: disable
                                    }}
                                    onChange={''}
                                />
                                <label data-label="Tenure(In Year)" className="form-label"></label>
                            </div>
                        </fieldset>
                    }
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"vehicle_type",
                                    name:"vehicle_type",
                                    className:"form-input",
                                    type:"text",
                                    value: props.vehicleTypeList.filter((ob:any)=>ob.value ===vehicle_type)?.[0]?.['label'],
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Vehicle Type" className="form-label"></label>
                        </div>
                    </fieldset>

                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"fmv_car",
                                    name:"fmv_car",
                                    className:"form-input",
                                    type:"text",
                                    value:car_fmv || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="FMV" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"color",
                                    name:"color",
                                    className:"form-input",
                                    type:"text",
                                    value:color || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Color" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"plate_no",
                                    name:"plate_no",
                                    className:"form-input",
                                    type:"text",
                                    value:plate_number || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Plate Number" className="form-label"></label>
                        </div>
                    </fieldset>
                    {
                       (insurance_type_id===INS_TYPE_CTPL && mv_number) && <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <InputField 
                                    inputProps={{
                                        id:"mv_number",
                                        name:"mv_number",
                                        className:"form-input",
                                        type:"text",
                                        value:mv_number || '',
                                        maxLength:'',
                                        disabled:disable
                                    }} 
                                    onChange={''}
                                />
                                <label data-label="MV File Number" className="form-label"></label>
                            </div>
                        </fieldset>
                    }
                    
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"machine_number",
                                    name:"machine_number",
                                    className:"form-input",
                                    type:"text",
                                    value:machine_number || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Engine Number" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"chassis_number",
                                    name:"chassis_number",
                                    className:"form-input",
                                    type:"text",
                                    value:chassis_number || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Chassis Number" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"seat_capacity",
                                    name:"seat_capacity",
                                    className:"form-input",
                                    type:"text",
                                    value:vehicle_seat || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Seating Capacity" className="form-label"></label>
                        </div>
                    </fieldset>

                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"fuel_type",
                                    name:"fuel_type",
                                    className:"form-input",
                                    type:"text",
                                    value:fuel_type || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Fuel" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <InputField 
                                inputProps={{
                                    id:"transmission",
                                    name:"transmission",
                                    className:"form-input",
                                    type:"text",
                                    value:transmission || '',
                                    maxLength:'',
                                    disabled:disable
                                }} 
                                onChange={''}
                            />
                            <label data-label="Transmission" className="form-label"></label>
                        </div>
                    </fieldset>
                </div>
            </div>


            {commonState?.leadDetail?.leadId && <LeadRemarksButton leadId={commonState.leadDetail.leadId} />}


        </div>


    )
}
