import LoanRibbenBox from './LoanRibbenBox';
import LeadDetailTabs from '../detail/LoanFiledTabs';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { updateLeadDetailsInStore, setInboundPaymentFromResponse, fetchLeadData, fetchDocuments } from '../../../store/slices/insurance';
import { getDocumentListing } from '../../../store/slices/common';
import FullPageLoader from '../../../common/FullPageLoader';
import { getMasterConfig, getStateCityList } from '@src/store/slices/common';
import { useDispatch, useSelector } from 'react-redux';
import { INBOUND_PAYMENT_FROM_CUSTOMER, INBOUND_PAYMENT_FROM_SALES_TEAM, INBOUND_PAYMENT_FROM_BROKER, STATUS_SUB_STATUS, API_TYPES, INBOUND_PAYMENT_FROM_OTO_INSURANCE, INBOUND_PAYMENT_FROM_INSURANCE_PARTNER, APP_TYPE_AGENCY } from "@src/config/constant";


export default function Details(props: any): ReturnType<React.FC> {
    const queryParams = useParams();
    const dispatch: any = useDispatch();
    const insuranceState = useSelector((state: any) => state.insurance);
    const commonState = useSelector((state: any) => state.common);

    let [leadHashId, setLeadHashId] = useState<string>();
    let [loading, setLoading] = useState<boolean>(false);

    let [apiCallFlag, setApiCallFlag] = useState(false);
    // let [leadDetailUpdated, setLeadDetailUpdated] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {

        getDetails();
        // if (Object.keys(insuranceState.leadDetail).length === 0) {
        // }


        return () => {
            dispatch(updateLeadDetailsInStore({}));
            dispatch(setInboundPaymentFromResponse(''));

        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!apiCallFlag) {
            setApiCallFlag(true);
            if (Object.keys(commonState.common).length === 0) {
                dispatch(getMasterConfig());
            }
            if ((commonState.state).length === 0 || (commonState.city).length === 0) {
                dispatch(getStateCityList());
            }
        }
    }, [dispatch, commonState, apiCallFlag]);


    const getDetails = async () => {
        setLoading(true);
        if (queryParams && queryParams.id) {
            setLeadHashId(queryParams.id);
            const documents:any = await fetchAllDocuments();

            (fetchLeadData({ "lead_id": queryParams.id, apiType: API_TYPES['customerData'] })).then((res: any) => {
                
                setLoading(false);
                if (res && res.status === 200) {
                    let isPageAccess = false;
                    // console.log(queryParams.type, res.data.sub_status_id, STATUS_SUB_STATUS['subStatus']['payment_from_broker'])
                    
                    if(res.data && !Object.keys(res.data).length) {
                        navigate('/not-found')
                        return false
                    }

                    if (queryParams && queryParams.type && res.data) {
                        if (queryParams.type === "sent-to-insurance-broker" && res.data.sub_status_id >= STATUS_SUB_STATUS['subStatus']['application_created']) {
                            isPageAccess = true;
                        } else if (queryParams.type === "soft-approval-received" && res.data.sub_status_id >= STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker']) {
                            isPageAccess = true;
                        } else if (queryParams.type === "insurance-policy-generated" && res.data.sub_status_id >= STATUS_SUB_STATUS['subStatus']['soft_approval_received']) {
                            isPageAccess = true;
                        } else if (queryParams.type === "insurance-policy-shared" && res.data.sub_status_id >= STATUS_SUB_STATUS['subStatus']['policy_generated']) {
                            isPageAccess = true;
                        } else if (queryParams.type === "payment-customer" && res.data.sub_status_id >= STATUS_SUB_STATUS['subStatus']['payment_from_customer']) {
                            isPageAccess = true;
                        } else if (queryParams.type === "payment-sales-team" && res.data.sub_status_id >= STATUS_SUB_STATUS['subStatus']['payment_from_sales_team']) {
                            isPageAccess = true;
                        } else if (queryParams.type === "payment-broker" && res.data.sub_status_id >= STATUS_SUB_STATUS['subStatus']['payment_from_broker']) {
                            isPageAccess = true;
                        } else if (queryParams.type === "payment-to-broker" && res.data.sub_status_id >= STATUS_SUB_STATUS['subStatus']['payment_from_customer']) {
                            isPageAccess = true;
                        } else if (queryParams.type === "payment-to-sales-team" && res.data.sub_status_id >= STATUS_SUB_STATUS['subStatus']['payment_from_customer']) {
                            isPageAccess = true;
                        } 
                        else if (queryParams.type === "payment-from-customer-to-partner-broker" && res.data.pay_from_cust_to_partner_broker_id) {
                            isPageAccess = true;
                        }
                    }

                    let allDocuments = documents?.data || []
                    dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...res.data, images: allDocuments, customer_api_call: true }))

                    // setInsuranceDetails(res.data);
               
                    const payload:any = {
                        leadId: queryParams.id,
                        source: 'web'
                      };

                    if(res?.data?.application_type_id && res.data.application_type_id === APP_TYPE_AGENCY) {
                        payload['application_type_id'] = APP_TYPE_AGENCY
                    }

                    dispatch(getDocumentListing(payload));
                    let getInboundPaymentFrom = res.data?.inbound_payment_from || res.data?.pay_from_cust_to_partner_broker_payment_from;
                    if (+getInboundPaymentFrom === INBOUND_PAYMENT_FROM_CUSTOMER) {
                        getInboundPaymentFrom = "customer";
                    } else if (+getInboundPaymentFrom === INBOUND_PAYMENT_FROM_SALES_TEAM) {
                        getInboundPaymentFrom = "sales_team";
                    } else if (+getInboundPaymentFrom === INBOUND_PAYMENT_FROM_BROKER) {
                        getInboundPaymentFrom = "broker";
                    } else if (+getInboundPaymentFrom === INBOUND_PAYMENT_FROM_OTO_INSURANCE) {
                        getInboundPaymentFrom = "oto_insurance";
                    } else if (+getInboundPaymentFrom === INBOUND_PAYMENT_FROM_INSURANCE_PARTNER) {
                        getInboundPaymentFrom = "insurance_partner";
                    }
                    if (getInboundPaymentFrom) {
                        dispatch(setInboundPaymentFromResponse(getInboundPaymentFrom));
                    }

                    // IF NOT AUTHORIZED 
                    if (queryParams && queryParams.type && !isPageAccess && !["personal-details", "residential-details", "vehicle-details"].includes(queryParams.type)) {
                        navigate(`/details/personal-details/${queryParams.id}`);
                        return false;
                    }

                    // setLeadDetailUpdated(true)

                }
            }).catch((err: any) => {
                setLoading(false)
                // setLeadDetailUpdated(true)

            });
        }
    }

    const fetchAllDocuments = async () => {
        if (queryParams && queryParams.id) {
            const result: any = insuranceState?.leadDetail?.images || await fetchDocuments({ lead_id: queryParams.id })
            return result
        }
        return null
    }

    return (
        <div>
            <FullPageLoader show={loading} />
            <div className="container-fluid">
                <LoanRibbenBox />
                <LeadDetailTabs getDetails={() => getDetails()} leadHashId={leadHashId} />
            </div >
        </div>
    )

}

