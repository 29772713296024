import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as HELPER from '@src/config/helper';
import { ROLE_FEATURES, REGEX_EMAIL_VALIDATION, SOURCE_WEB, ENCRYPTED_AGENCY_FIELDS, AGENCY_STATUS, AGENCY_DOC_STATUS, AGENCY_SOURCE } from '@src/config/constant';
import ReactSelect from '@common/ReactSelect';
import { addAgency, addAgencyDocument, deleteAgencyDocument, getAgencyDetailData, approveAgency } from '@src/store/slices/agency';
import { bankList } from '@src/store/slices/common';
import { useDispatch, useSelector } from 'react-redux';
import InputField from "../../../common/InputField";
import { getPartnerMappingList } from '@src/store/slices/partner';
import DocResubmitModal from './DocResubmitModal';
import ImageSlider from './ImageSlider';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import ConfirmPopUp from '@src/common/ConfirmPopUp';
import FullPageLoader from '@src/common/FullPageLoader';
import { Tab, Nav } from 'react-bootstrap';
import { approveAgencyDocument, updateAgencyStatus } from '@src/store/slices/agency';
import AgencyCommentModal from './AgencyCommentModal';

export default function AddEditInsuranceAgency(props: any): ReturnType<React.FC> {

    let [agencyBanksLists, setAgencyBanksList] = useState<any>([]);
    let initialInputState = {
        agency_name: '',
        email: '',
        mobile: '',
        tin_id_no: '',
        bank_name: '',
        branch_name: '',
        account_no: '',
        account_name: '',
        images: [],
        partner_id: [],
        source: '',
        doc_status: ''
    };

    let [selectedInput, setSelectedInput] = useState<any>(initialInputState)
    let [errors, setErrors] = useState<any>({})
    let [previewImages, setPreviewImages] = useState<any>([]);
    let [insuranceCompanies, setInsuranceCompanies] = useState<string[]>([]);
    let [resubmitDocPopup, setResubmitDocPopup] = useState(false);
    let [imageSlider, setImageSlider] = useState(false);
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const [trigger, setTrigger] = useState(0);
    const [basicDetailsName, setBasicDetailsName] = useState('Agent')
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    let [loading, setLoading] = useState<boolean>(false)
    const [disableField, setDisableField] = useState(false);
    const [activeImageIndex, setActiveImageIndex] = useState(-1);
    const [actionType, setActionType] = useState<any>('');
    const [pendingAction, setPendingAction] = useState<any>(null);
    const [handleType, setHandleType] = useState('');
    const [viewTimeLine, setViewTimeLine] = useState(false);

    const [editForm, setEditForm] = useState(true);
    const navigate = useNavigate();
    const queryParams = useParams();

    const dispatch: any = useDispatch();
    const commonState = useSelector((state: any) => state.common);
    const agency_id = queryParams.id//agency_id;
    /**
     * function to handle change in input field values
     * @param name 
     * @param value 
     */
    const handleChange = (name: string, value: any) => {
        setSelectedInput((prev: any) => ({
            ...prev,
            [name]: value.value
        }));

        setErrors((prevErrors: any) => ({
            ...prevErrors,
            [`${name}`]: ''
        }));

        errors[name] = '';
        setErrors({ ...errors });
    }

    const handleInputChange = (name: string, event: any) => {
        setSelectedInput((prev: any) => ({
            ...prev,
            [name]: event.target.value
        }));

        setErrors((prevErrors: any) => ({
            ...prevErrors,
            [`${name}`]: ''
        }));

        errors[name] = '';
        setErrors({ ...errors });
    }

    /**
     * function to submit api request
     */
    const handleSubmit = async () => {
        try {
            let errorExists = checkValidation()
            if (!errorExists) {
                if (selectedInput?.partner_id.length === 0) {
                    toast.error('Please select insurance partner.')
                    return;
                }
                setLoading(true);
                let params: any = {}
                params.agency_name = selectedInput?.agency_name
                params.email = selectedInput?.email
                params.mobile = selectedInput?.mobile
                params.tin_id_no = selectedInput?.tin_id_no
                params.bank_name = +selectedInput?.bank_name
                params.branch_name = selectedInput?.branch_name
                params.account_no = selectedInput?.account_no
                params.account_name = selectedInput?.account_name
                params.partner_id = selectedInput?.partner_id
                params.source = SOURCE_WEB
                params.type = (basicDetailsName === "Agency" ? 1 : 2)
                //for edit case agency_id will append
                if (agency_id) {
                    params.agency_id = agency_id
                }


                const documentFormData = new FormData();
                for (const key in selectedInput) {
                    let value = selectedInput[key];
                    if (key === 'images' && value?.length) {
                        value.forEach((file: File) => {
                            documentFormData.append('images', file);
                        });
                    }
                }

                const fields = ENCRYPTED_AGENCY_FIELDS;
                fields.forEach(field => {
                    if (params?.[field]) {
                        params[field] = HELPER.EncodetoBase64(params[field]);
                    }
                });
                const res: any = await addAgency(params);
                if (res && res.status === 200) {
                    toast.success(res.message)
                    if (selectedInput?.images?.length > 0) {
                        documentFormData.append('agency_id', res.data.hashId);
                        documentFormData.append('source', "" + AGENCY_SOURCE['web']);
                        addAgencyDocument(documentFormData).then((res: any) => {
                            if (res && res.status === 200) {
                                setTrigger(trigger + 1);
                            }
                        }).catch(err => setLoading(false))
                    } else {
                        //REFRESH PAGE
                        if (agency_id) {
                            setTrigger(trigger + 1);
                        }
                    }
                    //NAVIGATE TO EDIT PAGE AFTER CREATE
                    if (!agency_id) {
                        navigate('/agency/edit/' + res.data.hashId);
                    }
                    resetInputFields();
                    setEditForm(!editForm)
                } else {
                    setLoading(false);
                }

            }
        } catch (err) {
            setLoading(false)
        }
    }


    const handleFileInputChange = async (e: any) => {
        let selectedFiles = e.target.files;
        let validFiles: File[] = [];
        let imagePreview: any = [];
        let totalLength = selectedFiles.length + (previewImages?.length || 0)

        if (totalLength > 5) {
            toast.error("Maximum 5 Documents are allowed")
            return;
        }

        for (const file of selectedFiles) {
            let allowedExtensions = ['data:application/pdf']
            let base64PreviewImg: any = await HELPER.toBase64(file);
            let filetype = await HELPER.detectMimeType(base64PreviewImg, { allowedExtensions });
            let fileSize = file.size;

            if (!filetype) {
                toast.error("Please Select a valid file");
            } else if (fileSize < 25000 || fileSize > 5000000) {
                toast.error('file size should be between 25kb to 5mb');
            } else {
                validFiles.push(file);
                //previewImages.push(base64PreviewImg);
                imagePreview.push({ url: base64PreviewImg, type: file.type.split('/')[1] });
            }
        }

        if (validFiles.length > 0) {
            setSelectedInput((prev: any) => ({
                ...prev,
                images: [...(prev.images || []), ...validFiles]
            }));
            setPreviewImages((prev: any) => [...prev, ...imagePreview]);
        }

        e.target.value = null;

        errors['images'] = ''
        setErrors(errors)

    }


    const handleCheckboxChange = (id: number) => {
        setSelectedInput((prev: any) => {
            const isChecked = prev.partner_id.includes(id);
            const updatedPartnerIds = isChecked
                ? prev.partner_id.filter((companyId: number) => companyId !== id)
                : [...prev.partner_id, id];

            // Ensure partner_id is always an array, even if it's empty
            const partner_id = Array.isArray(updatedPartnerIds) ? updatedPartnerIds : [updatedPartnerIds];

            return { ...prev, partner_id };
        });
    };

    const checkValidation = (): boolean => {
        let error = false
        setErrors({})
        // Regex for email validation
        if (!selectedInput.agency_name || selectedInput.agency_name === '') {
            errors['agency_name'] = 'agency name is required';
            error = true;
        }
        if (!selectedInput.account_no || selectedInput.account_no === '') {
            errors['account_no'] = 'account number is required';
            error = true;
        }
        if (!selectedInput.bank_name || selectedInput.bank_name === '') {
            errors['bank_name'] = 'bank name is required';
            error = true;
        }
        if (!selectedInput.email || selectedInput.email === '') {
            errors['email'] = 'email is required';
            error = true;
        } else if (!REGEX_EMAIL_VALIDATION.test(selectedInput.email)) {
            errors['email'] = 'Please enter a valid email';
            error = true;
        }

        if (!selectedInput.mobile || selectedInput.mobile === '') {
            errors['mobile'] = 'mobile number is required';
            error = true;
        } else if (selectedInput.mobile[0] !== '9' || selectedInput.mobile.length !== 10) {
            errors['mobile'] = 'No. should start from 9 and be 10 digits long';
            error = true;
        }
        if (!selectedInput.tin_id_no || selectedInput.tin_id_no === '') {
            errors['tin_id_no'] = 'tin id no is required';
            error = true;
        }
        if (!selectedInput.account_name || selectedInput.account_name === '') {
            errors['account_name'] = 'Account name is required';
            error = true;
        }
        if (!selectedInput?.images?.length && !selectedInput?.documents?.length) {
            errors['images'] = 'document is required'
            error = true;
        }
        setErrors({ ...errors });
        return error
    }

    const resetInputFields = () => {
        setSelectedInput(initialInputState);
        setPreviewImages([]);
    }

    useEffect(() => {
        if (!commonState?.partner_list?.length) {

            let filters = {
                page_number: 1,
                pagination: false,
                namesOnly: true,
                status: 1,
                application_type_id : 4
            };
            if (!agency_id) setLoading(true);
            dispatch(getPartnerMappingList(filters)).then((res: any) => {
                if (!agency_id) setLoading(false);

                if (res && res.status === 200) {
                    const partnerMappingList = (res && res.data) || [];
                    setInsuranceCompanies(partnerMappingList);
                }
            }).catch((err: Error) => {
                if (!agency_id) setLoading(false);

                console.error(err);
            });
        } else {
            setInsuranceCompanies(commonState?.partner_list);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!commonState?.agencyBankList?.length) {
            dispatch(bankList()).then((res: any) => {
                if (res && res.status === 200) {
                    setAgencyBanksList((prev: any) => [...prev, ...res.data]);
                }
            })
        } else {
            setAgencyBanksList(commonState?.agencyBankList);
        }
        // eslint-disable-next-line
    }, []);

    const handleResubmitDoc = (param: any) => {
        setHandleType(param)
        setResubmitDocPopup(!resubmitDocPopup)
    }

    const showImagePopup = (activeImg: number) => {
        setImageSlider(!imageSlider)
        setActiveImageIndex(activeImg)
    }

    useEffect(() => {
        // Check if 'agency_id' parameters are present
        if (agency_id) {
            setLoading(true);
            setEditForm(false)
            dispatch(getAgencyDetailData({ agency_id: agency_id, source: SOURCE_WEB })).then((res: any) => {
                setLoading(false)
                if (res && res.status === 200) {
                    setDisableField(true);
                    const agencyData = res.data;
                    const partner_ids = agencyData?.partner_map?.map((partner: any) => partner.partner_id);
                    const documentPaths = agencyData?.document_data?.map((document: any) => ({ url: document.doc_path, id: document.id, status: document.status, type: (document.doc_path.indexOf('.pdf') !== -1 ? "pdf" : "image") }));
                    // Determine the value based on 'type'
                    if (+res.data.type === 1) {
                        setBasicDetailsName('Agency');
                    } else if (+res.data.type === 2) {
                        setBasicDetailsName('Agent');
                    }
                    setSelectedInput({
                        agency_name: agencyData.name,
                        email: agencyData.email,
                        mobile: agencyData.mobile,
                        tin_id_no: agencyData.tin_id_no,
                        bank_name: agencyData.bank_name,
                        branch_name: agencyData.branch_name,
                        account_no: agencyData.account_no,
                        account_name: agencyData.account_name,
                        partner_id: partner_ids,
                        source: agencyData.source,
                        doc_status: agencyData.doc_status,
                        documents: agencyData.document_data,
                        status: agencyData.status,
                        id: agencyData.id
                    });
                    setPreviewImages(documentPaths);


                    setEditForm(false)
                } else if (res?.message === "no_data_found") {
                    navigate("/not-found")
                }

            }).catch((err: Error) => {
                setLoading(false)
            })
        }
        // eslint-disable-next-line
    }, [searchParams, trigger]);

    // delete image function
    const handleImageDelete = async (image: any) => {
        if (!image || !Object.keys(image).length) return;


        setShowConfirmation(true);
        setActionType('deleteImage');
        setPendingAction({ image });
        setConfirmationMessage(`Are you sure you want to delete this document ?`);

    }

    const handleEdit = () => {
        setDisableField(!disableField);
        setEditForm(!editForm)
    }


    const addDataFor = (data: any) => {
        setErrors({})
        setBasicDetailsName(data);
    }

    const agencyProcess = (type: any) => {
        setShowConfirmation(true);
        setActionType(type);
        setConfirmationMessage(`Are you sure you want to ${type} ${selectedInput.agency_name} agency ?`);
    }

    const confirmYes = async () => {
        setShowConfirmation(false);

        if (actionType === 'approve' || actionType === 'reject') {
            approveAgency({ agency_id: agency_id, type: actionType }).then((res: any) => {
                if (res.status === 200) {
                    toast.success(res.message)
                    setTrigger(trigger + 1);
                }
            });

        } else if (actionType === 'deleteImage') {
            const { image }: any = pendingAction;
            const agencyId = agency_id;
            let uploadedImages = (selectedInput?.documents?.length || 0);
            if (!agencyId || (!image?.type || image?.type === "new")) {
                // Just remove image from frontend for new entries
                //FIND INDEX TO REMOVE FROM UPLOADED IMAGES ARRAY
                let fileIndex = image.id - uploadedImages;

                // Just remove image from frontend for new entries
                setPreviewImages((prev: any) => prev.filter((img: any, idx: number) => idx !== image.id));
                setSelectedInput((prev: any) => ({
                    ...prev,
                    images: prev.images.filter((img: any, idx: number) => idx !== fileIndex)
                }));

                return;
            } else {
                // Call API to delete the document
                try {
                    let result: any = await deleteAgencyDocument({ agency_id: agencyId, id: image.id });

                    if (result.status === 200) {
                        // Remove image from frontend
                        setPreviewImages((prev: any) => prev.filter((img: any) => img.id !== image.id));
                        setSelectedInput((prev: any) => ({
                            ...prev,
                            images: prev?.images?.filter((img: any) => img.id !== image.id),
                            documents: prev?.documents?.filter((img: any) => img.id !== image.id)
                        }));
                        // if (agencyId) {
                        //     setTrigger(trigger + 1);
                        // }
                        toast.success('Image deleted successfully');
                    } else {
                        toast.error('Failed to delete image');
                    }
                } catch (error) {
                    toast.error('Failed to delete image');
                }
            }

        }
        else if (actionType === 'markAsVerified') {
            approveAgencyDocument({ agency_id, type: 'approve', source: 2 }).then((res: any) => {
                if (res.status === 200) {
                    toast.success(res.message);
                    setTrigger(trigger + 1);

                }
            })
        }else if(["inactive", "active"].includes(actionType)) {

            if(+selectedInput.doc_status === AGENCY_DOC_STATUS['rejected'] && ["active"].includes(actionType)){
                toast.error("Please approve documents first");
                return false;
            }

            let updatedStatus = selectedInput.status ? 0 : 1
            const updatedData: any = { id: selectedInput.id, status: updatedStatus };
            setLoading(true);

            dispatch(updateAgencyStatus(updatedData)).then((res: any) => {
                if (res && res.status === 200) {
                    toast.success(res.message);
                    setSelectedInput((prev: any)=> ({...prev, status: updatedStatus}))
                }
                setLoading(false);
            }).catch((err: Error) => {
                setLoading(false);
            });
        }

        setActionType(null);
        setPendingAction(null);
    }



    const confirmNo = () => {
        setShowConfirmation(false);
        setActionType(null);
        setPendingAction(null);
    }

    const checkAgencyApprovedOrNot = (params: any) => {
        if (selectedInput.doc_status !== 1) {
            toast.error('Please approve document first')
            return;
        }

        agencyProcess(params);
    }

    const markAsVerified = () => {
        setShowConfirmation(true);
        setActionType('markAsVerified');
        setConfirmationMessage(`Are you sure you want to mark as verified ?`);
    }

    const changeAgencyStatus = () => {
        setActionType(`${selectedInput.status === 1 ? "inactive" : "active"}`);
        setShowConfirmation(true)
        setConfirmationMessage(`Are you sure you want to mark ${selectedInput.agency_name} ${[AGENCY_STATUS['inactive'],AGENCY_STATUS['rejected']].includes(+selectedInput.status) ? "as active": "as inactive"} ?`)

    }
   
return (
        <>
            <div className="container-fluid">
                <FullPageLoader show={loading} />

                <div className="top-heading financer-lead-heding">
                <h2>{!agency_id ? "Add Agent/Agency" : `${selectedInput.agency_name} ${selectedInput.id ? `(ID: ${selectedInput.id})` : ""}`}</h2>

                    {/* <div className="Add-Financer-popup bankdetail-popup"> */}
                    <div className='d-flex edit-approve-btn-top'>
                        {((agency_id) && (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_agency_partner_management'], accessType: 'edit' }))) &&
                            <button className="btn-line" onClick={handleEdit} disabled={[AGENCY_DOC_STATUS['resubmit']].includes(+selectedInput.doc_status)}>
                                {
                                    !editForm ? <> <i className='ic-edit m-xs-r'></i> <span>Edit</span></> : <span>Cancel</span>
                                }
                            </button>

                        }

                        {(agency_id && +selectedInput.source !== SOURCE_WEB) &&
                            <div className='m-sm-l d-flex'>
                                {
                                    ([AGENCY_DOC_STATUS['pending'], AGENCY_DOC_STATUS['resubmit'], AGENCY_DOC_STATUS['approved'],AGENCY_DOC_STATUS['rejected']].includes(+selectedInput.doc_status) && ![AGENCY_STATUS['active'], AGENCY_STATUS['inactive'],!AGENCY_STATUS['rejected']].includes(+selectedInput.status) && (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_agency_partner_management'], accessType: 'edit' }))) ?
                                        <>
                                            <button className="btn-primary" onClick={() => { checkAgencyApprovedOrNot("approve") }}  disabled={[AGENCY_DOC_STATUS['resubmit']].includes(+selectedInput.doc_status)}>
                                                Approve
                                            </button>
                                            {
                                                ![AGENCY_STATUS['rejected']].includes(+selectedInput.status) &&
                                                <button className="btn-line m-sm-l" onClick={() => { agencyProcess("reject") }}  disabled={[AGENCY_DOC_STATUS['resubmit']].includes(+selectedInput.doc_status)}>
                                                    Reject
                                                </button>
                                            }
                                        </> : ''
                                }
{/* 
                                {
                                    (selectedInput.doc_status === AGENCY_DOC_STATUS['approved'] && (selectedInput.status === AGENCY_STATUS['active'] || selectedInput.status === AGENCY_STATUS['inactive']) && (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_agency_partner_management'], accessType: 'edit' }))) &&
                                    <>
                                        <button className="btn-primary">
                                            <i className='ic-check m-xs-r'></i>Approved
                                        </button>
                                    </>
                                }
                                {
                                    (+selectedInput.status === AGENCY_DOC_STATUS['rejected'] && (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_agency_partner_management'], accessType: 'edit' }))) &&
                                    <>
                                        <button className="btn-primary" disabled={true}>
                                            <i className='ic-cancel m-xs-r'></i>Rejected
                                        </button>
                                    </>
                                } */}

                            </div>
                        }

                        {
                            agency_id && (+selectedInput.status === AGENCY_STATUS['active'] && (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_agency_partner_management'], accessType: 'edit' }))) &&
                            <div className='m-sm-l d-flex'>
                                <button className="btn-line" onClick={changeAgencyStatus}>
                                    Inactive
                                </button>
                            </div>
                            

                        }

                        {
                            agency_id && ([AGENCY_STATUS['inactive']].includes(+selectedInput.status) && (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_agency_partner_management'], accessType: 'edit' }))) &&
                            <div className='m-sm-l d-flex'>
                                <button className="btn-line" onClick={changeAgencyStatus}>
                                    Active
                                </button>
                            </div>
                            

                        }

                        {
                            agency_id &&  <div className='m-sm-l d-flex'>
                                <button className="btn-line" onClick={()=>{setViewTimeLine(true)}}>
                                    View History
                                </button>
                            </div>
                        }
                    </div>

                </div>


                <div className="rule-engine-menu-sec p-lg">
                    <Tab.Container id="left-tabs-example" defaultActiveKey={basicDetailsName === "Agent" ? "first" : "second"}>
                        <div className="p-rel">
                            <div className="role-management-top-sec">
                                <div className="selected-chips">
                                    {
                                        !agency_id &&
                                        <Nav variant="pills" className="flex-column nav nav-pills">
                                            <div className="tab-list d-flex m-lg-b">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first" onClick={() => { addDataFor('Agent') }}>Add Agent</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second" onClick={() => { addDataFor('Agency') }}>Add Agency </Nav.Link>
                                                </Nav.Item>

                                            </div>
                                        </Nav>
                                    }

                                </div>

                            </div>
                        </div>
                    </Tab.Container>
                    <div className='br-top'>


                        <div className="row agency-create-flow">

                            {/* <div className="rule-engine-menu-sec"> */}
                            <div className="col-md-6">

                                <div className="Add-Financer-popup bankdetail-popup add-agency-form">
                                    <div className="p-lg-b">
                                        <h2>Basic Details</h2>
                                    </div>

                                    <div className="row">
                                        {/* <div className='col-md-12'> */}
                                        {/* <div className='row'> */}
                                        <fieldset className="col-md-4 ">
                                            <div className="material" title={selectedInput?.agency_name}>
                                                <InputField
                                                    inputProps={{
                                                        id: "",
                                                        name: "agency_name",
                                                        className: "form-input",
                                                        type: "text",
                                                        value: selectedInput?.agency_name || '',
                                                        maxLength: "",
                                                        placeholder: " ",
                                                        disabled: disableField,
                                                    }}
                                                    onChange={(e: any) => handleInputChange('agency_name', e)}
                                                />
                                                <label data-label={`${basicDetailsName} Name*`} className="form-label"></label>
                                                {
                                                    errors && errors.agency_name
                                                        ?
                                                        <span className="error-txt">{errors.agency_name}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>

                                        <fieldset className="col-md-4 ">
                                            <div className="material" title={selectedInput?.email}>
                                                <InputField
                                                    inputProps={{
                                                        id: "email",
                                                        name: "email",
                                                        className: "form-input",
                                                        type: "text",
                                                        value: selectedInput.email || '',
                                                        maxLength: "",
                                                        disabled: disableField,
                                                        placeholder: " "
                                                    }}
                                                    onChange={(e: any) => handleInputChange('email', e)}
                                                />

                                                <label data-label="Email *" className="form-label"></label>
                                                {
                                                    errors && errors.email
                                                        ?
                                                        <span className="error-txt">{errors.email}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>

                                        <fieldset className="col-md-4 ">
                                            <div className="material" title={selectedInput?.mobile}>
                                                <InputField
                                                    inputProps={{
                                                        id: "mobile",
                                                        name: "mobile",
                                                        className: "form-input",
                                                        type: "text",
                                                        value: (selectedInput.mobile) ? selectedInput.mobile : '',
                                                        maxLength: "10",
                                                        disabled: disableField,
                                                        placeholder: " "

                                                    }}
                                                    onChange={(e: any) => handleInputChange('mobile', e)}
                                                />
                                                <label data-label="Mobile *" className="form-label"></label>
                                                {
                                                    errors && errors.mobile
                                                        ?
                                                        <span className="error-txt">{errors.mobile}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>

                                        <fieldset className="col-md-4 ">
                                            <div className="material" title={selectedInput?.tin_id_no}>
                                                <InputField
                                                    inputProps={{
                                                        id: "tin_id_no",
                                                        name: "tin_id_no",
                                                        className: "form-input",
                                                        type: "text",
                                                        value: selectedInput.tin_id_no || '',
                                                        maxLength: "",
                                                        disabled: disableField,
                                                        placeholder: " "
                                                    }}
                                                    onChange={(e: any) => handleInputChange('tin_id_no', e)}
                                                />
                                                <label data-label="TIN Number *" className="form-label"></label>
                                                {
                                                    errors && errors.tin_id_no
                                                        ?
                                                        <span className="error-txt">{errors.tin_id_no}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>

                                        {previewImages?.length > 0 && previewImages.map((image: any, index: any) => (

                                            <div className="col-md-4" key={`doc-${index}`}>

                                                <div key={index} className="image-container-agency">
                                                    {
                                                        image?.type?.indexOf("pdf") !== -1
                                                            ?
                                                            <i className={`ic-${image?.type}-file icons-normal t-accent`} onClick={() => showImagePopup(index)}></i>
                                                            :
                                                            <img src={image.url} alt={`preview-${index}`} className="upload-icn" onClick={() => showImagePopup(index)} />

                                                    }

                                                    {
                                                        editForm && (selectedInput?.documents?.length > 1 || !image.id) &&
                                                        <div className="close-icn" onClick={() => handleImageDelete({ ...image, id: (image.id || index), type: (!image.id ? "new" : "old") })}>
                                                            <i className="ic-clearclose" style={{ position: "absolute", top: 0, right: 0 }}></i>

                                                        </div>
                                                    }
                                                    {
                                                        (+selectedInput?.doc_status === AGENCY_DOC_STATUS['approved'] && image.id) &&
                                                        <span className="image-status">Approved</span>
                                                    }
                                                    {
                                                        +selectedInput?.doc_status === AGENCY_DOC_STATUS['resubmit'] && image.id &&
                                                        <span className="image-requested">Requested</span>
                                                    }
                                                    {
                                                        +selectedInput?.doc_status === AGENCY_DOC_STATUS['rejected'] && image.id &&
                                                        <span className="image-requested">Rejected</span>
                                                    }
                                                </div>

                                            </div>
                                        ))
                                        }

                                        {
                                            previewImages?.length < 5 &&
                                            <div className="img-bx-select-opt col-md-4 add-basic-doc">
                                                <div className="image-bx more-file-upload add-insurance-partner">


                                                    <div className="document-image-container ">
                                                        <div role="presentation" className="add-new-image upload-input-bx">
                                                            {
                                                                <input type="file" className="upload-image-input" name="documents[]" onChange={(e: any) => handleFileInputChange(e)} multiple disabled={disableField} />
                                                            }
                                                            <i className="ic-add"> </i>
                                                            <span>Add Document</span>
                                                        </div>
                                                    </div>



                                                    {
                                                        errors && errors.images
                                                            ?
                                                            <span className="error-txt error-left">{errors.images}</span>
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {
                                            editForm && selectedInput?.documents?.length > 0 && +selectedInput.source === 1 && ![AGENCY_DOC_STATUS['approved'], AGENCY_DOC_STATUS['resubmit']].includes(+selectedInput.doc_status) &&
                                            <div className='col-md-12 p-sm-t p-sm-b resubmit-btn'>
                                                <button className="btn-line" onClick={() => { handleResubmitDoc('resubmit') }}>
                                                    Resubmit
                                                </button>

                                                <button className="btn-line m-sm-l" onClick={markAsVerified}>
                                                    mark as verified
                                                </button>
                                            </div>

                                        }

                                        {/* </div> */}

                                        {/* </div> */}
                                    </div>

                                    <div className='row'>
                                        <div className='form-select-bx col-md-6 partner-add-edit'>
                                            <ReactSelect options={agencyBanksLists ?? []} value={agencyBanksLists.find((option: any) => +option.value === +selectedInput.bank_name)} handleChange={(e: any) => handleChange('bank_name', e)} placeholder="Bank Name *" fieldKey="bank_name" errorField={errors?.bank_name ? errors?.bank_name : ''} isDisabled={disableField} />
                                        </div>

                                        <fieldset className="col-md-4 ">
                                            <div className="material" title={selectedInput?.branch_name}>
                                                <InputField
                                                    inputProps={{
                                                        id: "branch_name",
                                                        name: "branch_name",
                                                        className: "form-input",
                                                        type: "text",
                                                        value: selectedInput.branch_name || '',
                                                        maxLength: "",
                                                        disabled: disableField,
                                                        placeholder: " "
                                                    }}
                                                    onChange={(e: any) => handleInputChange('branch_name', e)}
                                                />
                                                <label data-label="Branch Name" className="form-label"></label>

                                            </div>
                                        </fieldset>


                                        <fieldset className="col-md-4 ">
                                            <div className="material" title={selectedInput?.account_no}>
                                                <InputField
                                                    inputProps={{
                                                        id: "account_no",
                                                        name: "account_no",
                                                        className: "form-input",
                                                        type: "text",
                                                        value: selectedInput.account_no || '',
                                                        maxLength: "",
                                                        disabled: disableField,
                                                        placeholder: " "
                                                    }}
                                                    onChange={(e: any) => handleInputChange('account_no', e)}
                                                />
                                                <label data-label="Account Number *" className="form-label"></label>
                                                {
                                                    errors && errors.account_no
                                                        ?
                                                        <span className="error-txt">{errors.account_no}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>

                                        <fieldset className="col-md-4 ">
                                            <div className="material" title={selectedInput?.account_name}>
                                                <InputField
                                                    inputProps={{
                                                        id: "account_name",
                                                        name: "account_name",
                                                        className: "form-input",
                                                        type: "text",
                                                        value: selectedInput.account_name || '',
                                                        maxLength: "",
                                                        disabled: disableField,
                                                        placeholder: " "
                                                    }}
                                                    onChange={(e: any) => handleInputChange('account_name', e)}
                                                />
                                                <label data-label="Account Name *" className="form-label"></label>
                                                {
                                                    errors && errors.account_name
                                                        ?
                                                        <span className="error-txt">{errors.account_name}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>

                                        {/* </div> */}
                                        {/* </div> */}
                                    </div >

                                </div>
                            </div>

                            <div className="col-md-6 br-left">


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="insurance-list">
                                            <div className="p-lg-b">
                                                <h2>Mapped Insurance Companies</h2>
                                            </div>



                                            <ul>
                                                {insuranceCompanies.map((company: any) => (
                                                    <li key={company.partner_id}>


                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                id=""
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                name={company.name}
                                                                onChange={() => handleCheckboxChange(company.id)}
                                                                checked={selectedInput?.partner_id?.includes(company.id)}
                                                                disabled={disableField}
                                                            />
                                                            <label className="custom-control-label">
                                                                {company.name} (ID: {company.id})
                                                            </label>
                                                        </div>


                                                    </li>
                                                ))}
                                            </ul>

                                            {/* </div> */}
                                            {/* </div> */}

                                            {/* </div> */}



                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                resubmitDocPopup && <DocResubmitModal showModal={resubmitDocPopup} agency_id={agency_id} closeResubmitModal={() => setResubmitDocPopup(false)} handleType={handleType} triggerApi={() => { setTrigger(trigger + 1); setResubmitDocPopup(false) }} />
                            }

                            {
                                imageSlider && <ImageSlider showModal={imageSlider} sliderImages={previewImages} agency_id={agency_id} closeResubmitModal={() => setImageSlider(false)} activeImageIndex={activeImageIndex} selectedAgency={selectedInput} editForm={editForm} handleResubmitDocTrigger={handleResubmitDoc} markAsVerifiedTrigger={markAsVerified} />
                            }

                        </div>
                    </div>
                    <div className="btn-bottom t-center">
                        {

                            HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_agency_partner_management'], accessType: 'edit' }) &&
                            <button className="btn-primary" onClick={handleSubmit} disabled={disableField}>
                                Submit Details
                            </button>
                        }
                    </div>
                </div>

                {/* </div> */}
                {showConfirmation === false ? ("") : (
                    <ConfirmPopUp
                        confirmNo={confirmNo}
                        confirmYes={confirmYes}
                        message={confirmationMessage} />
                )}

                {agency_id && <AgencyCommentModal viewTimeLine={viewTimeLine} agency_id={agency_id} handleViewTimeLine={()=>setViewTimeLine(false)} />}

            </div>

        </>

    )
}
