import { APP_TYPE_AGENCY, FIELD_TYPE, INS_TYPE_COMPREHENSIVE, PAYMENT_TERM_INSTALLMENT, WITHOUT_RULE_ENGINE } from "@src/config/constant";
import dateFormat from "dateformat";
import { times } from "lodash";
const callServiceToCreateLead = (sectionList:any, params?: any) => {
    let param = {} as any 
    // eslint-disable-next-line  
    sectionList?.map((sectionItem: any) => {
        let blockList = sectionItem['blocks'] as []
        // eslint-disable-next-line
        blockList.map((blockItem: any) => { 
            // eslint-disable-next-line
            blockItem?.fields?.map((item: any) => { 
                if (isShowDynamicField(item,blockList)) { 
                    if (typeof item.value === 'object') {
                        if (item.name === 'expected_installment') {
                            let tenure = blockItem?.fields?.find((item: any) => item.name === 'payment_tenure')
                            let totalInstallments = tenure?.value?.label?.split(' ')[0] || null
                            let totalInstallmentDates = item.value ? Object.keys(item.value).length : -1
                            if (+totalInstallmentDates > 0) {
                                let value = item?.value
                                if (+totalInstallmentDates !== +totalInstallments) {
                                    times((totalInstallmentDates), (idx: number) => {
                                        if ((idx + 1) > totalInstallments) {
                                            delete value[idx + 1]
                                        }
                                    })
                                }
                            }
                            param[item.name] = item.value
                        }
                        else if (['make','model','version'].includes(item.name)) {
                            if (Object.keys(item.value).length) {
                                param[item.name] = parseFloat(item?.value?.value)
                                param[item.name+'_name'] = item?.value?.label
                            }
                        }  
                        else if (item.type === 'select_addon') {
                            // Checking if user has selected Model or not
                            let addonList = getAddonList(item)
                            if (addonList?.length) {
                                param[item.name] = addonList
                            }
                        }
                        else if(item.type ==='date' || item.type ==='future_date'){
                            param[item.name] = dateFormat(item.value, 'yyyy-mm-dd')
                        }
                        else if(item.type === "mdd"){                        
                        param['other_doc'] = item.value.map((val:any) => val.value);
                        }
                        else if (item?.value?.value) {
                            // Checking if user has saved value or not
                            if (item.data_type === 'number') {
                                param[item.name] = item?.value?.value ? parseFloat(item?.value?.value) : item?.value?.value
                            }
                            else { 
                                param[item.name] = item?.value?.value?.toString()
                            }
                            item.error = ''
                        }
                    } else if (item.type === 'select_addon') {
                        // Checking if user has selected Model or not
                        let addonList = getAddonList(item)
                        if (addonList?.length) {
                            param[item.name] = addonList
                        }
                    }
                    else { 
                      
                        if (item.name === 'per_sameas_cur') {
                            if (item?.value) param[item.name] = 2
                            else param[item.name] = 1
                        } 
                        else if (item.name === 'acc_price' && item.value) {
                            // Checking if user has entered Out of down charges then it should be less than Car FMV value
                            if (item.data_type === 'number') {
                                param[item.name] = item?.value ? parseFloat(item?.value) : item?.value
                                item.error = ''
                            }
                            else if (item?.value) {
                                param[item.name] = item?.value?.toString()
                                item.error = ''
                            }
                        }
                        else if (item?.value) { 
                            if (item.data_type === 'number') {
                                param[item.name] = item?.value ? parseFloat(item?.value) : item?.value
                                item.error = ''
                            }
                            else if (item?.value) {
                                param[item.name] = item?.value?.toString()
                                item.error = ''
                            }
                        }
                        //case: Edit Quote details & saving empty acc price & description
                        else if (params?.type === 'edit' && ['acc_price', 'acc_description'].includes(item.name) && !item?.value) {
                            param[item.name] = item.name === 'acc_price' ? 0 : ''
                        }
                    }
                }
            })
        })
    }) 
    return param; 
}

const isShowDynamicField = (detail: any,blocklist: any, params?: any) => { 
    if (detail?.name === 'expected_installment') {
        const filterFieldItem = blocklist?.[0]?.['fields'].filter((each: any) => each.name === 'payment_tenure')?.[0]
        const payTerm = blocklist?.[0]?.['fields'].filter((each: any) => each.name === 'payment_term')?.[0]
        const isInstallment = payTerm?.value?.value === PAYMENT_TERM_INSTALLMENT

        if (filterFieldItem?.value?.value && isInstallment) {
            return true
        }
        return false
    }

    else if (detail?.field_show) { 
        //if editing quote detail page && apptype is agency
        //then hide financing to/from date
        if (['finance_from_date', 'finance_to_date', 'insurer_name', 'financier', 'expiration_date'].includes(detail.name)
            && params?.apptype === APP_TYPE_AGENCY) {
            return false
        }
        const splitType = detail?.field_show?.split('/')  
        if (splitType?.length > 1) { 

            let fieldList = (blocklist?.[0]?.['fields'] ) ?  blocklist[0]['fields'] : [];
            // when Name is referrer name, mobile then onluy other details key will pick
            if (['referrer_name', 'referrer_mobile', 'lead_sub_source'].includes(detail.name)) {
                fieldList = blocklist.filter((val: any) => val.key === "lead_other_details").map((val: any) => val.fields);
                fieldList = fieldList[0];
            }
            const filterFieldItem:any =  fieldList.filter((ob:any)=>ob.name === splitType[0])?.[0]; 
            if (+filterFieldItem?.value?.value === +splitType[1] || +filterFieldItem?.value === +splitType[1]) {
                return true
            }
            else return false
        }
    }

    return !!detail?.['is_show']
}

const getAddonList = (item: any,filterList?:any,ruleEngine?:any) => {
    
    let addonParamList: object[] = []
    let filterAddon = item?.original_list?.filter((addonItem: any) => addonItem.is_selected)

    filterAddon =  (filterList)? filterList.filter((addonItem: any) => addonItem.is_selected) :filterAddon 
    
    if (filterAddon?.length) {
        filterAddon?.forEach((item: any) => {
            let addOnParam: { [key: string]: any } = {};
            addOnParam['id'] = item.id
            if (item.coverage_amt) {
                addOnParam['coverage_amount'] = item.coverage_amt
                let optionItem = item?.coverage_list?.filter((optionItem: any) => optionItem?.value === item.coverage_amt)?.[0]
                if (optionItem && item.type !=='pa') {
                    addOnParam['premium_amount'] = (ruleEngine && ruleEngine===2)? '':parseFloat(optionItem.premium_amount || '0')

                }else if(optionItem && item.type ==='pa'){
                  
                    addOnParam['seat_capacity'] = (item.seat_capacity)?item.seat_capacity:''
                    if(ruleEngine && ruleEngine===2){
                        addOnParam['premium_amount'] =(item.premium_amount)? parseFloat(item.premium_amount || '0'):''
                    }
                    if (item.extra_seat_value) {
                        addOnParam['extra_seat_capacity'] = item.extra_seat_value
                    }
                }
            }
            if (item.premium_amount) {
                addOnParam['premium_amount'] = parseFloat(item.premium_amount || '0')
            }
            if (item.aon_rate) {
                addOnParam['aon_rate'] = item.aon_rate
            }
            if (item.is_editable) {
                addOnParam['is_editable'] = item.is_editable
            }
            if(item.milestone_coverage_amount_limit){
                addOnParam['milestone_coverage_amount_limit'] = item.milestone_coverage_amount_limit
            }
            if(item.oona_coverage_amount_limit){
                addOnParam['oona_coverage_amount_limit'] = item.oona_coverage_amount_limit
                addOnParam['oona_seat_capacity_limit'] = item.oona_seat_capacity_limit
            }


            addonParamList.push(addOnParam)
        })
    }
    
    return addonParamList
}


const updateInsuranceForm = (params: any, sectionItem: Array<any>, stateCityList?: Array<any>, clear?: boolean) => {
    return new Promise((resolve, reject) => {
        if (clear) sectionItem?.forEach((key: any) => key.value = "")
        else {
            let { type } = params
            let disableFields: any = []
            //for mapping renewal api response and keys in section items
            if (type === 'renewal') {
                disableFields = ['insurance_type', 'make_year', 'make', 'model', 'version', 'vehicle_type', 'name', 'customer_type', 'dob', 'seat_capacity', 'color', 'machine_number', 'chassis_number', 'transmission', 'fuel_type', 'make_name', 'model_name', 'version_name', 'insurance_exists', 'insurer_name', 'expiration_date']
            }

            sectionItem?.forEach((key: any) => {
                let makeDetails = ['make', 'model', 'version', 'make_year']
                let residentialDetails = ['per_region', 'cur_region', 'per_city', 'cur_city', 'per_barangay', 'cur_barangay']

                if (params && Object.keys(params).length && (params.hasOwnProperty(key?.name))) {
                    let data = params[key.name as keyof typeof params]

                    if (key.name === 'financier' && (!type || type !== 'renewal')) {
                        key.value = data ? key.options.find((e: any) => e.ucf_financier_id === data) : null
                    }
                    else if (makeDetails.includes(key.name)) {
                        key.value = data
                    }
                    else if (residentialDetails.includes(key.name)) {
                        if (key.name === 'per_region' || key.name === 'cur_region') {
                            let value = stateCityList?.find((e: any) => +e.id === +data)
                            key.value = { label: value?.value, value: value?.id, city_list: value?.city_list }
                        }
                        else if (key.name === 'per_city' || key.name === 'cur_city') {
                            let value = stateCityList?.find((e: any) => +e.id === (key.name.startsWith('per') ? +params['per_region'] : +params['cur_region']))
                            let city = value?.city_list?.find((e: any) => +e.id === +data)
                            key.value = { label: city?.value, value: city?.id }
                        }
                        else if (key.name === 'per_barangay' || key.name === 'cur_barangay') {
                            let barangay = key?.options?.find((e: any) => +e.value === (key.name.startsWith('per') ? +params['per_barangay'] : +params['cur_barangay']))
                            if (barangay) key.value = { ...barangay }
                        }
                    }
                    else if (key.type === 'sdd' || key.type === 'dd') key.value = key.options.find((e: any) => e.value === data || e.id === data)
                    else key.value = data

                    if (key.value) {
                        //postal code get disabled based on barangay
                        if (type === 'renewal' && !['cur_postal_code', 'per_postal_code'].includes(key.name)) {
                            key.is_enable = (disableFields.includes(key.name) && key.value) ? false : true
                            //if renewal & vehicle type is not sedan suv, then disable vehicle usage
                            if (key.name === 'vehicle_usage' && ![1, 2, 6, 7].includes(params.vehicle_type_id)) {
                                key.is_enable = false
                            }
                        }
                        else if (typeof key.value === 'object' && key.value.value) key.is_enable = false
                        else if (typeof key.value !== 'object') key.is_enable = false

                        key.error_message = ``
                    }
                    // when value is null,for reset disabled then input field is enabled
                    if (!key.value) {
                        key.is_enable = true
                    }
                }

                 // finance_from_date and finance_to_date fields are not disabled when their values are empty
                if (key?.name === 'finance_from_date' || key?.name === 'finance_to_date') {
                    if (!key.value) {
                        key.is_enable = true
                    }
                }
            })
        }
        resolve(true)
    })
}

const updateInsuranceFormAgency = (params: any, sectionItem: Array<any>, clear?: boolean, additionalParams?: any) => {
    let keysToModify = ['make', 'seat_capacity', 'fuel_type', 'make_year']

    for (let key in params) {
        if (keysToModify.includes(key)) {
            let value = params[key]
            if(['seat_capacity', 'make_year'].includes(key)) {
                value = +value
            }
            params[key] = { label: value, value }
        }
    }

    return new Promise((resolve, reject) => {
        if (clear) sectionItem?.forEach((key: any) => key.value = "")
        else {
            sectionItem.forEach((ele: any) => {
                if (params?.[ele?.name]) {
                    if (!ele?.value) {
                        ele.value = params[ele.name]
                    } else {
                        //data already filled case
                        if (ele.type !== FIELD_TYPE.TEXT_BOX) {
                            let newValue = params[ele.name].label
                            let oldValue = ele.value.label

                            newValue = typeof newValue === 'string' ? newValue.toLowerCase() : newValue
                            oldValue = typeof oldValue === 'string' ? oldValue.toLowerCase() : oldValue

                            //case when old data & new data are not same
                            //then reset child dependencies
                            if (newValue !== oldValue) {
                                let newValueOptions: Array<any> = additionalParams[ele.name] || []
                                if (ele.name === 'make_year') {
                                    let { insurance_type, rule_engine } = additionalParams
                                    let keyName = ele.name

                                    if (rule_engine === WITHOUT_RULE_ENGINE) {
                                        keyName = 'without_mfg_comprehensive'
                                    } else if (insurance_type === INS_TYPE_COMPREHENSIVE) {
                                        keyName = 'mgf_comprehensive'
                                    } else {
                                        keyName = 'mgf_ctpl'
                                    }

                                    newValueOptions = additionalParams['make_year']?.[keyName] || []
                                }

                                if (newValueOptions?.length) {
                                    let findDataInOption = newValueOptions.find((el: any) => (typeof el.label === 'string' ? el.label.toLowerCase() : el.label) === newValue)
                                    if (findDataInOption) ele.value = findDataInOption
                                    else ele.value = ""
                                }

                                //reset child dependency
                                let childDependency: Array<any> = ele?.child_depedencies || []
                                if (['seat_capacity', 'make_year'].includes(ele.name)) childDependency.push('insurance_type')

                                if (childDependency?.length) {
                                    sectionItem?.forEach((item: any) => {
                                        if (childDependency.includes(item.name)) {
                                            item.value = ""
                                        }
                                    })
                                }
                            }
                        } else ele.value = params[ele.name]
                    }
                }
            })
        }

        return resolve(sectionItem)
    })
}


export {
    callServiceToCreateLead,
    getAddonList,
    isShowDynamicField,
    updateInsuranceForm,
    updateInsuranceFormAgency
} 