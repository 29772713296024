import { APP_TYPE_AGENCY, INS_TYPE_COMPREHENSIVE, INS_TYPE_CTPL } from "@src/config/constant";
import { useSectionList } from "@src/hooks/useSectionList";
import InsuranceService from "@src/services/InsuranceService";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { saveInsuranceAgency } from "@src/store/slices/insurance";

type Props = {
    fields: Array<any>,
    setLoading: Function,
    setPage: Function
}

export default function InsurerDetails(props: Props) {
    let { setLoading, setPage } = props
    // eslint-disable-next-line
    const [inputField, setInputField] = useState<any>({})
    const { fetchSectionItem, fetchItem } = useSectionList({})
    const [partnerList, setPartnerList] = useState<Array<any>>([])

    const navigate = useNavigate()
    const dispatch: any = useDispatch()

    const insurance = useSelector((state: any) => state.insurance)
    const vehicleDetails = fetchSectionItem('vehicle_details', 'vehicle_info')

    const fetchPartners = async () => {
        let { agency, insurance_type, partner_id, rule_engine } = insurance?.createInsuranceAgency || {}
        let params: any = {
            apptype: APP_TYPE_AGENCY,
            agency_id: agency,
            insurance_type,
            rule_engine
        }

        let vehicleType = fetchItem('vehicle_type', vehicleDetails?.fields || [])
        let vehicleUsage = fetchItem('vehicle_usage', vehicleDetails?.fields || [])
        let totalFMV = fetchItem('total_fmv', vehicleDetails?.fields || [])

        params['vehicle_type'] = vehicleType?.value?.value || null
        params['vehicle_usage'] = vehicleUsage?.value?.value || null
        params['total_fmv'] = totalFMV?.value || 0

        //navigate back if these fields are not filled
        let fields = ['vehicle_type', 'vehicle_usage', 'total_fmv']
        let error = false
        for (let key of fields) {
            if (!params[key]) {
                setPage(2)
                error = true
                break
            }
        }

        if (error) {
            navigate('/create-insurance-policy-agency?step=vehicle_details')
            return
        }

        if (insurance_type === INS_TYPE_CTPL) {
            let tenure = fetchItem('tenure', vehicleDetails?.fields || [])
            let mfgYear = fetchItem('make_year', vehicleDetails?.fields || [])
            tenure = tenure?.value?.value || null
            mfgYear = mfgYear?.value?.value || null

            params['tenure'] = tenure
            params['make_year'] = mfgYear
        } else if (insurance_type === INS_TYPE_COMPREHENSIVE) {
            let mfgYear = fetchItem('make_year', vehicleDetails?.fields || [])
            mfgYear = mfgYear?.value?.value || null
            params['make_year'] = mfgYear
        }

        setLoading(true);
        let result = await InsuranceService.getPartnerList(params)
        setLoading(false);
        if (result?.data?.data) {
            setPartnerList(result.data.data);
            if (partner_id) setInputField((prev: any) => ({ ...prev, partner_id }))
        } else {
            toast.error(result?.data?.message)
            setPage(2)
            navigate('/create-insurance-policy-agency?step=vehicle_details')
        }
    }

    const handlePartnerSelection = (partner: any) => {
        let { id } = partner
        setInputField((prev: any) => ({ ...prev, partner_id: id }))
        dispatch(saveInsuranceAgency({ ...insurance?.createInsuranceAgency, partner_id: id, partner_slug: partner.slug || '' }))
    }

    useEffect(() => {
        fetchPartners()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="row">

            {
                partnerList.length
                    ?
                    partnerList.map((partner: any, idx: number) => {
                        return (
                            <div className="col-md-6" key={partner.slug + (" " + idx + 1)}>
                                <div id={`partner-${partner.id}`} className={`insurer-list-item insurar-list-agency ${inputField['partner_id'] === partner.id ? 'active' : ''}`} onClick={() => handlePartnerSelection(partner)}>
                                    <div className="agency-partner-logo">
                                        <img src={partner.logo} className="" alt={`partner-${idx + 1}-logo`} />
                                    </div>
                                    <div className="agency-partner-name">
                                        {partner.name}
                                    </div>
                                    <i className="ic-arrow_forward_ios"></i>
                                </div>
                            </div >

                        )
                    })
                    : undefined
            }
        </div>

    )
}