import common from './slices/common';
import insurance from './slices/insurance';
import encryption from './slices/encryption';

import { applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit'


const loggerMiddleware = createLogger();

const appReducer = combineReducers({ common, insurance, encryption });

//WHEN USER LOGOUT CLEAR ALL REDUX STATE
// const rootReducer = (state, action) => {
//   if (action.type === 'USERS_LOGOUT') {
//     return appReducer(undefined, action)
//   }

//   return appReducer(state, action)
// }

let middleware = [];
if (process.env.NODE_ENV === 'dev') { //set development to show errors
    middleware = [...middleware, thunkMiddleware, loggerMiddleware];
} else {
    middleware = [...middleware, thunkMiddleware];
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore(
    { reducer: appReducer },
    composeEnhancer(applyMiddleware(...middleware)),
);

export default store;
