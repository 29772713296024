import React, { useState ,useEffect} from 'react';
import { CSVImport } from '@src/component/rule-engine/import/CSVImportFIle'
import { importCsv, uploadAddOnCsv } from '@src/store/slices/common';
import { toast } from 'react-toastify';
import secureStorage from "@src/config/encrypt";
import FullPageLoader from '@src/common/FullPageLoader';
import * as HELPER from "@src/config/helper";
import { ROLE_FEATURES } from "@src/config/constant";
import ReactSelect from '@common/ReactSelect';
import { getMasterConfig } from '../../../store/slices/common';
import { useDispatch, useSelector } from 'react-redux'

interface MRPBCAMFModalProps {
    setUpdateModal: any;
    type: any;
    title: string;
}


const MRPBCAMF: React.FC<MRPBCAMFModalProps> = ({ setUpdateModal, type, title }) => {
    const [csvValue, setCsvValue] = useState<any>(null);
    let [loading, setLoading] = useState<boolean>(false)
    let [emptyErr, setEmptyErr] = useState<boolean>(false)

    const [csvReset, setCsvReset] = useState(0); 

    const [BlankFlage, setBlankFlage] = useState(0); 
    const [urlLink,setUrlLink] = useState('');

    const dispatch: any = useDispatch()
    const commonState = useSelector((state: any) => state.common)
    let [methodList, setMethodList] = useState<[]>([])
    const [selectedFilters, setSelectedFilters] = useState<any>({});
    const [errField, setErrField] = useState<any>({
        appType: ""
    });

    const insuranceState = useSelector((state: any) => state.insurance)

    const validForm = () => {
        let formIsValid = true;
        // App Type required only for base issue rate
       if(type === 'Base Issue Rate'){
        if(Object.keys(selectedFilters).length === 0){
           
            formIsValid = false;
            setErrField((prevState:any) => ({
                ...prevState,
                appType: "Application Type is required",
            }));
        }
       }

        if (!csvValue) {

            formIsValid = false;
            setEmptyErr(true);
        } 
        return formIsValid;
    };


    const handleImportMRP = () => {
    if (validForm()) {
        let result = secureStorage.getItem("authUserInfo");
        let userId = result && result.user_data && result.user_data.id

        setLoading(true);

        let formData = new FormData();
        formData.append("type", type);
        formData.append("images", csvValue);
        formData.append("user_id", userId);

        if (type === 'mmv') {
            importCsv(formData).then((res: any) => {
                setLoading(false)
                if (res && res.status === 200) {
                    toast.success(res.message);
                    setUpdateModal(false);
                    setCsvValue('');
                    setBlankFlage(BlankFlage+1);
                } else {
                    setLoading(false);
                    setCsvValue('');
                    setBlankFlage(BlankFlage+1);
                }

            }).catch(err => setLoading(false))
        }
        else if (type === 'bi' || type === 'pa' || type === 'pd' || type === 'net_rate' || type === 'Base Issue Rate') {
            if(type === 'Base Issue Rate'){
                formData.append("application_type", selectedFilters.application_type);
            }
            uploadAddOnCsv(formData).then((res: any) => {
                setLoading(false)
                if (res && res.status === 200) {
                    toast.success(res.message);
                    setUpdateModal(false);
                    setCsvValue('');
                    setBlankFlage(BlankFlage+1);

                    if(type === 'Base Issue Rate'){
                        setSelectedFilters({});
                    }
                    
                } else {
                    setLoading(false);
                    setCsvValue('');
                    setBlankFlage(BlankFlage+1);
                }

            }).catch(err => setLoading(false))

        }

        setCsvReset(csvReset+1);
    }     
    };

    useEffect(()=>{ 
        const match = insuranceState.sampleCsv.find((insurance:any) => insurance.slug === type);
        if (match) {
            setUrlLink(match.path);
        }
    // eslint-disable-next-line
    },[type]);


    useEffect(() => {
        if (commonState && commonState.common && Object.keys(commonState.common).length) {
            let data = commonState.common
            let { apptype } = data
            setMethodList(apptype)
           
        }
        else {
            //api call to fetch static dropdowns
            dispatch(getMasterConfig()).then((res: any) => {
                if (res && res.status === 200) {
                    let data: any = res.data || {}
                    let { apptype } = data
                    setMethodList(apptype)
                  
                }
            }).catch((err: Error) => {
            })
        }
    }, [dispatch, commonState]) 

    
    // Handle changes in dropdown selections
     const handleChange = (selectedOption: any, key: string) => {
        setSelectedFilters({[key]: selectedOption['value']})
        errField['appType'] = '';
    }
    
    return (
        <div className="calculator-form csv-table">
            <FullPageLoader show={loading} />
            <div className="row">
                {  type === 'Base Issue Rate' &&    
                 <div className="col-md-12">     
                    <ReactSelect
                        options={methodList||[]}
                        value={methodList.find((option:any) => option.value === selectedFilters['application_type']) || null}
                        handleChange={(e:any, fieldKey:any) => handleChange(e, fieldKey)}
                        placeholder="Application Type*"
                        fieldKey="application_type"
                        errorField={errField['appType'] || ""}
                    />
                </div>
                }

                <div className="col-md-12">
                    <h3>Upload File</h3>
                    <CSVImport setCsvValue={setCsvValue}  csvReset= {csvReset} emptyErr= {emptyErr} BlankFlage = {BlankFlage} setError={setEmptyErr}/>
                   <div>
                    </div>                
                    <div className="download-txt">
                        Download input format < a href={`${urlLink}`} download>Click Here</a>
                    </div>
                </div>
                {
                 HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['import_module'], accessType: 'edit' }) &&
                <div className="col-md-12">
                    <button className="btn-primary" onClick={handleImportMRP}>Import {title}</button>
                </div>
                }
            </div>
        </div>
    )
}
export default MRPBCAMF;