import { useEffect } from 'react';
import secureStorage from '@src/config/encrypt';
import * as HELPER from '@src/config/helper';
import { useNavigate } from 'react-router';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
    let { accessPageId, accessType } = rest;
    const authUserInfo = secureStorage.getItem('authUserInfo');


    const navigate = useNavigate();


    useEffect(() => {
        //CHECK USER IS AUTHORIZED
        let isAuthorized = false;
        // if user has dashboard acess
        if (accessPageId && authUserInfo) {
            isAuthorized = HELPER.isUserHasAccessPage({permissionID: accessPageId, accessType});
        }

        // when user not login the it redirect
        if (!isAuthorized && !authUserInfo) {
            navigate('/login', { replace: true });
            return;
        }
        else if (!isAuthorized) {
            navigate('/rule-engine', { replace: true });
            return;
        }
    }, [accessPageId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <rest.components />
    );
};

export default PrivateRoute;