import React, { useState, useEffect } from "react";



export const CSVImport = (props: any) => {
    const [file, setFile] = useState<File | null>(null);
    const [dragOver, setDragOver] = useState(false);
    const [errorNotification, setErrorNotification] = useState<string | null>(null);

 
    useEffect(()=>{ 
        setFile(null)
    },[props.csvReset])
 
    useEffect(()=>{ 
        if(props.emptyErr){
            setErrorNotification("Please upload CSV file");
        } else setErrorNotification("");
    },[props.emptyErr])

    useEffect(()=>{
       const fileBox = document.getElementById(`upload-image-input`) as any;
       fileBox.value = ''
    },[props.BlankFlage]);

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!dragOver) {
            setDragOver(true);
        }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        // Validate file is of type Image
        const fileType = file.type.split("/")[0];
        if (fileType !== "image") {
            setFile(null);
            setErrorNotification("Not an image File");
            setDragOver(false);
            setTimeout(() => {
                setErrorNotification(null);
            }, 3000);
            return;
        }

        setFile(file);
        setDragOver(false);
    };

    const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file: any = e.target.files?.[0];
        const fileType: string = file && file.type;
        
        // Validate file is of type Image
        if (!file) return;
         props.setCsvValue(file);

        const maxFileSizeInBytes = 1024 * 1024 * 1024;
        if (file.size > maxFileSizeInBytes) {
            setFile(null);
            setErrorNotification("File size exceeds 1 GB");
            setTimeout(() => {
                setErrorNotification(null);
            }, 3000);
            return;
        }

        if (fileType !== "text/csv") {
            setFile(null);
            setErrorNotification("Not an CSV File");
            setTimeout(() => {
                setErrorNotification(null);
            }, 3000);
            return;
        }
        props.setError(false)
        setFile(file);
    };

    const handleUploadImage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (file) {
            // Handle image Upload
            // You can put your logic here to handle the file upload
        }
    };

    const dragOverClass = dragOver ? "display-box drag-over" : "display-box";

    const uploadText = file ? (
        <div className="upload-file-name">
            <span className="suceess-icn">
                <i className="ic-check"></i>
            </span>
            <h4>{file.name}</h4>
            <i className="ic-clearclose"></i>
            <button className="upload-button btn btn-primary" onClick={handleUploadImage}>
                Browse Another File
            </button>
        </div>
    ) : (
        <div className="upload-txt">
            <h4>Drop Files Here to Upload</h4>
            <span>Only .csv files are accepted</span>
            <div className="or-txt">or</div>
            <button className="btn-primary file-btn">Browse File</button>
        </div>
    );

    const errorNotificationElement = errorNotification ? (
        <div className="error-notification">
            <p>{errorNotification}</p>
        </div>
    ) : null;

    return (
        <div style={{ textAlign: "center" }}>
            <div>
                <div className="image-uploader-wrapper">
                    <div className={dragOverClass} onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
                        <div className="icon-text-box">
                            <div className="upload-icon">
                                <i className="ic-file-icon" aria-hidden="true" />
                            </div>

                            <div className="upload-text">{uploadText}</div>

                            {errorNotificationElement}
                        </div>
                        <div>
                            <input
                                type="file"
                                id="upload-image-input"
                                className="upload-image-input"
                                accept="text/csv"
                                onDrop={handleDrop}
                                onChange={handleAddImage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



