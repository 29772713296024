import { useEffect, useState } from 'react';
import UserListPopup from './UserListEdit';
import Pagination from '../../common/Pagination';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmPopUp from '../../common/ConfirmPopUp';
import { getRoleListing } from '../../store/slices/RoleUser';
import FullPageLoader from "../../common/FullPageLoader";
import { Tab, Nav } from 'react-bootstrap';
import AddUserPopup from './AddUserPopup';
import { getMasterConfig } from '../../store/slices/common';
import * as HELPER from '../../config/helper';
import { ROLE_FEATURES } from '@src/config/constant';

function UserListTable(props: any) {

    const dispatch: any = useDispatch();
    let [userManageList, setUserManageList] = useState<any>([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const [showUserPopup, setUserPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paginationData, setPaginationData] = useState<any>({});
    let [searchText, setSearchText] = useState('');
    let [activeTab, setActiveTab] = useState(1);
    const [showUserRole, setShowUserRole] = useState(false);
    let [roleList, setRoleList] = useState<any>([]);
    let [userDataToEdit, setUserDataToEdit] = useState<any>({});
    let [tabCount, setTabCount] = useState<any>({});
    let [filterData, setFilterData] = useState<any>({});
    const commonState = useSelector((state: any) => state.common);

    useEffect(() => {
        if (Object.keys(commonState.common).length === 0) {
            dispatch(getMasterConfig());
        }
        // eslint-disable-next-line
    }, []);

    let vertical_list = commonState?.common?.vertical_list??[];

    const getUserManageListDetails = async (params: any) => {
        // setSearchText('');
        let filters = {
            ...params,
            page_number: params?.page_number ?? 1,
            isPaginationApply: true
        }

        setFilterData(filters);

        setLoading(true);
        dispatch(getRoleListing.getUserManagementList(filters)).then((res: any) => {
            setLoading(false);
            if (res && res.status === 200) {

                res.data = res?.data?.map((data: any) =>{
                    let {password, ...rest} = data;
                    return rest });   /// Remove password by default
                setPaginationData({ ...res?.pagination });
                setUserManageList([...res?.data]);
                setTabCount({...res?.tabCount});
            }
        }).catch((err: Error) => {
            setLoading(false);
        })
    };



    const getRoleListDetails = async (params: any) => {
        let filters = {
            page_number: params?.page_number ?? 1
        }
        dispatch(getRoleListing.getRoleData(filters)).then((res: any) => {
            if (res && res.status === 200 && res.data) {
                roleList = res.data.filter((e: any) => e.status === 1).map((e: any) => {
                    return {
                        value: e.id,
                        label: e.roleName
                    }
                })
                setRoleList([...roleList]);
            }
            setLoading(false);
        }).catch((err: Error) => {
            setLoading(false);
        })
    };


    const searchUser = (event: any) => {

        let value = event.target.value;
        setSearchText(value);
    }

    const addNewUser = (action: boolean = true) => {
        setShowUserRole(action);
        document.body.classList.remove("overflow-hidden");

        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    }

    const showDeletePopUp = async (action: boolean, id: any) => {
        setIdToDelete(id);
        setShowConfirmation(action);
    }

    const confirmYes = async () => {
        dispatch(getRoleListing.userDelete({ id: idToDelete, is_delete: 1 })).then((res: any) => {
            if (res && res.status === 200) {
                toast.success(res.message);
                getUserManageListDetails({});
            }
        });
        confirmNo();
    };

    const confirmNo = () => {
        setShowConfirmation(false);
    };

    const userEditPopup = (action: boolean, userData: any) => {
        setUserPopup(action);
        action ? setUserDataToEdit(userData) : setUserDataToEdit({}); 
        document.body.classList.remove("overflow-hidden");
        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    }

    const handleRequestData = (params: any) => {
        let apiParams = {
            page_number: params.page_no
        }

        getUserManageListDetails({...filterData, ...apiParams});

    }

    const getApiData = async () => {
        await getUserManageListDetails({});
        await getRoleListDetails({});
    }

    useEffect(() => {
        getApiData();
        // eslint-disable-next-line
    }, []);

    /**
     * ON ENTER SEARCH 
     * @param event 
     */
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {

            getUserManageListDetails({...filterData, searchKeyword: searchText?.trim() || undefined, page_number: 1});
        }
    }
    return (

        <>
            <FullPageLoader show={loading} />
            <div className="top-filter-bg user-management-inner-tabs">
                <div className="selected-chips user-status-tab-chips">
                    <Tab.Container id="left-tabs-example" >

                      <Nav variant="pills" className="flex-column  nav nav-pills">
                            <div className="tab-list">
                                <Nav.Item>
                                    <Nav.Link className={activeTab === 1 ? "active" : ''} onClick={() => { setActiveTab(1); getUserManageListDetails({...filterData, page_number: 1,  status: undefined}) }}>All Users <span className='chip-count-span'>{tabCount.count_all_user || 0}</span> </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className={activeTab === 2 ? "active" : ''} onClick={() => { setActiveTab(2); getUserManageListDetails({ ...filterData, page_number: 1, status: 1 }) }}>Active Users <span className='chip-count-span'>{tabCount.count_active_user || 0}</span> </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className={activeTab === 3 ? "active" : ''} onClick={() => { setActiveTab(3); getUserManageListDetails({ ...filterData, page_number: 1, status: 0 }) }}>Inactive Users <span className='chip-count-span'>{tabCount.count_inactive_user || 0}</span></Nav.Link>
                                </Nav.Item>
                            </div>
                            <div className='search-user-add-btn'>
                            <fieldset className="search-bx">
                                <div className="material">
                                    <input id="Search" type="text" placeholder="Search User by name, email, mobile..." onChange={(e: any) => searchUser(e)} value={searchText} className="form-input" onKeyDown={handleKeyDown} />
                                    <i className="ic-search"></i>
                                </div>
                            </fieldset>
                            {
                                HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['task_role_assignment'], accessType: 'edit' }) &&
                            <button className="btn-line" onClick={() => addNewUser(true)}>
                                <span>Add User</span>
                            </button>
                            }
                            </div>
                        </Nav>
                    </Tab.Container>

                </div>
            </div>


          
                <div className="lead-list-data-table user-managment-table-data">
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Mobile No</th>
                                <th>Email</th>
                                <th>Reporting to</th>
                                <th>Vertical</th>
                                <th>Role Assigned</th>
                                <th>Status</th>
                                <th>Edit</th>
                                {
                                HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['task_role_assignment'], accessType: 'edit' }) &&
                                <th>Delete</th>
                                }
                                </tr>
                        </thead>
                        <tbody>
                            {(userManageList && userManageList.length > 0) ? userManageList.map((row: any, index: number) => (
                                <tr key={index}>
                                    <td>{row.name}</td>
                                    <td>{row.mobile}</td>
                                    <td title={row.email}>{row.email}</td>
                                    <td>{row.reportee_name}</td>
                                    <td>{vertical_list?vertical_list[0]?.label:'Carmudi'}</td>
                                    <td>{row.assigned_role_name} </td>
                                    <td><span className={+row.status ? "active-dot" : "inactive-dot"}>{+row.status ? "Active" : "InActive"}</span></td>
                                    <td>
                                        <ul className="action-btn">
                                            <li onClick={() => userEditPopup(true, row)}>
                                                <i className="ic-edit"></i>
                                            </li>
                                        </ul>

                                    </td>
                                    {
                                     HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['task_role_assignment'], accessType: 'edit' }) &&
                                    <td className="action-btn">
                                        <ul className="action-btn">
                                            <li onClick={() => showDeletePopUp(true, row.id)}>
                                                <i className="ic-delete"></i>
                                            </li>
                                        </ul>
                                    </td>
                                    }
                                </tr>
                            )) : 
                            <tr><td colSpan={9} style={{ textAlign: 'center' }}>No data found</td></tr>
                        }
                        </tbody>
                    </table>

                    {(paginationData && paginationData['total'] > 10) && <Pagination paginateData={{
                        page: 1,
                        loading: false,
                        totalLengthWithoutPagination: paginationData['total'],
                        paginationData,
                        filteredData: filterData
                    }}
                        sendRequestDataToParent={handleRequestData} />}

                    {showUserPopup && <UserListPopup
                        showUserPopup={showUserPopup}
                        userEditPopup={(e: boolean) => {
                            userEditPopup(e, {});
                        }}
                        userDetails={userDataToEdit}
                        role_list={roleList}
                        setLoading={setLoading}
                        getUserManageListDetails={(e: any) => {
                            getUserManageListDetails(e)
                        }} 
                        setSearchText = {setSearchText}
                        />}

                    {showUserRole && <AddUserPopup
                        showUserRole={showUserRole}
                        role_list={roleList}
                        setLoading={setLoading}
                        addNewUser={(e: boolean) => {
                            addNewUser(e);
                        }}
                        getUserManageListDetails={(e: any) => {
                            getUserManageListDetails(e)
                        }} 
                        setSearchText = {setSearchText}
                        />}
                    {showConfirmation === false ? ("") : (
                        <ConfirmPopUp
                            confirmNo={confirmNo}
                            confirmYes={confirmYes}
                            message={"Are you sure to delete this user ?"}
                        />
                    )}
                </div>
            



        </>
    )
}

export default UserListTable

