import { APP_TYPE_RENEWAL, APP_TYPE_STANDALONE, APP_TYPE_WITH_UCF, EXPECTED_INSTALLMENT } from '@src/config/constant';
import DynamicFormField from './DynamicFormField';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { updateInsuranceForm } from './FunctionList';
import { updateRenewalState } from '@src/store/slices/insurance';

const PersonalDetails = (props:any) => {

    const [refresh, setRefresh] = useState(false)
    const insuranceState = useSelector((state: any) => state.insurance);
    const dispatch: any = useDispatch()

    const onValueChange = (fieldItem:any) => {
        if (props.onChangeText) props.onChangeText(fieldItem)  
    }

    useEffect(() => {
        let personalDetails = props?.sectionItem?.blocks?.[0]?.fields
        let residentialDetails = props?.sectionItem?.blocks?.[1]?.fields

        if (props.apptype === APP_TYPE_WITH_UCF) {
            if (insuranceState?.activeLoanID && Object.keys(insuranceState?.activeLoanID).length) {
                updateInsuranceForm(insuranceState?.activeLoanID?.customer_details, [...personalDetails, ...residentialDetails], props?.stateCityList)
            } else updateInsuranceForm({}, [...personalDetails, ...residentialDetails ], [], true)

            setRefresh(!refresh)
        } else if (props.apptype === APP_TYPE_RENEWAL && !insuranceState?.renewalUpdateFlag?.customerDetails) {
            if (insuranceState.leadDetail && Object.keys(insuranceState.leadDetail).length) {
                updateInsuranceForm({...insuranceState.leadDetail, type: 'renewal'}, [...personalDetails, ...residentialDetails], props?.stateCityList)
                dispatch(updateRenewalState({...insuranceState.renewalUpdateFlag, customerDetails: true}))
            } else updateInsuranceForm({}, [...personalDetails, ...residentialDetails ], [], true)

            setRefresh(!refresh)
        }
       // eslint-disable-next-line
    }, [props.apptype, insuranceState.activeLoanID, insuranceState.leadDetail])


    useEffect(() => {
        //push expected installment date fieldItem
        if ([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(props.apptype)) {
            let personalDetails = props?.sectionItem?.blocks?.[0]?.fields
            let isFieldExists = personalDetails?.find((ele: any) => ele.name === "expected_installment")

            if (!isFieldExists && personalDetails) {
                // personalDetails.push({ ...EXPECTED_INSTALLMENT_DATE, value: {} })
                //PUSH BEFORE paid_by_customer
                var elementPos = personalDetails.map((elm:any)=> {return elm.name }).indexOf("paid_by_customer");
                personalDetails.splice(elementPos, 0, EXPECTED_INSTALLMENT);

            }
        }
        // eslint-disable-next-line
    }, [props])

    return (
        <div className="loan-filed-sec">
            <div className="form-group-bx">
                <div className="row">
                    {
                        props?.sectionItem?.blocks?.map((blockItem: any, index: number) =>
                            <React.Fragment key={index}>
                                <div className="col-md-12">
                                    <h3>{blockItem.title}</h3>
                                </div>
                                <DynamicFormField
                                    fields={blockItem?.fields}
                                    applicationType={props.applicationType}
                                    onValueChange={onValueChange}
                                    stateCityList={props?.stateCityList}
                                    insuranceType={props?.insuranceType}
                                    apptype={props.apptype}
                                    callfrom={props.apptype !== APP_TYPE_STANDALONE ? 'personal_details' : ''}
                                    activeLoanID={props.activeLoanID}
                                    saveDetails={props?.saveDetails}
                                    sectionItemAll={props?.sectionItemAll}
                                />
                            </React.Fragment>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default PersonalDetails