import { useEffect, useState } from 'react';
import EmailTemplateView from './email-template/EmailTemplateView';
import ShareDetailsForm from './email-template/ShareDetailsForm';
import { useSelector } from 'react-redux';
import { API_TYPES, DOCUMENT_CATEGORY, TEMPLATE_TYPE_POLICY_SHARED } from '@src/config/constant';
import { fetchLeadData, updateLeadDetailsInStore } from '@src/store/slices/insurance';
import { useDispatch } from 'react-redux';

const InsurancePolicyShared = (props: any) => {

    const [templateData, setTemplateData] = useState("");
    const [validDocuments, setValidDocuments] = useState<any>({firstSubmit: [], disbursal: []});

    const insuranceState = useSelector((state:any)=> state.insurance);
    const dispatch = useDispatch()

    useEffect(()=>{
        //CHECK VALID DOCUMENTS WHO HAVE IMAGES UPLOADED
        if(insuranceState.leadDetail && insuranceState.leadDetail.images && insuranceState.leadDetail.images.length) {
            for(let elm of insuranceState.leadDetail.images){
                if(elm.doc_type_id === DOCUMENT_CATEGORY['firstSubmit']['doc_type_id']){
                    validDocuments['firstSubmit'] = [...validDocuments['firstSubmit'], elm.doc_id];
                }else if(elm.doc_type_id === DOCUMENT_CATEGORY['disbursal']['doc_type_id']){
                    validDocuments['disbursal'] = [...validDocuments['disbursal'], elm.doc_id];
                }
            }
            setValidDocuments({...validDocuments});
        }
       // eslint-disable-next-line
    }, [insuranceState.leadDetail]);

    useEffect(() => {
        if(insuranceState.leadDetail.customer_api_call) {
            fetchLeadData({ "lead_id": props.leadHashId, "type": TEMPLATE_TYPE_POLICY_SHARED, apiType: API_TYPES['templateData'] }).then((res: any) => {
                if (res && res.data && Object.keys(res.data).length) {
                    let templateInfo = res.data.templateData
                    let response = res.data
                    delete response.templateInfo
                    dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, sharedTemplateData: templateInfo, ...response }))
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceState.leadDetail.customer_api_call])

    return (
        <div className="image-form-outer">

            <EmailTemplateView templateType="customer" insuranceDetails={props.insuranceDetails} setTemplateData={(data: string)=> setTemplateData(data)} />

            <ShareDetailsForm templateType="customer" getDocumentsIds={validDocuments} insuranceDetails={props.insuranceDetails} templateData={templateData} title="Insurance Policy Shared" activeTab={props.activeTab}/>

        </div>
    );
};

export default InsurancePolicyShared;
