import { toast } from "react-toastify";
import { createSlice } from '@reduxjs/toolkit';
import { BulkPaymentService } from "../../services/BulkPaymentService";

interface APIResponse {
    data: {
        status: number,
        message: string
    }
}

const payment = createSlice({
    name: 'payment',
    initialState: {
        paymentList: {},
        bulkPayment : {},
        leadIds: {},
        images : {}
    },
    reducers: {
        paymentListing: (state, action) => {
            state.paymentList = action.payload
        },
        saveBulkPayment : (state, action) =>{
            state.bulkPayment = action.payload
        },
        leadIdsList : (state, action) =>{
            state.leadIds = action.payload
        },
        saveProofDocs : (state, action) => {
            state.images = action.payload
        }
    }
})


export default payment.reducer;

const { paymentListing, saveBulkPayment, leadIdsList } = payment.actions


/**
 * Function to make API call to fetch partner mapping listing
 * @param params 
 * @returns 
 */
export const getBulkPaymentList = { 
    getBulkPayData: (params: object) => async (dispatch: any) => {
        return new Promise((resolve, reject) => {
            BulkPaymentService.getBulkPaymentList(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            dispatch(paymentListing(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    },
    saveBulkPaymentData : (params: object) => async (dispatch: any) => {
        return new Promise((resolve, reject) => {
            BulkPaymentService.saveBulkPayment(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            dispatch(saveBulkPayment(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    },
    getInsuranceIdsList : (params: object) => async (dispatch: any) => {
        return new Promise((resolve, reject) => {
            BulkPaymentService.getInsuranceIdsList(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            dispatch(leadIdsList(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    },
    savePaymentProofs : async(params: object) => {
        return new Promise((resolve, reject) => {
            BulkPaymentService.paymentProofDocs(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            // dispatch(saveProofDocs(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    }, 

    deleteProofDocument : async(params: object) => {
        return new Promise((resolve, reject) => {
            BulkPaymentService.deleteProofDocument(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    },

    deleteBulkPaymentLead : async(params: object) => {
        return new Promise((resolve, reject) => {
            BulkPaymentService.deleteBulkPaymentLead(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    },
    getReferencePaymentData: (params: object) => {
        return new Promise((resolve, reject) => {
            BulkPaymentService.getReferencePaymentData(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            if(Object.keys(response.data).length){
                                resolve(response.data)
                            }
                        }else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    }
   
}

