import { useEffect } from "react";

const ConfirmPopup = (props: any) => {
    let {show} = props;
    useEffect(()=>{
        if(show) document.body.classList.add("overflow-hidden");
        return ()=>{
            document.body.classList.remove("overflow-hidden");
        }
    }, [show])
    return (
        <div className="mark-lost-popup deleteConfirmation">
            <div className="modal display-block" role="dialog">
                <section className="modal-main" role="document">
                    <div className="modal-header">
                        <h2 className="modal-title">{props.title || 'Confirm'}</h2>
                        <button type="button" className="close_icn" data-dismiss="modal" aria-label="Close" onClick={() => {
                            props.confirmNo();
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h4>{props.message || "Are you sure to want to delete?"}</h4><br></br>
                    </div>
                    
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btn-lg m-sm-r" onClick={() => {
                            props.confirmYes();
                        }}>Yes</button>
                        
                        <button type="button" className="btn btn-line" onClick={() => {
                            props.confirmNo();
                        }}>No</button>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default ConfirmPopup;