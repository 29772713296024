import React, { useEffect, useState } from "react";
import Modal from "../../common/ModalPopup";
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { getRoleListing } from "../../store/slices/RoleUser";

function AddRolePopup(params: any) {
    let { addNewRole, showAddRole } = params;
    const [errors, setError] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    const dispatch: any = useDispatch();

    useEffect(() => {
        
    }, []);

    const handleChange = async (event:any, key:string) => {
		if (key) {
            formData[key] = event.target.value;
        }
		errors[key] = '';
		setError({ ...errors });
        setFormData({ ...formData });

	}

	const isFormValid = (formData:any) => {
		let isValid = true;
		if (!formData.role_name || formData.role_name === '') {
			errors['name'] = 'This Field is Required';
			isValid = false;
		}else if(formData && formData.role_name && (formData.role_name.split('').length < 3 || formData.role_name.split('').length > 30)){
			errors['name'] = 'Role Name should be between 3 to 30';
			isValid = false;
		}
		if(formData && formData.role_description && (formData.role_description.split('').length < 3 || formData.role_description.split('').length > 500)){
			errors['description'] = 'Description should be between 3 to 500';
			isValid = false;
		}
		setError({ ...errors });
		return isValid;

	}

    const getFormData = async () => {
        let postData:any = {};
        if (formData && formData.role_name) postData['role_name'] = formData?.role_name?.replace(/\s+/g,' ').trim();
        if (formData && formData.role_description) postData['role_description'] = formData?.role_description?.replace(/\s+/g,' ').trim();
        postData['status'] = 1;
        return postData;
    }

	const handleAddRole = async (e:any) => {
		e.preventDefault();
        let postData = await getFormData();
		if (isFormValid(postData)) {
            dispatch(getRoleListing.saveRoleData(postData)).then((res: any) => {
                if (res && res.status === 200) {
                    setFormData({});
                    addNewRole(false);
                    params.getRoleListDetails();
                    toast.success(res.message);
                }
            })
		}
	}
    return (
        <React.Fragment>
            <div className="user-screen-popup add-role-popup">
                <Modal handleClose={() => addNewRole(false)} show={showAddRole}>
                    <div className="modal-header">
                        <h2>Add Role</h2>
                    </div>
                    <div className="modal-body" >
                        <div className="row">
                            <div className="col-md-12">
                                    <fieldset className="form-filed">
                                        <div className="material">
                                            <input id="roleName" type="text" placeholder=" " className="form-input" value={formData.role_name} onChange={(e) => handleChange(e, "role_name")}/>
                                            <label data-label="Role Name" className="form-label"></label>
                                        <span className="error-txt">
                                            {errors.name || ""}
                                        </span>
                                        </div>
                                    </fieldset>
                            </div>
                            <div className="col-md-12">
                                <fieldset className="form-filed">
                                    <div className="material">
                                        <textarea id="description"  placeholder=" " name="RoleDescription" className="form-input" value={formData.role_description} onChange={(e) => handleChange(e, "role_description")}/>
                                        <label data-label="Description" className="form-label"></label>
                                        <span className="error-txt">
                                            {errors.description || ""}
                                        </span>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="col-md-12">
                                <button className="btn-primary" onClick={(e) => handleAddRole(e)}>Add Role</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default AddRolePopup