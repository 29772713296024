import Modal from '@src/common/ModalPopup';
import moment from 'moment';

export default function DocumentHistory(props: any) {
    let { ViewTimeLine, handleClose, data } = props

    return (
        <div className="view-timeline-popup image-timeline-popup doc-history-timeline">
            <Modal show={ViewTimeLine} handleClose={handleClose} >
                <div className="modal-header">
                    <h2>Document History</h2>
                </div>
                {
                    data && data.length ?
                        <div className="modal-body" >

                            <table>
                                <tbody>
                                    {
                                        data.map((history: any, idx: number) => {
                                            let date = moment(history.created_at).format('DD-MMM')
                                            let time = moment(history.created_at).format("hh:mm")
                                            return (
                                                <tr key={idx}>
                                                    <td>
                                                        <span className="date">{date}</span>
                                                        <span className="time">{time}</span>
                                                    </td>
                                                    <td>
                                                        <span className="heading">{history.parent_tag_name ? history.parent_tag_name + "(" + history.tag_name + ")" : history.tag_name}</span>
                                                        <span className="Subheading">{(history.comment)?history.comment:''}</span>
                                                    </td>
                                                    <td>{`By ${history.created_by_name || ""}`}</td>
                                                    {/* <td>
                                                        <i className="ic-save_alt"></i>
                                                    </td> */}
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        // <tr>
                                        //     <td>
                                        //         <span className="date">28 Feb</span>
                                        //         <span className="time">12:30 pm</span>
                                        //     </td>
                                        //     <td>
                                        //         <span className="heading">Residence Proof (Land Proof)</span>
                                        //         <span className="Subheading">Comment added</span>
                                        //     </td>
                                        //     <td>Added by Gaurav</td>
                                        //     <td>
                                        //         <i className="ic-save_alt"></i>
                                        //     </td>
                                        // </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                        : "No Data Found"
                }
            </Modal>
        </div>
    )
}