import { DOCUMENTS_CATEGORY_INBOUND } from "@src/config/constant";

const validateInstallmentDocs = (category: any, leadDetail: any, activeImage: any) => {
    const installmentDoc = getInstallmentDoc(category?.value) || '';
    const activeImgInstallmentDoc = getInstallmentDoc(activeImage?.doc_id) || '';

    const checkInstallmentDoc = leadDetail?.installment_details?.[installmentDoc || activeImgInstallmentDoc]

    if (checkInstallmentDoc) {
        const errMsg = (installmentDoc || activeImgInstallmentDoc)?.replaceAll("_", " ")
        return { error: true, msg: `Proof of ${errMsg} is locked` };
    }

    return false;
};

const getInstallmentDoc = (value: any) => {
    return Object.keys(DOCUMENTS_CATEGORY_INBOUND).find((e) => DOCUMENTS_CATEGORY_INBOUND[e as keyof typeof DOCUMENTS_CATEGORY_INBOUND] === value)?.replaceAll("_file", "");
}

export {
    validateInstallmentDocs
};