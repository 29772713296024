import React from 'react';
import { useSelector } from 'react-redux';
import InputField from "../../../../common/InputField"
import ReactSelect from '@common/ReactSelect';
import { APP_TYPE_AGENCY, INS_TYPE_CTPL } from '@src/config/constant';

export default function VehicleDetailsForm(props: any): ReturnType<React.FC> {
    const commonState = useSelector((state: any) => state.common);
    const insuranceState = useSelector((state: any) => state.insurance)
    const { application_type_id } = insuranceState?.leadDetail || {}


    const changeHandler = (value: any, key: string) => {

        let formFields: object = props.vehicleDetails;
        if (["fuel_type", "seat_capacity", "transmission"].includes(key)) {
            formFields = { ...formFields, [key]: value.value }
        } else {
            formFields = { ...formFields, [key]: value.target.value }
        }
        props.changeHandler(formFields, "vehicle-details");
    }

    let { vehicleDetails, errors } = props;
    return (
        <>
            <div className="">
                <h2>Vehicle Details</h2>
            </div>
            <div className="row">

                <fieldset className="col-md-6">
                    <div className="material" title={vehicleDetails?.plate_no}>
                        <InputField
                            inputProps={{
                                id: "plate_no",
                                name: "plate_no",
                                className: "form-input",
                                type: "text",
                                value: vehicleDetails.plate_no || '',
                                maxLength: '',
                                disabled: props.disable,
                                placeholder: " "
                            }}
                            onChange={(e: any) => changeHandler(e, 'plate_no')}
                        />
                        <label data-label={(props.insuranceType === INS_TYPE_CTPL) ? "Plate Number **" : "Plate Number *"} className="form-label"></label>
                        {
                            errors.plate_no ?
                                <span className="error-txt error-txt-t30">{errors.plate_no}</span>
                                : ''
                        }
                    </div>
                </fieldset>
                {
                    (props.insuranceType === INS_TYPE_CTPL) && <fieldset className="form-filed col-md-6">
                        <div className="material" title={vehicleDetails?.mv_number}>
                            <InputField
                                inputProps={{
                                    id: "mv_number",
                                    name: "mv_number",
                                    className: "form-input",
                                    type: "text",
                                    value: vehicleDetails.mv_number || '',
                                    maxLength: '',
                                    disabled: props.disable,
                                    placeholder: " "
                                }}
                                onChange={(e: any) => changeHandler(e, 'mv_number')}
                            />
                            <label data-label="MV File Number **" className="form-label"></label>
                            {
                                errors.mv_number ?
                                    <span className="error-txt error-txt-t30">{errors.mv_number}</span>
                                    : ''
                            }
                        </div>
                    </fieldset>
                }

                <fieldset className="col-md-6">
                    <div className="material" title={vehicleDetails?.machine_number}>
                        <InputField
                            inputProps={{
                                id: "machine_number",
                                name: "machine_number",
                                className: "form-input",
                                type: "text",
                                value: vehicleDetails.machine_number || '',
                                maxLength: '',
                                disabled: false,
                                placeholder: " "
                            }}
                            onChange={(e: any) => changeHandler(e, 'machine_number')}
                        />
                        <label data-label={application_type_id === APP_TYPE_AGENCY ? "Machine Number *" : "Engine Number"} className="form-label"></label>
                        {
                            errors.machine_number ?
                                <span className="error-txt">{errors.machine_number}</span>
                                : ''
                        }
                    </div>
                </fieldset>

                <fieldset className="col-md-6">
                    <div className="material" title={vehicleDetails?.chassis_number}>
                        <InputField
                            inputProps={{
                                id: "chassis_number",
                                name: "chassis_number",
                                className: "form-input",
                                type: "text",
                                value: vehicleDetails.chassis_number || '',
                                maxLength: '',
                                disabled: false,
                                placeholder: " "

                            }}
                            onChange={(e: any) => changeHandler(e, 'chassis_number')}
                        />
                        <label data-label={`Chassis Number ${application_type_id === APP_TYPE_AGENCY ? '*' : ''}`} className="form-label"></label>
                        {
                            errors.chassis_number ?
                                <span className="error-txt">{errors.chassis_number}</span>
                                : ''
                        }
                    </div>
                </fieldset>

                <fieldset className="col-md-6">
                    <div className="material" title={vehicleDetails?.color}>
                        <InputField
                            inputProps={{
                                id: "color",
                                name: "color",
                                className: "form-input",
                                type: "text",
                                value: vehicleDetails.color || '',
                                maxLength: '',
                                disabled: false,
                                placeholder: " "

                            }}
                            onChange={(e: any) => changeHandler(e, 'color')}
                        />
                        <label data-label={`Color ${application_type_id === APP_TYPE_AGENCY ? '*' : ''}`} className="form-label"></label>
                    </div>
                </fieldset>

                <ReactSelect options={commonState?.common?.fuel_type} value={commonState?.common?.fuel_type?.find((v: any) => vehicleDetails?.fuel_type?.toLowerCase() === v?.value?.toLowerCase()) || {}} placeholder={"Fuel Type *"} handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="fuel_type" fieldSetClassName="col-md-6" isDisabled={props.disable} />


                <ReactSelect options={commonState?.common?.transmission} value={commonState?.common?.transmission?.find((v: any) => vehicleDetails?.transmission?.toLowerCase() === v?.value?.toLowerCase()) || ''} placeholder={`Transmission ${application_type_id === APP_TYPE_AGENCY ? '*' : ''}`} handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="transmission" fieldSetClassName="col-md-6" isDisabled={props.disable} />


                <ReactSelect options={commonState?.common?.seat_capacity} value={commonState?.common?.seat_capacity?.find((v: any) => +vehicleDetails.seat_capacity === +v.value) || {}} placeholder={`Seat Capacity ${application_type_id === APP_TYPE_AGENCY ? '*' : ''}`} handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="seat_capacity" fieldSetClassName="col-md-6" isDisabled={props.disable} />


            </div>
        </>

    );
}

