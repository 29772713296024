import React, { useEffect, useState } from 'react';
import ToggleSwitch from '../../common/ToggleSwitch';
import Pagination from '../../common/Pagination';
import AddEditInsurancePartner from './AddEditInsurancePartner';
import { updatePartnerMappingStatus } from '../../store/slices/partner';
import { toast } from 'react-toastify';
import * as HELPER from '../../config/helper';
import { ROLE_FEATURES } from '@src/config/constant';

type SelectedPartnerType = {
    id: number,
    name: string,
    application_type_id: number,
    insurance_type_id: number,
    logo: string
}


export default function InsurancePartnerTable(props: any): ReturnType<React.FC> {
    let { apiResponse, insuranceTypeList, methodList, loading } = props
    let [showEditInsPartnerIcon, setShowEditInsPartnerIcon] = useState<boolean>(false)
    let [tableData, setTableData] = useState<any[]>([])
    let [paginationData, setPaginationData] = useState<{ total?: number }>({})
    let [selectedPartner, setSelectedPartner] = useState<SelectedPartnerType>({ id: 0, name: '', application_type_id: 0, insurance_type_id: 0, logo: '' })
    let totalLengthWithoutPagination = 0
    let page = 1


    const showModalEditInsurance = () => {
        setShowEditInsPartnerIcon(true)
    }

    const hideModalEditInsurance = () => {
        setSelectedPartner({ id: 0, name: '', application_type_id: 0, insurance_type_id: 0, logo: '' })
        setShowEditInsPartnerIcon(false)
    }

    const handleEditBtnClick = (item: any) => {
        setSelectedPartner(item)
        showModalEditInsurance()
    }

    const handleRequestData = (params: any) => {
        let apiParams = {
            page_number: params.page_no
        }

        props.getPartnerMappings(apiParams)

    }

    const handleToggleSwitch = async (element: { partner_id?: number, status: number }) => {
        props.handleLoader(true)

        let apiParams = {
            partner_id: element.partner_id,
            status: element.status === 1 ? 2 : 1
        }

        const updateMapping: any = await updatePartnerMappingStatus(apiParams)
        if (updateMapping && updateMapping.status === 200) {
            toast.success('Status Updated Successfully')

            if (apiResponse && apiResponse.data) {
                let response = apiResponse.data

                for (let data of response) {
                    if (data.partner_id === apiParams.partner_id) {
                        data['status'] = apiParams.status
                    }
                }

                setTableData(response)
            }

        }

        props.handleLoader(false)

    }

    useEffect(() => {
        if (apiResponse) {
            let { data, pagination } = apiResponse || {}
            setTableData(data ?? [])
            setPaginationData(pagination ?? {})
        }
    }, [apiResponse])

    const runPaginationFunc = () => { };

    const updateSelectedPartner = (params:any) => {
        tableData = tableData.map((partner:any)=> (+partner.id === +params.id) ? params : partner);
        setTableData(tableData ?? []);
    }

    return (
        <div>
            <div className="financer-table-list">

                <table>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>Insurance Partner Name</th>
                            <th>Logo</th>
                            <th>Insurance Type</th>
                            <th>Method</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData && tableData.map((element: { id: number, name: string, insurance_type: string, logo: string, application_type: string, status: 1 }, indx) => {

                            return (
                                <tr key={indx}>
                                    <td>{element.id}</td>
                                    <td>{element.name}</td>
                                    <td><img src={element.logo ?? ''} alt='partner_logo' /></td>
                                    <td>{element.insurance_type}</td>
                                    <td>{element.application_type}</td>
                                    <td>
                                        <div className="on-off-switch">
                                            <ToggleSwitch disabled={!HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['insurance_partner_management'], accessType: 'edit'})} checked={element.status === 1 ? true : false} onChange={() => handleToggleSwitch(element)} />
                                        </div>
                                    </td>
                                    <td>
                                        <ul className='ulaction-btn'>
                                            <li title="Edit" onClick={() => { handleEditBtnClick(element) }}>
                                                <i className='ic-createmode_editedit' style={{ cursor: 'pointer' }}></i>
                                            </li>
                                        </ul>
                                    </td>

                                </tr>)
                        })}

                    </tbody>
                </table>

                <div className="pagination-outer">
                    {paginationData && paginationData.total ? <Pagination
                        paginateData={{
                            page,
                            runPaginationFunc,
                            loading,
                            tableData: props.getPartnerMappings,
                            totalLengthWithoutPagination,
                            paginationData,
                            filteredData: props.searchFilters
                        }}
                        sendRequestDataToParent={handleRequestData}
                    /> : ''}
                </div>

            </div>

            <AddEditInsurancePartner callFrom={'EditInsurancePartner'} showAddInsPartnerIcon={showEditInsPartnerIcon} handleModalClose={hideModalEditInsurance} insuranceTypeList={insuranceTypeList} methodList={methodList} selectedPartner={selectedPartner} fetchPartnerMappings={props.getPartnerMappings} handleLoader={props.handleLoader} updateSelectedPartner={updateSelectedPartner}/>

        </div>
    )
}