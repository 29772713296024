import { FIELD_TYPE } from "@src/config/constant";
import { DynamicFormField } from "./DynamicFormField";
import { useSectionList } from "@src/hooks/useSectionList";
import InsuranceService from "@src/services/InsuranceService";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { updateInsuranceFormAgency } from "../create/FunctionList";
import { useSelector } from "react-redux";

export const PersonalDetails = forwardRef((props: any, ref) => {
    let { setLoading } = props
    const { fetchSectionItem, setSectionItem, fetchItem } = useSectionList({})
    const [personalDetailSectionItem, setPersonalDetailSectionItem] = useState<any>(null)
    const personalDetailItem = fetchSectionItem('personal_details', 'personal_info')
    const residentialDetailItem = fetchSectionItem('personal_details', 'residential_info')

    const insuranceState = useSelector((state: any) => state.insurance)
    const autoFillStatus = insuranceState?.insuranceData && Object.keys(insuranceState?.insuranceData)?.length

    const setFieldItem = (key: string, value: any) => {
        const field = setSectionItem('personal_details', 'personal_info', key, value)
        return field
    }

    const getFieldItem = (key: string) => {
        const field = fetchItem(key, [...personalDetailItem?.fields, ...residentialDetailItem?.fields])
        return field
    }

    const fetchCustomerDataWithMobileNumber = async (mobile: any) => {
        setLoading(true)
        let params = { phone: mobile }
        let result = await InsuranceService.getCustomerDetails(params)

        if (result?.data?.data) {
            let customerDetails = result.data.data;
            let response: any;
            if (customerDetails?.['per_city']) {
                response = await InsuranceService.getlocalityByCity({ city: [customerDetails?.['per_city'], customerDetails?.['cur_city']] });
            }
            setLoading(false)
            let localityList = response?.data?.data || []
            let sectionData = personalDetailSectionItem
            let blockList = sectionData['blocks'] as []
            blockList?.forEach((bloackItem: any) => {
                let fieldList = bloackItem['fields'] as []
                fieldList?.map(async (fieldItem: any) => {
                    if (autoFillStatus && ['name', 'per_full_address', 'cur_full_address'].includes(fieldItem.name) && fieldItem?.value) {
                        //do nothing
                    } else {
                        switch (fieldItem.type) {
                            case FIELD_TYPE.CURRENCY:
                            case FIELD_TYPE.TELEPHONE:
                            case FIELD_TYPE.SUM:
                            case FIELD_TYPE.TEXT_BOX:
                            case FIELD_TYPE.MOBILE_FIELD:
                            case FIELD_TYPE.FUTURE_DATE:
                            case FIELD_TYPE.DATE_PICKER:
                                //inputField
                                if (customerDetails[fieldItem.name]) {
                                    fieldItem.value = customerDetails[fieldItem.name]
                                    fieldItem.error_message = ""
                                }
                                break
                            case FIELD_TYPE.CHECK_BOX:
                                //checkbox
                                if (customerDetails[fieldItem.name]) {
                                    fieldItem.value = customerDetails[fieldItem.name] === 1 ? false : true
                                    fieldItem.error_message = ""
                                }
                                break
                            case FIELD_TYPE.LOCALITY:
                                if (customerDetails[fieldItem.name]) {
                                    fieldItem.value = customerDetails[fieldItem.name];
                                    fieldItem.options = localityList?.map((item: any) => { return { label: item.label, value: item.value } }) || []
                                    fieldItem.error_message = ""
                                }
                                break
                            default: {
                                //dropdown
                                if (customerDetails[fieldItem.name]) {
                                    fieldItem.value = customerDetails[fieldItem.name]
                                    fieldItem.error_message = ""
                                }
                            }
                        }
                    }
                })
            })
        } else {
            setLoading(false)
        }
    }

    useImperativeHandle(ref, () => ({
        getData: () => ({
            personalDetails: [...personalDetailItem?.fields],
            residentialDetails: [...residentialDetailItem?.fields]
        })
    }))

    const autoFillData = async (data: any, clear?: boolean) => {
        const updatedSectionList = await updateInsuranceFormAgency({ ...data }, [...personalDetailItem?.fields, ...residentialDetailItem?.fields], clear)
        let newSection = personalDetailSectionItem?.blocks?.[0]?.fields
        if (newSection) {
            newSection = updatedSectionList
            setPersonalDetailSectionItem(personalDetailSectionItem)
        }
    }

    useEffect(() => {
        let personalDetails = fetchSectionItem('personal_details')
        setPersonalDetailSectionItem(personalDetails)
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let { insuranceData } = insuranceState
        if (insuranceData && Object.keys(insuranceData)?.length && !insuranceData?.autoFillPersonalDetails) {
            autoFillData(insuranceData)
        } else if (!(insuranceData && Object.keys(insuranceData)?.length)) {
            autoFillData({}, true)
        }
    // eslint-disable-next-line
    }, [insuranceState.insuranceData])

    return (
        <div className="loan-filed-sec">
            <div className="form-group-bx">
                <div className="row">
                    {
                        personalDetailSectionItem?.blocks?.map((blockItem: any, index: number) =>
                            <React.Fragment key={index}>
                                <div className="col-md-12">
                                    <h3>{blockItem.title}</h3>
                                </div>
                                <DynamicFormField
                                    fields={blockItem?.fields}
                                    setFieldItem={setFieldItem}
                                    getFieldItem={getFieldItem}
                                    stateCityList={props.stateCityList}
                                    fetchCustomerData={fetchCustomerDataWithMobileNumber}
                                    autoFillStatus={autoFillStatus}
                                />
                            </React.Fragment>
                        )
                    }

                </div>
            </div>
        </div>
    )
})