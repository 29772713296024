import { BrowserRouter } from 'react-router-dom';
import AppRoutes from "@src/routes/routes";
import './styles/App.scss';
import store from './store/store';
import { Provider } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
    return (

        <div className="App">
            <Provider store={store}>
                <BrowserRouter>
                    <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} icon={false}/>
                    <AppRoutes />
                </BrowserRouter>
            </Provider>
        </div>


    );
}

export default App;
