import { updateMasterRates, updateReduxMasterRates } from '../../../store/slices/common';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import * as HELPER from "../../../config/helper";
import { ROLE_FEATURES } from '@src/config/constant';
import ReactSelect from '@common/ReactSelect';

type InputFieldType = {
    apptype: { label: string, value: number } | null,
    aon_rate: number | null
}


export default function Deviation(props: any): ReturnType<React.FC> {
    let { appTypeList, userId, handleLoader, handleModalClose } = props
    let [inputField, setInputFields] = useState<InputFieldType>({ apptype: null, aon_rate: null })
    let [errors, setErrors] = useState<any>()

    let dispatch = useDispatch()
    let commonState = useSelector((state: any) => state.common)

    let isRateEditable = HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_module'], accessType: 'edit' })

    /**
     * function to handle change in inputs
     * @param name 
     * @param value 
     */
    const handleChange = (name: string, value: any) => {

        if (name === 'aon_rate' && value) {
            //regex to accept numbers from 0-100 non-negative and upto 2 decimal places
            let isValid = (/^(?<whole>100|\d{1,2})(\.\d{0,2})?$/).test(value)
            if (!isValid) return
        }

        setInputFields((prev: any) => {
            return {
                ...prev,
                [name]: value
            }
        })

        //for displaying prefill aon rate based on apptype
        if (name === 'apptype') {
            autoFillPreviousRates(value.value)
        }
    }

    /**
     * checks if all inputs are valid or not
     * @returns boolean
     */
    const checkValidation = (): boolean => {
        let error = false
        setErrors({})

        if (inputField && Object.keys(inputField).length) {
            for (let key in inputField) {
                let value: any = inputField[key as keyof InputFieldType]

                if (value === "" || value < 0) {
                    error = true
                    setErrors((prev: any) => {
                        return {
                            ...prev,
                            [key]: true
                        }
                    })
                }
            }
        }
        return error
    }

    /**
     * executes on Save button click
     */
    const handleSubmit = async () => {
        let isErrorExist = checkValidation()

        if (!isErrorExist) {
            handleLoader(true)

            let apiParams = {
                aon_rate: {
                    application_type_id: inputField?.apptype?.value,
                    value: inputField?.aon_rate
                },
                user_id: userId
            }
            let updateAonRate: any = await updateMasterRates(apiParams)

            if (updateAonRate && updateAonRate.status && updateAonRate.status === 200) {
                toast.success("AON Rate Updated Successfully")
                handleModalClose()

                //update value in redux
                let updateRateInRedux = JSON.parse(JSON.stringify(commonState))
                let { rates } = updateRateInRedux
                let { add_on } = rates

                if (add_on) {
                    let updatedAddon: any = JSON.parse(JSON.stringify(add_on))

                    for (let key of updatedAddon) {
                        if (key.application_type_id === inputField.apptype?.value) {
                            key['application_type_id'] = inputField.apptype?.value
                            key['aon_rate'] = inputField.aon_rate
                        }
                    }

                    rates.add_on = updatedAddon
                    dispatch(updateReduxMasterRates(rates))
                }
            }



            handleLoader(false)
        }
    }


    /**
     * function for displaying prefill aon rate based on apptype
     * @param apptype 
     */
    const autoFillPreviousRates = useCallback((apptype: number) => {
        let { rates } = commonState
        if (rates && rates.add_on) {
            let addons = rates.add_on
            let element = addons.find((e: any) => e.application_type_id === apptype)
            element = element && element.aon_rate

            if (element || element === 0) {
                setInputFields((prev: any) => {
                    return {
                        ...prev,
                        aon_rate: +element
                    }
                })
            }
        }
    }, [commonState])

    useEffect(() => {
        if (appTypeList && appTypeList.length) {
            let preSelectedElement: any = appTypeList[0]
            setInputFields({ apptype: preSelectedElement, aon_rate: null })
            autoFillPreviousRates(preSelectedElement.value)
        }
    }, [appTypeList, autoFillPreviousRates])


    useEffect(() => {
    }, [props])


    return (
        <div className="calculator-form">

            <div className={`row ${props.callfor+'_popup'}`}>

                <ReactSelect options={appTypeList}
                    value={inputField && inputField.apptype}
                    handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)} placeholder="Application Type*" fieldKey="apptype" key="apptype"
                    errorField={errors?.apptype ? "This field is required" : ""}
                    fieldSetClassName={"col-md-6 react-select-max-value"}
                />

                {/* <fieldset className="form-filed col-md-6">
                    <div className={inputField.apptype?.value ? 'active-label material animation-effect' : 'material animation-effect'}>
                        <Select
                            options={appTypeList}
                            placeholder=""
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(e: any) => handleChange('apptype', e)}
                            value={inputField && inputField.apptype}
                        />
                        <label data-label="Application Type*" className="form-label"></label>
                        {
                            errors && errors.apptype
                                ?
                                <span className="error-txt">{"This field is required"}</span>
                                :
                                ''
                        }
                    </div>
                </fieldset> */}



                <fieldset className="form-filed col-md-6">
                    <div className="material">
                        <input id="" type="text" placeholder=" "
                            name="aon_rate"
                            className="form-input"
                            onChange={(e) => handleChange('aon_rate', e.target.value)}
                            value={(inputField.aon_rate || inputField.aon_rate === 0 ? inputField.aon_rate : '')}
                            disabled={!isRateEditable}

                        />
                        <label data-label="Add On Rate(%)*" className="form-label"></label>
                        {
                            errors && errors.aon_rate
                                ?
                                <span className="error-txt">{"This field is required"}</span>
                                :
                                ''
                        }
                    </div>
                </fieldset>

                {isRateEditable ?
                    <div className="col-md-12">
                        <button className="btn-primary" onClick={() => handleSubmit()}>Save</button>
                    </div>
                    : ''}

            </div>
        </div>
    )
}