import { useState } from "react";
import LeadRemarksModal from "./LeadRemarksModal";
import { useSelector } from "react-redux";
const LeadRemarksButton = (props: any) => {

    const { leadId } = props;

    const [remarkHistory, setRemarkHistory] = useState(false);
    const [selectedRemarkLead, setSelectedRemarkLead] = useState(false);

    const insuranceState = useSelector((state: any) => state.insurance);

    /**
     * HAHNDLE REMARK POPUP
     * @param type 
     * @param leadId 
     */
    const handleRemarkTimeline = (type: boolean, leadId: any) => {
        setRemarkHistory(type);
        setSelectedRemarkLead(leadId);

    }

    return (
        <>
            <button type="button" className="btn-line" onClick={() => handleRemarkTimeline(true, leadId)}>Remarks ({insuranceState?.leadDetail?.leadRemarkCount || 0})</button>

            {remarkHistory && <LeadRemarksModal remarkHistory={remarkHistory} selectedRemarkLead={selectedRemarkLead} handleRemarkTimeline={(action: boolean, leadId: any) => handleRemarkTimeline(action, leadId)} policyShareModal = {props.policyShareModal} />}
        </>

    )
}


export default LeadRemarksButton;