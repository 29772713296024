import React, { useState } from "react";
import { Tab, Nav } from 'react-bootstrap';
import AgencyListTable from './AgencyListTable';
import * as HELPER from '@src/config/helper';
import { ROLE_FEATURES } from '@src/config/constant';
import { useNavigate } from 'react-router-dom';


function AgencyList() {
    const [selectedTab, setSelectedTab] = useState('first');
    const [tabCount, setTabCount] = useState({ activeCount: 0, inActiveCount: 0, inProgressCount: 0, rejectedCount: 0 })
    const [searchKeyword, setSearchKeyword] = useState("");

    const navigate = useNavigate();

    const handleTabSelect = (eventKey: any) => {
        setSelectedTab(eventKey);
    };

    const updateTabCount = (params: any) => {
        setTabCount(params)
    }

    /**
     * ON ENTER SEARCH 
     * @param event 
     */
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            setSearchKeyword(event?.target?.value?.trim())
        }
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="top-heading financer-lead-heding">
                    <h1>Insurance Agency Management</h1>
                    {
                        (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_agency_partner_management'], accessType: 'edit' })) ?

                            <button className="btn-line" onClick={() => navigate("/agency/create")}>
                                <i className="ic-add"></i>
                                <span>Add Agent/Agency</span>
                            </button>
                            : ''
                    }
                   
                </div>
                <div className="loan-filter-bg managment-tabs tab-chips">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="p-rel">
                            <div className="role-management-top-sec">
                                <div className="selected-chips">
                                    <Nav variant="pills" className="flex-column tab-line nav nav-pills" onSelect={(k) => handleTabSelect(k)}>
                                        <div className="tab-list">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" disabled={!tabCount?.activeCount}>Active({tabCount?.activeCount})</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second" disabled={!tabCount?.inActiveCount}>Inactive({tabCount?.inActiveCount}) </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third" disabled={!tabCount?.inProgressCount}>In progress({tabCount?.inProgressCount})</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="forth" disabled={!tabCount?.rejectedCount}> Rejected({tabCount?.rejectedCount}) </Nav.Link>
                                            </Nav.Item>

                                        </div>

                                        {

                                            HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['task_role_assignment'], accessType: 'edit' }) &&
                                            <>
                                                <div className="btn-submit-reset">
                                                    <fieldset className="search-bx">
                                                        <div className="material">
                                                            <input id="Search" type="text" title="Search by agency id, agency name, email, mobile" placeholder="Search by agency id, agency name, email, mobile" onChange={(e: any) => (e)}  className="form-input" onKeyDown={handleKeyDown}/>
                                                            <i className="ic-search"></i>
                                                        </div>
                                                    </fieldset>


                                                  
                                                </div>
                                            </>
                                        }
                                    </Nav>
                                </div>

                                <AgencyListTable updateTabCount={updateTabCount} activeTab={selectedTab} searchKeyword={searchKeyword}/>


                            </div>
                        </div>

                    </Tab.Container>

                </div>

            </div>
        </React.Fragment>
    )
}

export default AgencyList