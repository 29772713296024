import React, { useEffect, useState } from 'react';
import PartnerListTable from "./partners/InsurancePartnerTable";
import { getPartnerMappingList } from '../store/slices/partner';
import { useDispatch, useSelector } from 'react-redux'
import FullPageLoader from '../common/FullPageLoader';
import { getMasterConfig } from '../store/slices/common';
import AddEditInsurancePartner from './partners/AddEditInsurancePartner';
import * as HELPER from "../config/helper";
import { ROLE_FEATURES } from '@src/config/constant';


export default function InsurancePartnerManagement(props: any): ReturnType<React.FC> {
    let [showAddInsPartnerIcon, setShowAddInsPartnerIcon] = useState<boolean>(false)
    let [partnerMappingList, setPartnerMappingList] = useState<any>([])
    let [loading, setLoading] = useState<boolean>(false)
    let [insuranceTypeList, setInsuranceTypeList] = useState<[]>([])
    let [methodList, setMethodList] = useState<[]>([])


    const dispatch: any = useDispatch()
    const commonState = useSelector((state: any) => state.common)

    const showModalAddInsurance = () => {
        setShowAddInsPartnerIcon(true)
    }

    const hideModalAddInsurance = () => {
        setShowAddInsPartnerIcon(false)
    }

    /**
     * function for API Call
     * fetches Partner Mapping List
     * @param params 
     */
    const getPartnerMappings = (params?: { page_number: number }) => {
        setLoading(true)

        let filters = {
            page_number: params?.page_number ?? 1
        }

        dispatch(getPartnerMappingList(filters)).then((res: any) => {
            if (res && res.status === 200) {
                const partnerMappingList = res
                setPartnerMappingList(partnerMappingList)
            }
            setLoading(false)

        }).catch((err: Error) => {
            setLoading(false)

        })


    }

    const fetchPartnerMappings = (params: any) => {
        getPartnerMappings(params)
    }

    /**
     * API call for rendering static dropdowns 
     */
    // const getConfig = async () => {


    // }

    const handleLoader = (input: boolean) => {
        setLoading(input)
    }

    useEffect(() => {
        getPartnerMappings() // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (commonState && commonState.common && Object.keys(commonState.common).length) {
            let data = commonState.common
            let { apptype, insurance_type, both } = data
            let updatedRuleEngine: any = []

            //pushing both in dropdowns
            if (both) {
                updatedRuleEngine.push(both)
                // apptype = (apptype && apptype.length) ? [...apptype, both] : []
                insurance_type = (insurance_type && insurance_type.length) ? [...insurance_type, both] : []
            }

            setMethodList(apptype)
            setInsuranceTypeList(insurance_type)
        }
        else {
            //api call to fetch static dropdowns
            dispatch(getMasterConfig()).then((res: any) => {
                if (res && res.status === 200) {
                    let data: any = res.data || {}
                    let { apptype, insurance_type, both } = data

                    //pushing both in dropdowns
                    if (both) {
                        // apptype = (apptype && apptype.length) ? [...apptype, both] : []
                        insurance_type = (insurance_type && insurance_type.length) ? [...insurance_type, both] : []
                    }

                    setMethodList(apptype)
                    setInsuranceTypeList(insurance_type)
                }
            }).catch((err: Error) => {
            })
        }
    }, [dispatch, commonState])

    return (
        <div className="container-fluid">
            <div className="top-heading financer-lead-heding">
                <h1>Insurance Partner Management</h1>
                {HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['insurance_partner_management'], accessType: 'edit'}) && <button className="btn-line" onClick={showModalAddInsurance}>
                    <i className="ic-add"></i>
                    <span>Add Insurance Partner</span>
                </button>}
            </div>

            <FullPageLoader show={loading} />

            <PartnerListTable apiResponse={partnerMappingList} getPartnerMappings={fetchPartnerMappings} loading={loading} insuranceTypeList={insuranceTypeList} methodList={methodList} handleLoader={handleLoader} />

            <AddEditInsurancePartner callFrom={'AddInsurancePartner'} showAddInsPartnerIcon={showAddInsPartnerIcon} handleModalClose={hideModalAddInsurance} insuranceTypeList={insuranceTypeList} methodList={methodList} fetchPartnerMappings={fetchPartnerMappings} handleLoader={handleLoader} updateSelectedPartner={()=>{}}/>

        </div>
    )
}

