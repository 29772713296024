import React, { useEffect, useState } from "react";
import { Tab, Nav } from 'react-bootstrap';
import InsuranceListTable from '@src/component/insurance/list/InsuranceListTable';
import InsuranceListTopFilter from '@src/component/insurance/list/InsuranceListTopFilter';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { exportInsuranceData, getInsuranceList, leadListFilters } from '@src/store/slices/insurance';
import FullPageLoader from '@src/common/FullPageLoader';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { getUserList } from "@src/store/slices/user";
import { INSURANCE_LIST_SORT, ROLE_FEATURES } from "@src/config/constant";
import * as HELPER from "@src/config/helper";
import ReactSelect from "@common/ReactSelect";

function InsuranceList() {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const insuranceState = useSelector((state: any) => state.insurance);
    const commonState = useSelector((state: any) => state.common);

    const [loading, setLoading] = useState<boolean>(false);
    const [insuranceList, setInsuranceList] = useState<any>([]);
    const [paginationData, setPaginationData] = useState<any>({});
    const [selectedFilters, setSelectedFilters] = useState<any>({ page_number: 1, activeTab: HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['my_cases'], accessType: 'isVisible' }) ? "all" : "all" });
    const [assignUsers, setAssignUsers] = useState<any>([]);
    const [listSort, setListSort] = useState<any>({});

    let searchKeywordParam: any = searchParams.get('searchKeyword');
    let statusParam: any = searchParams.get('status_id_arr');
    let subStatusParam: any = searchParams.get('sub_status_id_arr');
    let filterTypeParam: any = searchParams.get('filterType');

    const addNewApp = () => {
        navigate('/create-insurance-policy?step=insurance_form');
    }

    useEffect(() => {
        const getAllUsers = () => {
            getUserList({ role_id: 3, page_number: 1, isPaginationApply: false }).then((res: any) => {
                if (res.status === 200 && res.data && res.data.length) {
                    setAssignUsers(res.data.map((user: any) => { return { label: user.name, value: user.id } }));
                }
            })
        }


        getAllUsers()

    }, []);

    // useEffect(() => {
    //     getInsuranceListing({});

    // }, []);

    useEffect(() => {

        let updatedSelctedFilters: any = {...selectedFilters};

        if (searchKeywordParam) {
            updatedSelctedFilters['searchKeyword'] = searchKeywordParam;
        }

        if (statusParam) {
            updatedSelctedFilters['status_id_arr'] = statusParam.split(",").map((v: any) => +v);
        }

        if (subStatusParam) {
            updatedSelctedFilters['sub_status_id_arr'] = subStatusParam.split(",").map((v: any) => +v);
        }

        if (filterTypeParam) {
            updatedSelctedFilters['filterType'] = filterTypeParam;
        }

        if (!searchKeywordParam && !statusParam && !subStatusParam) {
            updatedSelctedFilters = { page_number: selectedFilters.page_number, activeTab: selectedFilters.activeTab };
        } else {

            setSelectedFilters((prev: any) => { return { ...prev, ...updatedSelctedFilters }; });
        }

        getInsuranceListing(updatedSelctedFilters);

        // eslint-disable-next-line
    }, [searchKeywordParam, statusParam, subStatusParam])

    useEffect(() => {
        if (Object.keys(insuranceState.insuranceListFilters).length) {
            setSelectedFilters({ ...insuranceState.insuranceListFilters });
        }
    }, [insuranceState])

    //GET INSURANCE LIST API
    const getInsuranceListing = (params: any) => {

        let filters = {
            activeTab: HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['my_cases'], accessType: 'isVisible' }) ? "all" : "all",
            // ...selectedFilters,
            ...params,
            page_number: params?.page_number ?? 1,
        }

        if (filters['dateType']) {
            let errorFilter = false;
            if (!filters['from_date'] && !filters['to_date']) {
                errorFilter = true;
                toast.error("From Date and To Date are required");
            } else if (filters['from_date'] && filters['to_date'] && new Date(filters['from_date']).getTime() > new Date(filters['to_date']).getTime()) {
                errorFilter = true;
                toast.error("To Date should be greater than From Date");
            }

            if (errorFilter) return false;
        }

        dispatch(leadListFilters(filters));

        setLoading(true);
        // for searching customer as encrypt and then decrypt backend
        let modifiedFilters = { ...filters };

        if (params?.searchKeyword) {
            modifiedFilters.searchKeyword = HELPER.EncodetoBase64(params?.searchKeyword);
        }
        dispatch(getInsuranceList(modifiedFilters)).then((res: any) => {
            setLoading(false)
            if (res && res.status === 200) {
                setPaginationData({ ...res.pagination })
                setInsuranceList([...res.data])
            }
        }).catch((err: Error) => {
            setLoading(false)
        })
    }

    const activeTabHandler = (tab: any) => {
        if (tab === "third") {
            getInsuranceListing({ ...selectedFilters, page_number: 1, activeTab: "disbursed" })
        } else if (tab === "fourth") {
            getInsuranceListing({ ...selectedFilters, page_number: 1, activeTab: "lost" })
        } else if (tab === "first") {
            getInsuranceListing({ ...selectedFilters, page_number: 1, activeTab: "myCases" })
        } else if (tab === "second") {
            getInsuranceListing({ ...selectedFilters, page_number: 1, activeTab: "all" })
        }
    }

    const handleSeeMoreClick = () => {
        let seeMore: any = document.getElementsByClassName('username-see-more')?.[0]
        //disable old Li elements
        if (seeMore.textContent === `- ${selectedFilters['username']?.length - 5} more`) {
            let documents: any = document.getElementsByClassName('to-hide')
            seeMore.textContent = `+ ${selectedFilters['username']?.length - 5} more`

            while (documents?.length) {
                documents[0].classList.add("hide");
                documents[0].classList.remove("to-hide");
            }

        } else {
            //enable hidden Li elements
            let documents: any = document.getElementsByClassName('hide')
            seeMore.textContent = `- ${selectedFilters['username']?.length - 5} more`

            while (documents?.length) {
                documents[0].classList.add("to-hide");
                documents[0].classList.remove("hide");
            }

        }
    }

    /**
     * MAKE FILTER VALUES CHIPS
     * @returns 
     */
    const makeFilterChips = () => {
        let chipsHtml = [];
        if (Object.keys(insuranceState.insuranceListFilters).length) {

            for (let filterKey of Object.keys(insuranceState.insuranceListFilters)) {
                if (filterKey === "page_number") continue;

                if (["partner_id", "status_id_arr", "sub_status_id_arr", "created_by_ids", "lead_source", "username", "apptype"].includes(filterKey) && insuranceState.insuranceListFilters[filterKey]) {
                    if (filterKey === 'username' && insuranceState.insuranceListFilters[filterKey]?.length > 5) {
                        for (let [indx, value] of insuranceState.insuranceListFilters[filterKey]?.entries()) {
                            let stateValue = commonState?.sfaUserList
                            let fetchRec = stateValue?.filter((el: any, key: number) => +value === el.value) || [];

                            if(fetchRec && fetchRec[0]) {
                                if (indx >= 5) {
                                    chipsHtml.push(
                                        <li key={filterKey + value} className={`hide`}>
                                            {fetchRec[0]['label']}
                                            <span onClick={() => onFilterChipRemove(filterKey, value)}>
                                                <i className="ic-clearclose"></i>
                                            </span>
                                        </li>
                                    );
                                }
                                else {
                                    chipsHtml.push(
                                        <li key={filterKey + value}>
                                            {fetchRec[0]['label']}
                                            <span onClick={() => onFilterChipRemove(filterKey, value)}>
                                                <i className="ic-clearclose"></i>
                                            </span>
                                        </li>
                                    );
                                }
                            }
                        }

                        <>
                            {
                                insuranceState?.insuranceListFilters[filterKey]?.length > 5
                                    ?
                                    chipsHtml.push(
                                        // eslint-disable-next-line no-useless-concat
                                        <li key={filterKey + "-see-more"} className={filterKey + "-see-more" + ' cursor-pointer'} onClick={() => handleSeeMoreClick()}>
                                            {`+ ${selectedFilters['username']?.length - 5} More`}
                                            <span onClick={() => { }}>
                                                {/* <i className="ic-clearclose"></i> */}
                                            </span>
                                        </li>
                                    )
                                    : null
                            }
                        </>




                    } else {
                        for (let filterVal of insuranceState.insuranceListFilters[filterKey]) {

                            let stateValue = [];
                            if (filterKey === "apptype") {
                                stateValue = commonState?.common?.apptype
                            } else if (filterKey === "partner_id") {
                                stateValue = commonState?.common?.partner_list
                            } else if (filterKey === "status_id_arr") {
                                stateValue = commonState?.common?.status_list
                            } else if (filterKey === "sub_status_id_arr") {
                                stateValue = commonState?.common?.sub_status_list
                            } else if (filterKey === "created_by_ids") {
                                stateValue = commonState?.common?.insurance_role
                            } else if (filterKey === "lead_source") {
                                stateValue = commonState?.common?.lead_source
                            } else if (filterKey === "username") {
                                stateValue = commonState?.sfaUserList
                            }
                            let fetchRec = stateValue?.filter((el: any, key: number) => +filterVal === el.value) || [];

                            if (fetchRec && fetchRec[0]) {
                                chipsHtml.push(
                                    <li key={filterKey + filterVal}>
                                        {fetchRec[0]['label']}
                                        <span onClick={() => onFilterChipRemove(filterKey, filterVal)}>
                                            <i className="ic-clearclose"></i>
                                        </span>
                                    </li>
                                )
                            }
                        }
                    }
                }

                else if (filterKey === "searchKeyword" && insuranceState.insuranceListFilters[filterKey]) {

                    chipsHtml.push(
                        <li key={filterKey}>
                            {insuranceState.insuranceListFilters[filterKey]}
                            <span onClick={() => onFilterChipRemove(filterKey, '')}>
                                <i className="ic-clearclose"></i>
                            </span>
                        </li>
                    );
                } else if (["from_date"].includes(filterKey) && insuranceState.insuranceListFilters[filterKey]) {
                    // in chip content date range show combine together
                    let chipContent = insuranceState.insuranceListFilters["from_date"];
                    if (insuranceState.insuranceListFilters["from_date"]) {
                        if (insuranceState.insuranceListFilters["to_date"] !== null) {
                            chipContent += " ~ " + insuranceState.insuranceListFilters["to_date"];
                        }
                    }
                    chipsHtml.push(
                        <li key={filterKey}>
                            {chipContent}
                            <span onClick={() => onFilterChipRemove(filterKey, '')}>
                                <i className="ic-clearclose"></i>
                            </span>
                        </li>
                    );
                } else if (["policy_effectivity_date_from"].includes(filterKey) && insuranceState.insuranceListFilters[filterKey]) {
                    // in chip content date range show combine together
                    let chipContent = insuranceState.insuranceListFilters["policy_effectivity_date_from"];
                    if (insuranceState.insuranceListFilters["policy_effectivity_date_to"]) {
                        chipContent += " ~ " + insuranceState.insuranceListFilters["policy_effectivity_date_to"];
                    }
                    chipsHtml.push(
                        <li key={filterKey}>
                            {chipContent}
                            <span onClick={() => onFilterChipRemove(filterKey, '')}>
                                <i className="ic-clearclose"></i>
                            </span>
                        </li>
                    );
                } else if (["policy_expiry_date_from"].includes(filterKey) && insuranceState.insuranceListFilters[filterKey]) {
                    // in chip content date range show combine together
                    let chipContent = insuranceState.insuranceListFilters["policy_expiry_date_from"];
                    if (insuranceState.insuranceListFilters["policy_expiry_date_to"]) {
                        chipContent += " ~ " + insuranceState.insuranceListFilters["policy_expiry_date_to"];
                    }
                    chipsHtml.push(
                        <li key={filterKey}>
                            {chipContent}
                            <span onClick={() => onFilterChipRemove(filterKey, '')}>
                                <i className="ic-clearclose"></i>
                            </span>
                        </li>
                    );
                }
                else {

                    let stateValue = [];
                    if (filterKey === "insurance_type_id") {
                        stateValue = commonState?.common?.insurance_type
                    } else if (filterKey === "rule_engine_id") {
                        stateValue = commonState?.common?.rule_engine
                    } else if (filterKey === "dateType") {
                        stateValue = commonState?.common?.sub_status_list
                    } else if (filterKey === "employee_type") {
                        stateValue = commonState?.common?.employee_type
                    } else if (filterKey === "business_line") {
                        stateValue = commonState?.common?.business_lines
                    } else if (filterKey === "user_role") {
                        stateValue = commonState?.common?.sfa_roles
                    } else if (filterKey === "payment_status") {
                        stateValue = commonState?.common?.paid_by_customer
                    }

                    let fetchRec = stateValue?.filter((el: any, key: number) => {
                        return filterKey !== 'business_line' ? +insuranceState.insuranceListFilters[filterKey] === +el.value : insuranceState.insuranceListFilters[filterKey] === el.value
                    }) || [];
                    if (fetchRec && fetchRec[0]) {

                        chipsHtml.push(
                            <li key={filterKey}>
                                {((filterKey==="payment_status") ? 'Payment Status: ' : '') + fetchRec[0]['label']}
                                <span onClick={() => onFilterChipRemove(filterKey, '')}>
                                    <i className="ic-clearclose"></i>
                                </span>
                            </li>
                        );
                    }
                }
            }

        }

        return chipsHtml;
    }

    const onFilterChipRemove = (filterKey: string, filterVal: any) => {
        if (filterVal) {
            selectedFilters[filterKey] = selectedFilters[filterKey].filter((el: any) => el !== filterVal);

            if (filterKey === 'username') {
                let seeMore: any = document.getElementsByClassName('username-see-more')?.[0]
                if (seeMore) {
                    let prevNumber = seeMore?.textContent?.split(' ')?.[1]
                    seeMore.textContent = seeMore.textContent.replace(prevNumber, selectedFilters[filterKey]?.length - 5)
                }
            }

            if (filterKey === "status_id_arr") {
                //RESET SUBSTATUS IF STATUS UPDATED
                let getAllSubstatusOfRemovedStatus: any = commonState?.common?.sub_status_list?.filter((el:any)=> +el.status_id === +filterVal)?.map((elm:any)=>elm.value);
                let updatedSubStatusFilter = selectedFilters?.sub_status_id_arr ? selectedFilters?.sub_status_id_arr?.filter((el:any)=> !getAllSubstatusOfRemovedStatus.includes(el)) : [];
                updatedSubStatusFilter = updatedSubStatusFilter.filter((el:any)=> el.value);

                selectedFilters['sub_status_id_arr'] = updatedSubStatusFilter?.length ? updatedSubStatusFilter : undefined;
            }

            if(filterKey === 'apptype' && !selectedFilters[filterKey]?.length) {
                delete selectedFilters[filterKey]; 
            }
        
        } else {
            if(filterKey === 'user_role') {
                delete selectedFilters['username']
            }
            delete selectedFilters[filterKey];
        }

        //REMOVE FROM URL
        // if (!selectedFilters[filterKey] || (selectedFilters[filterKey] && !selectedFilters[filterKey].length)) {
        //     updateUrl(filterKey);
        // }

        if (filterKey === "dateType") {
            delete selectedFilters["from_date"];
            delete selectedFilters["to_date"];

        }
        // for resting date range
        if (filterKey === "from_date") {
            delete selectedFilters["from_date"];
            delete selectedFilters["to_date"];
        }

        if (filterKey === "policy_effectivity_date_from") {
            delete selectedFilters["policy_effectivity_date_from"];
            delete selectedFilters["policy_effectivity_date_to"];
        }

        if (filterKey === "policy_expiry_date_from") {
            delete selectedFilters["policy_expiry_date_from"];
            delete selectedFilters["policy_expiry_date_to"];
        }

        selectedFilters['page_number'] = 1;

        setSelectedFilters(selectedFilters);


        getInsuranceListing(selectedFilters);

    }

    /**
     * SORT LIST
     * @param event 
     */
    const sortByhandleChange = (event: any) => {
        setListSort({});
        if (event['value']) {
            getInsuranceListing({ ...selectedFilters, sortBy: event, page_number: 1 });
            setListSort(event)
        }
    }

    const updateUrl = (filterKey: string) => {
        if(filterKey === 'resetAll'){
            if(searchParams.size){

                searchParams.delete('status_id_arr');
                searchParams.delete('sub_status_id_arr');
                searchParams.delete('filterType');
                delete selectedFilters['status_id_arr'];
                delete selectedFilters['sub_status_id_arr'];
                delete selectedFilters[filterKey];

                setSearchParams(searchParams);
                setSelectedFilters(selectedFilters);
            }else{

                getInsuranceListing({activeTab: selectedFilters.activeTab || undefined});

            }
        }else{
            //REMOVE FROM URL
            searchParams.delete(filterKey);
            delete selectedFilters[filterKey];
            setSearchParams(searchParams);
            setSelectedFilters(selectedFilters);
        }
    }

    const updateListFromChlid = (filterParams: any) => {
        // if (!filterParams['status_id_arr'] || (filterParams['status_id_arr'] && !filterParams['status_id_arr'].length)) {

        //     updateUrl("status_id_arr");
        // }

        // if (!filterParams['sub_status_id_arr'] || (filterParams['sub_status_id_arr'] && !filterParams['sub_status_id_arr'].length)) {

        //     updateUrl("sub_status_id_arr");
        // }


        getInsuranceListing(filterParams);

    }

    const handleExport = async () => {
        setLoading(true)
        const filterParams = insuranceState?.insuranceListFilters || { page_number: 1 }
        const exportData: any = await exportInsuranceData(filterParams)
        setLoading(false)

        if(exportData?.status === 200 && exportData.message) {
            toast.success(exportData.message)
        } else {
            toast.error("Error Occured")
        }
    }


    return (
        <React.Fragment>

            <FullPageLoader show={loading} />

            <div className="container-fluid">
                <div className="top-heading financer-lead-heding insurance-top-filer-heading">
                    <h1>Insurance Cases</h1>
                    {
                        HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['dashboard_page'], accessType: 'edit' }) &&
                        <button className="btn-line" onClick={addNewApp}>
                            <span>Add New Insurance Application</span>
                            <i className="ic-add"></i>
                        </button>
                    }
                </div>
                <div className="loan-filter-bg">

                    <div className="top-filter-bg">
                        <InsuranceListTopFilter submitHandler={(filterParams: any) => { updateListFromChlid(filterParams); }} resetSort={()=> {setListSort(INSURANCE_LIST_SORT[0]); updateUrl('resetAll');} } loader={(params: any) => setLoading(params)}/>
                        <div className="selected-chips">
                            <ul>
                                {
                                    makeFilterChips()
                                }


                            </ul>
                        </div>
                    </div>


                    <Tab.Container id="left-tabs-example" defaultActiveKey={HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['my_cases'], accessType: 'isVisible' }) ? "second" : "second"}>

                        <Nav variant="pills" className="flex-column tab-line nav nav-pills"
                            onSelect={(k) => activeTabHandler(k)}
                        >
                            <div className="tab-list">
                                {
                                    HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['my_cases'], accessType: 'isVisible' }) &&
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" disabled={!(paginationData && paginationData['myCasesCount'])}>
                                            My Cases ({(paginationData && paginationData['myCasesCount']) || 0})</Nav.Link>
                                    </Nav.Item>
                                }

                                <Nav.Item>
                                    <Nav.Link eventKey="second" disabled={!(paginationData && paginationData['allCasesCount'])}>All Cases ({(paginationData && paginationData['allCasesCount']) || 0})</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third" disabled={!(paginationData && paginationData['disbursedCasesCount'])}>
                                        Disbursed Cases ({(paginationData && paginationData['disbursedCasesCount']) || 0})</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth" disabled={!(paginationData && paginationData['lostCasesCount'])} >
                                        Lost Cases ({(paginationData && paginationData['lostCasesCount']) || 0})</Nav.Link>
                                </Nav.Item>


                            </div>

                            <div className="btn-submit-reset top-btn-none">
                                {
                                    insuranceList?.length
                                        ?
                                        <button className="btn-primary btn-width-auto" onClick={handleExport}>Export Data</button>
                                        :
                                        <button className="btn-primary btn-width-auto" disabled={true}>Export Data</button>
                                }
                            </div>

                            <div className="sorting-filter-cls">

                                <ReactSelect options={INSURANCE_LIST_SORT} value={listSort.value ? listSort : INSURANCE_LIST_SORT[0]} handleChange={(e: any, fieldKey: string) => sortByhandleChange(e)} placeholder={""} fieldKey="" disableMargin={true} />

                            </div>

                        </Nav>


                        <Tab.Content>
                            <Tab.Pane eventKey="first">

                                <InsuranceListTable insuranceList={insuranceList} paginationData={paginationData} getInsuranceListing={getInsuranceListing} selectedFilters={selectedFilters} assignUsers={assignUsers} />

                            </Tab.Pane>
                            <Tab.Pane eventKey="second">


                                <InsuranceListTable insuranceList={insuranceList} paginationData={paginationData} getInsuranceListing={getInsuranceListing} selectedFilters={selectedFilters} assignUsers={assignUsers} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">

                                <InsuranceListTable insuranceList={insuranceList} paginationData={paginationData} getInsuranceListing={getInsuranceListing} selectedFilters={selectedFilters} assignUsers={assignUsers} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="fourth">

                                <InsuranceListTable insuranceList={insuranceList} paginationData={paginationData} getInsuranceListing={getInsuranceListing} selectedFilters={selectedFilters} assignUsers={assignUsers} />

                            </Tab.Pane>
                        </Tab.Content>

                    </Tab.Container>




                </div>


            </div>
        </React.Fragment>
    )
}

export default InsuranceList;