import { DOCUMENT_CATEGORY, DOCUMENT_CATEGORY_OTHER_DOCUMENT } from '@src/config/constant';
import { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { useSelector } from 'react-redux';



export default function UploadDocumentList(props: any) {
    let { docType, images, handleImageClick } = props
    let [activeDoctypeName, setActiveDoctypeName] = useState<string>('First Submit')
    let [categoryList, setCategoryList] = useState<[]>([])
    let [vehicleImageUploaded, setVehicleImageUploaded] = useState<boolean>(false)

    let commonState = useSelector((state: any) => state.common)
    let insuranceState = useSelector((state: any) => state.insurance)

    const countImagesOfSameDoc = (docId: number) => {
        let count = props.countImagesOfSameDoc(docId)
        return count
    }

    useEffect(() => {
        if (commonState && commonState.documentList) {
            let documentList = JSON.parse(JSON.stringify(commonState.documentList))
            //IN CASE OF AGENCY SHOW INSTALMENT DOCS FOR DOC TYPE 1
            // if(insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY && insuranceState.leadDetail.payment_tenure_id && [1,3].includes(props.docType)){
            //     documentList = documentList.map((e: any) => { 
            //         if(e?.child?.length && e.parent_id === 12){ 
            //             e.doc_type_id = props.docType;
            //             e.label = "Proof of Payment"
            //             e.is_required = true
            //             e.child = e.child.map((child: any)=> { 
            //                 if(child.value>14 && child.value<27) child.doc_type_id = props.docType; child.is_required = true; return child 
            //             })
            //           }
            //         return e;
            //     });
            // }
            let updatedList = documentList.filter((e: any) => (e.doc_type_id === docType));
            if(+docType === 3) {//POST DISBURSAL
                //SHOW ONLY FOR VALID VALUES IF STATUS NOT CROSSED FROM INBOUND
                updatedList = updatedList.filter((e: any) => {
                    if (insuranceState?.getInboundPaymentFrom === "broker" && e.value === DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_inbound'] && insuranceState?.leadDetail?.pay_tenure) {
                        e['child'] = [...e.child, e]
                    }
                    return (+e.value !== 14 || (+e.value === 14 && ((insuranceState.getInboundPaymentFrom === "broker" && !insuranceState.leadDetail.inbound_amount) || (insuranceState.leadDetail.inbound_amount && insuranceState.leadDetail.pay_from_cust_to_partner_broker_id))))
                });
            }
            let doctypeName = (updatedList && updatedList[0] && updatedList[0].doc_type_name) || activeDoctypeName
            setActiveDoctypeName(doctypeName)
            setCategoryList(updatedList)
        }
        // eslint-disable-next-line
    }, [commonState, docType, activeDoctypeName])


    useEffect(() => {
        if (vehicleImageUploaded) {
            setVehicleImageUploaded(false)
        }
        // eslint-disable-next-line
    }, [images])

    const checkOtherDocuments = () => {
        let isChecked = false
        let docs = images && images.filter((e: any) => e.path && e.parent_id === DOCUMENT_CATEGORY_OTHER_DOCUMENT)
        
        if(docs && docs.length) isChecked = true
        return isChecked;
    }

    return (
        <div className="upload-document-list-accordain upload-doc-list-acc">
            <div className="doc-list-heading">
                <h3>{`List of documents: ${activeDoctypeName}`}</h3>
            </div>

            {
                categoryList && categoryList.length
                    ?
                    categoryList.map((e: any, idx: number) => {

                        if (e.child.length) {
                            let child = e.child

                            let otherDocCheck = false
                                                        
                            if(e.value === DOCUMENT_CATEGORY_OTHER_DOCUMENT) otherDocCheck = checkOtherDocuments()

                            let isLoanDoc = e.parent_id === DOCUMENT_CATEGORY['firstSubmit']['childs']['govt_id']['value'] || e.parent_id === DOCUMENT_CATEGORY['firstSubmit']['childs']['credit_advice_doc']['value'];
                                                                                    
                            return (
                                <Accordion allowZeroExpanded key={idx}>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span className="stepprogress">
                                                    <i className={(e.value !== DOCUMENT_CATEGORY_OTHER_DOCUMENT && (vehicleImageUploaded || isLoanDoc)) ? "sprite ic-check" : (e.value === DOCUMENT_CATEGORY_OTHER_DOCUMENT && otherDocCheck ? "sprite ic-check" : "sprite")}></i>
                                                </span>
                                                {e.is_required ? e.label + "*" : (e.conditional_mandatory && e.conditional_mandatory.length ? e.label + "**" : e.label)}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="sub-list">
                                                <ul className="m-xl-l">
                                                    {child.map((ele: any, index: number) => {
                                                        let count = countImagesOfSameDoc(ele.value)
                                                        let updatedImagesWithPath = images && images.filter((e: any) => e.path)
                                                        let isImageExist = updatedImagesWithPath && updatedImagesWithPath.find((img: any) => img.doc_id === ele.value)
                                                        let pathExists = isImageExist ? isImageExist.path : null
                                                        if (pathExists && !vehicleImageUploaded && ele.parent_id === DOCUMENT_CATEGORY['firstSubmit']['childs']['vehicle_photos']) setVehicleImageUploaded(true)

                                                        return (
                                                            <li key={index}>
                                                                <div className=" custom-control custom-checkbox">
                                                                    <span className="stepprogress"><i className={pathExists ? "sprite ic-check" : "sprite"}></i></span>
                                                                    <label style={ele.doc_type_id === DOCUMENT_CATEGORY['postDisbursal']['doc_type_id'] ? { width: '170px' } : {}} onClick={() => pathExists ? handleImageClick(isImageExist) : handleImageClick(ele)}>{ele.is_required ? ele.label + "*" : ele.label}</label>
                                                                    <span className="doc_count">{`${count}/${ele.max_upload}`}</span>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            )
                        }
                        else {
                            let count = countImagesOfSameDoc(e.value)
                            let updatedImagesWithPath = images && images.filter((e: any) => e.path)
                            let isImageExist = updatedImagesWithPath && updatedImagesWithPath.find((img: any) => img.doc_id === e.value)
                            let pathExists = isImageExist ? isImageExist.path : null

                            return (
                                <Accordion allowZeroExpanded key={idx}>
                                    <AccordionItem >
                                        <span className="stepprogress">
                                            <i className={pathExists ? "sprite ic-check" : "sprite"}></i>
                                        </span>
                                        <label className="" onClick={() => pathExists ? handleImageClick(isImageExist) : handleImageClick(e)}> {e.is_required ? e.label + "*" : (e.conditional_mandatory && e.conditional_mandatory.length ? e.label + "**" : e.label)} </label>
                                        <span className="doc_count">{`${count}/${e.max_upload}`}</span>

                                    </AccordionItem>
                                </Accordion>
                            )
                        }

                    })
                    : ''
            }

        </div>
    )
}