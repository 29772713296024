import { useState, useEffect, useRef } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { useSelector, useDispatch } from 'react-redux';
import { getLeadEmailTemplates, updateTemplateNote, updateLeadDetailsInStore, downloadTemplatePdf } from '@src/store/slices/insurance';
import FullPageLoader from '@common/FullPageLoader';
import * as HELPER from "../../../../config/helper";
import { ROLE_FEATURES, PHILIPPINE_CURRENCY, CURRENCY_SYMBOL, STATUS_SUB_STATUS, DEALER_PRESENT_NO, DEALER_PRESENT_YES, APP_TYPE_STANDALONE, APP_TYPE_RENEWAL } from "@src/config/constant";
const PesoImage =  require('../../../../styles/webroot/images/philippine-peso.png');

const EmailTemplateView = (props: any) => {

    const [templateData, setTemplateData] = useState("");
    const [whtsApptemplateData, setWhtsApptemplateData] = useState("");
    const [descriptionData, setDescriptionData] = useState<any>([]);
    const dispatch: any = useDispatch();

    const [loading, setLoading] = useState(false);

    const insuranceState = useSelector((state: any) => state.insurance);

    const [triggerTemplateData, setTriggerTemplateData] = useState(true);

    const [notesValue, setNotesValue] = useState({ templateType: "", note: "" });

    const contentRef = useRef(null);

    const [emailNoteValue, setemailNoteValue] = useState("");
    const [whatsappNoteValue, setWhatsappNoteValue] = useState("");

    const [emailNoteEditable, setEmailNoteEditable] = useState(false);
    const [whatsappNoteEditable, setWhatsappNoteEditable] = useState(false);

    // Process template data when descriptionData changes
    useEffect(() => {
        processTemplateData();
        // eslint-disable-next-line
    }, [descriptionData]);


    // Set the Email and WhatsApp template Type for different templateFor like  Sent to Insurance Broker(1) and Insurance Policy Shared(2)
    const processTemplateData = () => {

        if (descriptionData && descriptionData.length > 0) {
            const matchingTemplateType = props.templateType === "broker" ? 1 : 2;

            // Find the email and WhatsApp templates template_type = 1 is for email, template_type =2 is for whstapp
            const emailTemplate = descriptionData.find((item: any) =>
                item.template_type === 1 && item.template_for === matchingTemplateType
            );
            const whatsappTemplate = descriptionData.find((item: any) =>
                item.template_type === 2 && item.template_for === matchingTemplateType
            );


            if (emailTemplate) {
                const emailDescription = emailTemplate?.description?.replaceAll('\n', '<br/>');
                setTemplateData(emailDescription);
                setemailNoteValue(emailTemplate?.note);
            } else {
                setTemplateData("");
                setemailNoteValue("");

            }
            if (whatsappTemplate) {
                let whatsappDescription = whatsappTemplate?.description?.replaceAll('\n', '<br/>');
                setWhtsApptemplateData(whatsappDescription);
                setWhatsappNoteValue(whatsappTemplate?.note);

            } else {
                setWhtsApptemplateData("");
                setWhatsappNoteValue("");

            }
        }
    };

    useEffect(() => {
        let waTempData = whtsApptemplateData;
        if(whatsappNoteValue){
            waTempData += "<br/><br/>Note: "+whatsappNoteValue;
        }
        props.setTemplateData(waTempData);
        // eslint-disable-next-line
    }, [whtsApptemplateData, whatsappNoteValue])

    // Fetch description data based on the templateType prop

    useEffect(() => {
        const templateFor = props.templateType === "broker" ? 1 : 2;
        let sendBrokercheckFromApiCall = true;
        let policySharedcheckFromApiCall = true;
        //CHECK FOR SEND TO BROKER 
        if((insuranceState?.leadDetail?.status_id >= STATUS_SUB_STATUS['status']['sent_to_insurance_broker'] && props.templateType === "broker") && insuranceState?.leadDetail?.sharedTemplateData?.length){
            let filteredDescriptionData = insuranceState?.leadDetail?.sharedTemplateData?.filter((item: any) => item.type === templateFor);
            //IF DATA FOUND WITH DESCRIPTION 
            if(filteredDescriptionData.length && filteredDescriptionData[0] && filteredDescriptionData[0]['template_text']){

                filteredDescriptionData = filteredDescriptionData.map((template: any) => { return { "template_type": template.source, "template_for": template.type, description: template.template_text, note: template?.note , is_details_sent: template.is_details_sent} });
                setDescriptionData([...filteredDescriptionData]);
                sendBrokercheckFromApiCall = false;
            }

        }
        //CHECK FOR POLICY SHARE 
        if((insuranceState?.leadDetail?.status_id >= STATUS_SUB_STATUS['status']['insurance_policy'] && props.templateType === "customer") && insuranceState?.leadDetail?.sharedTemplateData?.length && insuranceState?.leadDetail?.sharedTemplateData[0] && insuranceState?.leadDetail?.sharedTemplateData[0]['template_text']){
            let filteredDescriptionData = insuranceState?.leadDetail?.sharedTemplateData?.filter((item: any) => item.type === templateFor);
            //IF DATA FOUND WITH DESCRIPTION 
            if(filteredDescriptionData.length && filteredDescriptionData[0] && filteredDescriptionData[0]['template_text']){

                filteredDescriptionData = filteredDescriptionData.map((template: any) => { return { "template_type": template.source, "template_for": template.type, description: template.template_text, note: template?.note , is_details_sent: template.is_details_sent} });
                setDescriptionData([...filteredDescriptionData]);
                policySharedcheckFromApiCall = false;
            }
        }
        //IF PRESAVED DATA FOUND THEN CALL API DATA
        if((policySharedcheckFromApiCall && props.templateType === "customer") || (sendBrokercheckFromApiCall && props.templateType === "broker")){ //IF NOT SHARED YET

            if (insuranceState.leadTemplateData.length) {
                let filteredDescriptionData: any = [];
                let checkFromState = false;
                if (insuranceState?.leadDetail?.sharedTemplateData?.length) {
                    //FILTER FROM SAVED RECORD FOR NOT  MAPPING
                    filteredDescriptionData = insuranceState?.leadDetail?.sharedTemplateData?.filter((item: any) => item.type === templateFor);
    
                    filteredDescriptionData = filteredDescriptionData.map((template: any) => { return { "template_type": template.source, "template_for": template.type, description: template.template_text, note: template?.note , is_details_sent: template.is_details_sent} });
    
                    if (!filteredDescriptionData.length) {
    
                        checkFromState = true;
    
                    } else {
                        //ELSE PICK DATA FROM TEMPLATE INFO DATA
                        let finalArray = [];
    
                        let templateConfigStateData = JSON.parse(JSON.stringify(filteredDescriptionData));

                        let checkDealerPresent = ([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(insuranceState.leadDetail.application_type_id)) ? (insuranceState.leadDetail.other_detail_dealer_id ? DEALER_PRESENT_YES : DEALER_PRESENT_NO) : null;
    
                        let getTemplatesFromCnfig = JSON.parse(JSON.stringify(insuranceState.leadTemplateData?.filter((item: any) => (item.template_for === templateFor && item.dealer_present === checkDealerPresent))));
    
    
                        for (let configTemplate of getTemplatesFromCnfig) {
    
                            let getSavedTemplateData = templateConfigStateData.find((savedTemp: any) => +savedTemp.template_type === +configTemplate.template_type);
    
                            if (getSavedTemplateData) {
                                if(!getSavedTemplateData.description){
    
                                    getSavedTemplateData = { ...getSavedTemplateData, ...configTemplate }
                                    
                                    getSavedTemplateData['template_text'] = getSavedTemplateData?.description || ""
                                    getSavedTemplateData['description'] = getSavedTemplateData?.description || configTemplate['description']
                                    getSavedTemplateData['note'] = getSavedTemplateData.note || ""
                                }
    
                            } else {
                                getSavedTemplateData = { 'template_text': getSavedTemplateData?.template_text || "" }
    
                                getSavedTemplateData = { ...getSavedTemplateData, ...configTemplate }
    
                            }
    
                            finalArray.push(getSavedTemplateData)
                        }
    
    
                        filteredDescriptionData = finalArray
    
                    }
                } else {
                    checkFromState = true;
                }
    
                if (checkFromState) {
                    let checkDealerPresent = ([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(insuranceState.leadDetail.application_type_id)) ? (insuranceState.leadDetail.other_detail_dealer_id ? DEALER_PRESENT_YES : DEALER_PRESENT_NO) : null;

                    filteredDescriptionData = insuranceState.leadTemplateData?.filter((item: any) => (item.template_for === templateFor && item.dealer_present === checkDealerPresent));

                }
                setDescriptionData([...filteredDescriptionData]);
    
            } else {
    
                if (insuranceState?.leadDetail?.partner_id && triggerTemplateData) {
    
                    setLoading(true);
                    dispatch(getLeadEmailTemplates({ partner_id: +insuranceState?.leadDetail?.partner_id, leadId: insuranceState?.leadDetail?.leadId }))
                        .then((res: any) => {
                            setTriggerTemplateData(false)
                            setLoading(false);
    
                        }).catch((err: any) => setLoading(false));
                }
            }
        }
        // eslint-disable-next-line
    }, [props.templateType, insuranceState.leadDetail.partner_id, insuranceState.leadTemplateData]);


    const saveNoteData = (e: any, key: any, val: any) => {
        e.stopPropagation();
        let source;
        let type;
        // here  Source => 1: email, 2: whstapp
        val === 'email' ? source = 1 : source = 2;
        // here type => 1: broker, 2: policy, 
        key === 'broker' ? type = 1 : type = 2;

        if(notesValue?.note && notesValue?.note?.trim()){

            setLoading(true);
            updateTemplateNote({ newNote: notesValue?.note, leadId: insuranceState?.leadDetail?.leadId, source: source, type: type })
            .then((res: any) => {
                setTriggerTemplateData(false)
                setLoading(false);
                dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, sharedTemplateData: res.data }));
                // after submit input filed hide
                if (val === 'email') {  
                    setEmailNoteEditable(false);
                }
                else if (val === 'whatsApp') {
                    props.setTemplateData(whtsApptemplateData+"<br/><br/>Note: "+notesValue?.note)
                    setWhatsappNoteEditable(false);
                }
                
                
                
            }).catch((err: any) => setLoading(false));
        }else{
            
            setEmailNoteEditable(false);
            setWhatsappNoteEditable(false);
            setNotesValue({ templateType:"", note:"" });
            setemailNoteValue("")
            setWhatsappNoteValue("");

        }
    }

    const handleInputChange = (templateType: string, note: any) => {
        setNotesValue({ templateType, note })
        if (templateType === "email") {
            setemailNoteValue(note)
        } else {
            setWhatsappNoteValue(note);
        }
    }

    const editNote = (templateType: string) => {
        // if template is email and whstapp then edit field show
       if(templateType === "email"){
        setEmailNoteEditable(true);
       }
       else if(templateType === "whatsApp"){
        setWhatsappNoteEditable(true);
       }
       else{
        setEmailNoteEditable(true);
        setWhatsappNoteEditable(false);
       }
    }

    const generatePDF = (e: any, templateType: any, templateFor: any) => {
        e.stopPropagation();

        setLoading(true)

        let combinedContent = "";

        if (templateFor === "email") {

            combinedContent = `${templateData}`;
            combinedContent.replaceAll(`&#8369;`, '₱')
            if(emailNoteValue){
                combinedContent += `<br/><br/>Note: ${emailNoteValue || ""}`;
            }

        }
        if (templateFor === "whatsapp") {

            combinedContent = `${whtsApptemplateData}`;
            combinedContent.replaceAll(`&#8369;`, '₱')

            if(whatsappNoteValue){
                combinedContent += `<br/><br/>Note: ${whatsappNoteValue || ""}`;
            }
        }

        let fileName = insuranceState?.leadDetail?.leadId;
        fileName += "-"+((templateType === "broker") ?  `${templateFor}-send-to-broker.pdf` : `${templateFor}-policy-shared.pdf`);

        combinedContent = combinedContent.replaceAll(CURRENCY_SYMBOL, `<img src="${PesoImage}" alt="currency-image" style="width:10px"/>`);
        combinedContent = combinedContent.replaceAll(PHILIPPINE_CURRENCY, `<img src="${PesoImage}" alt="currency-image" style="width:10px"/>`);
        
        downloadTemplatePdf({html:combinedContent, name: fileName, lead_id: insuranceState?.leadDetail?.leadId}).then((resp:any)=>{
            if(resp.status===200 && resp?.data?.link){
                window.open(resp.data.link, '_blank')
            }

            setLoading(false)
        }).catch((err: any) => {
            setLoading(false)
        })

       

    };
    
    return (

        <div className="image-tab-panel emailer-outer">
            <FullPageLoader show={loading} />

            <div className="email-templete-sec financer-email-popup">
                <div className="heading-icn">
                    <h3>Email | Whatsapp - {props.templateType === "broker" ? 'Sent to Insurance Broker' : 'Insurance Policy Shared'}</h3>

                </div>
                <div className="lead-form-filed send-financer">

                    <div className="financer-doc-list">
                        <Accordion allowZeroExpanded>

                            {
                                templateData &&
                                <AccordionItem >
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Email <i className="ic-save_alt template-download-icon" onClick={(e: any) => generatePDF(e, props.templateType, 'email')}></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>

                                        <>

                                            <div className=''>
                                                
                                            <div ref={contentRef} id="emailPdfGenreator" className="financer-email-data" dangerouslySetInnerHTML={{ __html: templateData }} />

                                                <div className='d-flex p-lg'>

                                                    <div className={`email-note-text ${emailNoteEditable ? 'note-edit-option w-100' : 'd-flex' }`} >
                                                        {
                                                            emailNoteEditable
                                                                ?
                                                                (
                                                                    <>
                                                                        <fieldset className="form-filed w-100">
                                                                            <span className='p-sm-r nowrap'>Note :</span>
                                                                            <div className="material w-100">
                                                                                <textarea id="description" placeholder=" " name="note" className="form-input" value={emailNoteValue} onChange={(e) => handleInputChange('email', e.target.value)} />
                                                                                <span className='note-check m-sm-l' onClick={(e) => { saveNoteData(e, props.templateType, 'email') }}> <i className="ic-check"></i> </span>
                                                                            </div>
                                                                        </fieldset>

                                                                    </>
                                                                )
                                                                :
                                                                <>
                                                                    <span className='nowrap'>Note: </span> 
                                                                    { emailNoteValue && <span className='d-flex p-md-l longtext' > {emailNoteValue || ""} </span> }                                                               
                                                                </>
                                                        }

                                                    </div>

                                                    {
                                                        HELPER.isUserHasUpdateAccess({ permissionID: (props.templateType === "broker" ? ROLE_FEATURES['send_to_insurance_broker'] : ROLE_FEATURES['insurance_policy_shared']), accessType: 'edit', leadDetails: insuranceState.leadDetail, pageSlug: (props.templateType === "broker" ? "sent_to_insurance_broker" : "policy_shared") }) && !emailNoteEditable &&

                                                        <div className={`note-edit-icn ${emailNoteValue ? 'm-md-l' : ''}`} onClick={() => editNote('email')}><i className="ic-edit pointer"></i></div>

                                                    }
                                               
                                               </div>

                                            </div>
                                        </>
                                        {

                                        }
                                    </AccordionItemPanel>
                                </AccordionItem>
                            }

                            {
                                whtsApptemplateData &&

                                <AccordionItem >
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Whatsapp <i className="ic-save_alt template-download-icon" onClick={(e: any) => generatePDF(e, props.templateType, 'whatsapp')}></i>                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>

                                        <>
                                            <div className=''>
                                                <div ref={contentRef} className="financer-email-data" dangerouslySetInnerHTML={{ __html: whtsApptemplateData }} />

                                                <div className='d-flex p-lg'>

                                                    <div className={`email-note-text ${whatsappNoteEditable ? 'note-edit-option w-100' : 'd-flex' }`} >
                                                        {
                                                            whatsappNoteEditable
                                                                ?
                                                                (
                                                                    <>
                                                                        <fieldset className="form-filed w-100">
                                                                            <span className='p-sm-r nowrap'>Note :</span>
                                                                            <div className="material w-100">
                                                                                <textarea id="description" placeholder=" " name="note" className="form-input" value={whatsappNoteValue} onChange={(e) => handleInputChange('whatsApp', e.target.value)} />
                                                                                <span className='note-check m-sm-l' onClick={(e) => { saveNoteData(e, props.templateType, 'whatsApp') }}> <i className="ic-check"></i> </span>
                                                                            </div>
                                                                        </fieldset>

                                                                    </>
                                                                )
                                                                :
                                                                <>
                                                                    <span className='nowrap'>Note: </span> 
                                                                    { whatsappNoteValue && <span className='d-flex p-md-l longtext'> {whatsappNoteValue || ""} </span> }
                                                                </>
                                                        }

                                                    </div>

                                                    
                                                    {
                                                        HELPER.isUserHasUpdateAccess({ permissionID: (props.templateType === "broker" ? ROLE_FEATURES['send_to_insurance_broker'] : ROLE_FEATURES['insurance_policy_shared']), accessType: 'edit', leadDetails: insuranceState.leadDetail, pageSlug: (props.templateType === "broker" ? "sent_to_insurance_broker" : "policy_shared") }) && !whatsappNoteEditable && <div className={`note-edit-icn ${whatsappNoteValue ? 'm-md-l' : ''}`} onClick={() => editNote('whatsApp')}><i className="ic-edit pointer"></i></div>
                                                    }
                                                </div>
                                                
                                            </div>

                                        </>


                                    </AccordionItemPanel>
                                </AccordionItem>
                            }

                        </Accordion>
                    </div>
                </div>
            </div>
        </div>

    );
};


export default EmailTemplateView;
