import { useState } from 'react';
import FullPageLoader from '@src/common/FullPageLoader';
import DocumentTypeTab from '@src/component/insurance/common/DocumentTypeTab';


const DocumentGallery = (props: any) => {
    let [loading, setLoading] = useState(false)

    const setLoader = (loader: boolean) => {
        setLoading(loader)
    }

    return (
        <div className="doument-gallery-tabs">
            <FullPageLoader show={loading} />
            <DocumentTypeTab callFrom='docGallery' setLoader={setLoader} formType={props.formType} payment_from={props.payment_from}/>
        </div>
    );
}

export default DocumentGallery;
