import Navigation from '../common/Navigation'; 

const Header = () => {
    return (
        <header>
            <Navigation />
        </header>
    );
}

export default Header;