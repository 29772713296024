import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getTemplateFields, getTemplateFieldinfo, updateTemplate } from '@src/store/slices/insurance';
import { useNavigate } from 'react-router-dom';
import FullPageLoader from '@src/common/FullPageLoader';
import ReactSelect from '@common/ReactSelect';
import * as HELPER from "@src/config/helper";
import { ROLE_FEATURES, APP_TYPE_STANDALONE, APP_TYPE_WITH_UCF, APP_TYPE_RENEWAL, APP_TYPE_AGENCY } from "@src/config/constant";
import { useDispatch, useSelector } from 'react-redux';
import { getMasterConfig } from '@src/store/slices/common';

let initialFilterData = {
    template_type: "",
    template_for: ""
};
let resetWarningMsg = "WARNING! The unsaved template changes will be removed.";

const SendToFinancierTemplateDetail = (props: any) => {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();


    let [loading, setLoading] = useState(false);

    let [templateDetails, setTemplateDetails] = useState<any>({
        financier_name: "",
        cohort_list: [],
        attributes: {},
        templateType: [],
        templateFor: [],
        dealer_present: []
    })

    const [groupedData, setGroupedData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState<any>(initialFilterData);
    const [showForm, setShowForm] = useState(false);

    const [templateKey, setTemplateKey] = useState('');

    const [selectionPos, setSelectionPos] = useState(-1);

    // const [cohortList, setCohortList] = useState([]);

    const [selectedTemplateData, setSelectedTemplateData] = useState<any>({});

    const [originalGroupedData, setOriginalGroupedData] = useState([]);

    const [showFiled, setShowFiled] = useState(false);
    // eslint-disable-next-line
    const [showTemplateConfiguration, setShowTemplateConfiguration] = useState(true);
    const [originalTemplateType, setOriginalTemplateType] = useState<any[]>([]);
    const [showEmailField, setShowEmailField] = useState(true);
    const [trigger, SetTrigger] = useState(0);

    const [showPermission, setShowPermission] = useState(false);

    const [errField, setErrField] = useState<any>({
        email: "",
        description: "",
    });

    const [originalDealerSelect, setOriginalDealerSelect] = useState<any[]>([]);

    const navigate = useNavigate();

    const dispatch: any = useDispatch();
    const commonState = useSelector((state: any) => state.common)
    // Handle changes in dropdown selections
    const handleChange = (selectedOption: any, key: string) => {
        let updatedFilters = selectedFilters;
        updatedFilters = { ...updatedFilters, [key]: selectedOption['value'] };
        setSelectedFilters(updatedFilters);
        // set permission
        if ((updatedFilters?.template_for === 1)) {
            setShowPermission(HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['sent_to_insurance_broker'], accessType: 'edit' }));
        }
        else if ((updatedFilters?.template_for === 2)) {
            setShowPermission(HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_policy'], accessType: 'edit' }))
        }

    }

    // Fetch data when the component mounts
    useEffect(() => {
        setLoading(true);
        // Fetch template details data
        getTemplateFieldinfo({ partner_id: searchParams.get('partner_id') }).then((res: any) => {
            if (res?.status === 200) {
                setTemplateDetails(res.data)

                setOriginalTemplateType(res.data.templateType);
                setOriginalDealerSelect(res.data.dealerPresent)
                setLoading(false);
            }
            else {
                setLoading(false);
                navigate('/email-template-config');
            }
        });
        // eslint-disable-next-line
    }, [trigger]);


    useEffect(() => {
        setLoading(true);
        // Fetch template fields data
        getTemplateFields().then((res: any) => {
            if (res?.status === 200) {
                // Create an array of child objects sorted by order
                const sortedChildObjects: any = Object.values(res.data)?.map((obj: any) => {
                    const sortedChild = Object.values(obj.child)?.sort((a: any, b: any) => a.order - b.order);
                    return { ...obj, child: sortedChild };
                });
                setGroupedData(sortedChildObjects)
                setOriginalGroupedData(sortedChildObjects);
                setLoading(false);
            } else {
                setLoading(false);
            }

        });
    }, [])

    const addTemplate: any = () => {
        setShowFiled(false);
        setShowForm(true);
        setSelectedFilters(initialFilterData);
        setSelectedTemplateData({ status: '1', group_name: ``, template_type: 0, template_for: 0, email: '' });

    }


    const handleTextChange = (e: any) => {
        setSelectedTemplateData({ ...selectedTemplateData, 'description': e.target.value });
    };


    const handleKeySelect = (e: any, params: any) => {
        e.stopPropagation();

        if (!params.child || (!params?.child?.length)) {
            setTemplateKey(params.field_key);
        }
    };


    const handleForwardButtonClick = () => {
        if (selectionPos >= 0 && templateKey) {

            // const selected = (selectedTemplateData?.description?.slice(0, selectionPos) || '') + `{{${templateKey}}}` + (selectedTemplateData?.description?.slice(selectionPos, -1) || '');
            // setSelectedTemplateData({ ...selectedTemplateData, 'description': selected });
            const selected = selectedTemplateData?.description || '';
            const newDescription = [
                selected.slice(0, selectionPos),
                `{{${templateKey}}}`,
                selected.slice(selectionPos)
            ].join('');
            setSelectedTemplateData({ ...selectedTemplateData, description: newDescription });
            setTemplateKey('');
        }
    };

    const handleReverseButtonClick = () => {
        if (templateKey) {
            //Remove all key at on click
            const keyRegex = new RegExp(`{{${templateKey}}}`, 'g');
            const updatedText = selectedTemplateData.description.replace(keyRegex, '');
            // setInputText(updatedText);
            setSelectedTemplateData({ ...selectedTemplateData, 'description': updatedText });

            setTemplateKey('');
        }
    };


    const SaveTemplateField = async () => {
        setLoading(true);
        if (await validForm()) {
            let saveParams: any = {
                email: selectedTemplateData.email,
                description: selectedTemplateData.description,
                status: selectedTemplateData.status
            };

            if (selectedTemplateData.template_id) {
                saveParams['templateID'] = selectedTemplateData.template_id;
                saveParams['template_for'] = selectedTemplateData.template_for;
                saveParams['template_type'] = selectedTemplateData.template_type;
                saveParams['partner_id'] = searchParams.get('partner_id');
                saveParams['email'] = selectedTemplateData?.email
            } else {
                saveParams['template_type'] = selectedTemplateData.template_type;
                saveParams['template_for'] = selectedTemplateData.template_for;
                saveParams['partner_id'] = searchParams.get('partner_id');
                saveParams['email'] = selectedTemplateData?.email

                if([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(selectedTemplateData.insurance_type)){
                    saveParams['dealer_present'] = selectedTemplateData?.dealer_present
                }

            }
            saveParams.insurance_type = selectedTemplateData.insurance_type
            updateTemplate(saveParams).then((res: any) => {
                if (res.status === 200) {
                    setSelectedTemplateData({ ...selectedTemplateData, template_id: res.data.id });
                    toast.success(res.message);
                    setLoading(false);
                    SetTrigger(trigger + 1);
                    if(res?.data?.id){
                        setShowFiled(true);
                    }

                } else {
                    setLoading(false);
                }
            });
        }
        else {
            setLoading(false);
        }
    }

    const submitFilter = () => {
        setLoading(true);
        if (selectedFilters.template_type && selectedFilters.template_for && selectedFilters.insurance_type) {

            let filteredTemplate: any = templateDetails?.cohort_list?.find((elm: any) => (+elm.template_for === +selectedFilters.template_for && +elm.template_type === +selectedFilters.template_type && +elm.insurance_type === +selectedFilters.insurance_type && (([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(+elm.insurance_type) && +elm.dealer_present === +selectedFilters.dealer_present) || ([APP_TYPE_WITH_UCF, APP_TYPE_AGENCY].includes(+elm.insurance_type) && !elm.dealer_present))));
            
            setSelectedTemplateData({});
            setShowForm(false)
            setShowFiled(false);
            setShowTemplateConfiguration(false);

            if (filteredTemplate && filteredTemplate.partner_id) {

                setSelectedTemplateData(filteredTemplate);

                setShowForm(true)
                setShowFiled(true);
                setShowTemplateConfiguration(true);
                // if template for is whatsapp then email field would reset during reset
                if (selectedFilters?.template_type === 2) {
                    setShowEmailField(true)
                } else {
                    setShowEmailField(false);
                }
            }
            else {
                setLoading(false);
                toast.error('No Financier Template Found');

            }
        }

        leftSideFieldsHandler(selectedFilters);

        setLoading(false);

    }

    const leftSideFieldsHandler = (params: any) => {
        // filter Details according to template For
        if (Object.keys(originalGroupedData)?.length > 0) {
            let filteredData: any = [...Object.values(originalGroupedData)];

            if (params.template_for === 2) {
                filteredData = filteredData.filter((item: any) =>
                    +item.insurance_policy_shared === 1
                );

                setGroupedData({ ...filteredData });
            }
            else if (params.template_for === 1) {
                filteredData = filteredData.filter((item: any) =>
                    +item.send_to_insurance_broker === 1
                );
                setGroupedData({ ...filteredData });
            }
        }

    }

    const handleTemplateData = (e: any, key: string) => {

        let value = "";
        if (key === "status") {
            value = +selectedTemplateData.status ? '0' : '1'
        }

        setSelectedTemplateData({ ...selectedTemplateData, [key]: value })
    }

    //sub filter handle
    const addTemplateFieldHandler = (e: any, key: string) => {
        let updatedSelectedData = { ...selectedTemplateData, [key]: (key === "email" ? e.target.value : e.value) };

        setSelectedTemplateData(updatedSelectedData);
        if (key === 'template_for') {
            setSelectedTemplateData({ ...updatedSelectedData, template_type: '' });
            // when user change Select Template then reset description
            if (updatedSelectedData.description) {
                delete updatedSelectedData.description;
            }
            let newList = templateDetails?.cohort_list?.filter((ob: any) => +ob.template_for === +e.value && +ob.insurance_type === updatedSelectedData?.insurance_type && (([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(updatedSelectedData?.insurance_type)  && ob.dealer_present === updatedSelectedData.dealer_present) || (updatedSelectedData?.insurance_type === APP_TYPE_WITH_UCF))).map((val: any) => +val.template_type);
            templateDetails.templateType = originalTemplateType.filter((ob: any) => !newList?.includes(+ob.value))

            setTemplateDetails(templateDetails);
            setShowTemplateConfiguration(false);

            leftSideFieldsHandler(updatedSelectedData);

        }
        if (key === 'template_type') {
            setShowEmailField(false);

            if (e.value !== 1) {
                setShowEmailField(true);
            }

            // Email will blank in whatsapp case only
            if (+updatedSelectedData?.template_type === 2) {
                updatedSelectedData.email = '';
                setSelectedTemplateData(updatedSelectedData);
            }


            let newList = templateDetails?.cohort_list?.filter((ob: any) =>  +ob.insurance_type === updatedSelectedData?.insurance_type && (+ob.template_for === +updatedSelectedData.template_for) && (+ob.template_type === +updatedSelectedData.template_type)).map((val: any) => +val.dealer_present);
            templateDetails.dealerPresent = originalDealerSelect.filter((ob: any) => !newList?.includes(+ob.value))

        }
        if (key === 'insurance_type') {
            // let newList = templateDetails?.cohort_list?.filter((ob: any) => +ob.insurance_type === +e.value ).map((val: any) => +val.template_for);
            // templateDetails.templateFor = originalTemplateFor.filter((ob: any) => !newList?.includes(+ob.value))

            // setTemplateDetails(templateDetails);

            // when user change Insurance Type then it reset Select Template
            if (updatedSelectedData.template_for) {
                delete updatedSelectedData.template_for;
            }
        }

        if ((updatedSelectedData?.template_for === 1)) {
            setShowPermission(HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['sent_to_insurance_broker'], accessType: 'edit' }));
        }
        else if ((updatedSelectedData?.template_for === 2)) {
            setShowPermission(HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_policy'], accessType: 'edit' }))
        }

    }

    // on cancel page re-direct to listing
    const CancelTemplateField = () => {
        if (window.confirm(resetWarningMsg)) {
            navigate('/email-template-config');
        }
    }

    useEffect(() => {
        // Check if 'partner_id' parameters are present
        if (!searchParams.get('partner_id')) {
            navigate('/email-template-config');
        }
    }, [searchParams, navigate]);

    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i); // eslint-disable-line  

    // Function to validate the form
    const validForm = async () => {
        let formIsValid = true;
        setErrField({
            email: "",
            description: "",

        });

        if (selectedTemplateData.email === "" && selectedTemplateData?.template_type === 1) {
            formIsValid = false;
            setErrField((prevState: any) => ({
                ...prevState,
                email: "Email is required",
            }));
        }
        else if (!validEmailRegex.test(selectedTemplateData.email) && selectedTemplateData?.template_type === 1) {
            let multipleEmailChk = selectedTemplateData.email.split(',');
            for (const email of multipleEmailChk) {
                if (!validEmailRegex.test(email.trim())) {
                    formIsValid = false;
                    setErrField((prevState: any) => ({
                        ...prevState,
                        email: "Please enter valid email",
                    }));
                    break;
                }
            }
        }

        if (!selectedTemplateData.dealer_present && [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(selectedTemplateData?.insurance_type)) {
            formIsValid = false;
            setErrField((prevState: any) => ({
                ...prevState,
                dealer_present: "Dealer present is required",
            }));
        }

        let checkValidKeys = checkFieldKeyExists(originalGroupedData)//formIsValid ? checkFieldKeyExists(originalGroupedData) : true;

        const regex = /{{(.*?)}}/g;
        const descriptionKeys = selectedTemplateData.description.match(regex); //GET ALL KEYS IN TEXT

        let fieldKeyError = false;
        if (+selectedTemplateData.template_for === 1) { //SENT TO BROKER TEMPLATE

            let unwantedKeys = descriptionKeys && descriptionKeys.filter((x: any) => {
                if (checkValidKeys && checkValidKeys.sentToBrokerKeys) {
                    return !checkValidKeys.sentToBrokerKeys.includes(x);
                }
                // handle the case when sentToBrokerKeys is undefined
                return false;
            });

            if (unwantedKeys?.length) {
                formIsValid = false;
                fieldKeyError = true;
            }

        } else { //POLICY SHARED 

            let unwantedKeys = descriptionKeys?.filter((x: any) => !checkValidKeys?.policySharedKeys?.includes(x));

            if (unwantedKeys?.length) {
                formIsValid = false;
                fieldKeyError = true;
            }
        }

        if (fieldKeyError) {
            toast.error("Please check template text, some of the keys are not allowed");
        }

        return formIsValid;
    };

    // Helper function to check if a field_key exists in the nested structure
    //  const checkFieldKeyExists = (object:any, field_key:any, templateFor:any) => {

    //     let isvalid = false
    //     if (object.field_key === field_key) {
    //         if (templateFor === 1) {
    //             return object.send_to_insurance_broker === 1;
    //         } else if (templateFor === 2) {
    //             return object.insurance_policy_shared === 1;
    //         }
    //     }

    //         if (object.child) {      
    //             for (const key in object.child) {          
    //                 let subChild = object.child[key].child;
    //                 let findElement = subChild && subChild.find((e: any) => e.field_key === field_key)  
    //             // Check if subChild element exists and has the required permission
    //                 if (findElement && findElement.insurance_policy_shared === 0) {   
    //                   isvalid = true
    //                   break;
    //                   }   
    //             }
    //         }

    //     //return true;
    //     return isvalid;
    // };

    //GET ALL FIELDS KEYs
    const checkFieldKeyExists = (fieldData: any): any => {
        let sentToBrokerKeys: any = [];
        let policySharedKeys: any = [];
        for (const key in fieldData) {
            const topLevelObject: any = fieldData[key];

            let childsLength = (topLevelObject.child) ? (Array.isArray(topLevelObject.child) ? Object.assign({}, topLevelObject.child) : (topLevelObject.child)) : {};
            if (Object.keys(childsLength).length) {

                let allKeys = checkFieldKeyExists(topLevelObject.child);
                sentToBrokerKeys = [...sentToBrokerKeys, ...allKeys.sentToBrokerKeys]
                policySharedKeys = [...policySharedKeys, ...allKeys.policySharedKeys]

            } else {
                if (topLevelObject.send_to_insurance_broker && +topLevelObject.insurance_policy_shared) {

                    sentToBrokerKeys.push(`{{${topLevelObject.field_key}}}`)
                    policySharedKeys.push(`{{${topLevelObject.field_key}}}`)

                }
                else {
                    if (topLevelObject.send_to_insurance_broker) {
                        sentToBrokerKeys.push(`{{${topLevelObject.field_key}}}`)

                    } else {
                        policySharedKeys.push(`{{${topLevelObject.field_key}}}`)
                    }
                }

            }

        }
        return { sentToBrokerKeys, policySharedKeys };

    };

    const resetHandler = () => {
        // when data is added
        if (selectedTemplateData.partner_id === undefined || selectedTemplateData.partner_id === null) {
            setSelectedTemplateData({ ...selectedTemplateData, template_for: '', template_type: '', email: '', description: '', insurance_type: '' });
        }
        //when data is Edited
        if (selectedTemplateData?.partner_id) {
            setSelectedTemplateData({ ...selectedTemplateData, email: '' });
        }
        setTemplateKey('');


    }

    useEffect(() => {
        if (Object.keys(commonState.common).length === 0) {
            dispatch(getMasterConfig());
        }
        // eslint-disable-next-line
    }, []);
    return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <div className="top-heading-outer">
                <div className="back-opt">
                    <Link to="/email-template-config" title="Back">
                        <i className="ic-arrow_back"></i>
                        Back
                    </Link>
                </div>
                <div className="top-heading">
                    <h1>Send to Financier Template</h1>
                </div>
                <div className="sub-heading">{templateDetails.financier_name}</div>
            </div>
            <div className="container-fluid">
                <div className="financier-detail-outer">
                    <div className='filter-filed-form'>
                        {/* Insurance Type dropdown for Searcing case*/}
                        <ReactSelect
                            options={commonState?.common?.apptype || []}
                            value={commonState?.common?.apptype?.find((v: any) => +v.value === +selectedFilters['insurance_type']) || null}
                            handleChange={(e: any, fieldKey: string) => handleChange(e, fieldKey)} placeholder="Select Insurance Type" fieldKey="insurance_type"
                        />

                        <ReactSelect options={templateDetails?.templateFor || []}
                            value={templateDetails['templateFor']?.find((v: any) => +v.value === +selectedFilters['template_for']) || null}
                            handleChange={(e: any, fieldKey: string) => handleChange(e, fieldKey)} placeholder="Select Template For" fieldKey="template_for"
                        />

                        <ReactSelect options={originalTemplateType}
                            value={originalTemplateType?.filter((v: any) => v.value === selectedFilters['template_type']) || null}
                            handleChange={(e: any, fieldKey: string) => handleChange(e, fieldKey)} placeholder="Select Template" fieldKey="template_type" key="template_type"
                        />

                        {
                            [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(selectedFilters['insurance_type']) &&
                            <ReactSelect options={originalDealerSelect}
                                value={originalDealerSelect?.filter((v: any) => v.value === selectedFilters['dealer_present']) || null}
                                handleChange={(e: any, fieldKey: string) => handleChange(e, fieldKey)} placeholder="Dealer Present" fieldKey="dealer_present" key="dealer_present"
                            />
                        }

                        <div className="btn-submit-reset">
                            <button className="btn-primary" type="submit" disabled={!(selectedFilters.template_for && selectedFilters.template_type && selectedFilters.insurance_type && (([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(selectedFilters.insurance_type) && selectedFilters.dealer_present) || ((selectedFilters.insurance_type === APP_TYPE_WITH_UCF)) || ((selectedFilters.insurance_type === APP_TYPE_AGENCY))))} onClick={submitFilter}>Search</button>
                            {
                                (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['sent_to_insurance_broker'], accessType: 'edit' }) || HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_policy'], accessType: 'edit' })) &&
                                <button className="btn-line" onClick={addTemplate}>Add New</button>
                            }
                        </div>
                    </div>
                    {showForm &&
                        <>
                            <div className='financier-sub-filter-list'>
                                <div className='financier-top-filter'>
                                    {/* Add extra dropdown for Insurance Type duing Add New case only*/}

                                    <ReactSelect options={commonState?.common?.apptype || []}
                                        value={commonState?.common?.apptype?.find((v: any) => +v.value === +selectedTemplateData['insurance_type']) || null}
                                        handleChange={(e: any, fieldKey: string) => addTemplateFieldHandler(e, fieldKey)} placeholder="Select Insurance Type" fieldKey="insurance_type" key="insurance_type"
                                        isDisabled={showFiled}
                                    />

                                    <ReactSelect options={templateDetails?.templateFor || []}
                                        value={(templateDetails && templateDetails.templateFor && selectedTemplateData?.template_for) ? templateDetails.templateFor?.find((elm: any) => +elm.value === selectedTemplateData?.template_for) : null}
                                        handleChange={(e: any, fieldKey: string) => addTemplateFieldHandler(e, fieldKey)} placeholder="Select Template" fieldKey="template_for" key="template_for"
                                        isDisabled={showFiled}
                                    />


                                    <ReactSelect options={templateDetails?.templateType || []}
                                        value={(templateDetails && templateDetails.templateType && selectedTemplateData?.template_type) ? templateDetails.templateType?.find((elm: any) => +elm.value === selectedTemplateData?.template_type) : null}
                                        handleChange={(e: any, fieldKey: string) => addTemplateFieldHandler(e, fieldKey)} placeholder="Template Configuration" fieldKey="template_type" key="template_type"
                                        isDisabled={showFiled}
                                    />


                                    <fieldset className="multiselect-dropDown">
                                        <div className="material animation-effect ">
                                            <input
                                                value={selectedTemplateData?.email || ""}
                                                placeholder={" "}
                                                className="form-input"
                                                onChange={(e) => addTemplateFieldHandler(e, 'email')}
                                                type="text"
                                                disabled={showEmailField}
                                                title={selectedTemplateData?.email || ""}
                                            />
                                            <label data-label={"Financier Email ID"} className="form-label"></label>
                                            {errField.email && (
                                                <span className="error-txt">
                                                    {errField['email']}
                                                </span>
                                            )}
                                        </div>
                                    </fieldset>
                                    {
                                        [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(selectedTemplateData?.insurance_type) &&
                                        <ReactSelect options={templateDetails?.dealerPresent || []}
                                            value={(selectedTemplateData?.dealer_present) ? templateDetails?.dealerPresent?.find((elm: any) => +elm.value === selectedTemplateData?.dealer_present) : null}
                                            handleChange={(e: any, fieldKey: string) => addTemplateFieldHandler(e, fieldKey)} placeholder="Dealer Present" fieldKey="dealer_present" key="dealer_present"
                                            isDisabled={showFiled}
                                        />
                                    }

                                    <div className="btn-submit-reset">
                                        <button className="btn-reset" onClick={resetHandler}>Reset</button>
                                    </div>

                                </div>
                            </div>

                            <div className='template-filed-outer'>
                                <div className="swith-txt-outer">
                                    <h3>Template Fields</h3>
                                    <div className="toggle-switch-outer">
                                        {showPermission &&
                                            <div className="toggleApp">
                                                <div className={`ToggleSwitch ${+selectedTemplateData?.status ? 'active' : ''}`} onClick={(e) => handleTemplateData(e, 'status')}>
                                                    <div className={`knob ${+selectedTemplateData?.status ? 'active' : ''}`}></div>
                                                    <div className="toggle-status">Publish</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="templete-list-filed-outer">
                                    <div className="list-left-panel">
                                        {Object.values(groupedData).map((parent: any) => (
                                            <div key={parent.id}>
                                                <h2>{parent.name}</h2>
                                                <ul>
                                                    {Object.values(parent.child).map((child: any) => (
                                                        <li key={child.id}
                                                            data-key={`{{${child.field_key}}}`}
                                                            className={(templateKey === child?.field_key) ? "selected-attribute" : ""}
                                                            onClick={(e) => handleKeySelect(e, child)}

                                                        >
                                                            {
                                                                child?.child?.length ?
                                                                    <strong>{child.name}</strong> :
                                                                    <span>{child.name}</span>
                                                            }
                                                            {(child?.child?.length > 0) &&
                                                                <ul>
                                                                    {
                                                                        Object.values(child?.child).map((innerChild: any) => (

                                                                            <li className={(templateKey === innerChild?.field_key) ? "selected-attribute" : ""} key={innerChild.id} onClick={(e) => handleKeySelect(e, innerChild)} >{innerChild.name}</li>
                                                                        )
                                                                        )
                                                                    }
                                                                </ul>
                                                            }

                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="add-remove-arrow">
                                        <ul>
                                            <li>
                                                <i className="ic-arrow_forward" onClick={handleForwardButtonClick}></i>
                                            </li>
                                            <li><i className="ic-arrow_back" onClick={handleReverseButtonClick}></i></li>
                                        </ul>
                                    </div>
                                    <div className="text-filed-select-area">
                                        <textarea
                                            className=""
                                            placeholder="Select the template field to configure template body"
                                            onChange={handleTextChange}
                                            onBlur={(e: any) => setSelectionPos(e.target.selectionStart)}
                                            onFocus={(e: any) => setSelectionPos(e.target.selectionStart)}
                                            id="inputTextArea"
                                            value={selectedTemplateData?.description || ''}
                                        >
                                        </textarea>
                                        {/* {errField.description && (
                                <span className="error-txt">
                                    {errField['description']}
                                </span>
                            )} */}
                                    </div>
                                </div>

                                <div className="btn-submit-reset update-btn">
                                    {
                                        showPermission &&
                                        <button className="btn-primary" onClick={(selectedTemplateData?.description?.trim() && selectedTemplateData?.template_type) ? SaveTemplateField : undefined}
                                            disabled={!(selectedTemplateData?.description?.trim() && selectedTemplateData?.template_type && (([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(selectedTemplateData?.insurance_type) && selectedTemplateData?.dealer_present) || [APP_TYPE_WITH_UCF, APP_TYPE_AGENCY].includes(selectedTemplateData?.insurance_type)))}
                                        >Update</button>
                                    }
                                    <button className="btn-line" onClick={CancelTemplateField}>Cancel</button>

                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default SendToFinancierTemplateDetail;