import { updateMasterRates, updateReduxMasterRates } from '../../../store/slices/common';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import * as HELPER from "../../../config/helper";
import { ROLE_FEATURES } from '@src/config/constant';
import ReactSelect from '@common/ReactSelect';

type InputFieldType = {
    insurance_type: { label: string, value: number } | null,
    partner: { label: string, value: number } | null
    value: number | null,
    rule_engine?: { label: string, value: number }

}

export default function VatRate(props: any): ReturnType<React.FC> {
    let { callfor, insuranceList, partnerList, userId, ruleEngineList, handleLoader, handleModalClose } = props
    let [inputField, setInputFields] = useState<InputFieldType>({ insurance_type: null, partner: null, value: null })
    let [filteredPartnerList, setFilteredPartnerList] = useState<[]>()
    let [errors, setErrors] = useState<any>()

    let dispatch = useDispatch()
    let commonState = useSelector((state: any) => state.common)

    let isRateEditable = HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_module'], accessType: 'edit' })

    /**
     * function to handle change in inputs
     * @param name 
     * @param value 
     */
    const handleChange = (name: string, value: any) => {
        //for updating partner list based on insurance type
        if (name === 'insurance_type') {
            if (value) {
                if (filteredPartnerList?.length) {
                    let updatedList: any = partnerList && partnerList.filter((e: any) => e.insurance_type_id.includes(value.value))
                    let selectedPartner = updatedList && updatedList[0]
                    let seletedRuleEngine = ruleEngineList && ruleEngineList[0]


                    let updateObj: any = {
                        partner: selectedPartner
                    }

                    if (callfor === 'local_govt_tax') {
                        updateObj['rule_engine'] = seletedRuleEngine
                    }

                    setInputFields((prev: any) => {
                        return {
                            ...prev,
                            ...updateObj
                        }
                    })

                    setFilteredPartnerList(updatedList)
                    autoFillPreviousRates({ insurance_type: value, partner: selectedPartner, rule_engine: seletedRuleEngine })
                }
            }
        }

        //for updating rate according to selected insurance_type and partner
        if (name === 'partner') {
            if (value) {
                let seletedRuleEngine = ruleEngineList && ruleEngineList[0]

                if (callfor === 'local_govt_tax') {
                    setInputFields((prev: any) => {
                        return {
                            ...prev,
                            rule_engine: seletedRuleEngine
                        }
                    })
                }
                autoFillPreviousRates({ insurance_type: inputField.insurance_type, partner: value, rule_engine: seletedRuleEngine })
            }
        }

        //for updating rate according to selected insurance_type and partner_id and rule_engine
        if (name === 'rule_engine' && inputField.insurance_type && inputField.partner && value) {
            let params = {
                insurance_type: inputField.insurance_type,
                partner: inputField.partner,
                rule_engine: value
            }
            autoFillPreviousRates(params)
        }


        if (name === 'value' && value) {
            //regex to accept numbers from 0-100 non-negative and upto 2 decimal places
            let isValid = (/^(?<whole>100|\d{1,2})(\.\d{0,2})?$/).test(value)
            if (!isValid) return

        }


        setInputFields((prev: any) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const checkValidation = (): boolean => {
        let error = false
        setErrors({})

        for (let key in inputField) {
            let value = inputField[key as keyof InputFieldType]
            if (!value) {
                error = true
                setErrors((prev: any) => {
                    return {
                        ...prev,
                        [key]: true
                    }
                })
            }
        }

        //specifc handling for rule engine in local govt tax popup
        if (callfor === 'local_govt_tax' && !inputField.rule_engine) {
            error = true
            setErrors((prev: any) => {
                return {
                    ...prev,
                    rule_engine: true
                }
            })
        }

        return error
    }

    /**
     * executes on Save button click
     */
    const handleSubmit = async () => {
        let isErrorExist = checkValidation()

        if (!isErrorExist) {
            handleLoader(true)
            let apiParams: any = { user_id: userId }

            let key = callfor === 'vat' ? 'vat_rate' : callfor

            apiParams[key] = {
                // remove insurace type id for rscc and pa
                insurance_type_id: (props.callfor === 'rscc' || props.callfor === 'pa') ? undefined : inputField.insurance_type?.value,
                partner_id: inputField.partner?.value,
                value: inputField.value
            }

            //concatenating rule engine in api call in case of local_govt_tax 
            if (callfor === 'local_govt_tax') {
                apiParams[key] = { ...apiParams[key], rule_engine_id: inputField.rule_engine?.value }
            }

            let updateVATRate: any = await updateMasterRates(apiParams)

            if (updateVATRate && updateVATRate.status && updateVATRate.status === 200) {
                toast.success(`Value Updated Successfully`)
                handleModalClose()
                //update redux state
                let updateRateInRedux = JSON.parse(JSON.stringify(commonState))
                let { rates } = updateRateInRedux
                let { tax_config } = rates

                if (tax_config) {
                    let updatedTax: any = JSON.parse(JSON.stringify(tax_config))

                    for (let key of updatedTax) {
                        //for vat/stamp_duty
                        if (callfor !== 'local_govt_tax') {
                            if (key.insurance_type_id === inputField.insurance_type?.value && key.partner_id === inputField.partner?.value) {
                                key[callfor] = inputField.value
                            }
                        }
                        //for local_govt_tax
                        if (callfor === 'local_govt_tax') {
                            if (key.insurance_type_id === inputField.insurance_type?.value && key.partner_id === inputField.partner?.value && key.rule_engine_id === inputField.rule_engine?.value) {
                                key['local_govt_taxes'] = inputField.value
                            }
                        }
                    }

                    //for new partners
                    if (!updatedTax.includes(inputField.partner?.value)) {
                        if (callfor !== 'local_govt_tax') {
                            //insert for both rule engines
                            for (let i = 0; i < 2; i++) {
                                let insertObj = {
                                    insurance_type_id: inputField.insurance_type?.value,
                                    partner_id: inputField.partner?.value,
                                    rule_engine_id: i++,
                                    [callfor]: inputField.value
                                }
                                updatedTax.push(insertObj)
                            }
                        }
                        else {
                            let insertObj = {
                                insurance_type_id: inputField.insurance_type?.value,
                                local_govt_taxes: inputField.value || 0,
                                partner_id: inputField.partner?.value,
                                rule_engine_id: inputField.rule_engine?.value,
                            }
                            updatedTax.push(insertObj)
                        }
                    }
                    rates.tax_config = updatedTax
                    dispatch(updateReduxMasterRates(rates))
                }
            }

            handleLoader(false)
        }

    }

    /**
    * function for displaying prefilled rates
    * @param apptype 
    */
    const autoFillPreviousRates = useCallback((params: any) => {
        let insTypeId = params.insurance_type?.value
        let partnerId = params.partner?.value
        let ruleEngineId = (params.rule_engine && params.rule_engine.value)

        let { rates } = commonState
        if (rates && rates.tax_config) {
            let prevRateData = rates.tax_config
            let element = prevRateData.find((e: any) => e.partner_id === partnerId && e.insurance_type_id === insTypeId)

            if (callfor === 'local_govt_tax') {
                element = prevRateData.find((e: any) => e.partner_id === partnerId && e.insurance_type_id === insTypeId && e.rule_engine_id === ruleEngineId)
            }

            if (callfor === 'vat') element = (element && element.vat) || 0
            if (callfor === 'stamp_duty') element = (element && element.stamp_duty) || 0
            if (callfor === 'local_govt_tax') element = (element && element.local_govt_taxes) || 0
            if (callfor === 'rscc') element = (element && element.rscc) || 0
            if (callfor === 'pa') element = (element && element.pa) || 0

            if (element || element === 0) {
                setInputFields((prev: any) => {
                    return {
                        ...prev,
                        value: +element
                    }
                })
            }
        }
    }, [commonState, callfor])

    useEffect(() => {
        if (insuranceList && partnerList) {
            let preSelectedIns: any = (insuranceList && insuranceList[0]) || {}

            let updatedPartnerList = partnerList
            if (updatedPartnerList.length && preSelectedIns) {
                updatedPartnerList = updatedPartnerList.filter((e: any) => e.insurance_type_id.includes(preSelectedIns.value))

                // Filtering based on props.callfor
                if (props.callfor === 'pa' || props.callfor === 'rscc') {
                    updatedPartnerList = updatedPartnerList.filter((val: any) => val.slug === "oona");
                }
            }

            // Set preSelectedPartner based on whether 'oona' is in the updatedPartnerList
            let preSelectedPartner: any = null;
            if (props.callfor === 'pa' || props.callfor === 'rscc') {
                preSelectedPartner = updatedPartnerList.find((partner: any) => partner.slug === 'oona');
            } else if (updatedPartnerList.length > 0) {
                preSelectedPartner = updatedPartnerList[0];
            }
            
            let preSelectedRE: any = (ruleEngineList && ruleEngineList[0]) || {}

            let ipField: any = {
                insurance_type: preSelectedIns, partner: preSelectedPartner, value: null
            }

            if (preSelectedRE) ipField['rule_engine'] = preSelectedRE
            setFilteredPartnerList(updatedPartnerList)
            setInputFields(ipField)
            autoFillPreviousRates(ipField)
        }
    }, [insuranceList, partnerList, ruleEngineList, autoFillPreviousRates,props.callfor])

    useEffect(() => {
    }, [props])


    return (
        <div className="calculator-form">

            <div className={`row ${props.callfor+'_popup'}`}>
                {(props.callfor !== 'pa' && props.callfor !== 'rscc') &&
                <ReactSelect options={insuranceList}
                    value={inputField && inputField.insurance_type}
                    handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)}
                    placeholder="Insurance Type*" fieldKey="insurance_type" key="insurance_type"
                    errorField={errors?.insurance_type ? "This field is required" : ""}
                    fieldSetClassName={"col-md-6"}
                />
                }

                <ReactSelect options={filteredPartnerList}
                    value={inputField && inputField.partner}
                    handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)}
                    placeholder="Insurance Partner Name*" fieldKey="partner" key="partner"
                    errorField={errors?.insurance_type ? "This field is required" : ""}
                    fieldSetClassName={"col-md-6 react-select-max-value"}
                />


                {callfor && callfor === 'local_govt_tax' ?
                    <ReactSelect options={ruleEngineList}
                        value={inputField && inputField.rule_engine}
                        handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)}
                        placeholder="Rule Engine*" fieldKey="rule_engine" key="rule_engine"
                        errorField={errors?.insurance_type ? "This field is required" : ""}
                        fieldSetClassName={"col-md-6"}
                    />

                    : ''}


                <fieldset className="form-filed col-md-6">
                    <div className="material">
                        <input id="" type="text" placeholder=" " name="vat" className="form-input" onChange={(e) => handleChange('value', e.target.value)} value={(inputField.value || inputField.value === 0 ? inputField.value : '')} disabled={!isRateEditable} />
                        <label data-label="Rate(%)" className="form-label"></label>
                        {
                            errors && errors.value
                                ?
                                <span className="error-txt">{"This field is required"}</span>
                                :
                                ''
                        }
                    </div>
                </fieldset>

                {isRateEditable ?
                    <div className="col-md-12">
                        <button className="btn-primary" onClick={() => handleSubmit()}>Save</button>
                    </div>
                    : ''}

            </div>
        </div>
    )
}