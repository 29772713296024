/* eslint-disable jsx-a11y/anchor-is-valid */
function Footer() {
    return (
        <footer>
            <div className="container-fluid">
                <div className="footer-txt">
                    <span className="copyright-txt">© Insurance Box. All Rights Reserved</span>
                    <ul className="footer-link">
                        <li className=""><a href="#">Terms & Conditions</a> </li>
                        <li className=""><a href="#">Privacy Policy</a> </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer