import { Tab, Nav } from 'react-bootstrap';
import { DOCUMENT_CATEGORY } from "@src/config/constant";
import UploadDocumentview from '@src/component/insurance/detail/UploadDocumentView';
import { useEffect, useState } from 'react';
import ListGridView from '@src/component/insurance/detail/DocumentViewGallery';
import { useSelector } from 'react-redux';
import { STATUS_SUB_STATUS } from '@src/config/constant';
import { fetchInstallmentDetails, updateLeadDetailsInStore } from '@src/store/slices/insurance';
import { useDispatch } from "react-redux";



export default function DocumentTypeTab(props: any) {

    let { setLoader, callFrom } = props
    let [doctype, setDoctype] = useState<number>(1)
    let [disbursalDisabled, setDisbursalDisabled] = useState<boolean>(false)
    let [postDisbursalDisabled, setPostDisbursalDisabled] = useState<boolean>(false)
    let category = null;

    const insuranceState = useSelector((state: any) => state.insurance)
    const dispatch: any = useDispatch();    

    const handleDocTypeChange = (type: number) => {
        setLoader(true)
        setDoctype(type)

        let installmentDetails = insuranceState?.leadDetail?.installment_details
        if (callFrom === "docUpload" && type === DOCUMENT_CATEGORY['postDisbursal']['doc_type_id'] && (!installmentDetails || !Object.keys(installmentDetails).length)) {
            fetchInstallmentDetails({ "lead_id": insuranceState?.leadDetail?.hashId }).then((res: any) => {
                if (res && res.data && Object.keys(res.data).length) {
                    let updatedLeadDetails = { ...insuranceState.leadDetail, installment_details: res.data }
                    dispatch(updateLeadDetailsInStore(updatedLeadDetails))
                }
            })
        }

        setLoader(false)
    }

    const firstSubmitDocType = DOCUMENT_CATEGORY['firstSubmit']['doc_type_id']
    const disbursalDocType = DOCUMENT_CATEGORY['disbursal']['doc_type_id']
    const postDisbursalDocType = DOCUMENT_CATEGORY['postDisbursal']['doc_type_id']


    let defaultActiveTab = "first";
    if (["personal-details", "residential-details", "vehicle-details"].includes(props.formType)) {
        defaultActiveTab = "first";
    } else if (["soft-approval-received", "insurance-policy-generated"].includes(props.formType)) {
        defaultActiveTab = "second";
    } else if (["inbound_payment", "outbound_payment", "payment_from_customer_to_partner_broker"].includes(props.formType)) {
        defaultActiveTab = "third";

    }

    if (props.formType) {
        let formType = props.formType;
        if (formType === "payment_from_customer_to_partner_broker") {
            category = DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_customer_partner_broker']
        } else if (formType === "inbound_payment") {
            category = DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_inbound']
        } else if (formType === "outbound_payment") {
            category = DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_outbound']
        }
    }


    useEffect(() => {
        let leadDetails = insuranceState?.leadDetail
        if (leadDetails) {
            let isLeadLost = leadDetails?.status_id === STATUS_SUB_STATUS['status']['lost']

            let isDisbursalDisabled = isLeadLost || leadDetails?.sub_status_id < STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker']
            let isPostDisbursalDisabled = isLeadLost || leadDetails?.sub_status_id < STATUS_SUB_STATUS['subStatus']['policy_shared']

            setDisbursalDisabled(isDisbursalDisabled)
            setPostDisbursalDisabled(isPostDisbursalDisabled)
        }
    }, [insuranceState.leadDetail])


    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey={defaultActiveTab}>

            <Nav variant="pills" className={callFrom === 'docUpload' ? "flex-column tab-line" : "flex-column"}>
                <div className="tab-list">
                    <Nav.Item>
                        <Nav.Link eventKey="first" onClick={() => handleDocTypeChange(firstSubmitDocType)}>First Submit</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="second" disabled={disbursalDisabled} onClick={() => handleDocTypeChange(disbursalDocType)}>Disbursal</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="third" disabled={postDisbursalDisabled} onClick={() => handleDocTypeChange(postDisbursalDocType)}>Post Disbursal</Nav.Link>
                    </Nav.Item>
                </div>

            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="first">

                    {/* <App /> */}
                    {
                        callFrom === 'docUpload'
                            ?
                            <UploadDocumentview docType={doctype} setLoader={setLoader} />
                            :
                            <ListGridView docType={firstSubmitDocType} setLoader={setLoader} />
                    }

                </Tab.Pane>
                <Tab.Pane eventKey="second">
                    {/* <ApproveUploadTagdoc /> */}
                    {
                        doctype === 2 || defaultActiveTab === 'second'
                            ?
                            <>
                                {/* <App /> */}
                                {
                                    callFrom === 'docUpload'
                                        ?
                                        <UploadDocumentview docType={doctype} setLoader={setLoader} />
                                        :
                                        <ListGridView docType={disbursalDocType} setLoader={setLoader} />
                                }
                            </>
                            : ''
                    }
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                    {/* <DisbursementUploadTagdoc /> */}
                    {
                        doctype === 3 || defaultActiveTab === 'third'
                            ?
                            <>
                                {/* <App /> */}
                                {
                                    callFrom === 'docUpload'
                                        ?
                                        <UploadDocumentview docType={doctype} setLoader={setLoader} />
                                        :
                                        <ListGridView docType={postDisbursalDocType} setLoader={setLoader} category={category} formType={props.formType} payment_from={props.payment_from}/>
                                }
                            </>
                            : ''
                    }
                </Tab.Pane>
            </Tab.Content>

        </Tab.Container >
    )
}