import Modal from "../../../common/ModalPopup";
import { useEffect, useState } from "react";
import Select from 'react-select';
import { useDispatch, useSelector} from 'react-redux';
import { toast } from "react-toastify";
import {markLeadAsLost}  from '@src/store/slices/insurance';
import { getMasterConfig } from '../../../store/slices/common';
import { updateLeadDetailsInStore } from "@src/store/slices/insurance";
import { STATUS_SUB_STATUS, LOST_REASON_IDS, ROLE_FEATURES} from "@src/config/constant";
import FullPageLoader from "@common/FullPageLoader";
import * as HELPER from "@src/config/helper";


const MarkAsLostPopup = (props: any) => {
    const [errors, setError] = useState<any>({});
    const [formData, setFormData] = useState<any>({status : 1});
	const [loading, setLoading] = useState(false);
    const dispatch: any = useDispatch();
    const commonState = useSelector((state: any) => state.common);
    const insuranceState = useSelector((state: any) => state.insurance);
    let prevStatusId =  (insuranceState.leadDetail && insuranceState.leadDetail.status_id) ? +insuranceState.leadDetail.status_id : 0;
    let prevSubStatusId = (insuranceState.leadDetail && insuranceState.leadDetail.sub_status_id) ? +insuranceState.leadDetail.sub_status_id : 0;

    useEffect(() => {
        if (Object.keys(commonState.common).length === 0) {
            dispatch(getMasterConfig());
        }
    }, [commonState.common, dispatch]);

    let subStatusList = commonState.common && commonState.common.sub_status_list ? commonState.common.sub_status_list : [];
    subStatusList = subStatusList.filter((status : any) => status.status_id === 6);

    // let lostReason = commonState.common && commonState.common.lost_reason_list ? commonState.common.lost_reason_list : [];
     let sub_status_list : any = [];
  
    if(props.statusId < 4){    // Filter lost reason on current state basis
        if(props.statusId < 4){
            sub_status_list = subStatusList.filter((l : any) => LOST_REASON_IDS.includes(l.value));
        } 

        if(+props.statusId === 2){
            sub_status_list = [...sub_status_list, ...subStatusList.filter((l : any) => l.value === 19)];          //Add Soft Approval Rejected reason in Send to Insurance Broker state.
            
        } else if(+props.statusId >= 3 ) {
            sub_status_list = [...sub_status_list, ...subStatusList.filter((l : any) => l.value === 20)];          //Add Insurance Policy Cancelled reason After Insurance Policy is Generated.
        }  

    }
    

    const handleChange = async (key:string, event:any) => {
		if(key === 'sub_status_id'){
            formData[key] = event.value;
        } else if (key === 'remarks') {
            formData[key] = event.target.value;
        }
		errors[key] = '';
		setError({ ...errors });
        setFormData({ ...formData });

	}

	const isFormValid = (formData : any) => {
		let isValid = true;
		if (!formData.sub_status_id || formData.sub_status_id === '') {
			errors['sub_status_id'] = 'This Field is Required';
			isValid = false;
		}
		if(formData && formData.remarks && (formData.remarks.split('').length < 3 || formData.remarks.split('').length > 500)){
			errors['remarks'] = 'Remarks should be between 3 to 500';
			isValid = false;
		}
		setError({ ...errors });
		return isValid;

	}
  
    const getFormData = async () => {
        let postData:any = {};
        if (formData && formData.sub_status_id) postData['sub_status_id'] = formData.sub_status_id;
        if (formData && formData.remarks) postData['remarks'] = formData.remarks;
        // if (formData && formData.reason) postData['reason'] = formData.reason;
        postData['status_id'] = STATUS_SUB_STATUS['status']['lost'];
        postData['lead_id'] = props.leadHashId;
      

        return postData;
    }


	const markLeadLost = async (e:any) => {
		e.preventDefault();
        let postData = await getFormData(); 
		if (isFormValid(postData)) {
			setLoading(true);
            dispatch(markLeadAsLost(postData)).then((res: any) => {
                if (res && res.status === 200) {
                    setFormData({});
                    props.showModalMarkAsLost(false);
                    if (res.data && Object.keys(res.data).length) {
                     if(postData?.remarks && postData?.remarks.trim() !== ""){
                        dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...res.data, prev_status_id : prevStatusId, prev_sub_status_id : prevSubStatusId ,"leadRemarkCount": (insuranceState.leadDetail.leadRemarkCount+1) }));
                     }
                     else{
                        dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...res.data, prev_status_id : prevStatusId, prev_sub_status_id : prevSubStatusId }));
                     }                   
                    }
                    toast.success(res.message);
                }
                setLoading(false);
            });
		}
	}

    return (

        <div className="view-timeline-popup image-timeline-popup">
            <FullPageLoader show={loading} />
            <Modal show={props.markLostPopup} handleClose={() => props.showModalMarkAsLost(false)} >
                <div className="modal-header">
                    <h2>Mark as Lost</h2>
                </div>
                <div className="modal-body" >
                    <div className="row">
                        <div className="col-md-12 m-lg-b">
                            <fieldset className="single-select">
                                <div className="material">
                                    <label >Sub Status <span className="required">*</span></label>
                                    <Select
                                        id="sub_status_id"
                                        className='react-select'
                                        onChange={(e: any) => handleChange("sub_status_id", e)}
                                        options={sub_status_list}
                                        name="sub_status_id"
                                        placeholder={"Sub Status"}
                                        value = {sub_status_list.filter((e : any) => e.value === formData.sub_status_id)}
                                    />
                                    <span className="error-txt">
                                        {errors.sub_status_id || ""}
                                    </span>
                                </div>
                            </fieldset>
                        </div>
                        {/* <div className="col-md-12 m-xl-t">
                            <fieldset className="single-select">
                                <div className="material">
                                    <label htmlFor="subStatus"> Sub Status Reason </label>
                                    <Select
                                        id="reason"
                                        onChange={(e: any) => handleChange("reason", e)}
                                        options={reason_list}
                                        name="reason"
                                        value={reason_list.filter((e : any) => e.id === formData.reason)}
                                        placeholder={"Sub Status Reason"}
                                        classNamePrefix={'sub-status-reason'}
                                    />
                                    
                                </div>
                            </fieldset>
                        </div> */}
                        <div className="col-md-12">
                            <fieldset className="form-filed">
                                <div className="material">
                                    <label htmlFor="remarks">{"Remarks"}</label>
                                    <textarea id="remarks" placeholder=" " name="remarks" className="form-input" value={formData.remarks} onChange={(e: any) => handleChange("remarks", e)}/>
                                    <span className="error-txt">
                                        {errors.remarks || ""}
                                    </span>
                                </div>
                            </fieldset>
                        </div>

                        <div className="col-md-12">
                            {
                                HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['lost'], accessType: 'edit', assignUserId: insuranceState?.leadDetail?.assign_user_id }) && +insuranceState?.leadDetail?.sub_status_id !== 3  &&
                                
                                <button className="btn-primary m-md-t" onClick={(e:any) => markLeadLost(e)}>Save</button>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </div>

    )

}
export default MarkAsLostPopup;