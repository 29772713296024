import { useEffect, useState } from 'react';
import Pagination from '@src/common/Pagination';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import ConfirmPopUp from '@src/common/ConfirmPopUp';
import { getAgencyList, updateAgencyStatus } from '@src/store/slices/agency';
import FullPageLoader from "@src/common/FullPageLoader";
import ToggleSwitch from '@src/common/ToggleSwitch';
import { Link } from 'react-router-dom';
import { Tooltip as TooltipLite } from 'react-tooltip';
import * as HELPER from '@src/config/helper';
import { ROLE_FEATURES } from '@src/config/constant';


function AgencyListTable(props: any) {
    const dispatch: any = useDispatch();
    let [agencyList, setAgencyList] = useState<[]>([]);
    const [paginationData, setPaginationData] = useState<any>({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [filterParam, setFilterParam] = useState({ page_number: 1 })
    const [partnerNameToolTip, setPartnerNameToolTip] = useState(0);


    const [currentToggle, setCurrentToggle] = useState<any>(null);

    useEffect(() => {
        let agencyStatus: number = 1;
        if (props?.activeTab === "second") { agencyStatus = 0; }
        if (props?.activeTab === "third") { agencyStatus = 2; }
        if (props?.activeTab === "forth") { agencyStatus = 3; }

        fetchAgency({ ...filterParam, status: agencyStatus, searchKeyword: props?.searchKeyword || undefined })

        // eslint-disable-next-line
    }, [props?.activeTab, props?.searchKeyword])

    const confirmNo = () => {
        setShowConfirmation(false);
        setCurrentToggle({});
    };


    const handleRequestData = (params: any) => {

        let agencyStatus: number = 1;
        if (props?.activeTab === "second") { agencyStatus = 0; }
        if (props?.activeTab === "third") { agencyStatus = 2; }
        if (props?.activeTab === "forth") { agencyStatus = 3; }

        let apiParams = {
            page_number: params.page_no,
            searchKeyword: props?.searchKeyword || undefined,
            status: agencyStatus
        }
        fetchAgency(apiParams);
    }

    const fetchAgency = (params: any) => {
        setLoading(true)
        getAgencyList(params).then((res: any) => {
            setLoading(false)

            if (res.status === 200) {
                setAgencyList(res.data)
                setPaginationData(res.pagination)
                props.updateTabCount(res.tabCount)
            }
        }).catch(err => setLoading(false))
    }

    const handleToggleSwitch = (element: any) => {
        setCurrentToggle(element);
        setShowConfirmation(true);
    };

    const confirmYes = async () => {
        if (currentToggle) {
            const updatedData = { id: currentToggle.id, status: currentToggle.status ? 0 : 1 };
            setLoading(true);

            dispatch(updateAgencyStatus(updatedData)).then((res: any) => {
                if (res && res.status === 200) {
                    toast.success(res.message);
                    fetchAgency({ ...filterParam, status: (props.activeTab === "first" ? 1 : 0) });

                }
                setLoading(false);
            }).catch((err: Error) => {
                setLoading(false);
            });
        }
        setShowConfirmation(false);

    };

    return (
        <>
            <FullPageLoader show={loading} />

            <div className="lead-list-data-table agency-Partner-list">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Insurance Agency Name</th>
                            <th>Agency/Agent</th>
                            <th>Email</th>
                            <th>Mobile No.</th>
                            <th>Insurance Name</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(agencyList && agencyList.length > 0) ? agencyList.map((row: any, index: number) => {

                            return (
                                <tr key={index}>
                                    <td title={row?.id}>
                                        <Link
                                            to={`/agency/edit/${row.hashId}`}
                                        >
                                            {row?.id}
                                        </Link>
                                    </td>
                                    <td title={row?.name}>{row?.name}</td>
                                    <td title={+row.type === 1 ? "Agency" : "Agent"}>{+row.type === 1 ? "Agency" : "Agent"}</td>
                                    <td title={row?.email}>{row?.email}</td>
                                    <td title={row?.mobile}>{row?.mobile}</td>
                                    <td>
                                        {row?.partner_map?.slice(0, 2).map((partner: any) => partner?.partner_name)?.join(",")}
                                        <span className='more-txt tooltio-ic' data-tooltip-id="my-tooltip-2" onClick={() => setPartnerNameToolTip(row.id)} onMouseLeave={() => { }}> {((row?.partner_map?.length > 2) ? ` +${row?.partner_map?.length - 2} more` : '')} </span>
                                        {
                                            partnerNameToolTip === row.id &&
                                            <div className='tooltip-outer'>


                                                <TooltipLite
                                                    isOpen={partnerNameToolTip === row.id}
                                                    className=" react-tooltip-lite"

                                                    id="my-tooltip-2"
                                                    place="bottom"
                                                    clickable={true}
                                                    render={() =>
                                                        <div className="controlled-example tooltip-main">
                                                            <div className="tooltip-data">

                                                                <span className="controlled-example_close-button" onClick={() => { setPartnerNameToolTip(0); }}><i className="ic-clearclose"></i></span>
                                                                <ul className="user-detail">
                                                                    {
                                                                        row?.partner_map?.map((partner: any) =>
                                                                            <li>
                                                                                <label>{partner.partner_name}</label>
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }

                                                />
                                            </div>
                                        }


                                    </td>
                                    <td>
                                        <div className="on-off-switch">
                                            {([0, 1].includes(row.status))
                                                ?
                                                (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_agency_partner_management'], accessType: 'edit' })) ?
                                                <ToggleSwitch checked={row.status} onChange={() => handleToggleSwitch(row)} />
                                                
                                                :<ToggleSwitch checked={row.status} />
                                                :
                                                <p className=''>{row.status === 2 ? "In Progress" : "Rejected"}</p>

                                            }

                                        </div>
                                    </td>
                                    <td>
                                        <ul className="action-btn">
                                            <li >
                                                <Link
                                                    to={`/agency/edit/${row.hashId}`}
                                                >
                                                    <i className='ic-remove_red_eyevisibility'></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </td>

                                </tr>)

                        })
                            :
                            <tr><td colSpan={8} style={{ textAlign: 'center' }}>No data found</td></tr>
                        }
                    </tbody>
                </table>
                {(paginationData && paginationData['total'] > 10) && <Pagination paginateData={{
                    page: 1,
                    loading: false,
                    tableData: paginationData['limit'],
                    totalLengthWithoutPagination: paginationData['total'],
                    paginationData,
                    filteredData: {}
                }}
                    sendRequestDataToParent={handleRequestData} />}

                {showConfirmation === false ? ("") : (
                    <ConfirmPopUp
                        confirmNo={confirmNo}
                        confirmYes={confirmYes}
                        message={`Are you sure you want to mark ${currentToggle.name} ${currentToggle.status === 0 ? "as active": "as inactive"} ?`} />
                )}

            </div>

        </>

    )
}

export default AgencyListTable

