import React, { useEffect, useState } from 'react';
import Modal from "@src/common/ModalPopup";
import AonRate from "@src/component/rule-engine/update-rule/AonRate";
import VatRate from "@src/component/rule-engine/update-rule/VatRate";
import BaseIssueRate from "@src/component/rule-engine/update-rule/BaseIssueRate";
import ImportModal from "@src/component/rule-engine/import/ImportModal";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { fetchMasterRates, getMasterConfig } from '@src/store/slices/common';
import FullPageLoader from '@src/common/FullPageLoader';
import { getPartnerMappingList } from '@src/store/slices/partner';
import secureStorage from 'config/encrypt';
import * as HELPER from "@src/config/helper";
import { NavLink } from "react-router-dom";
import { getSampleFile } from '@src/store/slices/insurance';
import TraRate from "@src/component/rule-engine/update-rule/TraRate";
import { ROLE_FEATURES } from '@src/config/constant';


const RuleEngine = () => {
    let [applicationTypes, setApplicationTypes] = useState<Array<{ value: number, label: string }>>()
    let [insuranceType, setInsuranceType] = useState<Array<{ value: number, label: string }>>()
    let [partnerList, setPartnerList] = useState<Array<{ value: number, label: string }>>()
    let [ruleEngine, setRuleEngine] = useState<Array<object>>()
    let [agencyPartnerList, setAgencyPartnerList] = useState<Array<{ value: number, label: string }>>()

    let authUserInfo = secureStorage.getItem('authUserInfo');
    let userId = authUserInfo && authUserInfo.user_data && authUserInfo.user_data.id

    let [loading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate();
    const dispatch: any = useDispatch()
    const commonState = useSelector((state: any) => state.common)
    const insuranceState = useSelector((state: any) => state.insurance)

    const modalSwitch = (param: string, cases: { [key: string]: any }) => {
        if (cases[param]) {
            return cases[param]
        } else {
            return cases.default
        }
    }

    const [updateFormType, setUpdateFormType] = useState("");
    const [showFormName, setShowFormName] = useState("");
    const [updateModal, setUpdateModal] = useState(false);


    const showUpdateModal = (updateFormType: string, action: boolean, formName: string) => {
        setUpdateModal(action);
        setUpdateFormType(updateFormType);
        setShowFormName(formName)
    }

    const handlerLoader = (loader: boolean) => {
        setLoading(loader)
    }

    const handleModalCloseFromChild = () => {
        showUpdateModal("", false, '')
    }

    useEffect(() => {
        if (commonState && commonState.common && Object.keys(commonState.common).length) {
            let data = commonState.common
            let { apptype, insurance_type, rule_engine } = data
            setApplicationTypes(apptype)
            setInsuranceType(insurance_type)
            setRuleEngine(rule_engine)
        }
        else {
            //api call to fetch static dropdowns
            dispatch(getMasterConfig()).then((res: any) => {
                if (res && res.status === 200) {
                    let data: any = res.data || {}
                    let { apptype, insurance_type, rule_engine } = data

                    setApplicationTypes(apptype)
                    setInsuranceType(insurance_type)
                    setRuleEngine(rule_engine)

                }
            }).catch((err: Error) => {
            })
        }
    // eslint-disable-next-line    
    }, [dispatch, commonState.common])

    useEffect(() => {
        setLoading(true)

        let filters = {
            page_number: 1,
            pagination: false,
            namesOnly: true
        }
        //api call to fetch partner list
        dispatch(getPartnerMappingList(filters)).then((res: any) => {
            if (res && res.status === 200) {
                const partnerMappingList = (res && res?.data) || []
                let updatedPartnerList = []
                let updatedAgencyPartnerList = [];


                if (partnerMappingList) {
                    for (let element of partnerMappingList) {
                        //only show active partners
                        if (element.status === 1) {
                            let updatedDropdown = {
                                value: element.partner_id,
                                label: element.name,
                                insurance_type_id: element.insurance_type_id,
                                slug: element.slug,
                                application_type_id:element.application_type_id.split(",").map(Number)
                            };
                    
                            updatedPartnerList.push(updatedDropdown);
                    
                            // Check for application_type_id containing agency value
                            if (element.application_type_id.split(',').includes("4")) {
                                updatedAgencyPartnerList.push(updatedDropdown);
                            }
                        }

                    }
                }

                setPartnerList(updatedPartnerList)
                setAgencyPartnerList(updatedAgencyPartnerList);
            }
            setLoading(false)

        }).catch((err: Error) => {
            setLoading(false)

        })

    }, [dispatch])

    useEffect(() => {
        //api call to fetch rates 
        dispatch(fetchMasterRates({}))
            .then((res: any) => { })
            .catch((err: Error) => {
            })
    }, [dispatch])


    useEffect(()=>{
        if(!insuranceState?.sampleCsv?.length) {
            dispatch(getSampleFile()).then((res: any) => {
                if (res && res.status === 200) {
                  
                }
            }).catch((err: Error) => {
            })
        }
    // eslint-disable-next-line    
    },[]);


    return (
        <React.Fragment>

            <FullPageLoader show={loading} />

            <div className="container-fluid">
                <div className="top-heading">
                    <h1>Rule Engine</h1>
                </div>
                <div className="rule-engine-menu-sec">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="menu-list">
                                {
                                    HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['sent_to_insurance_broker'], accessType: 'isVisible' }) || HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_policy'], accessType: 'isVisible' }) || HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['task_role_assignment'], accessType: 'isVisible' }) || HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_partner_management'], accessType: 'isVisible' }) || HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_agency_partner_management'], accessType: 'isVisible' }) ?
                                        <div>
                                            <div className="menu-icn">
                                                <i className="ic-configuration"></i>
                                            </div>
                                            <h2>Configuration</h2>
                                        </div>
                                        : ''
                                }
                                <ul>
                                    {
                                        (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['sent_to_insurance_broker'], accessType: 'isVisible' }) || HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_policy'], accessType: 'isVisible' })) ?
                                            <li>
                                                {/* <a href="#!" onClick={() => showCkEditorModal('ckEdit', true, "Email/Whats app/Viber templates - Sent to insurance broker", '1')}>Email/Whats app/Viber templates - Sent to insurance broker</a> */}
                                                <NavLink to="/email-template-config"> Email/Whats app templates Configuration</NavLink>
                                            </li>
                                            : ''}


                                    {
                                        HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['task_role_assignment'], accessType: 'isVisible' }) ?
                                            <li>
                                                <a href="#!" onClick={(() => navigate('/user-list'))}>User/Role Management</a>
                                            </li>
                                            : ''}
                                    {
                                        HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_partner_management'], accessType: 'isVisible' }) ?
                                            <li>
                                                <a href="#!" onClick={(() => navigate('/partners'))}>Insurance Partner Management</a>
                                            </li>
                                            : ''}

                                       {
                                        HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_bulk_payment'], accessType: 'isVisible' }) ?
                                            <li>
                                                <a href="#!" onClick={(() => navigate('/payment-list'))}>Bulk Insurance Payment</a>
                                            </li>
                                            : ''}  
                                    {
                                        HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_agency_partner_management'], accessType: 'isVisible' }) ?
                                            <li>
                                                <a href="#!" onClick={(() => navigate('/agency-list'))}>Insurance Agency Management</a>
                                            </li>
                                            : ''}        
                                </ul>

                            </div>
                        </div>
                        {
                            HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['import_module'], accessType: 'isVisible' }) ?
                                <div className="col-md-3">
                                    <div className="menu-list">
                                        <div className="menu-icn">
                                            <i className="ic-import"></i>
                                        </div>
                                        <h2>Import</h2>
                                        <ul>
                                            {/* MRP IMPORT IS NO LONGER NEEDED, AS WE ARE FETCHING
                                                ALL MMV FROM INVENTORY SERIVCE
                                            */}
                                            {/* <li>
                                                <a href="#!" onClick={() => showUpdateModal('mrp', true, "MRP Table")}>MRP Table </a>
                                            </li> */}


                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('netRate', true, "Net Rate")}>Net Rate </a>
                                            </li>

                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('bi', true, "BI Premium")}>BI Premium </a>
                                            </li>

                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('pd', true, "PD Premium")}>PD Premium </a>
                                            </li>

                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('pa', true, "PA Premium")}>PA Premium </a>
                                            </li>

                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('BaseIssueRate', true, "Base Issue Rate")}>Base Issue Rate </a>
                                            </li>


                                        </ul>

                                    </div>
                                </div>
                                : ''
                        }


                        {
                            HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_module'], accessType: 'isVisible' }) ?
                                <div className="col-md-4">
                                    <div className="menu-list">
                                        <div className="menu-icn">
                                            <i className="ic-reassign"></i>
                                        </div>
                                        <h2>Update</h2>
                                        <ul>
                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('aonRate', true, "Update AON Rate")}>AON Rate</a>
                                            </li>

                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('vatRate', true, "Update VAT Rate")}>VAT Rate</a>
                                            </li>
                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('stampDuty', true, "Update Stamp Duty Tax")}>Stamp Duty Tax</a>
                                            </li>
                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('govtTax', true, "Update Local Govt Tax")}>Local Govt Tax</a>
                                            </li>
                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('baseIssueRate', true, "Update Base Issue Rate")}>Base Issue Rate</a>
                                            </li>
                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('paRate', true, "PA Rate")}>PA Rate</a>
                                            </li>
                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('rsccRate', true, "RSCC Rate")}>RSCC Rate</a>
                                            </li>
                                            <li>
                                                <a href="#!" onClick={() => showUpdateModal('traRate', true, "TRA Rate")}>TRA Rate</a>
                                            </li>


                                        </ul>

                                    </div>
                                </div>
                                : ''
                        }


                    </div>
                </div>

                <div className={`calculator-popup davition-popup ${["aonRate"].includes(updateFormType) ? "rule-engine-popup-height-medium" : ''} ${["vatRate", "stampDuty", "govtTax", "baseIssueRate"].includes(updateFormType) ? "rule-engine-popup-height-large" : ''}`}>
                    <Modal show={updateModal} handleClose={() => showUpdateModal("", false, '')} >
                        <div className="modal-header">
                            <h2>{showFormName}</h2>
                        </div>
                        <div className="modal-body" >

                            {

                                modalSwitch(updateFormType, {
                                    "aonRate": <AonRate callfor={'aon'} appTypeList={applicationTypes} handleLoader={handlerLoader} handleModalClose={handleModalCloseFromChild} userId={userId} />,
                                    "vatRate": <VatRate callfor={'vat'} insuranceList={insuranceType} partnerList={partnerList} handleLoader={handlerLoader} handleModalClose={handleModalCloseFromChild} userId={userId} />,
                                    "stampDuty": <VatRate callfor={'stamp_duty'} insuranceList={insuranceType} partnerList={partnerList} handleLoader={handlerLoader} handleModalClose={handleModalCloseFromChild} userId={userId} />,
                                    "govtTax": <VatRate callfor={'local_govt_tax'} insuranceList={insuranceType} partnerList={partnerList} ruleEngineList={ruleEngine} handleLoader={handlerLoader} handleModalClose={handleModalCloseFromChild} userId={userId} />,
                                    "baseIssueRate": <BaseIssueRate appTypeList={applicationTypes} handleLoader={handlerLoader} handleModalClose={handleModalCloseFromChild} userId={userId} partnerList={agencyPartnerList} />,
                                    "paRate": <VatRate callfor={'pa'} insuranceList={insuranceType} partnerList={partnerList} handleLoader={handlerLoader} handleModalClose={handleModalCloseFromChild} userId={userId} />,
                                    "rsccRate": <VatRate callfor={'rscc'} insuranceList={insuranceType} partnerList={partnerList} handleLoader={handlerLoader} handleModalClose={handleModalCloseFromChild} userId={userId} />,
                                    "traRate": <TraRate callfor={'tra'} appTypeList={applicationTypes} partnerList={partnerList} handleLoader={handlerLoader} handleModalClose={handleModalCloseFromChild} userId={userId} />,

                                    //mrp import is no more available, since we are fetching all mmv from inventory db
                                    // "mrp": <ImportModal setUpdateModal={setUpdateModal} type="mmv" title={"MRP"} />,
                                    "netRate": <ImportModal setUpdateModal={setUpdateModal} type="net_rate" title={"Net Rate"} />,
                                    "bi": <ImportModal setUpdateModal={setUpdateModal} type="bi" title={"BI"} />,
                                    "pd": <ImportModal setUpdateModal={setUpdateModal} type="pd" title={"PD"} />,
                                    "pa": <ImportModal setUpdateModal={setUpdateModal} type="pa" title={"PA"} />,
                                    "BaseIssueRate": <ImportModal setUpdateModal={setUpdateModal} type="Base Issue Rate" title={"Base Issue Rate"} />,
                                    // "ckEdit": <CkEditorModal data={editorData} onChange={handleEditorChange} templateID={templateID} setUpdateModal={setUpdateModal} />,
                                    default: ""
                                })

                            }
                        </div>
                    </Modal>
                </div>


            </div>
        </React.Fragment >
    )

}

export default RuleEngine;