import { DynamicFormField } from "./DynamicFormField";
import { useSectionList } from "@src/hooks/useSectionList";
import { saveInsuranceAgency } from "@src/store/slices/insurance";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateInsuranceFormAgency } from "../create/FunctionList";

export const VehicleDetails = forwardRef((props: any, ref) => {
    const { fetchSectionItem, setSectionItem, fetchItem } = useSectionList({})
    // const vehicleSectionItem = fetchSectionItem('vehicle_details')
    const [vehicleSectionItem, setVehicleSectionItem] = useState<any>([])
    const vehicleDetailsItem = fetchSectionItem('vehicle_details', 'vehicle_info')

    const insuranceState = useSelector((state: any) => state.insurance)
    const commonState = useSelector((state: any) => state.common)
    const autoFillStatus = insuranceState?.insuranceData && Object.keys(insuranceState?.insuranceData)?.length

    const dispatch: any = useDispatch()

    const setFieldItem = (key: string, value: any) => {
        const field = setSectionItem('vehicle_details', 'vehicle_info', key, value)
        return field
    }

    const getFieldItem = (key: string) => {
        const field = fetchItem(key, vehicleDetailsItem.fields)
        return field
    }

    useImperativeHandle(ref, () => ({
        getData: () => vehicleDetailsItem.fields || []
    }))

    const onVersionSelect = (data: any) => {
        const fields = [
            { key: 'seating_capacity', field: getFieldItem('seat_capacity') },
            { key: 'transmission', field: getFieldItem('transmission') },
            { key: 'fuel_type', field: getFieldItem('fuel_type') }
        ]

        fields.forEach(({ key, field }) => {
            if (data[key]) {
                field['value'] = (!autoFillStatus || key === 'transmission') ? data[key]?.toString() : field['value']
                field['is_enable'] = (!autoFillStatus || key === 'transmission') ? false : true
                field['error_message'] = ''
            } else {
                field['value'] = (!autoFillStatus || key === 'transmission') ? '' : field['value']
                field['is_enable'] = true
            }

            if (key === 'seating_capacity') {
                if (data[key]) {
                    dispatch(saveInsuranceAgency({ ...insuranceState?.createInsuranceAgency, seating_capacity_autofill: true }))
                }
                else {
                    dispatch(saveInsuranceAgency({ ...insuranceState?.createInsuranceAgency, seating_capacity_autofill: false }))
                }
            }
        })
    }

    const autoFillData = async (data: any, clear?: boolean) => {
        const updatedSectionList = await updateInsuranceFormAgency({ ...data }, vehicleDetailsItem?.fields, clear, { make: commonState?.mmvList?.make, ...commonState?.common, ...insuranceState?.createInsuranceAgency })
        let newSection = vehicleSectionItem?.blocks?.[0]?.fields
        if (newSection) {
            newSection = updatedSectionList
            setVehicleSectionItem(vehicleSectionItem)
        }
    }

    useEffect(() => {
        let vehicleDetails = fetchSectionItem('vehicle_details')
        setVehicleSectionItem(vehicleDetails)
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let { insuranceData } = insuranceState
        if (insuranceData && Object.keys(insuranceData)?.length && !insuranceData?.autoFillVehicleDetails) {
            autoFillData(insuranceData)
        } else if (!(insuranceData && Object.keys(insuranceData)?.length)) {
            autoFillData({}, true)
        }
    // eslint-disable-next-line
    }, [insuranceState.insuranceData])

    return (
        <div className="loan-filed-sec">
            <div className="form-group-bx">
                <div className="row">
                    {
                        vehicleSectionItem?.blocks?.map((blockItem: any, index: number) =>
                            <React.Fragment key={blockItem.title + index}>
                                <div className="col-md-12">
                                    <h3>{blockItem.title}</h3>
                                </div>
                                <DynamicFormField
                                    fields={blockItem?.fields}
                                    setFieldItem={setFieldItem}
                                    getFieldItem={getFieldItem}
                                    onVersionSelect={onVersionSelect}
                                    autoFillStatus={autoFillStatus}
                                    setLoading={props.setLoading}
                                />
                            </React.Fragment>
                        )
                    }

                </div>
            </div>
        </div>
    )
})