import { toast } from "react-toastify";
import { createSlice } from '@reduxjs/toolkit';
import EncryptionService from '../../services/EncryptionService';
import { APIResponse } from '../types/Common';
import secureStorage from '../../config/encrypt';

const encryption = createSlice({
    name: 'encryption',
    initialState: {
        publicKeyPem: ''
    },
    reducers: {
        config: (state, action) => {
            state.publicKeyPem = action.payload;
        },
        
    },
});

export default encryption.reducer;

// const { config } = encryption.actions


export const getPublicKey = () => {
    return new Promise((resolve, reject) => {

        EncryptionService.fetchPublicKey().then((response: APIResponse) => {
            if ((response && response.data && response.data.status) === 200) {
                // let data = {key: response?.data?.data, expiry: new Date(new Date().getTime() + 10000).getTime()}
                let data = response.data && response.data.data
               
                secureStorage.setItem('encryptionPublicKey', (data));
                resolve(response.data)
            }
            else {
                let errorMsg = (response && response.data && response.data.message) || ''
                if (errorMsg) toast.error(response.data.message);
                resolve(response && response.data)
            }
        },
            (error: Error) => {
                reject(error);
            })
    })
}