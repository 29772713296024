import MasterService from "./MasterService";

const InsuranceService = {
    ...MasterService,

    getInsuranceListing(params: object) {
        return this.post('/lead/adminLeadList', params)
    },

    getLeadHistory(params: object) {
        return this.post('/lead/getLeadHistory', params)
    },

    getLeadRemarks(params: object) {
        return this.post('/lead/getLeadRemarks', params)
    },

    addLeadRemark(params: object) {
        return this.post('/lead/addLeadRemark', params)
    },

    updateLeadAssignUser(params: object) {
        return this.post('/lead/updateLeadAssignUser', params)
    },

    updateTemplate(params: object) {
        return this.post('/ruleEngine/updateTemplate', params)
    },
    templateInfo(params?: object) {
        return this.post('/ruleEngine/templateInfo', params)
    },

    getInsuranceDetails(params: object) {
        return this.post('/lead/adminLeadDetails', params)
    },

    getFormConfig(applicationType: number) {
        return this.get('/common/formconfig?rule_engine_ids=' + applicationType)
    },

    getMasterConfig() {
        return this.get('/common/master/config')
    },

    getAddonList(params: object) {
        return this.post('/addOn/getAddOn', params)
    },

    getCarFMV(params: object) {
        return this.post('/mmv/getCarPrice', params)
    },
    getPartnerList(params: object) {
        return this.post('/partners/getInsurancePartners', params)
    },
    getAddonDetails(params: object) {
        return this.post('/addon/getAddonDetails', params)
    },
    getDocumentList(params: object) {
        return this.post('/common/document_list', params)
    },
    savePaymentDetails(params: object) {
        return this.post('/lead/savePaymentDetail', params)
    },
    updateInsuranceDetails(params: object) {
        return this.post('/lead/adminUpdateDetails', params)
    },

    sharePolicyDetails(params: object) {
        return this.post('/leadDetails/sharePolicyDetails', params)
    },

    saveInsurancePolicy(params: object) {
        return this.post('/lead/saveInsurancePolicy', params)
    },

    getPaymentDetails(params: object) {
        return this.post('/lead/getPaymentDetails', params)
    },

    getFinancierTemplateConfig(params: object) {
        return this.post('/ruleEngine/getFinancierTemplateConfig', params)
    },

    templateFieldsDetails() {
        return this.post('/ruleEngine/templateFieldsDetails')
    },

    templateFieldsInfo(params: any) {
        return this.post('/ruleEngine/templateFieldsinfo', params)
    },
    getLeadEmailTemplates(params: any){
        return this.post('/ruleEngine/getLeadEmailTemplates', params)
    },    
    getDocumentHistory(params: object) {
        return this.post('/lead/getLeadDocHistory', params)
    },

    requestDocResubmission(params: object) {
        return this.post('/lead/requestDocResubmission', params)
    },

    markDocApprove(params: object) {
        return this.post('/lead/markDocApproved', params)
    },
    getlocalityByCity(params: object) {
        return this.post('/common/getlocality', params);
    },
    createLead(params: object) {
        return this.post('/lead/createLead', params);
    },
    getCustomerDetails(params: object) {
        return this.post('/customer/customerDetail', params);
    },  
    markAsLost(params: object) {
        return this.post('/lead/saveLeadMarkedLost',params);
    },
    
    reopenLost(params: object) {
        return this.post('/lead/reOpenMarkLostLead',params);
    },
    
    getTemplateFieldinfo(params: any){
        return this.post('/ruleEngine/templateFieldsinfo', params)
    },

    shareLoanQuoteDetails(params: any){
        return this.post('/lead/shareLoanQuoteDetails', params)
    },

    downloadLoanQuoteDetails(params: any){
        return this.post('/lead/generatePdf', params)
    },
    updateTemplateNote(params: any){
        return this.post('/ruleEngine/updateTemplateNote', params)
    },    
    downloadTemplatePdf(params: any){
        return this.post('/ruleEngine/downloadTemplatePdf', params)
    },
    fetchInstallmentDetails(params: any){
        return this.post('/lead/fetchInstallmentDetails', params)
    },
    fetchLeadProcessingDetails(params: any){
        return this.post('/lead/fetchLeadProcessingDetails', params)
    },
    fetchAllDocuments(params: any){
        return this.post('/lead/fetchAllDocuments', params)
    },
    fetchQuoteDetails(params: any){
        return this.post('/lead/fetchQuoteDetails', params)
    },
    getLoanApplications(params: any){
        return this.post('/lead/getLoanApplications', params)
    },
    getLoanApplicationById(params: any){
        return this.post('/lead/getLoanApplicationById', params)
    },
    getSampleFile(){
        return this.post('/ruleEngine/getSampleFile')
    },
    exportInsuranceData(params: any){
        return this.post('/lead/export', params)
    },
    getRenewalApplications(params: any){
        return this.post('/lead/getRenewalApplications', params)
    },
    getRenewalApplicationById(params: any){
        return this.post('/lead/getRenewalApplicationById', params)
    },
    getAgencyUsers(){
        return this.post('/lead/getAgencyUsers', {})
    },
   
}

export default InsuranceService;