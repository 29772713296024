import { toast } from "react-toastify";
import { createSlice } from '@reduxjs/toolkit';
import PartnerService from "../../services/PartnerService";
import { APIResponse } from '../../store/types/Common';


const financier = createSlice({
    name: 'partners',
    initialState: {
        partners: {}
    },
    reducers: {
        partnerListing: (state, action) => {
            state.partners = action.payload
        }
    }
})


export default financier.reducer;

const { partnerListing } = financier.actions


/**
 * Function to make API call to fetch partner mapping listing
 * @param params 
 * @returns 
 */
export const getPartnerMappingList = (params: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        PartnerService.getInsurancePartnerMappingListing(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(partnerListing(response.data));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


/**
 * Function to make API call to update Insurance Partner Mappings
 * @param params 
 * @returns 
 */
export const editInsurancePartner = (params: any) => {
    return new Promise((resolve, reject) => {
        PartnerService.editInsurancePartnerMapping(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


export const addInsurancePartner = (params: object) => {
    return new Promise((resolve, reject) => {
        PartnerService.addInsurancePartnerMapping(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}



export const updatePartnerMappingStatus = (params: object) => {
    return new Promise((resolve, reject) => {
        PartnerService.updatePartnerMappingStatus(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const togglePartnerKey = (params: object) => {
    return new Promise((resolve, reject) => {
        PartnerService.togglePartnerKey(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}



/**
 * Function to make API call to get Insurance Partner By id
 * @param params 
 * @returns 
 */
export const fetchInsurancePartnerById = (params: object) => {
    return new Promise((resolve, reject) => {
        PartnerService.getPartnerDetailsById(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


