import MasterService from "./MasterService";

const CommonService = {
    ...MasterService,

    getDashboardCount() {
        return this.post('/lead/dashboardCount');
    },
    updateMasterRates(params: object) {
        return this.post('/common/updateMasterRates', params)
    },
    fetchMasterRates(params: object) {
        return this.post('/common/fetchMasterRates', params)
    },
    importCsv(params: object) {
        return this.post('/mmv/uploadCsv', params)
    },
    uploadAddOnCsv(params: object) {
        return this.post('/cron/uploadAddOnCsv', params)
    },
    getDocumentList(params: object) {
        return this.post('/common/document_list', params)
    },
    getStateCityList() {
        return this.get('/common/cityStateList');
    },
    getlocalityList(params: object) {
        return this.post('/common/getlocality',params);
    },
    addDocument(params: object) {
        return this.post('/lead/addDocumentWeb',params);
    },
    getStateWiseCity() {
        return this.get('/common/getStateWiseCity')
    }, 
    getMMVList(params: object) {
        return this.post('/mmv/getMMV', params)
    },
    fetchBaseIssueRate(params: object) {
        return this.post('/common/getBaseRate', params)
    },
    getDealersList() {
        return this.get('/common/dealersList');
    },
    getSfaUserByRole(params: object) {
        return this.post('/common/getSfaUsersByRole', params)
    },
    bankList() {
        return this.get('/agency/bankList')
    },
    fetchTraRates(params: object) {
        return this.post('/common/fetchTraData', params)
    },
    getDataFromDocument(params: object) {
        return this.post('/common/uploadAndReadDocument', params)
    },
}

export default CommonService
