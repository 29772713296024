import { toast } from "react-toastify";
import { createSlice } from '@reduxjs/toolkit';
import { RoleUserService } from "../../services/RoleUserService";

interface APIResponse {
    data: {
        status: number,
        message: string
    }
}

const roles = createSlice({
    name: 'role',
    initialState: {
        roles: {},
        features : {},
        users : {}
    },
    reducers: {
        roleListing: (state, action) => {
            state.roles = action.payload
        },
        featureList : (state, action) =>{
            state.features = action.payload
        },
        userList : (state, action) =>{
            state.users = action.payload
        },
        roleDelete : (state, action) =>{
            state.users = action.payload
        },
        userManagementList : (state, action) =>{
            state.users = action.payload
        }, 
        saveUserData : (state, action) =>{
            state.users = action.payload
        },
        userDelete : (state, action) =>{
            state.users = action.payload
        }
    }
})


export default roles.reducer;

const { roleListing, featureList, userList,roleDelete, userManagementList, saveUserData , userDelete} = roles.actions


/**
 * Function to make API call to fetch partner mapping listing
 * @param params 
 * @returns 
 */
export const getRoleListing = { 
    getRoleData: (params: object) => async (dispatch: any) => {
        return new Promise((resolve, reject) => {
            RoleUserService.getRoleList(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            dispatch(roleListing(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    },
    saveRoleData : (params: object) => async (dispatch: any) => {
        return new Promise((resolve, reject) => {
            RoleUserService.addEditRoleDetails(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            dispatch(roleListing(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    },
    getFeaturesData: (params: object) => async (dispatch: any) => {
        return new Promise((resolve, reject) => {
            RoleUserService.getFeaturesList(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            dispatch(featureList(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    },
    getUserListData: (params: object) => async (dispatch: any) => {
        return new Promise((resolve, reject) => {
            RoleUserService.getUsersList(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            dispatch(userList(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    }, 
    updateStatus: (params: object) => async (dispatch: any) => {
        return new Promise((resolve, reject) => {
            RoleUserService.updateStatus(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            dispatch(roleDelete(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    },
    getUserManagementList: (params: object) => async (dispatch: any) => {
        return new Promise((resolve, reject) => {
            RoleUserService.getUserManagementList(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            dispatch(userManagementList(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    },
    saveUserData: (params: object) => async (dispatch: any) => {
        return new Promise((resolve, reject) => {
            RoleUserService.saveUserData(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            dispatch(saveUserData(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    },  

    userDelete: (params: object) => async (dispatch: any) => {
        return new Promise((resolve, reject) => {
            RoleUserService.userDelete(params)
                .then(
                    (response: APIResponse) => {
                        if ((response && response.data && response.data.status) === 200) {
                            resolve(response.data)
                            dispatch(userDelete(response.data));
                        }
                        else {
                            let errorMsg = (response && response.data && response.data.message) || ''
                            if (errorMsg) toast.error(response.data.message);
                            resolve(response && response.data)
                        }
                    },
                    (error: Error) => {
                        reject(error);
                    }
                )
        })
    }
}

