import MasterService from "./MasterService";

const AgencyService = {
    ...MasterService,

    approveAgencyDocument(params: object) {
        return this.post('/agency/approveAgencyDocument', params)
    },
    getAgencyList(params: object) {
        return this.post('/agency/getAgencyList', params)
    },
    updateAgencyStatus(params: object) {
        return this.post('/agency/updateAgencyStatus', params)
    },
    addAgency(params: object) {
        return this.post('/agency/addAgency', params)
    },
    addAgencyDocument(params: object) {
        return this.post('/agency/addAgencyWebDocument', params)
    },
    getAgencyDetailData(params: object) {
        return this.post('/agency/getAgencyDetailData', params)
    },
    deleteAgencyDocument(params: object) {
        return this.post('/agency/deleteAgencyDocument', params)
    },
    approveAgency(params: object) {
        return this.post('/agency/approveAgency', params)
    },
    saveAgencyPaymentDetails(params: object) {
        return this.post('/lead/saveAgencyPaymentDetail', params)
    },
    agencyCommentHistory(params: object) {
        return this.post('/agency/agencyCommentHistory', params)
    },
   
    getAllAgencyList(params: any) {
        return this.post('/agency/agencyList', params)
    },
    createAgencyLead(params: any) {
        return this.post('/lead/createAgencyLead', params)
    }
}

export default AgencyService;