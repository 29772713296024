import { useState, useEffect } from "react";

// interface InputFieldProps {
//     inputProps: FieldProps,
//     onChange: any
// }
// interface FieldProps {
//     id: string,
//     type: string,
//     name: string,
//     maxLength: any,
//     value: any,
//     className: string,
//     disabled: boolean,
//     placeholder?: string|null|undefined,
// }

const InputField=(props: any) => {


    const [inputProps, setInputProps] = useState<any>({
        id: props.inputProps.id || '',
        type: props.inputProps.type || '',
        name: props.inputProps.name || '',
        maxLength: props.inputProps.maxLength || '',
        value: props.inputProps.value || "",
        className: props.inputProps.className || 'form-control',
        disabled: props.inputProps.disabled || false,
        placeholder: props.inputProps.placeholder || ""
    });

    useEffect(() => {
        setInputProps({ ...inputProps, ...props.inputProps })
        // eslint-disable-next-line
    }, [props.inputProps]);

    const changeHandel = (event: any) => {
        if (typeof props.onChange === 'function') {

            let pattern = /^\d+$/;
            let isValid = true;
            let updatedValue: any = ''
            let percentagePattern  = /^[0-9]+(\.[0-9]{0,2})?$/;

            if (props.inputProps.id === 'mobile' || props.inputProps.id === 'alternate_mobile' || props.inputProps.id === 'tin_id_no') {
                if (event.target.value && !pattern.test(event.target.value)) {
                    updatedValue = inputProps?.value
                    // return false;
                    isValid = false;
                } else if (props.inputProps.maxLength && event.target.value.length > props.inputProps.maxLength) {
                    // return false;
                    updatedValue = inputProps?.value

                    isValid = false;

                }else{
                    updatedValue = event.target.value

                }
            }else if(props.inputProps.id === "percentage"){
                if (event.target.value && !percentagePattern.test(event.target.value)) {

                    // return false;
                    updatedValue = inputProps?.value

                    isValid = false;

                }else if (props.inputProps.maxLength && +event.target.value > +props.inputProps.maxLength) {
                    // return false;
                    updatedValue = inputProps?.value

                    isValid = false;

                }else{
                    updatedValue = event?.target?.value

                }
            } else{
                updatedValue = event.target.value

            }
            //  else if (props.inputProps.id === "customer_name") {
            //     if (event.target && event.target.value && !event.target?.value?.trim()) {
            //         return false;
            //     }
            // }
            if(isValid){
                props.onChange(event);
            }

            setInputProps({ ...inputProps, value: updatedValue });
        }
    }

    const chkValFocusOut = (event: any) => {
            setInputProps({ ...inputProps, value: event.target.value });
            if(props.onBlur){
                props?.onBlur(event)
            }

    }
    const chkValFocus = (event: any) => {
        if (event.target.value) {
            setInputProps({ value: event.target.value })
            props.onChange(event)
        }
    }

    return (
        <>
            <input {...inputProps}
                onBlur={chkValFocusOut}
                onFocus={chkValFocus}
                onChange={changeHandel} className={props.inputProps.className} autoComplete="off" />
        </>
    );
};

export default InputField;