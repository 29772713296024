import MasterService from "./MasterService";

export const RoleUserService = {
    ...MasterService,

    addEditRoleDetails(params: object) {
        return this.post('/features/saveRoleFeatures', params)
    },

    getRoleList(params: object) {
        return this.post('/features/getRoleList', params)
    },

    getFeaturesList(params: object) {
        return this.post('/features/getAssignFeaturesList', params)
    },

    getUsersList(params: object) {
        return this.post('/user/getUserList', params)
    },

    updateStatus(params:object){
        return this.post('/features/roleDelete', params)
    }, 

    getUserManagementList(params: object) {
        return this.post('/user/userList', params)
    },

    saveUserData(params: object) {
        return this.post('/user/saveUser', params)
    },
    userDelete(params: object) {
        return this.post('/user/userDelete', params)
    }

}