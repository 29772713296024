import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import secureStorage from "@src/config/encrypt";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import * as HELPER from "@src/config/helper";
import { ROLE_FEATURES } from "@src/config/constant";
const LOGO = require('../styles/webroot/images/logo.svg');

const Navigation = () => {
    const [role, setRole] = useState("");
    // eslint-disable-next-line
    const [isMount, setIsMount] = useState(false);
    // eslint-disable-next-line
    const [redirect, setRedirect] = useState(false);
    const [name, setName] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setIsMount(true);
        let result = secureStorage.getItem("authUserInfo");

        if (!result) {
             setRedirect(true)
        } else {
            let userInfo = result.user_data;

            setName((userInfo && userInfo.name) || '');
            setRole((userInfo && userInfo.assigned_role_name) || '');

        }
    },[]);

    const logOutUser = async () => {
        let result = secureStorage.getItem("authUserInfo")
        if (result) {
            secureStorage.removeItem("authUserInfo")
            toast.success('LogOut Successfully')
            setTimeout(()=> { navigate('/login');  },100)
        }
    };
    
    //Re direct home page
    const redirectToDashboard = () => {
        navigate('/dashboard');
      };

    return (
        <div className="container-fluid" >
            <div className="header-main">
                <div className="logo">
                    {/* <img src="images/logo.svg" /> <span>Insurance BOX</span> */}
                    <img src={LOGO.default} className="" alt="insurance-logo" onClick={redirectToDashboard}/>

                </div>
                <div className="right-panel-naviganion menupanel">
                    <div className="nav">
                        <ul>
                            {
                                HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['dashboard_page'], accessType: 'isVisible'}) ?
                                    <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                                    : ''
                            }
                            {
                                HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['listing_page'], accessType: 'isVisible'}) ?
                                <li><NavLink to="/insurance-list">Manage Panel</NavLink></li>
                                    : ''
                            }
                            <li className="dropdownmenu"><NavLink to="#">More</NavLink>
                                <ul className="submenu">
                                    <li><NavLink to="/rule-engine">Rule Engine</NavLink> </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="notification-btn">
                        <Notification />

                    </div> */}

                    <div className="user-detail ">
                        <ul>

                            <li className="dropdownmenu"><NavLink to="#">
                                <span className="userarc">
                                    {name[0]?.toUpperCase()}
                                </span>
                                <div className="username">
                                    <span>{name}</span>
                                    <span className="user-degintion" title={role}>{role.length>10 ? role.substr(0, 10)+'...' : role}</span>
                                </div>
                            </NavLink>
                                <ul className="submenu">
                                    <li><NavLink to="#" onClick={logOutUser}>Logout</NavLink> </li>
                                </ul>
                            </li>

                        </ul>
                    </div>
                    <div className="language">
                        <ul>
                            <li className="dropdownmenu">
                                <NavLink to="#">
                                    ENG
                                </NavLink>
                                <ul className="submenu">
                                    <li><NavLink to="#">Eng</NavLink> </li>
                                </ul>
                            </li>

                        </ul>
                    </div>
                </div>

            </div>

        </div >
    );
}

export default Navigation;