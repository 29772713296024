import secureStorage from '@src/config/encrypt';
import { useNavigate } from 'react-router-dom';

export default function NotFound() : ReturnType<React.FC>  {
    const navigate = useNavigate();
    const user = secureStorage.getItem("authUserInfo");

    const handleClick = () => {
        navigate('/')
    }
    return (
        <div className="not-found-container">
          <h1>404 - Page Not Found</h1>
          <p className="m-t-25">Sorry, the page you are looking for cannot be found.</p>
          <button className="btn-primary m-t-25" onClick={() => handleClick()}>{user ? "Go Back to Dashboard" : "Go Back to Login"}</button>
        </div>
      );
}