import { toast } from "react-toastify";
import { createSlice } from '@reduxjs/toolkit';
import CommonService from '../../services/CommonService';
import InsuranceService from '../../services/InsuranceService';
import { APIResponse } from '../types/Common';


const common = createSlice({
    name: 'common',
    initialState: {
        common: {},
        rates: {},
        documentList: [],
        city: [],
        state: [],
        locality: [],
        stateWithCity:[],
        mmvList:{},
        getDealerList: [],
        sfaUserList: [],
        agencyBankList:[],
    },
    reducers: {
        config: (state, action) => {
            state.common = action.payload;
        },
        city: (state, action) => {
            state.city = action.payload;
        },
        state: (state, action) => {
            state.state = action.payload;
        },
        locality: (state, action) => {
            state.locality = action.payload;
        },

        rates: (state, action) => {
            state.rates = action.payload
        },
        documentList: (state, action) => {
            state.documentList = action.payload
        },
        stateWithCity: (state, action) => {
            state.stateWithCity = action.payload;
        },
        mmvList: (state, action) => {
            state.mmvList = {...state.mmvList, ...action.payload};
        },
        getDealerList: (state, action) => {
            state.getDealerList = action.payload;
        },
        sfaUserList: (state, action) => {
            state.sfaUserList = action.payload;
        },    
        agencyBankList: (state, action) => {
            state.agencyBankList = action.payload;
        },
    },
});

export default common.reducer;

const { config, rates, city, state, locality, documentList,stateWithCity ,mmvList, getDealerList, sfaUserList, agencyBankList} = common.actions


export const getMasterConfig = () => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getMasterConfig().then((response: APIResponse) => {
            if ((response && response.data && response.data.status) === 200) {
                let data = response.data && response.data.data
                resolve(response.data)
                dispatch(config(data))
            }
            else {
                let errorMsg = (response && response.data && response.data.message) || ''
                if (errorMsg) toast.error(response.data.message);
                resolve(response && response.data)
            }
        },
            (error: Error) => {
                reject(error);
            })
    })
}

export const getDashboardCount = () => async (dispatch: any) => {
    return new Promise((resolve, reject) => {

        CommonService.getDashboardCount().then(
            (response: APIResponse) => {

                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data)
                }
                else {
                    let errorMsg = (response && response.data && response.data.message) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            (error: Error) => {
                reject(error);
            }
        )
    })
}

/**
 * Function to call updateMasterRate API
 * updates aon, vat, stamp duty, local_govt_taxes, base issue with min and max
 * @param params 
 * @returns 
 */
export const updateMasterRates = (params: object) => {
    return new Promise((resolve, reject) => {
        CommonService.updateMasterRates(params).then(
            (response: APIResponse) => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data)
                }
                else {
                    let errorMsg = (response && response.data && response.data.message) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            (error: Error) => {
                reject(error);
            }
        )
    })
}

/**
 * function to make API call for fetching all rates display on rule engine page
 * @param params 
 * @returns 
 */
export const fetchMasterRates = (params: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        CommonService.fetchMasterRates(params).then(
            (response: APIResponse) => {
                if ((response && response.data && response.data.status) === 200) {
                    let data = response.data && response.data.data
                    resolve(data)
                    dispatch(rates(data))
                }
                else {
                    let errorMsg = (response && response.data && response.data.message) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            (error: Error) => {
                reject(error);
            }
        )
    })
}

/**
 * function to update rates redux state
 * @param params 
 * @returns 
 */
export const updateReduxMasterRates = (params: object): any => {
    return async (dispatch: any) => {
        dispatch(rates(params))
    }
}
// import csv

export const importCsv = async (params: object) => {
    return new Promise((resolve, reject) => {
        CommonService.importCsv(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


// import uploadAddOnCsv

export const uploadAddOnCsv = async (params: object) => {
    return new Promise((resolve, reject) => {
        CommonService.uploadAddOnCsv(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


export const getDocumentListing = (params: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        CommonService.getDocumentList(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        let data = response.data && response.data.data
                        resolve(data)
                        dispatch(documentList(data))
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const getStateCityList = () => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        CommonService.getStateCityList().then((response: APIResponse) => {
            if ((response && response.data && response.data.status) === 200) {
                let data = response.data && response.data.data
                resolve(response.data)
                dispatch(city(data.city))
                dispatch(state(data.state))
            }
            else {
                let errorMsg = (response && response.data && response.data.message) || ''
                if (errorMsg) toast.error(response.data.message);
                resolve(response && response.data)
            }
        },
            (error: Error) => {
                reject(error);
            })
    })
}

export const getlocalityList = (params: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        CommonService.getlocalityList(params).then((response: APIResponse) => {
            if ((response && response.data && response.data.status) === 200) {
                let data = response.data && response.data.data
                resolve(response.data)
                dispatch(locality(data))

            } else {
                let errorMsg = (response && response.data && response.data.message) || ''
                if (errorMsg) toast.error(response.data.message);
                resolve(response && response.data)
            }
        },
            (error: Error) => {
                reject(error);
            })
    })
}



export const addDocument = (params: object) => {
    return new Promise((resolve, reject) => {
        CommonService.addDocument(params).then((response: APIResponse) => {
            if ((response && response.data && response.data.status) === 200) {
                resolve(response.data)

            } else {
                let errorMsg = (response && response.data && response.data.message) || ''
                if (errorMsg) toast.error(response.data.message);
                resolve(response && response.data)
            }
        },
            (error: Error) => {
                reject(error);
            })
    })
}

export const getStateWiseCityList = () => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        CommonService.getStateWiseCity().then((response: APIResponse) => {
            if ((response && response.data && response.data.status) === 200) {
                let data = response.data && response.data.data
                resolve(response.data)
                dispatch(stateWithCity(data))

            } else {
                let errorMsg = (response && response.data && response.data.message) || ''
                if (errorMsg) toast.error(response.data.message);
                resolve(response && response.data)
            }
        },
            (error: Error) => {
                reject(error);
            })
    })
}

export const getMMVList = (params:any) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        let mmvPatams = JSON.parse(JSON.stringify(params));
        delete mmvPatams['category_id'];

        CommonService.getMMVList(mmvPatams).then((response: APIResponse) => {
            if ((response && response.data && response.data.status) === 200) {
                let data = response.data && response.data.data
                resolve(response.data)
                dispatch(mmvList({[params['category_id']]: data}))

            } else {
                let errorMsg = (response && response.data && response.data.message) || ''
                if (errorMsg) toast.error(response.data.message);
                resolve(response && response.data)
            }
        },
            (error: Error) => {
                reject(error);
            })
    })
}

/**
 * API to fetch base issue rate with provided params
 * @param params 
 * @returns 
 */
export const fetchBaseRate = (params: object) => {
    return new Promise((resolve, reject) => {
        CommonService.fetchBaseIssueRate(params).then((response: APIResponse) => {
            if ((response && response.data && response.data.status) === 200) {
                resolve(response.data)

            } else {
                let errorMsg = (response && response.data && response.data.message) || ''
                if (errorMsg) toast.error(response.data.message);
                resolve(response && response.data)
            }
        },
            (error: Error) => {
                reject(error);
            })
    })
}

export const getDealesList = () => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        CommonService.getDealersList().then((response: APIResponse) => {
            if ((response && response.data && response.data.status) === 200) {
                let data = response.data && response.data.data
                resolve(response.data)
                dispatch(getDealerList(data))
            }
            else {
                let errorMsg = (response && response.data && response.data.message) || ''
                if (errorMsg) toast.error(response.data.message);
                resolve(response && response.data)
            }
        },
            (error: Error) => {
                reject(error);
            })
    })
}

/**
 * API to fetch SFA users by sfa role id
 * @param params
 * @returns
 */
export const getSfaUsersByRole = (params: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        CommonService.getSfaUserByRole(params).then((response: APIResponse) => {
            if ((response && response.data && response.data.status) === 200) {
                resolve(response.data)
                dispatch(sfaUserList(response?.data?.data || []))

            } else {
                let errorMsg = (response && response.data && response.data.message) || ''
                if (errorMsg) toast.error(response.data.message);
                resolve(response && response.data)
            }
        },
            (error: Error) => {
                reject(error);
            })
    })
}



/**
 * function to update sfa user list state
 * @param params 
 * @returns 
 */
export const updateSfaUserList = (params: object): any => {
    return async (dispatch: any) => {
        dispatch(sfaUserList(params))
    }
}

// agency bank list
export const bankList = () => async(dispatch:any) => {
    return new Promise((resolve, reject) => {
        CommonService.bankList()
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(agencyBankList(response?.data?.data))
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


export const fetchTraRates = (params: object) => {
    return new Promise((resolve, reject) => {
        CommonService.fetchTraRates(params).then(
            (response: APIResponse) => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data)
                }
                else {
                    let errorMsg = (response && response.data && response.data.message) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            (error: Error) => {
                reject(error);
            }
        )
    })
}