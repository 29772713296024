import { useState } from 'react';
import Modal from "@src/common/ModalPopup";
import { AGENCY_DOC_STATUS, AGENCY_SOURCE } from '@src/config/constant';

function ImageSlider(props: any) {

    const { showModal, sliderImages, selectedAgency } = props;
    const [activeImageIndex, setActiveImageIndex] = useState(props.activeImageIndex);

    const changeSlider = (sliderIndex: number) => {
        setActiveImageIndex(sliderIndex)
    }

    const downloadFile = (fileUrl: string, file: any) => {
        let fileName: any = fileUrl.split('/');
        fileName = fileName[fileName.length - 1].split("?")[0];

        if(!file.id && file.type){
            fileName = new Date().getTime()+'.'+file.type   
        }

        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                // Create a URL for the blob object
                const url = window.URL.createObjectURL(blob);

                // Create an anchor element and set the download attribute
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileName;

                // Append the anchor to the document and trigger a click
                document.body.appendChild(a);
                a.click();

                // Remove the anchor and revoke the object URL
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error downloading the image:', error));
    }

    return (
        <div className="view-timeline-popup tin-document-popup">
            <Modal show={showModal} handleClose={() => { props.closeResubmitModal(false, 0); }} >
                <div className="modal-header">
                    <h2>TIN Document</h2>
                </div>
                <div className="modal-body" >
                    {
                        sliderImages?.length && sliderImages?.map((img: any, key: number) =>
                            <div key = {key} className={`img-bx-select-opt  ${activeImageIndex === key ? "show" : "hide"} p-rel`}>

                                {
                                    key > 0 && <span onClick={() => changeSlider(key - 1)} className="arrow-prev arrow-silder"> {"<"} </span>
                                }

                                {
                                    img?.type?.indexOf("pdf") !== -1
                                        ?
                                        <div><i className={`ic-${img?.type}-file icons-normal t-accent`} ></i></div>
                                        :
                                        <img src={img.url} alt={`preview-${key}`} className="upload-icn" />

                                }
                                {
                                    +selectedAgency?.doc_status === AGENCY_DOC_STATUS['approved'] && img.id &&
                                    <span className="image-status">Approved</span>
                                }
                                {
                                    +selectedAgency?.doc_status === AGENCY_DOC_STATUS['resubmit'] && img.id &&
                                    <span className="image-requested">Requested</span>
                                }
                                {
                                    +selectedAgency?.doc_status === AGENCY_DOC_STATUS['rejected'] && img.id &&
                                    <span className="image-requested">Rejected</span>
                                }
                                {
                                    (sliderImages?.length - 1 !== key) && <span onClick={() => changeSlider(key + 1)} className="arrow-next arrow-silder"> {">"} </span>
                                }
                                <div className="t-center p-lg-t silder-action-button">

                                    {
                                        props.editForm && selectedAgency?.source === AGENCY_SOURCE['sfa'] && selectedAgency?.doc_status !== AGENCY_DOC_STATUS['resubmit'] && selectedAgency?.doc_status !== AGENCY_DOC_STATUS['approved'] && +selectedAgency.status !== AGENCY_DOC_STATUS['rejected'] && 
                                        <button className="btn-line m-sm-r" onClick={() => {props.markAsVerifiedTrigger()}}>
                                            Mark as verified
                                        </button>
                                    }

                                    {
                                         props.editForm && selectedAgency?.source === AGENCY_SOURCE['sfa'] && selectedAgency?.doc_status !== AGENCY_DOC_STATUS['resubmit'] && selectedAgency?.doc_status !== AGENCY_DOC_STATUS['approved'] && +selectedAgency.status !== AGENCY_DOC_STATUS['rejected'] &&
                                        <button className="btn-line m-sm-r" onClick={() => {props.handleResubmitDocTrigger("resubmit")}}>
                                            Request Resubmission
                                        </button>
                                    }



                                    <button className="btn-line" onClick={() => downloadFile(sliderImages[activeImageIndex]['url'], img)} >
                                        {/* <a download="custom-filename.jpg"
                                     href={sliderImages[activeImageIndex]['url']} 
                                     > */}
                                        Download
                                        {/* </a> */}
                                    </button>
                                </div>
                            </div>
                        )
                    }


                </div>
            </Modal>
        </div>

    )
}

export default ImageSlider;