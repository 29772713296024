import MasterService from "./MasterService";

const PartnerService = {
    ...MasterService,

    getInsurancePartnerMappingListing(params: object) {
        return this.post('/partners/getPartnerTypeDetails', params)
    },

    editInsurancePartnerMapping(params: object) {
        return this.post('/partners/updateInsurancePartner', params)
    },

    addInsurancePartnerMapping(params: object) {
        return this.post('/partners/addInsurancePartner', params)
    },

    updatePartnerMappingStatus(params: object) {
        return this.post('/partners/updatePartnerStatus', params)
    },

    getPartnerDetailsById(params: object) {
        return this.post('/partners/getPartnerDetailsById', params)
    },
    
    togglePartnerKey(params: object) {
        return this.post('/partners/togglePartnerKey', params)
    },
}

export default PartnerService;