import MasterService from "./MasterService";

export const BulkPaymentService = {
    ...MasterService,

    getBulkPaymentList(params: object) {
        return this.post('/payment/getBulkPaymentList', params)
    },
    saveBulkPayment(params: object) {
        return this.post('/payment/bulkPayment', params)
    },
    getInsuranceIdsList(params: object) {
        return this.post('/payment/getInsuranceIdsList', params)
    },
    paymentProofDocs(params: object) {
        return this.post('/payment/paymentProofDocs', params) 
    },
    deleteProofDocument(params: object) {
        return this.post('/payment/deleteProofDocument', params)
    },
    getReferencePaymentData(params: object) {
        return this.post('/payment/getReferencePaymentData', params)
    },   
    deleteBulkPaymentLead(params: object) {
        return this.post('/payment/deleteBulkPaymentLead', params)
    }

}