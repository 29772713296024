import React, { useState } from "react";
import { Tab, Nav } from 'react-bootstrap';
import RoleListTable from './RoleListTable';
import UserListTable from './UserListTable';

function RoleList() {
    const [selectedTab, setSelectedTab] = useState('first');

    const handleTabSelect = (eventKey: string) => {
        setSelectedTab(eventKey);
    };

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="top-heading">
                    <h1>Role/User Management</h1>
                </div>
                <div className="loan-filter-bg managment-tabs tab-chips">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="p-rel">
                        <div className="role-management-top-sec">
                            <div className="selected-chips">
                                <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                                    <div className="tab-list">
                                        <Nav.Item onClick={() => handleTabSelect("first")}>
                                            <Nav.Link eventKey="first">
                                                Role Management
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={() => handleTabSelect("second")}>
                                            <Nav.Link eventKey="second">User Management</Nav.Link>
                                        </Nav.Item>

                                    </div>
                                </Nav>
                            </div>
                           
                           
                            {selectedTab === "first" &&
                                      <RoleListTable/> 
                                }

                            {selectedTab === "second" &&
                                      <UserListTable/> 
                                }
                        </div>
                        </div>
                        
                    </Tab.Container>

                </div>
              
            </div>
        </React.Fragment>
    )
}

export default RoleList