import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { OUTBOUND_PAYMENT_TO_SALES_TEAM, OUTBOUND_PAYMENT_TO_BROKER, REFERRAL_FEE_PAYOUT } from "@src/config/constant";

const BankDetails = (props: any) => {
    const insuranceState = useSelector((state: any) => state.insurance);

    const [bankDetails, setBankDetails] = useState<any>({});

    const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (insuranceState.leadDetail && insuranceState.leadDetail.leadId) {
            setFieldDisabled(false);
            let bankDetailsData: any = {};

            if (+props.formType === REFERRAL_FEE_PAYOUT) { // FOR AGENCY REFERRAL PAYOUT
                setFieldDisabled(true);
                if (insuranceState?.leadDetail?.outbound_agency_referral_bank_name && insuranceState?.leadDetail?.outbound_agency_referral_bank_name !== '') {
                    bankDetailsData.bank_name = insuranceState.leadDetail.outbound_agency_referral_bank_name;
                }
                if (insuranceState?.leadDetail?.outbound_agency_referral_branch_name && insuranceState?.leadDetail?.outbound_agency_referral_branch_name !== '') {
                    bankDetailsData.branch_name = insuranceState.leadDetail.outbound_agency_referral_branch_name;
                }
                if (insuranceState?.leadDetail?.outbound_agency_referral_beneficiary_name && insuranceState?.leadDetail?.outbound_agency_referral_beneficiary_name !== '') {
                    bankDetailsData.beneficiary_name = insuranceState.leadDetail.outbound_agency_referral_beneficiary_name;
                }
                if (insuranceState?.leadDetail?.outbound_agency_referral_bank_account_no && insuranceState?.leadDetail?.outbound_agency_referral_bank_account_no !== '') {
                    bankDetailsData.bank_account_no = insuranceState.leadDetail.outbound_agency_referral_bank_account_no;
                }
                setBankDetails(bankDetailsData);
            } else {

                if (+props.formType === OUTBOUND_PAYMENT_TO_SALES_TEAM) { // FOR SALES TEAM

                    if (insuranceState?.leadDetail?.outbound_sales_team_bank_name && insuranceState?.leadDetail?.outbound_sales_team_bank_name !== '') {
                        bankDetailsData.bank_name = insuranceState.leadDetail.outbound_sales_team_bank_name;
                    }
                    if (insuranceState?.leadDetail?.outbound_sales_team_branch_name && insuranceState?.leadDetail?.outbound_sales_team_branch_name !== '') {
                        bankDetailsData.branch_name = insuranceState.leadDetail.outbound_sales_team_branch_name;
                    }
                    if (insuranceState?.leadDetail?.outbound_sales_team_beneficiary_name && insuranceState?.leadDetail?.outbound_sales_team_beneficiary_name !== '') {
                        bankDetailsData.beneficiary_name = insuranceState.leadDetail.outbound_sales_team_beneficiary_name;
                    }
                    if (insuranceState?.leadDetail?.outbound_sales_team_bank_account_no && insuranceState?.leadDetail?.outbound_sales_team_bank_account_no !== '') {
                        bankDetailsData.bank_account_no = insuranceState.leadDetail.outbound_sales_team_bank_account_no;
                    }

                    setBankDetails(bankDetailsData);

                    if (insuranceState.leadDetail.outbound_sales_team_id) setFieldDisabled(true);
                } else {
                    setFieldDisabled(true);
                    if (insuranceState?.leadDetail?.outbound_broker_bank_name && insuranceState?.leadDetail?.outbound_broker_bank_name !== '') {
                        bankDetailsData.bank_name = insuranceState.leadDetail.outbound_broker_bank_name;
                    }
                    if (insuranceState?.leadDetail?.outbound_broker_branch_name && insuranceState?.leadDetail?.outbound_broker_branch_name !== '') {
                        bankDetailsData.branch_name = insuranceState.leadDetail.outbound_broker_branch_name;
                    }
                    if (insuranceState?.leadDetail?.outbound_broker_beneficiary_name && insuranceState?.leadDetail?.outbound_broker_beneficiary_name !== '') {
                        bankDetailsData.beneficiary_name = insuranceState.leadDetail.outbound_broker_beneficiary_name;
                    }
                    if (insuranceState?.leadDetail?.outbound_broker_bank_account_no && insuranceState?.leadDetail?.outbound_broker_bank_account_no !== '') {
                        bankDetailsData.bank_account_no = insuranceState.leadDetail.outbound_broker_bank_account_no;
                    }
                    setBankDetails(bankDetailsData);

                }
            }
            props.getBankDetail(bankDetailsData);

        }
        // eslint-disable-next-line
    }, [insuranceState.leadDetail, props.formType]);

    const changeHandler = (e: any, key: string) => {
        let updatedBankDetails = { ...bankDetails, [key]: e.target.value }
        setBankDetails({ ...updatedBankDetails });

        props.getBankDetail(updatedBankDetails);
        props.updateErrors({ ...props.errors, [key]: "" });
    }

    let branchNameDisabled = (+props.formType === OUTBOUND_PAYMENT_TO_BROKER && !insuranceState.leadDetail.outbound_broker_id) ? false : true
    branchNameDisabled = (+props.formType === OUTBOUND_PAYMENT_TO_SALES_TEAM && !insuranceState.leadDetail.outbound_sales_team_id) ? false : branchNameDisabled;

    return (
        <>
            <fieldset className="col-md-6">
                <div className="material" title={bankDetails?.bank_name || ""}>
                    <input id="" type="text" placeholder=" " name="bankName" className="form-input" value={bankDetails?.bank_name || ""} disabled={fieldDisabled} onChange={fieldDisabled ? undefined : (e: any) => changeHandler(e, 'bank_name')} />
                    <label data-label={`Bank Name ${insuranceState?.leadDetail && +insuranceState.leadDetail?.lead_type !== 3 ? '*' : ''}`} className="form-label"></label>
                    <span className="error-txt">
                        {props?.errors?.bank_name || ""}
                    </span>
                </div>
            </fieldset>
            <fieldset className="col-md-6">
                <div className="material" title={bankDetails?.bank_account_no || ""}>
                    <input id="" type="text" placeholder=" " name="bankAccountNo" className="form-input" value={bankDetails?.bank_account_no || ""} disabled={fieldDisabled} onChange={fieldDisabled ? undefined : (e: any) => changeHandler(e, 'bank_account_no')} />
                    <label data-label={`Bank Account Number ${insuranceState?.leadDetail && +insuranceState.leadDetail?.lead_type !== 3 ? '*' : ''}`} className="form-label"></label>

                    <span className="error-txt">
                        {props?.errors?.bank_account_no || ""}
                    </span>
                </div>
            </fieldset>
            <fieldset className="col-md-6">
                <div className="material" title={bankDetails?.beneficiary_name || ""}>
                    <input id="" type="text" placeholder=" " name="BeneficiaryName" className="form-input" value={bankDetails?.beneficiary_name || ""} disabled={fieldDisabled} onChange={fieldDisabled ? undefined : (e: any) => changeHandler(e, 'beneficiary_name')} />
                    <label data-label={`${(+props.formType === REFERRAL_FEE_PAYOUT) ? "Account Name" : "Beneficiary Name"}  ${insuranceState?.leadDetail && +insuranceState.leadDetail?.lead_type !== 3 ? '*' : ''}`} className="form-label"></label>

                    <span className="error-txt">
                        {props?.errors?.beneficiary_name || ""}
                    </span>
                </div>
            </fieldset>
            <fieldset className="col-md-6">
                <div className="material" title={bankDetails?.branch_name || ""}>
                    <input id="" type="text" placeholder=" " name="BranchName" className="form-input" value={bankDetails?.branch_name || ""} disabled={branchNameDisabled} onChange={branchNameDisabled ? undefined : (e: any) => changeHandler(e, 'branch_name')} />
                    <label data-label="Branch Name" className="form-label"></label>

                    <span className="error-txt">
                        {props?.errors?.branch_name || ""}
                    </span>
                </div>
            </fieldset>
        </>
    );
}



export default BankDetails;