import { useEffect, useState } from "react";
import * as HELPER from '../config/helper';

interface CurrencyInputFieldProps {
    inputProps: object,
    onChange: any
}


const CurrencyInputField: React.FC<CurrencyInputFieldProps> = (props: any) => {    
    const [inputProps, setInputProps] = useState<any>({
        id: props.inputProps.id || '',
        type: props.inputProps.type || '',
        name: props.inputProps.name || '',
        label: props.inputProps.label || '',
        dataerror: '',
        maxLength: props.inputProps.maxLength || '',
        value: HELPER.addCurrencyFormatting(props.inputProps.value) || "",
        className: props.inputProps.className || 'form-control',
        maxdigits: 12,
        disabled: props.inputProps.disabled || false,
        placeholder: props.inputProps.placeholder || ""
    });

    // Validate the number with up to two decimal digits
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;

    useEffect(()=>{
        if(inputProps.value !== props.inputProps.value){            
            setInputProps({...inputProps, ...props.inputProps, value: props.inputProps.value ? HELPER.addCurrencyFormatting(props.inputProps.value) : '' })
        }
        // eslint-disable-next-line
    }, [props.inputProps])

    const changeHandel = (event: any) => {
        if (typeof props.onChange === 'function') {
            if (event.target.value === '' || (!isNaN(event.target.value) && regex.test(event.target.value))) {      
                setInputProps({ value: event.target.value })
                props.onChange(event);
            }
        }
    }

    const chkValFocusOut = (event: any) => {
        if (event.target.value) { 
            event.target.value = HELPER.addCurrencyFormatting(event.target.value);
            setInputProps({ value: event.target.value })
            props.onChange(event)

        }

    }
    const chkValFocus = (event: any) => {
        if (event.target.value) { 
            event.target.value = HELPER.removeCurrencyFormatting(event.target.value)
            setInputProps({ value: event.target.value })
            props.onChange(event)
        }
    }
    
    return (
        <>
            <input {...inputProps} onBlur={chkValFocusOut} onFocus={chkValFocus} onChange={changeHandel} className={props.inputProps.className} autoComplete="off" />
        </>
    );
};

export default CurrencyInputField;