import { toast } from "react-toastify";
import { createSlice } from '@reduxjs/toolkit';
import AgencyService from "../../services/AgencyService";

interface APIResponse {
    data: {
        status: number,
        message: string,
        data?: any
    }
}

const Agency = createSlice({
    name: 'insurance',
    initialState: {
        // insuranceList: {},
        // insuranceListFilters: {},
        // leadDetail: {},
        // createInsurance:{},
        // templateList: [],
        // getInboundPaymentFrom: "",
        // paymentData : [],
        // savePayment : [],
        // markLost : [],
        // reopenLostLead : [],
        // leadTemplateData: [],
        // activeLoanID: null,
        // sampleCsv : [],
        // formDetails: { extraField: {}, formDetails: []},
        // formConfig: [],
        // renewalUpdateFlag: { insuranceForm: false, customerDetails: false }
    },
    reducers: {
        // agencyDocumentResubmit: (state, action) => {
        //     state.insuranceList = action.payload
        // }
    }
})


export default Agency.reducer;

// const {  } = Agency.actions

/**
 * Function to make API call to fetch Insurance remarks
 * @param params 
 * @returns 
 */
// export const 

// export const AgencyPartner = {
export const approveAgencyDocument = async (params: object) => {
    return new Promise((resolve, reject) => {
        AgencyService.approveAgencyDocument(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const getAgencyDetailData = (params: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        AgencyService.getAgencyDetailData(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        // dispatch(agnecyListing(response.data));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const getAgencyList = (params: object) => {
    return new Promise((resolve, reject) => {
        AgencyService.getAgencyList(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        // dispatch(roleListing(response.data));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const updateAgencyStatus = (params: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        AgencyService.updateAgencyStatus(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        // dispatch(roleListing(response.data));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const deleteAgencyDocument = (params: object) => {
    return new Promise((resolve, reject) => {
        AgencyService.deleteAgencyDocument(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const addAgency = (params: object) => {
    return new Promise((resolve, reject) => {
        AgencyService.addAgency(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const addAgencyDocument = (params: object) => {
    return new Promise((resolve, reject) => {
        AgencyService.addAgencyDocument(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}
 
export const approveAgency = (params: object) => {
    return new Promise((resolve, reject) => {
        AgencyService.approveAgency(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        // dispatch(roleListing(response.data));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const saveAgencyPaymentDetails = (params: any) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        AgencyService.saveAgencyPaymentDetails(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        // dispatch(savePayment((response.data && response.data['data']) || []));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const getAllAgencyList = (params: any) => {
    return new Promise((resolve, reject) => {
        AgencyService.getAllAgencyList(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const agencyCommentHistory = (params: object) => {
    return new Promise((resolve, reject) => {
        AgencyService.agencyCommentHistory(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

