
export default function ToggleSwitch(props: any): ReturnType<React.FC> {

    let { checked, disabled } = props

    const onToggleSwitchChange = () => {
        if (props.onChange && !disabled) {
            props.onChange()
        }
    }

    return (
        <div className='ToggleSwitch ToggleSwitch__rounded'>
            <div className='ToggleSwitch__wrapper'>
                <div className={`Slider ${checked && 'isChecked'} ${disabled && 'disabled'}`} onClick={onToggleSwitchChange}></div>
            </div>
        </div>
    )
}
