import MasterService from "./MasterService";

const EncryptionService = {
    ...MasterService,

    fetchPublicKey() {
        return this.post('/getPublicKey')
    }
}

export default EncryptionService
