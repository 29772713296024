import React, { useState, useEffect } from "react";
import Modal from "../../common/ModalPopup";
import MultiSelect from "../../common/MultiSelect";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getRoleListing } from "../../store/slices/RoleUser";
import ReactSelect from "@common/ReactSelect";
import { getMasterConfig } from '../../store/slices/common';
import InputField from "../../common/InputField"
const TickIcon = require('../../styles/webroot/images/tick.png');

function AddUserPopup(params: any) {
    let { role_list, addNewUser, showUserRole } = params;
    let [formData, setFormData] = useState<any>({});
    let [userList, setUserList] = useState<any>([]);
    let [errors, setErrors] = useState<any>({});
    const mobile_regex = /^[9]\d{9}$/;
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const password_regex = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/ // eslint-disable-line
    const dispatch: any = useDispatch();
    const commonState = useSelector((state: any) => state.common);
    useEffect(() => {
        if (Object.keys(commonState.common).length === 0) {
            dispatch(getMasterConfig());
        }
        getUserList();
        // eslint-disable-next-line
    }, []);

    let verticleData = commonState?.common?.vertical_list??[];

    const getUserList = async () => {
        dispatch(getRoleListing.getUserManagementList({ isPaginationApply: false })).then((res: any) => {
            if (res && res.status === 200) {
                let userList = [];
                userList = (res.data && res.data.length && formData) ?
                    res.data.map((e: any) => {
                        let isChecked = formData.reportee_id ? formData.reportee_id === e.id : false;
                        return {
                            value: e.id,
                            label: e.name,
                            isChecked: isChecked,
                            assigned_role: e.assigned_role_ids ? e.assigned_role_ids.split(',') : []
                        }
                    }) : [];
                setUserList([...userList]);
            }
        })
    };

    const handleChange = async (key: any, event: any) => {
        let value: any = '';
        let target = event.target;

        if (key === 'status') {
            value = formData.status === "1" ? "0" : "1";
        }
        else if (event instanceof Array) {
            value = event.map((e) => e.value);
        }
        else if (target) {
            value = target.value;
        }
        else if (key === "reportee_role_id") {
            value = event.value || event.id;
            formData["reportee_id"] = null;
        }
        else if (event) {
            value = event.value || event.id;
        }
        formData[key] = value;
        errors[key] = '';
        setFormData({ ...formData });
        setErrors({ ...errors });
    }

    const isFormValid = (formData: any) => {
        let isValid = true;
        if (!formData.vertical_id || formData.vertical_id === '') {
            errors['vertical_id'] = "Verticle id is required";
            isValid = false;
        }
        if (!formData.reportee_id || formData.reportee_id === '') {
            errors['reportee_id'] = 'Reportee Name is required';
            isValid = false;
        }
        if (!formData.name || formData.name === '') {
            errors['name'] = 'Name is required';
            isValid = false;
        } else if (formData && formData.name && (formData.name.split('').length < 3 || formData.name.split('').length > 30)) {
            errors['name'] = 'Name should be between 3 to 30';
            isValid = false;
        }
        
        if (!formData.mobile ||  formData.mobile === '') {
            errors['mobile'] = 'Mobile number is required';
            isValid = false;
        }else if(!mobile_regex.test(formData.mobile)){
            errors['mobile'] = 'Mobile number should start from 9 and should be 10 digits.';
            isValid = false;
        }
        if (!formData.email || formData.email === '') {
            errors['email'] = 'Email is required';
            isValid = false;
        } else if (!email_regex.test(formData.email)) {
            errors['email'] = 'Invalid Email';
            isValid = false;
        }
        if (!formData.password || formData.password === '') {
            errors['password'] = 'Password is required';
            isValid = false;
        } else if (!password_regex.test(formData.password)) {
            errors['password'] = 'Password should be atleast 8 character with 1 special char,1 Uppercase,1 lowercase and 1 digit';
            isValid = false;
        }
        setErrors({ ...errors });
        return isValid;

    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let postData = await getFormData();
        if (isFormValid(postData)) {
            dispatch(getRoleListing.saveUserData(formData)).then((res: any) => {
                if (res && res.status === 200) {
                    toast.success(res.message);
                    setFormData({})
                    params.addNewUser(false);
                    params.getUserManageListDetails({});
                } else {
                    formData.status = '1';
                    setFormData(formData);
                }
            })
        }
        params.setSearchText('');
    }

    const getFormData = async () => {
        let postData:any = {};
        if (formData && formData.vertical_id) postData['vertical_id'] = formData.vertical_id;
        if (formData && formData.assigned_role) postData['assigned_role'] = formData.assigned_role;
        if (formData && formData.reportee_role_id) postData['reportee_role_id'] = formData.reportee_role_id;
        if (formData && formData.reportee_id) postData['reportee_id'] = formData.reportee_id;
        if (formData && formData.name) postData['name'] = formData.name?.replace(/\s+/g,' ').trim();
        if (formData && formData.email) postData['email'] = formData.email?.replace(/\s+/g,' ').trim();
        if (formData && formData.password) postData['password'] = formData.password?.replace(/\s+/g,' ').trim();
        if (formData && formData.mobile) postData['mobile'] = formData.mobile;
        if(formData && formData.status) postData['status'] = formData.status;
        if(formData && formData.id) postData['id'] = formData.id;
        return postData;
    }
    let user_list : any = []
    if (formData && formData.reportee_role_id) {
        user_list = userList.filter((e: any) => {
            return e.assigned_role?.includes(String(formData.reportee_role_id))
        });
    } 

    return (

        <React.Fragment>
            <div className="user-screen-popup user-managent-add-user-popup">
                <Modal handleClose={() => addNewUser(false)} show={showUserRole}>
                    <div className="modal-header">
                        <h2>Add New User</h2>
                    </div>
                    <div className="modal-body" >

                        <div className="row">

                            <div className="add-user-data col-md-12">
                                <Accordion allowZeroExpanded>

                                    <AccordionItem >
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                1. Business Verticle {formData['vertical_id'] &&  <img src={TickIcon} alt="tick"/> }
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>

                                            <ReactSelect options={verticleData ?? []} value={verticleData?.filter((v: any) => formData['vertical_id'] === Number(v.value))} handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)} placeholder="Business Verticle" fieldKey="vertical_id" errorField={errors?.vertical_id} />

                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem >
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                2. Assign Role {formData['assigned_role'] && formData['assigned_role'].length > 0 &&  <img src={TickIcon} alt="tick"/> }
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <fieldset className="form-filed">

                                                <div className="multiselect-dropDown">
                                                    <li className="searchitems selectWidth form-group">
                                                        <MultiSelect
                                                            isSelectAll={true}
                                                            placeholder={"Role"}
                                                            options={role_list}
                                                            value={
                                                                (role_list instanceof Array &&
                                                                    formData.assigned_role &&
                                                                    role_list.filter((e) =>
                                                                        [
                                                                            ...(formData.assigned_role instanceof
                                                                                Array
                                                                                ? formData.assigned_role
                                                                                : []),
                                                                        ].includes(e.value)
                                                                    )) ||
                                                                []
                                                            }
                                                            onChange={(e: any) => handleChange('assigned_role', e)}
                                                        />
                                                    </li>
                                                </div>
                                            </fieldset>
                                        </AccordionItemPanel>
                                    </AccordionItem>


                                    <AccordionItem >
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                3. Reporting {formData['reportee_role_id'] && formData['reportee_id'] &&  <img src={TickIcon} alt="tick"/> }
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>

                                        <ReactSelect options={role_list} value={role_list?.filter((v: any) => formData['reportee_role_id'] === v.value)} handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)} placeholder="Reporting Role" fieldKey="reportee_role_id" key="reportee_role_id" />
                                        
                                        <ReactSelect options={user_list} value={user_list?.filter((v: any) => formData['reportee_id'] === Number(v.value))} handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)} placeholder="Reporting Name" fieldKey="reportee_id" key="reportee_id" errorField={errors?.reportee_id} />

                                            {/* <fieldset className="multiselect-dropDown">
                                                <div className="material">
                                                    <Select
                                                        key={"reportee_role_id"}
                                                        options={role_list}
                                                        onChange={(e: any) => handleChange("reportee_role_id", e)}
                                                        value={role_list?.filter((v: any) => formData['reportee_role_id'] === v.value)}
                                                        placeholder={"Reporting Role"} />
                                                </div>
                                            </fieldset> */}
                                            {/* <fieldset className="multiselect-dropDown">
                                                <div className="material">
                                                    <Select
                                                        key={"dropdown"}
                                                        options={user_list}
                                                        value={user_list?.filter((v: any) => formData['reportee_id'] === Number(v.value))}
                                                        onChange={(e: any) => handleChange("reportee_id", e)}
                                                        placeholder={"Reporting Name"} />
                                                    <span className="error-txt">
                                                        {errors.reportee_id || ""}
                                                    </span>
                                                </div>
                                            </fieldset> */}


                                        </AccordionItemPanel>
                                    </AccordionItem>


                                    <AccordionItem >
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                4. Basic Details {formData['name'] && formData['email'] && formData['password'] && formData['mobile'] &&  <img src={TickIcon} alt="tick"/> }
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <fieldset className="form-filed">
                                                <div className="material">
                                                    <input id="" type="text" placeholder=" " name="Name" className="form-input" value={formData.name || ''} onChange={(e: any) => handleChange("name", e)} />
                                                    <label data-label="Name" className="form-label"></label>
                                                    <span className="error-txt">
                                                        {errors.name || ""}
                                                    </span>
                                                </div>
                                            </fieldset>
                                            <fieldset className="form-filed">
                                                <div className="material">
                                                    <input id="email" type="text" placeholder=" " name="email" className="form-input" value={formData.email || ''} onChange={(e: any) => handleChange("email", e)} />
                                                    <label data-label="Email" className="form-label"></label>
                                                    <span className="error-txt">
                                                        {errors.email || ""}
                                                    </span>
                                                </div>
                                            </fieldset>
                                            <fieldset className="form-filed">
                                                <div className="material">
                                                    <input id="password" type="text" placeholder=" " name="password" className="form-input" value={formData.password || ''} onChange={(e: any) => handleChange("password", e)} />
                                                    <label data-label="Password" className="form-label"></label>
                                                    <span className="error-txt">
                                                        {errors.password || ""}
                                                    </span>
                                                </div>
                                            </fieldset>
                                            <fieldset className="form-filed">
                                                <div className="material">
                                                    {/* <input id="mobileNo" type="number" placeholder=" " name="MobileNumber" className="form-input" value={formData.mobile || ''} onChange={(e: any) => handleChange("mobile", e)} /> */}
                                                    <InputField
                                                    inputProps={{
                                                        id: "mobile",
                                                        name: "mobile",
                                                        className: "form-input",
                                                        type: "text",
                                                        value: formData.mobile ? formData.mobile : '',
                                                        maxLength: "10",
                                                        disabled: false,
                                                        placeholder: " "

                                                    }}
                                                  onChange={(e: any) => handleChange("mobile", e)}
                                                />

                                                    <label data-label="Mobile Number" className="form-label"></label>
                                                    <span className="error-txt">
                                                        {errors.mobile || ""}
                                                    </span>                                
                                                </div>
                                            </fieldset>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                </Accordion>

                            </div>
                            <button className="btn-primary m-md-t" onClick={(e: any) => handleSubmit(e)}>Add User</button>
                        </div>

                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default AddUserPopup