import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { APP_TYPE_WITH_UCF, APP_TYPE_STANDALONE, FIVE_YEAR, SIX, TWO_MONTH, APP_TYPE_RENEWAL, SIXTY_DAYS, SEVEN_DAYS} from "@src/config/constant";

function Calendra(props:any) {
    const startDate = null;

    return (
        <div className="datepicker">
            <DatePicker
                selected={props.selected ? new Date(props.selected) : startDate}
                onChange={(date: any) => props.onChange(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="--/ --/ --"
                minDate={props.minDate ? new Date(props.minDate) : null}
            />
            {/* <label data-label="Date/Time" className="form-label"></label> */}
        </div>
    );
}

function Calendra2(props: any) {
    // Initialize variables for start date, today's date, maximum date, and minimum value.
    const startDate = null;
    const today = new Date();
    let maxDate:any = null;
    let minValue:any = null;
    
    // Set minimum and maximum dates for the finance_from_date field in UCF case.
    if (props?.fieldname === "finance_from_date" && +props?.apptype === APP_TYPE_WITH_UCF) {
        // Set max date to two months in the future
        maxDate = new Date(today.getFullYear(), today.getMonth() + TWO_MONTH, today.getDate());
        // Set min date to two months ago
        minValue = new Date(today.getFullYear(), today.getMonth() - TWO_MONTH, today.getDate());
    } else if (props?.fieldname === "finance_to_date" && +props?.apptype === APP_TYPE_WITH_UCF) {
        // If the field is finance_to_date and the application type is 1 (UCF),
        // adjust the minimum date based on the value of the finance_from_date field.
        if (props?.fields) {
            const fromDateField = props.fields.find((field: any) => field.label === "Financing From Date");
            if (fromDateField && fromDateField.value) {
                const fromDate = new Date(fromDateField.value);
                // Set min date to 6 months after the Financing From Date
                minValue = new Date(fromDate.getFullYear(), fromDate.getMonth() + SIX, fromDate.getDate());
            }
        }
    }
    // Set minimum financing from date to past 5 years, and financing to date = financing from date + 5 years for standalone case.
    else if (props?.fieldname === "finance_from_date" && [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(+props?.apptype)) {
        // Set min date previous 5 year
        minValue = new Date(today.getFullYear() - FIVE_YEAR, today.getMonth(), today.getDate());
        maxDate = new Date();
    }
    else if (props?.fieldname === "finance_to_date" && [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(+props?.apptype)) {
    
        // If the field is finance_to_date and the application type is 2 (standalone),
        // adjust the minimum date based on the value of the finance_from_date field.
        if (props?.fields) {
            const fromDateField = props.fields.find((field: any) => field.name === "finance_from_date");
            if (fromDateField && fromDateField.value) {
                const fromDate = new Date(fromDateField.value);
                // Set min date  finance_from_date + 5 years
                maxDate = new Date(fromDate.getFullYear() + FIVE_YEAR, fromDate.getMonth(), fromDate.getDate());
                minValue = new Date (fromDate);
            }
        }
    } else if (["Insurance Policy Expiration Date", 'Existing Policy Expiry Date'].includes(props?.fieldLabel)) {
       // Set minimum date to today + 1 day
       const tomorrow = new Date(today);
       tomorrow.setDate(today.getDate() + 1);
       minValue = tomorrow;
    }
    else if(props.minDate){
        minValue = new Date(props.minDate);
    }
    else if(["Customer DOB", 'DOB'].includes(props?.fieldLabel)){
       // Date selected only more than 18 yr
        maxDate = new Date(today.getFullYear() - 17, today.getMonth(), today.getDate()); 
    }
    
    
    return (
        <div className="datepicker">
            <DatePicker
                onChange={(date : any) => props.onChange(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              //  placeholderText={props.placeholder ? props.placeholder :'--/ --/ --'}
                selected={props.selected ? new Date(props.selected) : startDate}
                disabled={props.disabled || false}
                minDate = {minValue}    
                maxDate = {maxDate}
                dateFormat={props.dateFormat ? props.dateFormat : 'mm/dd/yyyy'}
            />
            <label data-label={props.placeholder ? props.placeholder: "Date/Time"} className="form-label"></label>
        </div>
    );
}


function DobCalendar(props: any) {
    const [startDate, setStartDate] = useState(null);
   
    const today = new Date();
    // Calculate the minimum allowed date , if date is less than 18 yr then it is disabled
    const minDate = new Date(today.getFullYear() - 17, today.getMonth(), today.getDate()); 

    const isDateDisabled = (date:any) => {
        return date < minDate;
      };
    
    return (
        <div className="datepicker">
            <DatePicker
                onChange={(date:any) => {
                    if (date <= minDate) {
                      setStartDate(date);
                      props.onChange(date);
                    } else {
                      setStartDate(null); // Clear the date if it's invalid
                    }
                  }}
                dateFormat={props.dateFormat ? props.dateFormat :''}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText={props.placeholder ? props.placeholder :'--/ --/ --'}
                selected={props.selected ? new Date(props.selected) : startDate}
                filterDate={isDateDisabled} 
                maxDate={minDate}
                disabled={props.disabled ? props.disabled :false } 

            />
        </div>
    );
}


function InsuranceDate(props: any) {
    const [startDate, setStartDate] = useState(props.insurancePolicyDate ? props.insurancePolicyDate :null);
    const [minTime, setMinTime] = useState(undefined)
    const [maxTime, setMaxTime] = useState(undefined)
    //freeze future time
    const currentDate = moment();
    const previousDate = moment().startOf('day');

    let isPreviousDate = moment(startDate).isBefore(currentDate, 'day');
    if(props.disableMaxDate) {
        isPreviousDate = true // to disable min/max Time
    }
    useEffect(()=>{
        if(props.insurancePolicyDate !== startDate){
            setStartDate(props.insurancePolicyDate);
        }
        handleMinMaxTime()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.insurancePolicyDate]);


    const handleMinMaxTime = () => {
        let minTime: any = undefined;
        let maxTime: any = undefined;

        if (!props.enableAllDates && props.disableMaxDate && (!props.insurancePolicyDate || moment(props.insurancePolicyDate).isSame(currentDate, 'day'))) {
            minTime = moment().add(1, 'minute').toDate();
            maxTime = moment().endOf('day').toDate();

        } else if ((props.disableMaxDate && !moment(props.insurancePolicyDate).isSame(currentDate, 'day')) || props.enableAllDates) {
            minTime = undefined;
            maxTime = undefined;
        }
        else if (!isPreviousDate) {
            minTime = previousDate.toDate();
            maxTime = currentDate.toDate();
        }
        setMinTime(minTime)
        setMaxTime(maxTime)
    }

    const handleDateChange = (date: any) => {
        let finalisedDate = date
        if (!props.enableAllDates) {
            //case: max date selection
            if (!props.disableMaxDate) {
                let isAfter = moment(date).isAfter(currentDate)
                finalisedDate = isAfter ? moment(minTime).startOf('hours').toDate() : date
            }
            //case: min date selection
            if (props.minDate) {
                let isBefore = moment(date).isBefore(currentDate)
                finalisedDate = isBefore ? moment(minTime).startOf('hour').add(1, 'hour').toDate() : date
            }
        }
        setStartDate(finalisedDate);
        props.onChange(finalisedDate);
    }
    
    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date: any) => handleDateChange(date)}
                dateFormat="dd MMM, yyyy h:mm aa"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTimeSelect={true}
                placeholderText=""
                minTime= {minTime}
                maxTime={maxTime}
                maxDate={(props.disableMaxDate || props.enableAllDates) ? undefined : currentDate.toDate()}
                minDate={props.minDate ? props.minDate : undefined}
                disabled={props.disabled || false} 
            />
         <label title={props.installment ? props.text : ''} data-label={props.text} className={`form-label datepick-heading ${props.installment ? "label-txt" : ""}` }></label>    
        </div>
    );
}


function ExpiryEffectivityDate(props: any) {
    const [startDate, setStartDate] = useState(props.insurancePolicyDate ? props.insurancePolicyDate : null);
    const [minDate, setMinDate] = useState(props.minDate ?? undefined)
    const [maxDate, setMaxDate] = useState(props.maxDate ?? undefined)
    const [minTime, setMinTime] = useState(undefined)
    const [maxTime, setMaxTime] = useState(undefined)

    const currentDate = moment();

    useEffect(() => { 
        handleTime() 
        setMinDate(props.minDate)
        setMaxDate(props.maxDate)
        setStartDate(props.insurancePolicyDate ? props.insurancePolicyDate : null ) //(props.minDate ? props.minDate: null))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleTime = () => {
        let minTime: any = undefined;
        let maxTime: any = undefined;

        let isCurrDate = props.minDate ? (moment(props.minDate).isSame(currentDate, 'day') ? true : false) : false

        if((isCurrDate && (!props.insurancePolicyDate || moment(props.insurancePolicyDate).isSame(currentDate, 'day')))) {
            minTime = moment().add(1, 'minute').toDate();
            maxTime = moment().endOf('day').toDate();
        }
        
        setMinTime(minTime)
        setMaxTime(maxTime)
    }


    const handleDateChange = (date: any) => {
        if(!(props.minDate && moment(props.minDate).isSame(new Date(0), 'date'))) {
            let isBefore = moment(date).isBefore(currentDate)
            let finalisedDate = isBefore && props.flag !== "effectivity_date" ? moment(date).startOf('hour').toDate() : date
            setStartDate(finalisedDate)
            props.onChange(finalisedDate);
        }
    }
    

    return (
        <div className="datepicker">
            <DatePicker
                selected={props.flag === "expiry_date" ? startDate || moment(props.effectivity_date).add(1, 'year').toDate() : startDate}
                onChange={(date: any) => {
                    handleDateChange(date)
                }}
                dateFormat="dd MMM, yyyy h:mm aa"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTimeSelect={true}
                placeholderText=""
                minTime={minTime}
                maxTime={maxTime}
                maxDate={maxDate}
                minDate={minDate}
                disabled={props.disabled || false} 
            />
         <label title={''} data-label={props.text} className={`form-label datepick-heading`}></label>    
        </div>
    );
}


function ExpectedInstallmentDate(props: any) {
    const [minDate, setMinDate] = useState<any>(null);

    const handleInstallmentDates = () => {
        let minDate = null

        let { installmentNumber, prevInstallmentDate, minDateAgency } = props
        if (installmentNumber === 1) {
            // 60 days only for details section, and during add lead  7 days subtract in min Date
            if (props?.source) {
                minDate = moment().subtract(SIXTY_DAYS, 'd').toDate();
            } else {
                minDate = moment().subtract(SEVEN_DAYS, 'd').toDate();
            }

            //OVERRIGHT FOR AGENCY
            if(minDateAgency){
                minDate = minDateAgency
            }
        } else {
            minDate = prevInstallmentDate ? moment(prevInstallmentDate).add(1, 'd').toDate() : null
        }
        return  minDate
    }

    useEffect(() => {
        const minDate: any = handleInstallmentDates()
        setMinDate(minDate)
        // eslint-disable-next-line
    }, [props])
    return (
        <div className="datepicker">
            <DatePicker
                onChange={(date: any) => props.onChange(date)}
                dateFormat={props.dateFormat ? props.dateFormat : ''}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText=""
                selected={props.selected ? new Date(props.selected) : null}
                minDate={minDate}
                disabled={props.disabled ? props.disabled : false}
                showTimeSelect={props.showTimeSelect || false}

            />
            <label title={''} data-label={props.text} className={`form-label datepick-heading label-txt ${props?.['edit-label-width'] ? 'edited-label-txt' : ''}`}></label>
        </div>
    );
}

// export { Calendra, Calendra2, CalendraSimple, StartDateCalendra, EndDateCalendra, DateTimePicker, FollowUpDate, POReceived, SurveyDate, SurveCompletedyDate, Timepicker, RescheduleDate,AcceptanceReceivedDate,PolicyGeneratedDate, InsuranceDate, DobCalendar }
export { Calendra,  InsuranceDate, DobCalendar, Calendra2, ExpiryEffectivityDate, ExpectedInstallmentDate }
