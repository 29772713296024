import ReactSelect from '@common/ReactSelect';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMasterConfig, getDealesList } from '@src/store/slices/common';
import { Calendra2 } from '@common/DatePicker';
import InputField from '@common/InputField';
import { CLIENT_REFERRAL, AGENT, DSA, APP_TYPE_STANDALONE, MARKETING, APP_TYPE_RENEWAL, APP_TYPE_AGENCY } from '@src/config/constant';


export default function OtherDetails(props: any) {
    let { otherDetails, errors } = props;
    const commonState = useSelector((state: any) => state.common);
    const insuranceState = useSelector((state: any) => state.insurance);
    const { application_type_id } = insuranceState?.leadDetail || {}
    const dispatch: any = useDispatch();
    const [insuranceLeadSource, setInsuranceLeadSource] = useState(otherDetails?.insurance_lead_source);
    // eslint-disable-next-line
    const [paidByCustomer, setPaidByCustomer] = useState(otherDetails?.paid_by_customer);

    const changeHandler = (value: any, key: string) => {
        let formFields: any = otherDetails;
        if (key === "financing_from_date") {
            // Reset "Financing Till Date" when "Financing From Date" is changed
            formFields = { ...formFields, financing_to_date: null, [key]: value ? new Date(value).toISOString() : '' };
        }
        else if (["financing_from_date", "financing_to_date", "insurance_expire_date"].includes(key)) {
            formFields = { ...formFields, [key]: value ? new Date(value).toISOString() : '' }
        } else if (["is_under_financing", "payment_method_id", "payment_term_id", "copy_of_insurance", "insurance_exists", "financier_id", "lead_source", "other_detail_dealer_id", "lead_sub_source", "paid_by_customer", "paid_to_whom", "paid_from_agency"].includes(key)) {
            formFields = { ...formFields, [key]: value.value }
        } else if (key === "insurance_lead_source") {
            //  reset agent/ client referal vice versa
            if (+insuranceState?.leadDetail?.insurance_lead_source === +value.value) {
                formFields = { ...formFields, [key]: value.value, referrer_name: insuranceState?.leadDetail?.referrer_name, referrer_mobile: insuranceState?.leadDetail?.referrer_mobile }
            } else {
                formFields = { ...formFields, [key]: value.value, referrer_mobile: "", referrer_name: "", lead_sub_source: null }
            }
            setInsuranceLeadSource(value.value);
        }
        else {
            formFields = { ...formFields, [key]: value?.target?.value }
        }
        props.changeHandler(formFields, "other-details");
    }

    useEffect(() => {
        if (Object.keys(commonState?.common).length === 0) {
            dispatch(getMasterConfig());
        }
        if (Object.keys(commonState?.getDealerList).length === 0) {
            dispatch(getDealesList());
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <h2>Other Details</h2>
            <div className="row">
                <ReactSelect
                    options={commonState?.common?.YesAndNo}
                    value={commonState?.common?.YesAndNo?.find((v: any) => otherDetails?.is_under_financing === v.value) || {}}
                    placeholder={"Car Under Financing*"}
                    isDisabled={false}
                    handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="is_under_financing"
                    fieldSetClassName="col-md-6"
                    errorField={errors.is_under_financing}
                />
                {
                    otherDetails?.is_under_financing === 1
                        ?
                        <>
                            <ReactSelect
                                options={commonState?.common?.financier}
                                value={commonState?.common?.financier?.find((e: any) => e.value === otherDetails?.financier_id)}
                                placeholder={`Financier Name ${application_type_id !== APP_TYPE_AGENCY ? '*' : ''}`}
                                isDisabled={false}
                                handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="financier_id"
                                fieldSetClassName="col-md-6"
                                errorField={errors.financier_id}
                            />
                            {
                                application_type_id !== APP_TYPE_AGENCY
                                    ?
                                    <>
                                        <fieldset className="col-md-6 date-picker-detail">
                                            <div className={"material animation-effect" + (otherDetails.financing_from_date ? ' active-label' : '')}>
                                                <Calendra2
                                                    onChange={(e: Date) => changeHandler(e, 'financing_from_date')}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={otherDetails?.financing_from_date}
                                                    fieldLabel={"Financing From Date"}
                                                    placeholder={"Financing From Date*"}
                                                    fieldname={"finance_from_date"}
                                                    apptype={insuranceState?.leadDetail?.application_type_id}
                                                />
                                                {
                                                    errors?.financing_from_date ?
                                                        <span className="error-txt">{errors.financing_from_date}</span>
                                                        : null
                                                }
                                            </div>
                                        </fieldset>
                                        <fieldset className="col-md-6 date-picker-detail">
                                            <div className={"material animation-effect" + (otherDetails.financing_to_date ? ' active-label' : '')}>
                                                <Calendra2
                                                    onChange={(e: Date) => changeHandler(e, 'financing_to_date')}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={otherDetails?.financing_to_date}
                                                    fieldLabel={"Financing Till Date"}
                                                    placeholder={"Financing Till Date*"}
                                                    // this props pass as it is, beacuse of condition satisfy the logic for till date
                                                    fields={[{ label: 'Financing From Date', value: otherDetails?.financing_from_date, name: 'finance_from_date' }]}
                                                    fieldname={"finance_to_date"}
                                                    apptype={insuranceState?.leadDetail?.application_type_id}
                                                />
                                                {
                                                    errors?.financing_to_date ?
                                                        <span className="error-txt">{errors.financing_to_date}</span>
                                                        : null
                                                }
                                            </div>
                                        </fieldset>
                                    </>
                                    : null
                            }
                        </>
                        :
                        null
                }

                <ReactSelect
                    options={commonState?.common?.YesAndNo}
                    value={commonState?.common?.YesAndNo?.find((v: any) => otherDetails?.insurance_exists === v.value) || {}}
                    placeholder={"Insurance Policy Exists*"}
                    isDisabled={insuranceState?.leadDetail?.application_type_id === APP_TYPE_RENEWAL ? true : false}
                    handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="insurance_exists"
                    fieldSetClassName="col-md-6"
                />
                {
                    otherDetails?.insurance_exists === 1
                        ?
                        <>
                            <fieldset className="col-md-6">
                                <div className="material" title={otherDetails?.insurer_name}>
                                    <InputField
                                        inputProps={{
                                            id: "insurer_name",
                                            name: "insurer_name",
                                            className: "form-input",
                                            type: "text",
                                            value: otherDetails?.insurer_name || '',
                                            maxLength: "",
                                            disabled: insuranceState?.leadDetail?.application_type_id === APP_TYPE_RENEWAL ? true : false,
                                            placeholder: " "
                                        }}
                                        onChange={(e: any) => changeHandler(e, 'insurer_name')}
                                    />
                                    <label data-label={`Insurance Policy Insurer ${application_type_id !== APP_TYPE_AGENCY ? '*' : ''}`} className="form-label"></label>
                                    {
                                        errors?.insurer_name ?
                                            <span className="error-txt">{errors.insurer_name}</span>
                                            : null
                                    }
                                </div>
                            </fieldset>
                            <fieldset className="col-md-12 date-picker-detail">
                                <div className={"material animation-effect" + (otherDetails?.insurance_expire_date ? ' active-label' : '')}>
                                    <Calendra2
                                        onChange={(e: Date) => changeHandler(e, 'insurance_expire_date')}
                                        dateFormat="dd/MM/yyyy"
                                        selected={otherDetails?.insurance_expire_date}
                                        fieldLabel={"Insurance Policy Expiration Date"}
                                        placeholder={`Existing Policy Expiry Date ${application_type_id !== APP_TYPE_AGENCY ? '*' : ''}`}
                                        disabled={insuranceState?.leadDetail?.application_type_id === APP_TYPE_RENEWAL ? true : null} />
                                    {
                                        errors?.insurance_expire_date ?
                                            <span className="error-txt">{errors.insurance_expire_date}</span>
                                            : null
                                    }
                                </div>
                            </fieldset>
                        </>
                        :
                        null
                }

                {
                    application_type_id !== APP_TYPE_AGENCY
                        ?
                        <ReactSelect
                            options={commonState?.common?.lead_source}
                            value={commonState?.common?.lead_source?.find((v: any) => +insuranceLeadSource === v.value) || {}}
                            placeholder={"Lead Source*"}
                            isDisabled={insuranceState?.leadDetail?.application_type_id === APP_TYPE_RENEWAL ? true : false}
                            handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="insurance_lead_source"
                            fieldSetClassName="col-md-6"
                        />
                        :
                        null
                }

                {
                    otherDetails?.insurance_lead_source === MARKETING && <ReactSelect
                        options={commonState?.common?.lead_sub_source}
                        value={commonState?.common?.lead_sub_source?.find((v: any) => +otherDetails?.lead_sub_source === +v.value) || null}
                        placeholder={"Marketing Lead Category*"}
                        isDisabled={false}
                        handleChange={(e: any) => changeHandler(e, 'lead_sub_source')} fieldKey="lead_sub_source"
                        fieldSetClassName="col-md-6"
                        errorField={errors['lead_sub_source']}
                    />
                }
                {/* If Lead source is Client referal || Agent then it show two more fileds */}
                {(otherDetails?.insurance_lead_source === CLIENT_REFERRAL || otherDetails?.insurance_lead_source === AGENT || otherDetails?.insurance_lead_source === DSA) &&
                    (
                        <>
                            <fieldset className="col-md-6">
                                <div className="material" title={otherDetails?.insurer_name}>
                                    <InputField
                                        inputProps={{
                                            id: "referrer_name",
                                            name: "referrer_name",
                                            className: "form-input",
                                            type: "text",
                                            value: otherDetails?.referrer_name || '',
                                            maxLength: "",
                                            disabled: false,
                                            placeholder: " "
                                        }}
                                        onChange={(e: any) => changeHandler(e, 'referrer_name')}
                                    />
                                    {/* <label data-label="Referrer Name*" className="form-label"></label> */}
                                    <label data-label={insuranceLeadSource === CLIENT_REFERRAL
                                        ? "Referrer Name*"
                                        : insuranceLeadSource === DSA
                                            ? "Agency Name*"
                                            : insuranceLeadSource === AGENT
                                                ? "Agent Name*"
                                                : ""} className="form-label"></label>
                                    {
                                        errors?.referrer_name ?
                                            <span className="error-txt">{errors.referrer_name}</span>
                                            : null
                                    }
                                </div>
                            </fieldset>
                        </>
                    )}
                {/* If Lead source is Client referal then it show fileds */}
                {((otherDetails?.insurance_lead_source === CLIENT_REFERRAL) || (otherDetails?.insurance_lead_source === AGENT))
                    &&
                    (
                        <>

                            <fieldset className="col-md-6">
                                <div className="material" title={otherDetails?.referrer_mobile}>
                                    <InputField
                                        inputProps={{
                                            id: "mobile",
                                            name: "referrer_mobile",
                                            className: "form-input",
                                            type: "text",
                                            value: otherDetails?.referrer_mobile || '',
                                            maxLength: 10,
                                            disabled: false,
                                            placeholder: " "
                                        }}
                                        onChange={(e: any) => changeHandler(e, 'referrer_mobile')}
                                    />
                                    <label data-label={+insuranceLeadSource === CLIENT_REFERRAL ? "Referrer Mobile Number*" : "Agent Contact Number*"} className="form-label"></label>
                                    {
                                        errors?.referrer_mobile ?
                                            <span className="error-txt">{errors.referrer_mobile}</span>
                                            : null
                                    }
                                </div>
                            </fieldset>
                        </>
                    )

                }

                {/* If Lead Type is standalone then it show  */}
                {[APP_TYPE_STANDALONE].includes(insuranceState?.leadDetail?.application_type_id) &&
                    <ReactSelect
                        options={commonState.getDealerList}
                        value={commonState.getDealerList?.find((v: any) => otherDetails.other_detail_dealer_id === v.value) || []}
                        placeholder={"Dealer"}
                        isDisabled={false}
                        handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="other_detail_dealer_id"
                        fieldSetClassName="col-md-6"
                    />
                }


                {[APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(insuranceState?.leadDetail?.application_type_id) &&
                    <>
                        <ReactSelect
                            options={commonState?.common?.paid_by_customer}
                            value={commonState?.common?.paid_by_customer?.find((v: any) => +otherDetails.paid_by_customer === +v.value) || []}
                            placeholder={"Paid By Customer*"}
                            isDisabled={+paidByCustomer === 1 ? true : false}
                            handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="paid_by_customer"
                            fieldSetClassName="col-md-6"
                        />
                        {
                            +otherDetails?.paid_by_customer === 1 &&
                            <ReactSelect
                                options={commonState?.common?.paid_to_whom}
                                value={commonState?.common?.paid_to_whom?.find((v: any) => +otherDetails.paid_to_whom === +v.value) || []}
                                placeholder={"Paid to whom*"}
                                isDisabled={+paidByCustomer === 1 ? true : false}
                                handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="paid_to_whom"
                                fieldSetClassName="col-md-6"
                                errorField={errors['paid_to_whom']}
                                />
                        }
                    </>
                }

            </div>
        </div>
    )
}