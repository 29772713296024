import { useEffect, useState } from 'react';
import Modal from '../../common/ModalPopup';
import { toast } from 'react-toastify';
import { addInsurancePartner, editInsurancePartner, togglePartnerKey} from '../../store/slices/partner';
import * as HELPER from '../../config/helper';
import { ROLE_FEATURES } from '@src/config/constant';
import ReactSelect from '@common/ReactSelect';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import InputField from '@common/InputField';
import ToggleSwitch from '../../common/ToggleSwitch';
import ConfirmPopUp from '../../common/ConfirmPopUp';
import { useSelector, useDispatch } from 'react-redux';
import { getMasterConfig } from '@src/store/slices/common';
import _ from 'lodash';
import MultiSelect from '@common/MultiSelect';

type SelectedInputType = {
    insurance_type: any,
    method: any,
    ins_partner_name: string
}


type ApiParamsType = {
    name: string,
    insurance_type_id: number[],
    application_type_id: number[]
}


export default function AddEditInsurancePartner(props: any): ReturnType<React.FC> {
    let { insuranceTypeList, methodList, showAddInsPartnerIcon, handleModalClose, callFrom } = props;
    const [selectedPartner, setSelectedPartner] = useState(props.selectedPartner);
    let [selectedInput, setSelectedInput] = useState<any>({ insurance_type: {}, method: [], ins_partner_name: (selectedPartner && selectedPartner.name) || ''})
    let [logo, setLogo] = useState<File>()
    let [previewImageLogo, setPreviewImageLogo] = useState<string>("images/upload_icn.svg")
    let [errors, setErrors] = useState<any>({})
    const [bankDetails, setBankDetails] = useState(
         selectedPartner?.bank_details?.length > 0 
            ? selectedPartner.bank_details 
            : []
    );

    const [dataToDelete, setDataToDelete] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isChecked, setIsChecked] = useState<any>({});
    const [actionPerform, setActionPerform] = useState('');
    const [activationData, setActivationData] = useState<any>({});
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [bankListName, setBankListName] = useState<any>([]);
    const [isFormValid, setIsFormValid] = useState<any>({});
    const dispatch: any = useDispatch();
    const commonState = useSelector((state: any) => state.common);

    useEffect(()=>{
        if(props?.selectedPartner?.id && (!selectedPartner?.id || (selectedPartner?.id !== props?.selectedPartner?.id) || (JSON.stringify(selectedPartner) !== JSON.stringify(props.selectedPartner)))){
            setSelectedPartner(props.selectedPartner);
            setInputFields(props?.selectedPartner)
        }
        // eslint-disable-next-line
    }, [props.selectedPartner])

    // check all bank mandatary fields is fill or not
    const validateBankDetails = (bankDetails:any) => {
        const formValidations = bankDetails.map((bank:any) => ({
            isValid: bank.bank_name && bank.bank_account_no && bank.beneficiary_name,
        }));
        return formValidations;
    };
    
    /**
     * function to handle change in input field values
     * @param name 
     * @param value 
     */
    const handleChange = (name: string, value: any, index?: number) => {
        if (index !== undefined) {
            setBankDetails((prev: any) => {
                const updatedBankDetails: any = [...prev];
                updatedBankDetails[index][name] = name === 'bank_account_no' || name === 'beneficiary_name' || name === 'branch_name' ? value.target.value : value.value;
                return updatedBankDetails;
            });
           
            const formValidations = validateBankDetails(bankDetails);
            setIsFormValid((prev:any) => ({
                ...prev,
                [index]: formValidations[index].isValid,
            }));

        } else {
            // Handling ins_partner_name
            setSelectedInput((prev: any) => ({
                ...prev,
                [name]: (name === 'ins_partner_name') ? value.target.value : value
            }));
        }
        // Clear errors on change
        if (bankDetails?.length && index !== undefined) {
            setErrors((prevErrors: any) => ({
                ...prevErrors,
                [`bankDetails[${index}].${name}`]: ''
            }));
        }
        else {
            setErrors((prevErrors: any) => ({
                ...prevErrors,
                [`${name}`]: ''
            }));
        }

    }
    
    // for toggle switch checked fetch
    useEffect(() => {
        const initialCheckedState:any = {};
        bankDetails.forEach((bank:any, index:any) => {
            initialCheckedState[`status_${index}`] = +bank.status === 1;
        });
        setIsChecked(initialCheckedState);
    }, [bankDetails]);

    // enable disabled toggle switch base on mandatary filed
    useEffect(() => {
        const formValidations = validateBankDetails(bankDetails);
        const initialFormValidState:any = {};
        formValidations.forEach((validation:any, index:any) => {
            initialFormValidState[index] = validation.isValid;
        });
        setIsFormValid(initialFormValidState);
    }, [bankDetails]);

    // main modal close Xs
    const handleClose = () => {
        handleModalClose()
        setLogo(undefined)
        // setPartnerLogo('');
        setPreviewImageLogo("images/upload_icn.svg")
        setSelectedInput({
            insurance_type: {},
            method: [],
            ins_partner_name:
                (selectedPartner && selectedPartner.name) || ''
        })
        setErrors({})
        //when modal close from add, only than value reset otherwise not
        if(callFrom === 'AddInsurancePartner'){
            setBankDetails([]);
        }
        setSelectedPartner({})
        setInputFields({})
        
    }


    /**
     * function to validate input fields before submittingI
     * @returns boolean
     */
    const checkValidation = (): boolean => {
        let error = false
        setErrors({})
        
        for (let key in selectedInput) {
            let keytype = typeof (selectedInput[key as keyof SelectedInputType])

            if (keytype === 'object' && !Object.keys(selectedInput[key as keyof SelectedInputType]).length) {
                setErrors((prev: any) => {
                    return {
                        ...prev,
                        [key]: true
                    }
                })
                error = true
            }

            if (keytype === 'object' && Array.isArray(selectedInput[key]) && !selectedInput[key]?.length) {
                setErrors((prev: any) => {
                    return {
                        ...prev,
                        [key]: true
                    }
                })
                error = true
            }

            if (keytype === 'string' && !selectedInput[key as keyof SelectedInputType]) {
                setErrors((prev: any) => {
                    return {
                        ...prev,
                        [key]: true
                    }
                })
                error = true
            }
        }
        //check if file is selected
        if (callFrom && callFrom === 'AddInsurancePartner' && !logo) {
            setErrors((prev: any) => {
                return {
                    ...prev,
                    file: true
                }
            })
            error = true
        }

        // Loop over bankDetails array for validation
        bankDetails.forEach((bankDetail: any, index: number) => {
            for (let key in bankDetail) {
                let keyType = typeof bankDetail[key as keyof typeof bankDetail];

                if (keyType === 'string'  && key !== 'branch_name' && !bankDetail[key as keyof typeof bankDetail]) {
                    setErrors((prev: any) => ({
                        ...prev,
                        [`bankDetails[${index}].${key}`]: true
                    }));
                    error = true;
                }
            }
        });


        return error
    }

    /**
     * handles file input
     * @param e 
     */
    const handleFileInputChange = async (e: any) => {
        let selectedFile = e.target.files[0]

        if (selectedFile) {
            let base64PreviewImg: any = await HELPER.toBase64(selectedFile)
            let filetype = await HELPER.detectMimeType(base64PreviewImg)
            let fileSize = selectedFile.size


            if (!filetype) {
                toast.error("Please Select A Valid File")
                e.target.value = ''
                return
            }

            else if (fileSize < 25000 || fileSize > 5000000) {
                toast.error('file size should be between 25kb to 5mb')
                e.target.value = ''
                return
            }
            setLogo(selectedFile)
            setPreviewImageLogo(base64PreviewImg)
        }
       
        setErrors((prevErrors: any) => ({
            ...prevErrors,
            file: ''
        }));


    }

    /**
     * function to submit api request
     *  validates request and calls service according to callFrom flag
     */
    const handleSubmit = async () => {
        let errorExists = checkValidation()
         //if validation error
        if (errorExists) {
            // toast error message because when bank detail not expand in that case it will show
            toast.error('Please ensure all partner and bank details are completed.')
            return
        }


        //CHECK UNIQUNESS
        const bankDetailsUnique = bankDetails.map((bank:any)=> { return { bank_account_no: bank.bank_account_no}})
        const uniqueBanks = _.uniqWith(bankDetailsUnique, _.isEqual);

        if(bankDetails.length && (uniqueBanks.length !== bankDetails.length)){
            toast.error("Bank details should not be same")
            return
        }

        //preparing form data
        let formData = new FormData()
        let apiParams: any = {
            name: selectedInput.ins_partner_name,
            insurance_type_id: selectedInput.insurance_type.value === 'both' ? [1, 2] : [selectedInput.insurance_type.value],
            application_type_id: selectedInput?.method?.map((ele: any) => +ele.value) || [],
            bankDetails: JSON.stringify(bankDetails.map((val: any) => {
                return {
                    id: val.id,
                    bank_account_no: val.bank_account_no,
                    bank_name: val.bank_name,
                    branch_name: val.branch_name,
                    beneficiary_name: val.beneficiary_name,
                    is_deleted: val.is_deleted,
                    partner_id: val.partner_id,
                    status: val.status,
                }
            }))
        }

        for (let key in apiParams) {
            let value = apiParams[key as keyof ApiParamsType]

            if (typeof (value) == 'object') {
                formData.append(key, JSON.stringify(value))
            }
            else {
                formData.append(key, value)
            }
        }

        let saveResponse: any;
        let savePartnerMsg: string = "";

        if (callFrom && callFrom === 'AddInsurancePartner') {
            props.handleLoader(true)

            if (logo) formData.append('logo', logo)

            const addPartnerMapping: any = await addInsurancePartner(formData)
            saveResponse = addPartnerMapping?.data?.[0] || {} 
            savePartnerMsg = 'Partner Created Successfully';
            // if (addPartnerMapping && addPartnerMapping.status === 200) {
            //     // callFrom = "EditInsurancePartner";
            //     toast.success('Partner Created Successfully')
            //     // props.handleModalClose()
            //     setPreviewImageLogo('images/upload_icn.svg')
            //     setLogo(undefined)
            //     // set input field when value save successfully, then show in input field
            //     setSelectedInput((prev:any) => {
            //         return {
            //             ...prev,
            //             method: {
            //                 value: (["1,2", "2,1"].includes(addPartnerMapping.data[0].application_type_id)) ? 3 :addPartnerMapping.data[0].application_type_id ?? {},
            //                 label: (["1,2", "2,1"].includes(addPartnerMapping.data[0].application_type_id)) ? "Both" : addPartnerMapping.data[0].application_type ?? ''
            //             },
            //             insurance_type: {
            //                 value: (["1,2", "2,1"].includes(addPartnerMapping.data[0].insurance_type_id)) ? 3 : addPartnerMapping.data[0].insurance_type_id ?? {},
            //                 label: (["1,2", "2,1"].includes(addPartnerMapping.data[0].insurance_type_id)) ? "Both" : addPartnerMapping.data[0].insurance_type ?? ''
            //             },
            //             ins_partner_name: addPartnerMapping.data[0].name ?? ''
            //         }
            //     })
            //     setBankDetails(addPartnerMapping.data[0].bank_details || []);
            //     // setPartnerLogo(addPartnerMapping.data[0].logo);
               
            //     // props.fetchPartnerMappings({})

            //     setSelectedPartner(addPartnerMapping.data[0])
            //     // setBankDetails([]);
            // }

            // props.handleLoader(false)
        }

        else if ((callFrom && callFrom === 'EditInsurancePartner')) {
            props.handleLoader(true)
            savePartnerMsg = 'Data Updated Successfully';

            //ediiting formdata
            formData.delete('name')
            formData.append('partner_id', selectedPartner.partner_id)

            const updatePartnerMapping: any = await editInsurancePartner(formData)
            saveResponse = updatePartnerMapping?.data?.[0] || {} 
            

        }

        if (saveResponse && Object.keys(saveResponse).length) {
            toast.success(savePartnerMsg)
            // set input field when value save sucesfully, then show in input field
            const selectedMethods = saveResponse?.application_type_id?.length ? saveResponse?.application_type_id?.split(',') : []
            setSelectedInput((prev:any) => {
                return {
                    ...prev,
                    method: methodList?.filter((e: any) => {
                        if(selectedMethods.includes("" + e.value)) return true
                        return false
                    }),
                    insurance_type: {
                        value: (["1,2", "2,1"].includes(saveResponse.insurance_type_id)) ? "both" : +saveResponse.insurance_type_id ?? {},
                        label: (["1,2", "2,1"].includes(saveResponse.insurance_type_id)) ? "Both" :saveResponse.insurance_type ?? ''
                    },
                    ins_partner_name: saveResponse.name ?? ''
                }
            })
           
            setSelectedPartner(saveResponse)
            setBankDetails(saveResponse.bank_details || []);

            props.updateSelectedPartner(saveResponse)
        }

        props.handleLoader(false)


    }

    /**
     * Function to auto fill input fields in edit mode based on selected Partner
     * @param selectedPartner 
     */
    const setInputFields = (selectedPartner: any) => {
        let apptypeId = selectedPartner.application_type_id || []
        apptypeId = apptypeId?.length ? apptypeId?.split(',') : []
        let insuranceTypeId = selectedPartner.insurance_type_id ?? ''

        let method: any = []
        let insuranceType: any = []

        if (apptypeId) {
            method = methodList && methodList.filter((e: any) => {
                if (apptypeId.includes(""+e.value)) {
                    return true
                }
                return false
                // else if (e.value === 'both' && (apptypeId === '1,2' || apptypeId === '2,1')) {
                //     return e
                // }
                // return null

            })
        }

        if (insuranceTypeId) {
            insuranceType = insuranceTypeList && insuranceTypeList.filter((e: any) => {
                if (e.value === +insuranceTypeId) {
                    return e
                }
                else if (e.value === 'both' && (insuranceTypeId === '1,2' || insuranceTypeId === '2,1')) {
                    return e
                }
                return null
            })
        }
        
        setSelectedInput((prev:any) => {
            return {
                ...prev,
                method,
                insurance_type: insuranceType[0] ?? {},
                ins_partner_name: (selectedPartner && selectedPartner.name) || ''
            }
        })
    }


    // update bank details when selectedPartner changes
    useEffect(() => {
        if (selectedPartner && selectedPartner.bank_details) {
            setBankDetails(selectedPartner.bank_details);
        }
        // eslint-disable-next-line
    }, [selectedPartner]);

    // initialize the checked state for each bank based on its status
    useEffect(() => {
        const initialCheckedState:any = {};
        bankDetails.forEach((bank:any, index:any) => {
          initialCheckedState[`status_${index}`] = +bank.status === 1;
        });
        setIsChecked(initialCheckedState);
      }, [bankDetails]);
    

    const handleToggleSwitch = async (key: string, value: boolean, data:any) => {
        const updatedData = { ...data, status: value ? 1 : 0 };
        setActionPerform('activation');
        setActivationData({ key, value, data: updatedData });
        setConfirmationMessage(`Are you sure you want to ${value ? 'activate' : 'deactivate'} this record?`);
        setShowConfirmation(true);
    }

    const addNewBank = () => {
        if (bankDetails?.length < 5) {
            setBankDetails([...bankDetails, { bank_name: '', bank_account_no: '', branch_name: '', beneficiary_name: '' }]);
        }
        else{
            toast.error('Cannot add more than 5 banks')
        }
    };
  
    const showDeletePopUp = async (data: any) => {
        setActionPerform('is_deleted');
        setDataToDelete(data);
        setConfirmationMessage('Are you sure you want to delete this record?');
        setShowConfirmation(true);
    }

    // handle the 'No' action in the confirmation modal
    const confirmNo = () => {
        setShowConfirmation(false);
    };

    // handle the 'Yes' action in the confirmation modal
    const confirmYes = async () => {
        let obj:any = {};

        if (dataToDelete) {
            // await handleDelete();

             // Remove the item from the state immediately
            const updatedBankDetails = bankDetails.filter((bank:any) => bank !== dataToDelete);
            setBankDetails(updatedBankDetails);

            obj.action = actionPerform
            obj.data = dataToDelete
            obj.data.partner_id = selectedPartner?.partner_id
            obj.data.is_deleted = 1;
            
        } else {
            const { key, value, data } = activationData;
            setIsChecked((prevState:any) => ({ ...prevState, [key]: value }));
            let bankDetailIndex = key.split('_');
            setBankDetails((prev: any)=>{
                return prev.map(( bank: any, key: number)=>{ return (key === +bankDetailIndex[1]) ? {...bank, status: +value} : bank })
            });

            obj = { data ,action: actionPerform};
        }

        if(obj?.data?.id){

            togglePartnerKey(obj).then((res: any) => {
                if (res && res.status === 200) {
                    setBankDetails((prev:any)=>{
                        return prev.map((bank: any)=>{ return (bank.id === res.data.id) ? {...bank, status: res.data.status, is_delete: res.data.is_delete} : bank })
                    })
                    toast.success(res.message)         
                }
            });
        }
        //for updating the api after delete the record

        confirmNo(); 
    };
    

    useEffect(() => {
        if (Object.keys(commonState?.common).length === 0) {
            dispatch(getMasterConfig()).then((res: any) => {
                if (res && res.status === 200) {
                    let data: any = res.data || []
                   setBankListName(data?.bank_name);         
                }
            });
        }else{
            setBankListName(commonState?.common?.bank_name);
        }
         // eslint-disable-next-line
    }, []);  
    return (
        <div className="Add-Financer-popup bankdetail-popup">
            <Modal show={showAddInsPartnerIcon ?? false} handleClose={handleClose} >
                <div className="modal-header">

                    <h2>{!selectedPartner?.id ? "Add New Insurance Partner" : "Edit Insurance Partner"}</h2>
                </div>

                <div className="modal-body" >

                    <div className="row">
                        <div className='col-md-6'>
                            <div className='row'>
                                <fieldset className="form-select-bx col-md-12 w-100">
                                    <div className="material">
                                        <input id="" type="text" placeholder=" " name="insurance_partner_name" className="form-input" disabled={((callFrom && callFrom === 'EditInsurancePartner') || selectedPartner?.id) ? true : false} onChange={(e: any) => handleChange('ins_partner_name', e)} value={callFrom === 'EditInsurancePartner' ? (selectedPartner && selectedPartner.name) : selectedInput['ins_partner_name']} />
                                        <label data-label="Insurance Partner Name *" className="form-label"></label>
                                        {
                                            errors && errors.ins_partner_name
                                                ?
                                                <span className="error-txt">{"This field is Required"}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>
                                

                                <div className='form-select-bx col-md-12 partner-add-edit'>
                                    <ReactSelect options={insuranceTypeList ?? []} value={Object.keys(selectedInput['insurance_type']).length ? selectedInput['insurance_type'] : ''} handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)} placeholder="Insurance Type *" fieldKey="insurance_type" errorField={errors?.insurance_type ? "This field is required" : ''} />
                                </div>
                                <div className='form-select-bx col-md-12 multiselect-dropDown m-md-t'>
                                    {/* <ReactSelect options={methodList ?? []} value={Object.keys(selectedInput['method']).length ? selectedInput['method'] : ''} handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)} placeholder="Method *" fieldKey="method" errorField={errors?.method ? "This field is required" : ''} /> */}
                                    <div className={selectedInput?.['method']?.length ? "active-label material animation-effect" : "material animation-effect "}>
                                        <MultiSelect
                                            key={"method"}
                                            options={methodList ?? []}
                                            value={selectedInput['method'] || []}
                                            onChange={(e: any) => handleChange('method', e)}
                                            isSelectAll={true}
                                            placeholder={""}
                                            fieldKey="method"
                                            errorField={errors?.method ? "This field is required" : ''}
                                        />
                                        <label data-label={'Method *'} className="form-label"></label>
                                        {errors?.method ? <span className="error-txt">This field is required</span> : null}
                                    </div>

                                </div>

                            </div>


                        </div>
                        <div className="img-bx-select-opt col-md-6">

                            <div className="image-bx more-file-upload add-insurance-partner">
                                {((callFrom && callFrom === 'EditInsurancePartner') || selectedPartner?.logo) ?
                                    <img src={(selectedPartner && selectedPartner?.logo) || ''} alt='partner_logo' className="upload-icn" />
                                    :
                                    <>
                                        {selectedPartner?.logo
                                            ?
                                            <img src={selectedPartner?.logo || ''} alt='partner_logo' className="upload-icn" />
                                            :
                                            <img src={(previewImageLogo || selectedPartner?.logo)} alt={"uploadImage"} className="upload-icn" />
                                        }
                                       
                                        <input
                                            type="file"
                                            name="image"
                                            id="upload-image-input"
                                            className="upload-box"
                                            accept="image/*"
                                            onChange={(e: any) => handleFileInputChange(e)} />
                                        <p>Upload Photo</p>

                                    </>
                                }
                                <label data-label="Insurance Partner Logo *" className="form-label insurance-partner-logo">Insurance Partner Logo *</label>

                                {
                                    errors && errors.file
                                        ?
                                        <span className="error-txt error-left">{"Please select a file"}</span>
                                        :
                                        ''
                                }
                            </div>

                        </div>
                    </div>
                 
                    <div className="insuranc-partner-accordion">
                        <div className="lead-form-filed send-financer">
                            <div className="financer-doc-list">
                                {bankDetails.map((bank:any, index:any) => (
                                    <Accordion key={index} allowZeroExpanded>

                                        {

                                            <AccordionItem >
                                                <div className='on-off-delete-icn'>
                                                    <div className="on-off-switch">
                                                        <ToggleSwitch checked={isChecked[`status_${index}`]} onChange={() => handleToggleSwitch(`status_${index}`, !isChecked[`status_${index}`], bank)}  disabled={!isFormValid[index] || !HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['insurance_partner_management'], accessType: 'edit'})}/>
                                                    </div>
                                                    {HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_partner_management'], accessType: 'edit' }) &&
                                                        <i className="ic-delete template-delete-icon m-sm-l pointer" onClick={() => showDeletePopUp(bank)}></i>
                                                    }
                                                    </div>

                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                      <span className='bankText'>  { (bank.bank_name || bank.bankNames) ? `${bankListName?.find((val:any) => val.value === bank.bank_name)?.label || bank.bankNames} - ${bank.bank_account_no}` : `Bank Detail ${index + 1}`} </span>
                                                    </AccordionItemButton>

                                                </AccordionItemHeading>
                                                <AccordionItemPanel>

                                                    <>
                                                        <div className=''>
                                                            <div id="emailPdfGenreator" className="financer-email-data" />
                                                            <div className="row">

                                                                <div className='col-md-6 partner-add-edit'>
                                                                    <ReactSelect options={bankListName ?? []} value={bankListName.filter((val:any)=> +val.value === +bank.bank_name )}  handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e, index)} placeholder="Bank Name *" fieldKey="bank_name"  errorField={errors[`bankDetails[${index}].bank_name`] ? "This field is required" : '' }/>
                                                                </div>

                                                                <fieldset className="col-md-6">
                                                                    <div className="material" title={bank.bank_account_no}>
                                                                        <InputField
                                                                            inputProps={{
                                                                                id: "bank_account_no",
                                                                                name: "bank_account_no",
                                                                                className: "form-input",
                                                                                type: "text",
                                                                                value: bank.bank_account_no || '',
                                                                                maxLength: '',
                                                                                disabled: props.disable,
                                                                                placeholder: " "
                                                                            }}
                                                                            onChange={(e: any) => handleChange('bank_account_no', e, index)}
                                                                        />
                                                                        <label data-label="Bank Account No *" className="form-label"></label>
                                                                        {
                                                                            errors[`bankDetails[${index}].bank_account_no`] ?
                                                                                <span className="error-txt">{"This field is Required"}</span>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </fieldset>

                                                                <fieldset className="col-md-6">
                                                                    <div className="material" title={bank.branch_name}>
                                                                        <InputField
                                                                            inputProps={{
                                                                                id: "branch_name",
                                                                                name: "branch_name",
                                                                                className: "form-input",
                                                                                type: "text",
                                                                                value: bank.branch_name || '',
                                                                                maxLength: '',
                                                                                disabled: props.disable,
                                                                                placeholder: " "
                                                                            }}
                                                                            onChange={(e: any) => handleChange('branch_name', e, index)}
                                                                        />
                                                                        <label data-label="Branch Name" className="form-label"></label>
                                                                    </div>
                                                                </fieldset>

                                                                <fieldset className="col-md-6">
                                                                    <div className="material" title={bank?.beneficiary_name}>
                                                                        <InputField
                                                                            inputProps={{
                                                                                id: "beneficiary_name",
                                                                                name: "beneficiary_name",
                                                                                className: "form-input",
                                                                                type: "text",
                                                                                value: bank.beneficiary_name || '',
                                                                                maxLength: '',
                                                                                disabled: props.disable,
                                                                                placeholder: " "
                                                                            }}
                                                                            onChange={(e: any) => handleChange('beneficiary_name', e, index)}
                                                                        />
                                                                        <label data-label="Beneficary Name *" className="form-label"></label>
                                                                        {
                                                                            errors[`bankDetails[${index}].beneficiary_name`] ?
                                                                                <span className="error-txt">{"This field is Required"}</span>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                        </div>

                                                    </>

                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        }

                                    </Accordion>
                                ))}
                            </div>
                            {
                                (bankDetails?.length < 5  &&  HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_partner_management'], accessType: 'edit' }) ) &&
                                <div>
                                    <button className="m-sm-t btn-line btn-width-auto-bank-details p-lg-l p-lg-r" onClick={addNewBank}>
                                        <i className='ic-add m-xs-r'></i>
                                        Add New Bank
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    
                   
                    {

                        HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_partner_management'], accessType: 'edit' }) &&
                        <button className="btn-primary" onClick={handleSubmit}>
                            Save
                        </button>
                    }

                </div>

            </Modal>

            {showConfirmation === false ? ("") : (
                <ConfirmPopUp
                    confirmNo={confirmNo}
                    confirmYes={confirmYes}
                    message={confirmationMessage}
                />
            )}

        </div>
    )
}
