
import { useEffect, useState } from "react";
import DocumentGallery from '../DocumentGallery';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { ExpectedInstallmentDate, InsuranceDate } from "../../../../common/DatePicker";
import { savePaymentDetails, updateLeadDetailsInStore, fetchLeadData } from '../../../../store/slices/insurance';
import DocumentForm from "./Documents";
import BankDetails from "./BankDetails";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import FullPageLoader from '@src/common/FullPageLoader';
import * as HELPER from "../../../../config/helper";
import { APP_TYPE_WITH_UCF, INBOUND_PAYMENT_FROM_CUSTOMER, INBOUND_PAYMENT_FROM_SALES_TEAM, INBOUND_PAYMENT_FROM_BROKER, PAYMENT_TYPE_INBOUND, PAYMENT_TYPE_OUTBOUND, DOCUMENT_CATEGORY, ROLE_FEATURES, APP_TYPE_STANDALONE, OUTBOUND_PAYMENT_TO_BROKER, PAYMENT_TYPE_FROM_CUSTOMER_TO_BROKER, PAYMENT_TERM_INSTALLMENT, DOCUMENTS_CATEGORY_INBOUND, INS_TYPE_CTPL, API_TYPES, OUTBOUND_PAYMENT_TO_SALES_TEAM, APP_TYPE_RENEWAL, STATUS_SUB_STATUS } from "@src/config/constant";
import LeadRemarksButton from '../../common/LeadRemarksButton';
import CurrencyInputField from "@src/common/CurrencyInputField";
import ReactSelect from '@common/ReactSelect';
import { times } from "lodash";
import { getDocumentListing } from "@src/store/slices/common";
import React from "react";


interface PaymentTypeProps {
    formType: number;
    leadHashId: string;
    payment_type: number
}


const PaymentType: React.FC<PaymentTypeProps> = (props) => {
    const [errors, setErrors] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<any>({});

    const [leadDetails, setLeadDetails] = useState<any>({});

    const [submitClicked, setSubmitClicked] = useState<boolean>(false);

    const dispatch: any = useDispatch();
    const insuranceState = useSelector((state: any) => state.insurance);
    const navigate = useNavigate();

    let [amountLabel, setAmountLabel] = useState("");
    let [headerType, setHeaderType] = useState("");
    let [headerName, setHeaderName] = useState("");
    const [bankDetails, setBankDetails] = useState<any>({});
    const [filesCount, setFilesCount] = useState<number>(0);
    const [inputFields, setInputFields] = useState<any>({ payment_method_id: null, payment_term_id: null, payment_tenure_id: null })
    const [isInstallmentDisabled, setIsInstallmentDisabled] = useState<any>({})
    const [enableFutureDates, setEnableFutureDate] = useState(false);
    const [buttonText, setButtonText] = useState("Verify & Next")
    const [saveBtnAccessibility, setSaveBtnAccessibility] = useState<boolean>(true)

    const commonState = useSelector((state: any) => state.common);
    let paymentTermOptions = commonState?.common?.payment_term
    const displayInstallmentForm = (props.payment_type === PAYMENT_TYPE_FROM_CUSTOMER_TO_BROKER || (props.payment_type === PAYMENT_TYPE_INBOUND && (props.formType === INBOUND_PAYMENT_FROM_SALES_TEAM || props.formType === INBOUND_PAYMENT_FROM_CUSTOMER))
    )


    if(insuranceState?.leadDetail?.insurance_type_id === INS_TYPE_CTPL && commonState?.common?.payment_term) {
        paymentTermOptions = commonState.common.payment_term.filter((e: any) => e.value !== PAYMENT_TERM_INSTALLMENT)
    }
     
    const checkFutureDateApplication = () => {
        let enableFutureDates = commonState?.common?.payment_method?.find((v: any) => v.label === "Post Dated Cheque")
        enableFutureDates = (enableFutureDates && (inputFields.payment_method_id || insuranceState?.leadDetail?.payment_method_id) === enableFutureDates.value && (inputFields.payment_term_id || insuranceState?.leadDetail?.payment_term_id) === PAYMENT_TERM_INSTALLMENT) ? true : false

        return setEnableFutureDate(enableFutureDates)
    }

    const handleChange = async (key: any, event: any) => {
        if (key === 'amount_date') {
            formData["amount_date"] = event;
        } else {
            let value: any = '';
            let target = event ? event.target : {};

            if (target) {
                value = target.value;
            } else if (event) {
                value = event.value || event.id;
            }

            formData[key] = HELPER.removeCurrencyFormatting(value);
            setFormData({ ...formData });
        }

        errors[key] = '';
        setErrors({ ...errors });
    };


    const isFormValid = () => {
        let isValid = true;
        let errors: any = {}

        setErrors({})

        if (displayInstallmentForm) {
            if (!inputFields.payment_method_id) {
                errors['payment_method_id'] = "Please select payment method"
                isValid = false;
            }
            if (!inputFields.payment_term_id) {
                errors['payment_term_id'] = "Please select payment term"
                isValid = false;
            }
            if (inputFields.payment_term_id === PAYMENT_TERM_INSTALLMENT) {
                if (!inputFields.payment_tenure_id) errors['payment_tenure_id'] = "Please select payment tenure"
                if (!inputFields.premium_amount && !insuranceState?.leadDetail?.premium_amount) errors['premium_amount'] = "Please enter total premium amount"

                isValid = (errors['payment_tenure_id'] || errors['premium_amount']) ? false : true

                if (inputFields.premium_amount) {
                    let premiumAmount = Number(HELPER.removeCurrencyFormatting(inputFields['premium_amount']))
                    let totalInstallmentSum:any = 0;

                    for (const ins in inputFields) {
                        //fetch amount
                        if (ins.includes('_installment') && !(ins.includes('file') || ins.includes('date')) && inputFields[ins]) {
                            let sum = Number(HELPER.removeCurrencyFormatting(inputFields[ins]))
                            totalInstallmentSum = +totalInstallmentSum + +sum

                            totalInstallmentSum = !Number.isInteger(totalInstallmentSum) ? ''+totalInstallmentSum.toFixed(2) : totalInstallmentSum;

                            if (sum === 0) {
                                errors[ins] = "Amount Cannot be 0"
                                isValid = false
                            }
                            if (totalInstallmentSum > premiumAmount) {
                                errors[ins] = "Invalid Installment Amount"
                                isValid = false
                            }
                        }
                    }

                    if (+totalInstallmentSum > +premiumAmount) {
                        toast.error("Total Premium Amount cannot be less than sum of installments")
                        isValid = false
                    }

                    if (premiumAmount === 0) {
                        errors['premium_amount'] = "Total Premium Amount cannot be 0"
                        isValid = false
                    }

                    if (premiumAmount > insuranceState?.leadDetail?.car_fmv) {
                        errors['premium_amount'] = "Total Premium Amount should not be greater than FMV"
                        isValid = false
                    }
                }

                //apply expected amount validation only for new lead ids or lead ids that do not 
                //fall into below condition
                const validationLeadId = commonState?.common?.installment_validation_lead_id
                const currentLeadId = insuranceState?.leadDetail?.lead_id
                const substatus = insuranceState?.leadDetail?.sub_status_id
                let showError = true

                if (currentLeadId < validationLeadId &&
                    substatus >= STATUS_SUB_STATUS['subStatus']['payment_from_customer']
                ) {
                    showError = false
                }

                //validation for expected installment date
                if (inputFields?.payment_tenure_id > 0 && [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(insuranceState.leadDetail.application_type_id)) {
                    const totalInstallments = commonState?.common?.payment_tenure?.find((pt: any) => inputFields.payment_tenure_id === pt.value)?.label?.split(' ')?.[0]
                    const expectedDateErr: any = {}
                    let totalPremium = insuranceState?.leadDetail?.total_premium || 0
                    let totalInsAmount = 0;
                    //add errors to empty expected date
                    times(totalInstallments, (indx: number) => {
                        let value = inputFields?.expected_installment?.[indx + 1]
                        let errorObj: any = {}
                        let errorFlag = false
                        totalInsAmount += +(value?.amount ? (HELPER.removeCurrencyFormatting(value.amount) || 0) : 0)

                        if (!value?.amount && showError) {
                            errorObj['amount'] = `Please enter ${indx + 1}${HELPER.nthNumber(indx + 1)} Expected Amount`
                            errorFlag = true
                        }

                        if (+value?.amount === 0 && showError) {
                            errorObj['amount'] = `Amount Cannot be 0`
                            errorFlag = true
                        }
    
                        if (!value?.date) {
                            errorObj['date'] = `Please select ${indx + 1}${HELPER.nthNumber(indx + 1)} Expected Date`
                            errorFlag = true
                        }
    
                        if (errorFlag) expectedDateErr[indx + 1] = { ...errorObj }
                    })

                    if(totalPremium !== +(totalInsAmount).toFixed(2) && showError) {
                        isValid = false
                        toast.error("Sum of Expected Installments should be equal to Total Premium")
                    }

                    if (Object.keys(expectedDateErr).length) {
                        isValid = false
                        errors['expected_installment'] = { ...expectedDateErr }
                    }
                }
            }
        }

        if (!displayInstallmentForm || inputFields.payment_term_id !== PAYMENT_TERM_INSTALLMENT) {
            if (!formData.amount || formData.amount === '') {
                errors['amount'] = 'Amount is required';
                isValid = false;
            } else if (isNaN(formData.amount) || !formData.amount) {
                errors['amount'] = 'Amount is not valid';
                isValid = false;
            } else if (insuranceState.leadDetail && +formData.amount > insuranceState?.leadDetail?.car_fmv) {
                errors['amount'] = 'Amount should not be greater than FMV';
                isValid = false;
            }
            if (!formData.amount_date) {
                errors['amount_date'] = 'Amount Received Date is required';
                isValid = false;
            }
        }

        if (props.payment_type === PAYMENT_TYPE_OUTBOUND && +props.formType === 2 && +insuranceState.leadDetail.lead_type !== 3) { //VALIDATE BANK DETAILS FOR SALES TEAM AND IF NOT FROM WEB CREATED

            if (!bankDetails.bank_account_no || (bankDetails.bank_account_no && !('' + bankDetails.bank_account_no).trim())) {
                errors['bank_account_no'] = 'Account no. is required';
                isValid = false;
            }

            if (!bankDetails.beneficiary_name || (bankDetails.beneficiary_name && !bankDetails.beneficiary_name.trim())) {
                errors['beneficiary_name'] = 'Beneficiary name is required';
                isValid = false;
            }

            if (!bankDetails.bank_name || (bankDetails.bank_name && !bankDetails.bank_name.trim())) {
                errors['bank_name'] = 'Bank name is required';
                isValid = false;
            }

        }

        let getProofOfPayCustToBrokerDoc = insuranceState?.leadDetail?.images.filter((docs: any) => docs.doc_type_id === DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_customer_partner_broker']);

        if (props.payment_type === PAYMENT_TYPE_FROM_CUSTOMER_TO_BROKER && !getProofOfPayCustToBrokerDoc.length && !filesCount && !inputFields.payment_tenure_id) {
            isValid = false;
            errors['customerToPartnerDocument'] = "Please select at least one document"
        }


        setErrors({ ...errors });
        return isValid;

    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitClicked(false);

        // let postData = await getFormData();
        let postData: any = {
            'payment_from': props.formType,
            'amount_date': formData.amount_date ? formData.amount_date.toISOString() : "",
            'lead_id': props.leadHashId,
            'payment_type': props.payment_type,
            'amount': formData.amount || 0,
            'payment_method': inputFields.payment_method_id,
            'payment_term': inputFields.payment_term_id,
            'payment_tenure': inputFields.payment_tenure_id || 0
        };

        //for installment 
        if (displayInstallmentForm && inputFields.payment_tenure_id) {
            postData = handleInstallmentSubmission()
            if (!postData) return
        }

        //add expected installment date
        let expectedInstallmentDates = { ...inputFields?.expected_installment }
        let isExpectedDateFilled = expectedInstallmentDates ? Object.keys(expectedInstallmentDates).length : 0

        //remove extra expected dates
        let totalDates = +inputFields.payment_tenure_label?.split(" ")?.[0]
        if (isExpectedDateFilled && isExpectedDateFilled !== totalDates) {
            for (let key in expectedInstallmentDates) {
                if (!expectedInstallmentDates[totalDates--]) delete expectedInstallmentDates[key]
            }
        }

        if (isExpectedDateFilled) postData['expected_installment'] = expectedInstallmentDates
        
        if (+props.payment_type === PAYMENT_TYPE_INBOUND) {
            postData['id'] = (insuranceState?.leadDetail?.inbound_id || undefined);
        } else if (+props.payment_type === PAYMENT_TYPE_FROM_CUSTOMER_TO_BROKER) {
            postData['id'] = (insuranceState?.leadDetail?.pay_from_cust_to_partner_broker_id || undefined);
        } else { //OUTBOUND
            if (+props.formType === INBOUND_PAYMENT_FROM_BROKER) { //BROKER
                postData['id'] = (insuranceState?.leadDetail?.outbound_broker_id || undefined);

            } else { //SALES TEAM
                postData['id'] = (insuranceState?.leadDetail?.outbound_sales_team_id || undefined);

            }
        }

        if (isFormValid()) {
            setLoading(true);

            dispatch(savePaymentDetails({ ...postData, ...bankDetails })).then((res: any) => {

                if (res && res.status === 200) {
                    toast.success(res.message);

                    if (res.data && Object.keys(res.data).length) {
                        setLeadDetails({ ...insuranceState.leadDetail, ...res.data });

                        if (res.data.installment_details) {
                            let updatedState = { ...insuranceState.leadDetail, ...res.data }
                            dispatch(updateLeadDetailsInStore(updatedState));
                              // here with payload pass for fetching other details document
                            const payload:any = {
                                leadId: props.leadHashId,
                                source: 'web'
                              };
                          
                            dispatch(getDocumentListing(payload));
                        
                        }

                        if (+props.payment_type === PAYMENT_TYPE_INBOUND) { //INBOUNT PAYMENT

                            setSubmitClicked(true);
                        } else if (+props.payment_type === PAYMENT_TYPE_FROM_CUSTOMER_TO_BROKER) {
                            setSubmitClicked(true); //PAYMENT FROM CUSTOMER TO BROKER
                            const payload:any = {
                                leadId: props.leadHashId,
                                source: 'web'
                              };
                            
                            dispatch(getDocumentListing(payload)); //FOR UPDATING DOCUMENT LISIING

                        } else {
                            setSubmitClicked(true);

                            //FOR OUTBOUND SCREEN NAVIGATION HANDLING
                            setLoading(false);

                            if (+insuranceState.leadDetail.application_type_id === APP_TYPE_WITH_UCF) {
                                if ([INBOUND_PAYMENT_FROM_CUSTOMER, INBOUND_PAYMENT_FROM_BROKER].includes(+insuranceState.leadDetail.inbound_payment_from)) {

                                    navigate(`/details/payment-to-sales-team/${props.leadHashId}`);
                                }
                            } else {

                                //STANDALONE
                                navigate(`/details/payment-to-sales-team/${props.leadHashId}`);

                            }

                            dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...res.data }));


                        }
                    } else {
                        setLoading(false);

                    }


                } else {
                    setLoading(false);

                }
                setErrors({});
            }).catch((err: Error) => {
                setLoading(false);
            })
        }
    }

    // Redirect to the next page after successful update
    const nextPageRedirect = () => {
        if (+props.payment_type === PAYMENT_TYPE_INBOUND) {

            if (+leadDetails.application_type_id === APP_TYPE_WITH_UCF) {
                if ([INBOUND_PAYMENT_FROM_CUSTOMER, INBOUND_PAYMENT_FROM_SALES_TEAM].includes(+leadDetails.inbound_payment_from)) {

                    navigate(`/details/insurance-policy-shared/${props.leadHashId}`);
                } else {
                    navigate(`/details/payment-to-sales-team/${props.leadHashId}`);
                }

            } else if ([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(+leadDetails.application_type_id)) {
                if ([INBOUND_PAYMENT_FROM_CUSTOMER].includes(+leadDetails.inbound_payment_from)) {

                    navigate(`/details/payment-to-broker/${props.leadHashId}`);
                } else {
                    navigate(`/details/payment-to-sales-team/${props.leadHashId}`);

                }
            }
        }

        else if (+props.payment_type === PAYMENT_TYPE_FROM_CUSTOMER_TO_BROKER) {
            navigate(`/details/payment-broker/${props.leadHashId}`);
        }

    }
    useEffect(() => {
        if (insuranceState.leadDetail && props.payment_type !== PAYMENT_TYPE_OUTBOUND) {
            let data: any = {};
            data["type"] = props.payment_type;
            updateActualInstalments()

            //INBOUND
            if (props.payment_type === PAYMENT_TYPE_INBOUND && +props.formType === +insuranceState.leadDetail.inbound_payment_from) {
                data["id"] = insuranceState.leadDetail.inbound_id;
                data["amount"] = insuranceState.leadDetail.inbound_amount || ((insuranceState.getInboundPaymentFrom !== "broker") ? insuranceState?.leadDetail?.premium_amount : '');
                data["payment"] = insuranceState.leadDetail.inbound_payment_from;
                data["amount_date"] = insuranceState.leadDetail.inbound_date ? new Date(insuranceState.leadDetail.inbound_date) : ((insuranceState.getInboundPaymentFrom !== "broker") ? (insuranceState?.leadDetail?.premium_amount_date ? new Date(insuranceState?.leadDetail?.premium_amount_date) :'') : '');

            // } else if (props.payment_type === PAYMENT_TYPE_OUTBOUND && +props.formType === 2) { //OUTBOUND & SALES TEAM
            //     data["id"] = insuranceState.leadDetail.outbound_sales_team_id;
            //     data["amount"] = insuranceState.leadDetail.outbound_sales_team_amount;
            //     data["payment"] = insuranceState.leadDetail.outbound_sales_team_payment;
            //     data["amount_date"] = insuranceState.leadDetail.outbound_sales_team_date ? new Date(insuranceState.leadDetail.outbound_sales_team_date) : '';

            // } else if (props.payment_type === PAYMENT_TYPE_OUTBOUND && +props.formType === 1) { //OUTBOUND & BROKER
            //     data["id"] = insuranceState.leadDetail.outbound_broker_id;
            //     data["amount"] = insuranceState.leadDetail.outbound_broker_amount;
            //     data["payment"] = insuranceState.leadDetail.outbound_broker_payment;
            //     data["amount_date"] = insuranceState.leadDetail.outbound_broker_date ? new Date(insuranceState.leadDetail.outbound_broker_date) : '';

            } 
            else if (props.payment_type === PAYMENT_TYPE_FROM_CUSTOMER_TO_BROKER) {
                data["id"] = insuranceState.leadDetail.pay_from_cust_to_partner_broker_id;
                data["amount"] = insuranceState.leadDetail.pay_from_cust_to_partner_broker_amount || insuranceState.leadDetail.premium_amount;
                data["payment"] = insuranceState.leadDetail.pay_from_cust_to_partner_broker_payment_from;
                data["amount_date"] = insuranceState.leadDetail.pay_from_cust_to_partner_broker_date ? new Date(insuranceState.leadDetail.pay_from_cust_to_partner_broker_date) : (insuranceState.leadDetail.premium_amount_date ? new Date(insuranceState.leadDetail.premium_amount) : '');
            }
            setFormData({ ...data });
            setErrors({});

            updateInputField()
        }

        let headerName = "";
        // eslint-disable-next-line react-hooks/exhaustive-deps
        amountLabel = props.payment_type === PAYMENT_TYPE_INBOUND ? "Premium Amount Received" : "Premium Amount Paid";
        headerType = props.payment_type === PAYMENT_TYPE_INBOUND ? "Inbound Payment" : "Outbound Payment"; // eslint-disable-line react-hooks/exhaustive-deps
        let access = null;

        if (props.payment_type === PAYMENT_TYPE_INBOUND) {
            if (props.formType === INBOUND_PAYMENT_FROM_CUSTOMER) {
                headerName = "Payment From Customer";
                amountLabel = "Premium Amount Received";
                access = ROLE_FEATURES['payment_from_customer']
            } else if (props.formType === INBOUND_PAYMENT_FROM_SALES_TEAM) {
                headerName = "Payment From Sale Team";
                amountLabel = "Premium Amount Received";
                access = ROLE_FEATURES['payment_from_sales_team']

            } else if (props.formType === INBOUND_PAYMENT_FROM_BROKER) {
                headerName = "Payment From Insurance Broker";
                amountLabel = "Incentive Amount Received";
                access = ROLE_FEATURES['payment_from_insurance_broker']
            }
        } else if (props.payment_type === PAYMENT_TYPE_OUTBOUND) {
            setButtonText('Verify & Next')
            if (+insuranceState.leadDetail.application_type_id === 1) {
                if ([1, 3].includes(+insuranceState.leadDetail.inbound_payment_from) && props.formType === INBOUND_PAYMENT_FROM_BROKER) {
                    headerName = "Payment To Insurance Broker";
                    amountLabel = "Premium Amount Paid";
                    access = ROLE_FEATURES['payment_to_insurance_broker']

                } else if ([1, 3].includes(+insuranceState.leadDetail.inbound_payment_from) && props.formType === INBOUND_PAYMENT_FROM_SALES_TEAM) {
                    headerName = "Payment To Sales Team";
                    amountLabel = "Incentive Amount Paid";
                    access = ROLE_FEATURES['payment_to_sales_team']

                } else if ([2].includes(+insuranceState.leadDetail.inbound_payment_from) && props.formType === OUTBOUND_PAYMENT_TO_BROKER) {
                    headerName = "Payment To Insurance Broker";
                    amountLabel = "Premium Amount Paid";
                    access = ROLE_FEATURES['payment_to_insurance_broker']

                }

            } else {
                //STANDALONE
                if ([1, 3].includes(+insuranceState.leadDetail.inbound_payment_from) && props.formType === INBOUND_PAYMENT_FROM_BROKER) {
                    headerName = "Payment To Insurance Broker";
                    amountLabel = "Premium Amount Paid";
                    access = ROLE_FEATURES['payment_to_insurance_broker']

                } else if ([1, 3].includes(+insuranceState.leadDetail.inbound_payment_from) && props.formType === INBOUND_PAYMENT_FROM_SALES_TEAM) {
                    headerName = "Payment To Sales Team";
                    amountLabel = "Incentive Amount Paid";
                    access = ROLE_FEATURES['payment_to_insurance_broker']

                }

            }
        } else {
            headerName = "Payment From Customer to Partner/Broker";
            amountLabel = "Premium Amount Received";
            headerType = "";
            access = ROLE_FEATURES['payment_from_customer_to_partner_broker']
        }
        setAmountLabel(amountLabel);
        setHeaderType(headerType);
        setHeaderName(headerName);
        checkFutureDateApplication()

        handleBtnAccessibility(access)

    }, [props.payment_type, props.formType, insuranceState.leadDetail]);


    useEffect(() => {
        if (inputFields.payment_tenure_id) {
            let updatedFields = { ...inputFields }
            let tenureLabel = inputFields.payment_tenure_label ? inputFields.payment_tenure_label : insuranceState?.leadDetail?.pay_tenure
            if (tenureLabel) {
                times((tenureLabel).split(" ")[0], (idx: number) => {
                    updatedFields = { ...updatedFields, [idx + 1 + "_installment_file_count"]: updatedFields[idx + 1 + "_installment_file_count"] ?? 0 }
                })
            }

            setInputFields((prev: any) => {
                return { ...prev, ...updatedFields }
            })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputFields.payment_tenure_id, insuranceState.leadDetail])

    const resolveFileUpload = (filesStatus: any) => {
        setLoading(false);
        if (filesStatus.status) {
            dispatch(updateLeadDetailsInStore({ ...leadDetails, images: [...leadDetails.images, ...filesStatus.data] }));
            nextPageRedirect();
        }
    }

    const getBankDetail = (params: any) => {
        setBankDetails({ ...params });
    }

    const updatedFileCount = (param: any) => {
        if (param?.type === 'installment') {
            setInputFields((prev: any) => {
                return { ...prev, [param.key]: param.value }
            })
        }
        else {
            setFilesCount(param);
            setErrors((prev: any) => ({ ...prev, customerToPartnerDocument: '' }))
        }
    }

    const changeHandler = (data: any, key: string) => {
        //for updating installment amount and dates
        if (key.includes('installment') || key.includes('premium_amount')) {
            
            //for expected installment dates
            if (key.includes('expected_installment')) {
                let updatedKey = key.split('-')
                let installmentNum = +updatedKey[1]
                const isDate = updatedKey?.[0] === 'expected_installment_date' ? true : false
                let updatedExpectedDates = JSON.parse(JSON.stringify({ ...inputFields?.['expected_installment'], [installmentNum]: {...inputFields?.['expected_installment']?.[installmentNum] || null} }))
                key = `expected_installment`

                //reset next expected installment dates
                const totalInstallments = commonState?.common?.payment_tenure?.find((pt: any) => inputFields?.payment_tenure_id === pt.value)?.label?.split(' ')?.[0]
                if (isDate) {
                    for (let i: any = installmentNum + 1; i <= totalInstallments; i++) {
                        let nextDateVal = new Date(updatedExpectedDates[i]?.date).getTime()
                        let currDateVal = new Date(data).getTime()
                        if(nextDateVal <= currDateVal) {
                            delete updatedExpectedDates[i]?.date
                        }
                    }
                }

                updatedExpectedDates[installmentNum][isDate ? 'date' : 'amount'] = data
                data = { ...updatedExpectedDates }
            }

            setInputFields({
                ...inputFields,
                [key]: data?.target?.value ?? data
            })
        }
        //for updating payment term, method and tenure
        else {
            let updatedFields = { ...inputFields }
            if (key === 'payment_method_id') {
                updatedFields = { ...updatedFields, payment_term_id: null, payment_term_label: null, payment_tenure_id: null, payment_tenure_label: null, expected_installment: null }
            }
            else if (key === 'payment_term_id') {
                updatedFields = { ...updatedFields, payment_tenure_id: null, payment_tenure_label: null }
            }
            //if any payment id is being updated then delete amounts stored
            if (key.includes("id")) {
                delete updatedFields['premium_amount']
                Object.keys(updatedFields).forEach((e: any) => {
                    if (e.includes("installment") && !e.includes('expected_installment')) delete updatedFields[e]
                })

                //reset back to original expected installment amounts
                if (key === 'payment_tenure_id') {
                    let isSameTenure = data?.value === insuranceState?.leadDetail?.payment_tenure_id
                    if (isSameTenure) {
                        updatedFields = { ...updatedFields, expected_installment: insuranceState?.leadDetail?.expected_installment_inbound }
                    }
                }
            }

            setInputFields({
                ...updatedFields,
                [key]: data?.value,
                [key.replace('_id', "_label")]: data?.label
            })
        }

        checkFutureDateApplication()

        setErrors((prev: any) => {
            return { ...prev, [key]: '' }
        })
    }

    useEffect(() => {
        if (insuranceState.leadDetail.customer_api_call) {
            //calling api to fetch installment details
            if (props.payment_type !== PAYMENT_TYPE_OUTBOUND) callPaymentAPI()

            if (props.payment_type === PAYMENT_TYPE_OUTBOUND) {
                setLoading(true)
                fetchLeadData({ "lead_id": props.leadHashId, apiType: API_TYPES['outboundData'] }).then((res: any) => {
                    if (res && res.data && Object.keys(res.data).length) {
                        let data: any = {}
                        let updatedLeadDetails = { ...res.data.bankDetails }
                        let paymentDetails = res.data.paymentDetails
                        if (paymentDetails.length) {
                            paymentDetails.forEach((pd: any) => {
                                if (pd.payment_from === +props.formType) { //FROM BROKER OR FROM SALES
                                    let key = +props.formType === 1 ? 'outbound_broker' : 'outbound_sales_team'
                                    data["id"] = pd.id;
                                    data["amount"] = pd.amount;
                                    data["payment"] = pd.payment_from;
                                    data["amount_date"] = pd.amount_date ? new Date(pd.amount_date) : '';

                                    updatedLeadDetails = {
                                        ...updatedLeadDetails,
                                        [key + `_id`]: data.id,
                                        [key + `_amount`]: data.amount,
                                        [key + `_payment`]: data.payment,
                                        [key + `_date`]: pd.amount_date,
                                    }
                                }
                            })
                        }

                        setFormData({ ...data })
                        setErrors({});

                        let dispatchAction = false
                        if (+props.formType === OUTBOUND_PAYMENT_TO_SALES_TEAM && !insuranceState?.leadDetail?.hasOwnProperty('outbound_sales_team_bank_name')) {
                            dispatchAction = true
                        }
                        else if (!insuranceState?.leadDetail?.hasOwnProperty('outbound_broker_bank_name')) {
                            dispatchAction = true
                        }

                        if (dispatchAction || data.amount) {
                            dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...updatedLeadDetails }))
                        }
                        updateInputField()
                    }
                }).finally(() => setLoading(false))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formType, props.payment_type, insuranceState.leadDetail.customer_api_call])

    useEffect(() => {
        if (props.payment_type !== PAYMENT_TYPE_OUTBOUND) {
            //for updating installment document count
            if (insuranceState?.leadDetail?.images) {
                let installmentFileObj: any = {}
                insuranceState.leadDetail.images.forEach((ele: any) => {
                    if (ele.doc_id >= DOCUMENTS_CATEGORY_INBOUND['1_installment_file']) {
                        let key = Object.keys(DOCUMENTS_CATEGORY_INBOUND).find((e: any) => DOCUMENTS_CATEGORY_INBOUND[e as keyof typeof DOCUMENTS_CATEGORY_INBOUND] === ele.doc_id)
                        installmentFileObj = { ...installmentFileObj, [key + "_count"]: installmentFileObj[key + "_count"] ? installmentFileObj[key + "_count"] + 1 : 1 }
                    }
                })

                setInputFields((prev: any) => {
                    return { ...prev, ...installmentFileObj }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceState.leadDetail.images])

    const handleBtnAccessibility = (access: any) => {
        let saveBtnAccessibility = HELPER.isUserHasUpdateAccess({ permissionID: access, accessType: 'edit', leadDetails: insuranceState.leadDetail, pageSlug: (+props.payment_type === 1 ? "inbound_payment" : +props.payment_type === 2 ? "outbound_payment" : "payment_from_customer_to_partner_broker"), pageChildSlug: (+props.formType === 1 ? "broker" : (+props.formType === 2 ? "sales_team" : "customer")), pay_tenure: (insuranceState?.leadDetail?.payment_tenure_id ? (insuranceState?.leadDetail?.pay_tenure || inputFields.payment_tenure_label) : null) })   
        setSaveBtnAccessibility(saveBtnAccessibility)
    }

    const updateInputField = () => {
        let { payment_method_id, payment_term_id, payment_tenure_id, expected_installment_inbound } = insuranceState?.leadDetail

        let updatedInputFields: any = {
            payment_method_id,
            payment_tenure_id,
            payment_term_id,
            payment_tenure_label: (commonState?.common?.payment_tenure?.find((v: any) => payment_tenure_id === v.value))?.label
        }

        if(insuranceState?.leadDetail?.expected_installment_inbound && Object.keys(expected_installment_inbound)?.length) updatedInputFields['expected_installment'] = expected_installment_inbound

        let installmentDetails = insuranceState?.leadDetail?.installment_details
        
        if (installmentDetails && Object.keys(installmentDetails).length) {
            let disabledFields: any = {}
            for (const ins in installmentDetails) {
                let insNumber = ins.split("_")[0]
                let amount = installmentDetails[ins]['amount'] ?? ''

                if (+insNumber === 0) {
                    updatedInputFields = { ...updatedInputFields, premium_amount: amount }
                    if (+installmentDetails[ins]['amount'] > 0) disabledFields['premium_amount'] = true
                }
                else {
                    let date = installmentDetails[ins]['date'] ? new Date(installmentDetails[ins]['date']) : ''
                    updatedInputFields = { ...updatedInputFields, [insNumber + "_installment"]: amount, [insNumber + "_installment_date"]: date }
                    disabledFields[insNumber + "_installment"] = (amount > 0 || date) ? true : false
                }
            }
            setIsInstallmentDisabled(disabledFields)
        }

        //for prefilling fields with actual installment from customer section
        // let actualInstalments = insuranceState?.leadDetail?.actual_installment
        // if (actualInstalments && Object.keys(actualInstalments).length) {
        //     for (let key in actualInstalments) {
        //         let insNum = +key

        //         if (!updatedInputFields?.[insNum + '_installment']) {
        //             updatedInputFields[insNum + '_installment'] = actualInstalments[key].amount
        //             updatedInputFields[insNum + '_installment_date'] = actualInstalments[key].date ? new Date(actualInstalments[key].date) : ''
        //         }
        //     }
        // }
        setInputFields((prev: any) => {
            return { ...prev, ...updatedInputFields }
        })
    }

    const updateActualInstalments = () => {
        let actualInstalments = insuranceState?.leadDetail?.actual_installment
        let stateInstalments = insuranceState?.leadDetail?.installment_details
        if (actualInstalments && Object.keys(actualInstalments).length) {
            let fields: any = {}
            for (let key in actualInstalments) {
                let insNum = +key

                if (!stateInstalments?.[insNum + '_installment']?.amount) {
                    fields[insNum + '_installment'] = actualInstalments[key].amount
                    fields[insNum + '_installment_date'] = actualInstalments[key].date ? new Date(actualInstalments[key].date) : ''
                }
            }

            if(Object.keys(fields).length) {
                setInputFields((prev: any) => ({...prev, ...fields}))
            }

        }
    }
    const handleInstallmentSubmission = () => {
        let postData: any = {
            'payment_from': props.formType,
            'lead_id': props.leadHashId,
            'payment_type': props.payment_type,
            'payment_method': inputFields.payment_method_id,
            'payment_term': inputFields.payment_term_id,
            'payment_tenure': inputFields.payment_tenure_id,
            'installment_details': {}
        }
        //pushing total premium amount
        if (!insuranceState?.leadDetail?.installment_details?.['0_installment']?.amount) {
            let amount = (inputFields.premium_amount || +insuranceState?.leadDetail?.premium_amount)
            amount = amount ? HELPER.removeCurrencyFormatting(amount) : 0
            postData = { ...postData, installment_details: { '0_installment': { amount } } }
        }

        for (const ele in inputFields) {
            let key = ele.split("_")[0] //fetching installment index
            let isValueExists = inputFields[ele]
            let stateInstallment = insuranceState?.leadDetail?.installment_details?.[key + "_installment"]
            let isInstallmentPrefilled = (stateInstallment && +stateInstallment.amount === +inputFields[key + "_installment"]) ? true : false
            if (!isInstallmentPrefilled) {
                if(!ele.includes('expected_installment')) {
                    if (isValueExists && ele.includes("installment_date")) {
                        let date = inputFields[ele]?.toISOString()
                        postData['installment_details'][key + "_installment"] = { ...postData['installment_details'][key + "_installment"], date }
                    }
                    else if (isValueExists && ele.includes("installment") && !ele.includes("file")) {
                        let amount = inputFields[ele] ? HELPER.removeCurrencyFormatting(inputFields[ele]) : 0
                        postData['installment_details'][key + "_installment"] = { ...postData['installment_details'][key + "_installment"], amount }
                    }
                }
            }
        }

        let tenureLabel = inputFields?.payment_tenure_label?.split(" ")[0]
        let allInstallments = { ...insuranceState?.leadDetail?.installment_details, ...postData?.installment_details }
        delete allInstallments['0_installment']

        //Check if last installment and if sum of all installment is equal to premium amount
        if (+tenureLabel && allInstallments && +tenureLabel === Object.keys(allInstallments).filter((ins: any) => allInstallments[ins]?.amount || allInstallments[ins]?.date).length) {
            let installmentSum: any = 0
            Object.keys(allInstallments).forEach((ele: any) => installmentSum += (+allInstallments[ele].amount))

            installmentSum = !Number.isInteger(installmentSum) ? installmentSum.toFixed(2) : installmentSum;
            if (+installmentSum !== (+postData?.['installment_details']?.['0_installment']?.['amount'] || +insuranceState?.leadDetail?.installment_details?.['0_installment']?.amount)) {
                toast.error("Total Premium Amount should be equal to sum of Installments")
                return false
            }
        }

        //check if actual installment filled in customer detail
        //is present in payment section or not if not throw error
        if (+tenureLabel && insuranceState?.leadDetail?.actual_installment) {
            for (let key in insuranceState?.leadDetail?.actual_installment) {
                let installmentIndx = +key
                if (!postData['installment_details']?.[installmentIndx + "_installment"]?.amount
                    && !+insuranceState?.leadDetail?.installment_details?.[installmentIndx + '_installment']?.amount
                ) {
                    toast.error(`${installmentIndx} Instalment is required`)
                    return false
                }
            }
        }

        return postData
    }

    const callPaymentAPI = () => {
        setLoading(true)
        fetchLeadData({ "lead_id": props.leadHashId, apiType: API_TYPES['inboundData'], payment_type: props.payment_type }).then((res: any) => {
            // let key = props.payment_type === PAYMENT_TYPE_FROM_CUSTOMER_TO_BROKER ? `pay_from_cust_to_partner_broker` : `inbound`
            if (res && res.data && Object.keys(res.data).length) {
                let isInstallment = res.data?.['0_installment']?.amount
                let data = res.data
                data = isInstallment ? { installment_details: res.data } : data
                if (isInstallment && !insuranceState?.leadDetail?.installment_details) {
                    dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...data }))
                }
                // else if (!isInstallment && !insuranceState?.leadDetail?.hasOwnProperty(key + '_amount')) { 
                else if (!isInstallment) { 
                    dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...res.data }))
                }

                if (displayInstallmentForm && isInstallment) setButtonText('Save')
                else setButtonText("Verify & Next")
            }
        }).finally(() => setLoading(false))
    }
    
    return (
        <div className="image-form-outer">
            <FullPageLoader show={loading} />

            <div className="image-tab-panel">
                <DocumentGallery formType={(props.payment_type === PAYMENT_TYPE_INBOUND) ? "inbound_payment" : props.payment_type === PAYMENT_TYPE_OUTBOUND ? "outbound_payment" : "payment_from_customer_to_partner_broker"} payment_from={props.formType} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading">
                        <span className="main-heading">{headerType}</span>
                    </div>
                    <div className="lead-form-filed radio-btn payment-paid-form">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>{headerName}</h3>
                            </div>

                            {
                                displayInstallmentForm
                                    ?
                                    <>
                                        <ReactSelect
                                            options={commonState?.common?.payment_method}
                                            value={commonState?.common?.payment_method?.find((v: any) => inputFields.payment_method_id === v.value) || {}}
                                            placeholder={"Payment Method*"}
                                            isDisabled={isInstallmentDisabled['premium_amount'] ? true : false}
                                            fieldKey="payment_method_id"
                                            handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldSetClassName="col-md-6"
                                            errorField={errors.payment_method_id} />

                                        <ReactSelect
                                            options={paymentTermOptions}
                                            value={paymentTermOptions?.find((v: any) => inputFields.payment_term_id === v.value) || null}
                                            placeholder={"Payment Term*"}
                                            isDisabled={isInstallmentDisabled['premium_amount'] ? true : false}
                                            handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="payment_term_id"
                                            fieldSetClassName="col-md-6"
                                            errorField={errors.payment_term_id} />
                                    </>
                                    : ""
                            }

                            {
                                displayInstallmentForm && paymentTermOptions?.find((v: any) => inputFields?.payment_term_id === v.value)?.label === "Installment"
                                    ?
                                    <>
                                        <ReactSelect
                                            options={commonState?.common?.payment_tenure?.filter((pay: any) => pay.payment_method === inputFields.payment_method_id && pay.payment_term === inputFields.payment_term_id)}
                                            value={commonState?.common?.payment_tenure?.find((v: any) => inputFields.payment_tenure_id === v.value) || null}
                                            placeholder={"Payment Tenure*"}
                                            isDisabled={isInstallmentDisabled['premium_amount'] ? true : false}
                                            fieldKey="payment_tenure_id"
                                            handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)}
                                            fieldSetClassName="col-md-12"
                                            errorField={errors.payment_tenure_id} />

                                        <fieldset className="col-md-12">
                                            <div className="material">
                                                {
                                                    isInstallmentDisabled['premium_amount']
                                                        ?
                                                        <CurrencyInputField
                                                            inputProps={{
                                                                id: "premium_amount",
                                                                type: "text",
                                                                name: "premium_amount",
                                                                placeholder: " ",
                                                                value: inputFields.premium_amount || insuranceState?.leadDetail?.installment_details?.['0_installment']?.amount,
                                                                className: "form-input",
                                                                disabled: true
                                                            }}

                                                            onChange={(e: any) => { }}
                                                        />
                                                        :
                                                        <CurrencyInputField
                                                            inputProps={{
                                                                id: "premium_amount",
                                                                type: "text",
                                                                name: "premium_amount",
                                                                placeholder: " ",
                                                                value: inputFields.premium_amount || insuranceState?.leadDetail?.premium_amount || "",
                                                                className: "form-input",
                                                                disabled: false
                                                            }}

                                                            onChange={(e: any) => changeHandler(e, "premium_amount")}
                                                        />
                                                }

                                                <label data-label={"Total Premium Amount Received*"} className="form-label"></label>
                                                <span className="error-txt">
                                                    {errors.premium_amount || ""}
                                                </span>
                                            </div>
                                        </fieldset>
                                    </>
                                    : ''
                            }
                            {
                                [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(insuranceState?.leadDetail.application_type_id) && displayInstallmentForm && inputFields.payment_tenure_id && inputFields.payment_tenure_label
                                    ?
                                    times((inputFields.payment_tenure_label).split(" ")[0], (idx: number) => {
                                        //to show expected date fields in col-md-6 or col-md-12 we use index & divide tenure by 2
                                        let isTenureOdd = (inputFields.payment_tenure_label).split(" ")[0]
                                        isTenureOdd = isTenureOdd % 2 !== 0 && (idx + 1) === +isTenureOdd ? true : false
                                        let incrementedIndx = idx + 1;

                                        return (
                                            <React.Fragment key={idx}>
                                                <fieldset className="col-md-6">
                                                    <div className={(inputFields?.["expected_installment"]?.amount) ? "active-label material animation-effect" : "material animation-effect"}>
                                                        {
                                                            isInstallmentDisabled['premium_amount']
                                                                ?
                                                                <CurrencyInputField
                                                                    inputProps={{
                                                                        id: 'expected' + incrementedIndx + "_amount",
                                                                        type: "text",
                                                                        name: 'expected' + incrementedIndx + "_amount",
                                                                        placeholder: " ",
                                                                        value: inputFields['expected_installment']?.[idx + 1]?.amount || 0,
                                                                        className: "form-input",
                                                                        disabled: true,
                                                                    }}

                                                                    onChange={(e: any) => {}}
                                                                />
                                                                :
                                                                <CurrencyInputField
                                                                    inputProps={{
                                                                        id: 'expected' + incrementedIndx + "_amount",
                                                                        type: "text",
                                                                        name: 'expected' + incrementedIndx + "_amount",
                                                                        placeholder: " ",
                                                                        value: inputFields['expected_installment']?.[idx + 1]?.amount || 0,
                                                                        className: "form-input",
                                                                        disabled: false,
                                                                    }}

                                                                    onChange={(e: any) => changeHandler((HELPER.removeCurrencyFormatting(e.target.value) || 0), `expected_installment_amount-${idx + 1}`)}
                                                                />

                                                        }
                                                        <label data-label={`Expected ${idx + 1}${HELPER.nthNumber(idx + 1)} Amount`} className="form-label"></label>
                                                        <span className={`error-txt`}>
                                                            {errors?.expected_installment?.[idx + 1]?.amount || ""}
                                                        </span>
                                                    </div>
                                                </fieldset>
                                                <fieldset className={`col-md-6 date-picker-detail`}>
                                                    <div className={"material animation-effect" + (inputFields?.expected_installment?.[idx + 1]?.date ? ' active-label' : '')}>
                                                        {
                                                            isInstallmentDisabled['premium_amount']
                                                                ? <ExpectedInstallmentDate
                                                                    onChange={(e: Date) => { }}
                                                                    selected={inputFields?.expected_installment?.[idx + 1]?.date || ''}
                                                                    dateFormat="dd MMM, yyyy"
                                                                    disabled={true}
                                                                    installmentNumber={idx + 1}
                                                                    prevInstallmentDate={inputFields?.expected_installment?.[idx]?.date || null}
                                                                    text={`Expected ${idx + 1}${HELPER.nthNumber(idx + 1)} Installment Date*`}
                                                                    edit-label-width={isTenureOdd ? false : false}
                                                                    source = "detailSection"
                                                                />
                                                                :
                                                                <>
                                                                    <ExpectedInstallmentDate
                                                                        onChange={(e: Date) => changeHandler(e, `expected_installment_date-${idx + 1}`)}
                                                                        selected={inputFields?.expected_installment?.[idx + 1]?.date || ''}
                                                                        dateFormat="dd MMM, yyyy"
                                                                        disabled={false}
                                                                        installmentNumber={idx + 1}
                                                                        prevInstallmentDate={inputFields?.expected_installment?.[idx]?.date || null}
                                                                        text={`Expected ${idx + 1}${HELPER.nthNumber(idx + 1)} Installment Date*`}
                                                                        edit-label-width={isTenureOdd ? false : false}
                                                                        source = "detailSection"
                                                                    />
                                                                    <span className={`error-txt`}>
                                                                        {errors?.expected_installment?.[idx + 1]?.date || ""}
                                                                    </span>
                                                                </>
                                                        }
                                                    </div>
                                                </fieldset>
                                            </React.Fragment>
                                        )
                                    })
                                    : null
                            }
                            {
                                displayInstallmentForm && inputFields.payment_tenure_id && inputFields.payment_tenure_label
                                    ?
                                    times((inputFields.payment_tenure_label).split(" ")[0], (idx: number) => {
                                        let incrementedIndx = idx + 1;
                                        let formattedIndx = HELPER.nthNumber(incrementedIndx)
                                        let prefilledAmount = insuranceState?.leadDetail?.installment_details?.[incrementedIndx + '_installment']?.amount
                                        let prefilledDate = insuranceState?.leadDetail?.installment_details?.[incrementedIndx + '_installment']?.date
                                        let isStandalone = insuranceState.leadDetail.application_type_id === APP_TYPE_STANDALONE
                                        return (
                                            <React.Fragment key={idx}>
                                                <fieldset className="col-md-6">
                                                    <div className={(inputFields[incrementedIndx + "_installment"]?.amount) ? "active-label material animation-effect" : "material animation-effect"}>
                                                        {
                                                            (isInstallmentDisabled[incrementedIndx + "_installment"])
                                                                ?
                                                                <CurrencyInputField
                                                                    inputProps={{
                                                                        id: incrementedIndx + "_amount",
                                                                        type: "text",
                                                                        name: incrementedIndx + "_amount",
                                                                        placeholder: " ",
                                                                        value: prefilledAmount,
                                                                        className: "form-input",
                                                                        disabled: true
                                                                    }}

                                                                    onChange={(e: any) => { }}
                                                                />
                                                                :
                                                                <CurrencyInputField
                                                                    inputProps={{
                                                                        id: incrementedIndx + "_amount",
                                                                        type: "text",
                                                                        name: incrementedIndx + "_amount",
                                                                        placeholder: " ",
                                                                        value: inputFields[incrementedIndx + "_installment"] || "",
                                                                        className: "form-input",
                                                                        disabled: false
                                                                    }}

                                                                    onChange={(e: any) => changeHandler(e, incrementedIndx + "_installment")}
                                                                />
                                                        }

                                                        <label data-label={incrementedIndx + formattedIndx + " Installment Amount"} className="form-label"></label>
                                                        <span className={`error-txt`}>
                                                            {errors[incrementedIndx + "_installment"] || ""}
                                                        </span>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="col-md-6">
                                                    <div className={(inputFields[incrementedIndx + "_installment_date"] || prefilledDate) ? "active-label material animation-effect datepicker-width-400" : "material animation-effect datepicker-width-400"}>
                                                        {
                                                            (prefilledAmount || prefilledDate)
                                                                ?
                                                                <InsuranceDate text={`${isStandalone ? 'Actual' : ''} ${incrementedIndx}${formattedIndx} Installment Date & Time`} insurancePolicyDate={prefilledDate ? new Date(prefilledDate) : ''} onChange={(e: any) => { }} disabled={true} installment={true} />
                                                                :
                                                                <InsuranceDate text={`${isStandalone ? 'Actual' : ''} ${incrementedIndx}${formattedIndx} Installment Date & Time`} insurancePolicyDate={inputFields[incrementedIndx + "_installment_date"]} onChange={(e: any) => changeHandler(e, incrementedIndx + "_installment_date")} installment={true} enableAllDates={enableFutureDates ? true: false}/>
                                                        }

                                                    </div>
                                                </fieldset>
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    ((props.payment_type === PAYMENT_TYPE_OUTBOUND || (props.payment_type === PAYMENT_TYPE_INBOUND && props.formType === INBOUND_PAYMENT_FROM_BROKER)) || inputFields?.payment_term_id !== PAYMENT_TERM_INSTALLMENT)
                                        ?
                                        <>

                                            <fieldset className="col-md-12">
                                                <div className="material">
                                                    <CurrencyInputField
                                                        inputProps={{
                                                            id: "amount",
                                                            type: "text",
                                                            name: "amount",
                                                            placeholder: " ",
                                                            value: formData.amount || "",
                                                            className: "form-input"
                                                        }}
                                                        onChange={(e: any) => handleChange('amount', e)}
                                                    />
                                                    <label data-label={amountLabel + '*'} className="form-label"></label>
                                                    <span className="error-txt">
                                                        {errors.amount || ""}
                                                    </span>
                                                </div>
                                            </fieldset>
                                            <fieldset className="col-md-12">
                                                <div className={(formData.amount_date) ? "active-label material animation-effect" : "material animation-effect "}>
                                                    <InsuranceDate text={amountLabel + " Date & Time*"} insurancePolicyDate={formData.amount_date} onChange={(e: any) => handleChange('amount_date', e)} />
                                                    <span className="error-txt">
                                                        {errors.amount_date || ""}
                                                    </span>
                                                </div>
                                            </fieldset>
                                        </>
                                        : ''
                            }


                            {props.payment_type === PAYMENT_TYPE_OUTBOUND && <BankDetails getBankDetail={getBankDetail} errors={errors} formType={props.formType} updateErrors={(data: any) => setErrors({ ...data })} />}


                        </div>
                        <DocumentForm docTypeId={[DOCUMENT_CATEGORY['postDisbursal']['doc_type_id']]} docId={(props.payment_type === PAYMENT_TYPE_INBOUND) ? [DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_inbound']] : props.payment_type === PAYMENT_TYPE_OUTBOUND ? [DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_outbound']] : [DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_customer_partner_broker']]} formType={(props.payment_type === PAYMENT_TYPE_INBOUND) ? "inbound_payment" : props.payment_type === PAYMENT_TYPE_OUTBOUND ? "outbound_payment" : "payment_from_customer_to_partner_broker"} resolveFileUpload={resolveFileUpload} submitClicked={submitClicked} updatedFileCount={updatedFileCount} errField={errors} installmentDetails={displayInstallmentForm ? inputFields : {}} />


                        <div className="btn-save-remarks m-lg-t">
                            {
                                saveBtnAccessibility
                                    ?
                                    <button type="button" className="btn-primary" onClick={(e: any) => handleSubmit(e)}>{buttonText}</button>
                                    :
                                    <button type="button" className="btn-primary" disabled>{buttonText}</button>

                            }
                            <LeadRemarksButton leadId={props.leadHashId} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentType;