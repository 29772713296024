import Modal from "../../../common/ModalPopup";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import {reOpenLostLead}  from '@src/store/slices/insurance';
import { updateLeadDetailsInStore } from "@src/store/slices/insurance";
import FullPageLoader from "@common/FullPageLoader";
import * as HELPER from "@src/config/helper";
import { ROLE_FEATURES } from "@src/config/constant";


const MarkAsLostPopup = (props: any) => {
    const [errors, setError] = useState<any>({});
    const [formData, setFormData] = useState<any>({status : 1});
	const [loading, setLoading] = useState(false);
    const insuranceState = useSelector((state: any) => state.insurance);
    const dispatch: any = useDispatch();


    const handleChange = async (key:string, event:any) => { 
        formData[key] = event.target.value;
		errors[key] = '';
		setError({ ...errors });
        setFormData({ ...formData });

	}

	const isFormValid = (formData : any) => {
		let isValid = true;
        if(!formData || !formData.remarks || formData.remarks === ''){
            errors['remarks'] = 'This field is Required';
			isValid = false;
        }
		if(formData && formData.remarks && (formData.remarks.split('').length < 3 || formData.remarks.split('').length > 500)){
			errors['remarks'] = 'Remarks should be between 3 to 500';
			isValid = false;
		}
		setError({ ...errors });
		return isValid;
	}
  
    const getFormData = async () => {
        let postData:any = {};
        postData['remarks'] = formData && formData.remarks ? formData.remarks : '';
        postData['lead_id'] = props.leadHashId;
        return postData;
    }

    let updateLeadObj = {
        status_id : insuranceState?.leadDetail?.prev_status_id,
        sub_status_id : insuranceState?.leadDetail?.prev_sub_status_id,
        prev_status_id : insuranceState?.leadDetail?.status_id,
        prev_sub_status_id : insuranceState?.leadDetail?.sub_status_id,
    }


	const reOpenLead = async (e:any) => {
		e.preventDefault();
        let postData = await getFormData();
     
		if (isFormValid(postData)) {
			setLoading(true);
            dispatch(reOpenLostLead(postData)).then((res: any) => {
                if (res && res.status === 200) {
                    setFormData({});
                    props.showReopenPopup(false);
                    dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...res.data, ...updateLeadObj, "leadRemarkCount": (insuranceState.leadDetail.leadRemarkCount+1) }));
                    toast.success(res.message);
                }
                setLoading(false);
            });
		}
	}

    

    return (

        <div className="view-timeline-popup image-timeline-popup">
            <FullPageLoader show={loading} />
            <Modal show={props.reopenPopup} handleClose={() => props.showReopenPopup(false)} >
                <div className="modal-header">
                    <h2>Re-Open</h2>
                </div>
                <div className="modal-body" >
                    <div className="row">
                        <div className="col-md-12 m-xs-b">
                            <fieldset className="form-filed">
                                <div className="material">
                                    <label>Remarks <span className="required">*</span></label>
                                    <textarea id="remarks" placeholder=" " name="remarks" className="form-input" value={formData.remarks} onChange={(e: any) => handleChange("remarks", e)}/>
                                    <span className="error-txt">
                                        {errors.remarks || ""}
                                    </span>
                                </div>
                            </fieldset>
                        </div>

                        <div className="col-md-12">
                        {
                            HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['re-open'], accessType: 'edit', assignUserId: insuranceState?.leadDetail?.assign_user_id }) && 
                            
                            <button className="btn-primary m-md-t" onClick={(e:any) => reOpenLead(e)}>Save</button>
                        }
                        </div>
                    </div>
                </div>
            </Modal>
        </div>

    )

}
export default MarkAsLostPopup;