import secureStorage from '../../config/encrypt';
import { UserService } from '../../services/UserService';

import { createSlice , PayloadAction, Dispatch } from '@reduxjs/toolkit'

import { toast } from "react-toastify";

interface CaptchaData {
   // data: string; 
   data: {
    svg: string;
    value: string;
  }; 
}

export interface OtpResponse {
    data: {
      status: number;
      message: string;
      user_id: string;
    };
  }

interface OtpRequest {
    email: string;
    password?: string;
    captcha?: string;
    resendOtp?: boolean;
}

interface UserState {
    authUser: any; // Replace 'any' with the appropriate type for 'authUser'
    login: boolean;
    redirectTo: string;
    cities: any[]; // Replace 'any[]' with the appropriate type for 'cities'
  } 
  
const user = createSlice({
    name: 'user',
    initialState: {
      authUser: null, // Set the initial value for 'authUser' to null or the appropriate initial value
      login: false,
      redirectTo: '/',
      cities: []
    } as UserState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<any>) => {
            state.authUser = action.payload;
            state.login = true;
            state.redirectTo = '/'

        }
    },
});

export default user.reducer;

const { loginSuccess  } = user.actions

export const loadloginCaptcha = () => {
    return new Promise<CaptchaData>((resolve, reject) => {
        UserService.loginCaptcha()
            .then(
                captcha => { resolve(captcha?.data) },
                error => {
                    reject(error)
                }
            )
    })
}


export const createOtp = (params: OtpRequest): Promise<OtpResponse> => {

    return new Promise<OtpResponse>((resolve, reject) => {
        UserService.createOtp(params)
            .then(
                (user: any) => {
                    if (user && user.data && user.data.status === 200) {
                        toast.success(user.data.message);
                        return resolve(user.data)
                    }
                    else {
                        toast.error(user?.data?.message);
                        return resolve(user && user?.data)
                    }

                },
                error => {
                    reject(error);
                }
            );
    });

}


export const VerifyOtpHandler = async (params: OtpRequest, dispatch: Dispatch): Promise<OtpResponse> => {

    return new Promise((resolve, reject) => {
        UserService.VerifyOtp(params)
            .then(
                user => {
                    if (user && user.data && user.data.status === 200) {

                        let ob = user.data.data;

                        secureStorage.setItem('authUserInfo', ob);
                        toast.success(user.data.message);
                        dispatch(loginSuccess(ob));
                        resolve(user.data)
                    }
                    else {
                        toast.error(user.data.message);
                        resolve(user && user.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


export const getUserList = async (params: any): Promise<any> => {

    return new Promise((resolve, reject) => {
        UserService.getAllUsers(params)
            .then(
                user => {
                    if (user && user.data && user.data.status === 200) {
                        resolve(user.data)
                    }
                    else {
                        toast.error(user.data.message);
                        resolve(user && user.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


// export const checkAuth = () => {
//     UserService.checkAuth();
// }