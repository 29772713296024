import { useEffect, useState } from 'react';
import Select from 'react-select';
import Pagination from '@src/common/Pagination';
import { NavLink, useNavigate } from 'react-router-dom';
import LeadHistoryModal from '@src/component/insurance/common/LeadHistoryModal';
import LeadRemarksModal from '@src/component/insurance/common/LeadRemarksModal';
import dateFormat from "dateformat";
import * as HELPER from "@src/config/helper";
import { updateLeadAssignUser } from "@src/store/slices/insurance";
import { ROLE_FEATURES,INS_TYPE_CTPL } from '@src/config/constant';
import { useSelector } from 'react-redux';


function InsuranceListTable(props: any) {
    let { insuranceList, paginationData, selectedFilters, assignUsers } = props;

    const navigate = useNavigate();

    const [viewTimeLine, setViewTimeLine] = useState(false);
    const [remarkHistory, setRemarkHistory] = useState(false);

    const page = 1;

    const [selectedRemarkLead, setSelectedRemarkLead] = useState<any>(0);

    const [insuranceListData, setInsuranceListData] = useState<any>([]);
    const [showSelectedAssignSave, setShowSelectedAssignSave] = useState(0);

    const [selectedRemarkLeadStatus, setSelectedRemarkLeadStatus] = useState<any>(0);

    const commonState = useSelector((state: any) => state.common)

    useEffect(() => {
        setInsuranceListData(insuranceList);
    }, [insuranceList]);

    const handleChangeAssign = (leadId: any, e: any) => {
        let updatedInsuranceListData: any = insuranceListData.map((u: any) => { return (u.leadId === leadId) ? { ...u, 'assign_user_id': e.value } : u })
        setInsuranceListData(updatedInsuranceListData);
        setShowSelectedAssignSave(leadId);
    }

    const detailPageRedirect = (leadId: string) => {
        navigate(`/details/personal-details/${leadId}`);
    }

    const handleRequestData = (params: any) => {
        let apiParams = {
            ...selectedFilters,
            page_number: params.page_no
        }

        props.getInsuranceListing(apiParams)

    }

    /**
     * HAHNDLE TIMELINE POPUP
     * @param type 
     * @param leadId 
     */
    const handleViewTimeLine = (type: boolean, leadId: any) => {
        setViewTimeLine(type);
        setSelectedRemarkLead(leadId);

    }

    /**
     * HAHNDLE REMARK POPUP
     * @param type 
     * @param leadId 
     */
    const handleRemarkTimeline = (type: boolean, leadId: any, AllLeadData:any) => {
        setRemarkHistory(type);
        setSelectedRemarkLead(leadId);

        setSelectedRemarkLeadStatus(AllLeadData?.status_id);
    }

    const getSelectedAssignUser = (assignUserId: any) => {
        let getSelectedUser = assignUsers.find((u: any) => +u.value === +assignUserId);
        return getSelectedUser || null;
    }

    const saveAssignLeadHandler = (params: { hashId: string, action: string }) => {
        if (params.action === "cancel") {
            setShowSelectedAssignSave(0);
            setInsuranceListData([...insuranceList])
            // let updatedInsuranceListData: any = insuranceList.map((u: any)=> { return (u.hashId === params.hashId) ? {...u, 'assign_user_id': e.value} : u })
            // setInsuranceListData(updatedInsuranceListData);

        } else {
            let getLead: any = insuranceListData.find((ins: any) => ins.hashId === params.hashId) || {};
            updateLeadAssignUser({ lead_id: params.hashId, "assign_user_id": getLead['assign_user_id'] }).then(res => {
                handleRequestData({ ...selectedFilters, page_no: selectedFilters?.activeTab === 'myCases' ? 1 : selectedFilters?.page_number })
                setShowSelectedAssignSave(0)
            });
        }
    }

    return (
        <div className="lead-list-data-table managepanel-tab">
            <table>
                <thead>
                    <tr>
                        <th>Insurance ID</th>
                        <th>Customer Details</th>
                        <th>Car Details</th>
                        <th>Policy Details</th>
                        <th>Case Details</th>
                        <th>Status</th>
                        <th>Insurance</th>
                        <th>Application Type</th>
                        { HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['assign_lead_to_user'], accessType: 'isVisible'}) && <th>Assign to</th> }
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        insuranceListData.length
                            ?
                            insuranceListData.map((lead: any, key: number) =>

                                <tr key={key}>
                                    <td>  <NavLink to={`/details/personal-details/${lead.hashId}`}> {lead.leadId} </NavLink></td>
                                    <td>
                                        <div className="table-txt-data">
                                            <h5 title={`${lead.name}(${lead.phone})`}>{lead.name || ""}</h5>
                                            <span>{lead.phone || ""}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-txt-data">
                                            <h5 title={`${lead.make_name || ""} ${lead.model_name || ""} ${lead.version_name || ""}`}>{`${lead.make_name || ""} ${lead.model_name || ""} ${lead.version_name || ""}`}</h5>
                                            <span>{lead.mfg_year || ""}</span>
                                            <span title ="Plate Number">{lead.plate_no || ""}</span>
                                            {
                                              (lead.insurance_type===INS_TYPE_CTPL && lead.mv_number) && <span title ="MV File number ">{lead.mv_number || ""}</span>  
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-txt-data">
                                            <h5 title={`${HELPER.addCurrencyFormatting(lead.gross_premium)}`}>Gross {HELPER.addCurrencyFormatting(lead.gross_premium) || 0}</h5>
                                            <span title={`${HELPER.addCurrencyFormatting(lead.total_tax_amount)}`}>Tax {HELPER.addCurrencyFormatting(lead.total_tax_amount) || 0}</span>
                                            <span title={`${HELPER.addCurrencyFormatting(lead.net_premium)}`}>Net {HELPER.addCurrencyFormatting(lead.net_premium) || 0}</span>

                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-txt-data">
                                            <h5 title={lead.created_by_name}>{lead.created_by_name || ""}</h5>
                                            <span>{lead.created_by_role || ""}</span>
                                            <span>Created on</span>
                                            <span>{dateFormat(lead.applied_on, 'd mmm yyyy')}</span>

                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-txt-data">
                                            <h5 title={lead.sub_status_name}>{lead.sub_status_name || ""}</h5>
                                            <span>Last Updated</span>
                                            <span>{dateFormat(lead.updated_at, 'd mmm yyyy')}</span>

                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-txt-data">
                                            <h5 title={lead.insurance_partner}>{lead.insurance_partner || ""}</h5>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-txt-data">
                                            <h5 title={'application type'}>{(commonState?.common?.apptype?.find((e: any) => lead.apptype === e.value))?.label || ""}</h5>
                                        </div>
                                    </td>

                                    {
                                        HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['assign_lead_to_user'], accessType: 'isVisible'}) && <td>
                                            <div className="select-table">
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <Select
                                                            value={getSelectedAssignUser(lead.assign_user_id)}
                                                            onChange={(e) => handleChangeAssign(lead.leadId, e)}
                                                            options={assignUsers}
                                                            placeholder="Assign To"
                                                        />

                                                        {(showSelectedAssignSave === lead.leadId) && <div className="amt-edit-opt"><button className="btn-primary" onClick={() => saveAssignLeadHandler({ hashId: lead.hashId, action: 'save' })}>Save</button><i className="ic-clearclose" onClick={() => saveAssignLeadHandler({ hashId: lead.hashId, action: 'cancel' })}></i></div>}
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </td>
                                    }

                                    <td>
                                        <ul className="action-btn">
                                            <li onClick={() => detailPageRedirect(lead.hashId)}>
                                                <i className="ic-remove_red_eyevisibility"></i>

                                            </li>
                                            <li onClick={() => handleViewTimeLine(true, lead.leadId)}>
                                                <i className="ic-Call-history"></i>
                                            </li>
                                            <li onClick={() => handleRemarkTimeline(true, lead.leadId, lead)}>
                                                <i className="ic-chat"></i>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>

                            )

                            :
                            <tr><td colSpan={9} style={{ textAlign: 'center' }}>No data found</td></tr>

                    }

                </tbody>
            </table>

            {(paginationData && paginationData['total'] > 10) && <Pagination paginateData={{
                page,
                loading: false,
                totalLengthWithoutPagination: paginationData['total'],
                paginationData,
                filteredData: selectedFilters
            }}
                sendRequestDataToParent={handleRequestData} />}

            {viewTimeLine && <LeadHistoryModal viewTimeLine={viewTimeLine} selectedRemarkLead={selectedRemarkLead} handleViewTimeLine={(action: boolean, leadId: any) => handleViewTimeLine(action, leadId)} />}


            {remarkHistory && <LeadRemarksModal remarkHistory={remarkHistory} selectedRemarkLead={selectedRemarkLead} selectedRemarkLeadStatus={selectedRemarkLeadStatus} handleRemarkTimeline={(action: boolean, leadId: any, lead:any) => handleRemarkTimeline(action, leadId,lead)} />}



        </div >
    )
}

export default InsuranceListTable