import { useEffect, useState } from 'react';
import Pagination from '../../common/Pagination';
import FullPageLoader from "../../common/FullPageLoader";
import * as HELPER from '../../config/helper';
import { Link } from 'react-router-dom';
import { Tooltip as TooltipLite } from 'react-tooltip';


function PaymentListTable(props: any) {
    let [bulkPaymentList, setBulkPaymentList] = useState<any>(props.bulkPaymentList || []);
    const [paginationData, setPaginationData] = useState<any>(props.paginationData || {});
    const [loading, setLoading] = useState(props.loading || false);
    let [filterData, setFilterData] = useState<any>({});
    const [insuranceIdToolTip, setInsuranceIdTooltip] = useState<any>(0);
    const [paymentRefToolTip, setPaymentRefToolTip] = useState<any>(0);
    const [dateTimeToolTip, setDateTimeToolTip] = useState<any>(0);

    useEffect(() => {
        setBulkPaymentList(props.bulkPaymentList)
        setPaginationData(props.paginationData)
        setLoading(props.loading)
    }, [props])


    const getBulkListDetails = async (params: any) => {
        let filters = {
            ...params,
            page_number: params?.page_number ?? 1,
            isPaginationApply: true
        }

        setFilterData(filters);
        props.getBulkListDetails(filters)
    };


    const formatDate = (timestamp: any) => {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });

        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
        return `${formattedDate}, ${formattedTime}`;
    };

    const handleRequestData = (params: any) => {
        let apiParams = {
            page_number: params.page_no,
            searchKey: props.searchText
        }
        getBulkListDetails(apiParams);
        setInsuranceIdTooltip(0);
        setPaymentRefToolTip(0);
        setDateTimeToolTip(0);
    }

    return (
        <>
            <FullPageLoader show={loading} />

            <div className="lead-list-data-table bulk-payment-table">
                <table>
                    <thead>
                        <tr>
                            <th>Bulk Payment ID</th>
                            <th>Payment Reference No</th>
                            <th>Paid to Whom?</th>
                            <th>Date & Time</th>
                            <th>Insurance ID</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(bulkPaymentList && bulkPaymentList.length > 0) ? bulkPaymentList.map((row: any, index: number) => (
                            <tr key={index}>
                                <td>{row.payment_id}</td>
                                <td>
                                    <div className='p-rel'>
                                        {row?.payment_tenure_data?.slice(0, 2).filter((ref: any) => ref?.payment_ref_no ).map((ref: any) => ref?.payment_ref_no)?.join(",")}
                                        {row?.payment_tenure_data?.length > 2 && <span className=' tooltio-ic more-text' data-tooltip-id={"my-tooltip-ref-" + row.payment_id}
                                            onClick={() => setPaymentRefToolTip(row.payment_id)}
                                            onMouseLeave={() => { }}> {` +${row?.payment_tenure_data?.length - 2} more`} </span>}

                                        {paymentRefToolTip === row.payment_id &&
                                            <div className='tooltip-outer bulk-payment-tooltip-outer'>

                                                <TooltipLite
                                                    isOpen={+paymentRefToolTip === +row.payment_id}
                                                    className=" react-tooltip-lite"
                                                    id={"my-tooltip-ref-" + row.payment_id}
                                                    place="bottom"
                                                    clickable={true}
                                                    render={() =>
                                                        <div className="controlled-example tooltip-main">
                                                            <div className="tooltip-data">
                                                                <span className="controlled-example_close-button" onClick={() => { setPaymentRefToolTip(0); }}><i className="ic-clearclose"></i></span>
                                                                <ul className="user-detail">
                                                                    {
                                                                        row?.payment_tenure_data?.map((ref: any) =>
                                                                            <li>
                                                                                <span>{ref.payment_ref_no}</span>
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }

                                                />
                                            </div>
                                        }

                                    </div>
                                </td>
                                <td>{row.paid_to}</td>
                                <td>
                                    <div className='p-rel'>
                                        {row?.payment_tenure_data?.slice(0, 2).filter((ref: any) => ref?.date_time ).map((date:any)=> formatDate(date?.date_time))?.join(",") }
                                        {row?.payment_tenure_data?.length > 2 && <span className=' tooltio-ic more-text' data-tooltip-id={"my-tooltip-date-" + row.payment_id}
                                            onClick={() => setDateTimeToolTip(row.payment_id)}
                                            onMouseLeave={() => { }}> {` +${row?.payment_tenure_data?.length - 2} more`} </span>}

                                        {dateTimeToolTip === row.payment_id &&
                                            <div className='tooltip-outer bulk-payment-tooltip-outer'>

                                                <TooltipLite
                                                    isOpen={+dateTimeToolTip === +row.payment_id}
                                                    className=" react-tooltip-lite"
                                                    id={"my-tooltip-date-" + row.payment_id}
                                                    place="bottom"
                                                    clickable={true}
                                                    render={() =>
                                                        <div className="controlled-example tooltip-main">
                                                            <div className="tooltip-data">
                                                                <span className="controlled-example_close-button" onClick={() => { setDateTimeToolTip(0); }}><i className="ic-clearclose"></i></span>
                                                                <ul className="user-detail">
                                                                    {
                                                                        row?.payment_tenure_data?.map((ref: any) =>
                                                                            <li>
                                                                                <span>{formatDate(ref.date_time)}</span>
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }

                                                />
                                            </div>
                                        }

                                    </div>
                                    {/* {formatDate(row.date_time)} */}
                                </td>
                                <td>
                                    <div className='p-rel'>
                                        {row?.lead_info?.slice(0, 2).map((lead: any) => lead?.lead_id)?.join(",")}
                                        {row?.lead_info?.length > 2 && <span className=' tooltio-ic more-text' data-tooltip-id={"my-tooltip-" + row.payment_id}
                                            onClick={() => setInsuranceIdTooltip(row.payment_id)}
                                            onMouseLeave={() => { }}> {` +${row?.lead_info?.length - 2} more`} </span>}

                                        {insuranceIdToolTip === row.payment_id &&
                                            <div className='tooltip-outer bulk-payment-tooltip-outer'>

                                                <TooltipLite
                                                    isOpen={+insuranceIdToolTip === +row.payment_id}
                                                    className=" react-tooltip-lite"
                                                    id={"my-tooltip-" + row.payment_id}
                                                    place="bottom"
                                                    clickable={true}
                                                    render={() =>
                                                        <div className="controlled-example tooltip-main">
                                                            <div className="tooltip-data">

                                                                <span className="controlled-example_close-button" onClick={() => { setInsuranceIdTooltip(0); }}><i className="ic-clearclose"></i></span>
                                                                <ul className="user-detail">
                                                                    {
                                                                        row?.lead_info?.map((lead: any) =>
                                                                            <li>
                                                                                <span>ID {lead.lead_id} • {lead.customer_name} • {lead.agency_name} • Premium {HELPER.addCurrencyFormatting(lead.amount)} </span>
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }

                                                />
                                            </div>
                                        }

                                    </div>

                                </td>

                                <td>
                                    <ul className="action-btn">
                                        <li >
                                            <Link
                                                to={`/payment/edit/${row.hash_id}`}
                                            >
                                                <i className='ic-remove_red_eyevisibility' style={{ cursor: 'pointer' }}></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        )) :
                            <tr><td colSpan={5} style={{ textAlign: 'center' }}><h3>No data found</h3></td></tr>
                        }
                    </tbody>
                </table>

                {(paginationData && paginationData['total'] > 10) && <Pagination paginateData={{
                    page: 1,
                    loading: false,
                    totalLengthWithoutPagination: paginationData['total'],
                    paginationData,
                    filteredData: filterData
                }}
                    sendRequestDataToParent={handleRequestData} />}




            </div>




        </>

    )
}

export default PaymentListTable

