import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFinancierTemplateConfig } from '@src/store/slices/insurance';
import { useDispatch } from 'react-redux'
import Pagination from '@src/common/Pagination';
import { Nav } from 'react-bootstrap';
import FullPageLoader from '@src/common/FullPageLoader';
import * as HELPER from "@src/config/helper";
import { ROLE_FEATURES } from '@src/config/constant';

const SendToFinancierTemplateListing = () => {
    let [loading, setLoading] = useState(false);
    let [templateFinancierList, setTemplateFinancierList] = useState<any>([]);

    let [paginationData, setPaginationData] = useState<any>({})
    let page = 1

    const dispatch: any = useDispatch();

    // Function to fetch template financier list
    const getTemplateFinancierList = async (filters: any) => {
        // setLoading(true);
        dispatch(getFinancierTemplateConfig(filters)).then((res: any) => {
            if (res?.status === 200) {

                setTemplateFinancierList(res?.data || []);
                setPaginationData(res?.pagination ?? {})
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        });

    }

    useEffect(() => {
        setLoading(true);
        let filters = {
            page_number: 1,
            pagination: true
        }
        getTemplateFinancierList(filters);

        setPaginationData({})
        // setLoading(false);
        // eslint-disable-next-line
    }, []);

    // Function to handle pagination data requests
    const handleRequestData = (params: any) => {
        setLoading(true);
        let apiParams = {
            page_number: params.page_no
        }

        getTemplateFinancierList(apiParams);
        // setLoading(false);
    }


    return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <div className="top-heading-outer">
                <div className="top-heading">
                    <h1>Template Configuration</h1>
                </div>
                <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                    <Nav.Item>
                        <Nav.Link eventKey="first" active> Template </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div className="container-fluid">
                <div className="data-table financier-bx-table-outer">
                    <table>
                        <thead>
                            <tr>
                                <th style={{width:"15%"}}>Financier</th>
                                <th style={{width:"15%"}}>Cohort Analysis</th>
                                {HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['sent_to_insurance_broker'], accessType: 'isVisible' }) && <th style={{width:"25%"}}>Send to Insurance Broker Template</th>}
                                {HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_policy'], accessType: 'isVisible' }) && <th style={{width:"25%"}}>Insurance Policy Shared Template</th>}
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templateFinancierList.map((financierData: any) => (
                                <tr key={financierData.partner_id}>
                                    <td>
                                        <Link
                                            to={`/email-template-config-detail?partner_id=${financierData.hashId}`}
                                        >
                                            {financierData.partner_name}
                                        </Link>
                                    </td>

                                    <td>
                                        <span>Active : {financierData.all_status ? financierData.all_status.split(",").filter((status: any) => +status === 1).length : 0} </span><br />
                                        <span>InActive : {financierData.all_status ? financierData.all_status.split(",").filter((status: any) => +status === 0).length : 0} </span>
                                    </td>
                                    {HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['sent_to_insurance_broker'], accessType: 'isVisible' }) &&
                                        <td>
                                            <span>Email : {financierData?.send_to_insurance_broker_email}
                                                {
                                                    (financierData?.send_to_insurance_broker_email_ucf || financierData?.send_to_insurance_broker_email_standalone || financierData?.send_to_insurance_broker_email_renewal)
                                                        ?
                                                        ` (${financierData?.send_to_insurance_broker_email_ucf ? "With UCF" : ""}${financierData?.send_to_insurance_broker_email_ucf && financierData?.send_to_insurance_broker_email_standalone ? ", " : ""}${financierData?.send_to_insurance_broker_email_standalone ? "Standalone" : ""}${(financierData?.send_to_insurance_broker_email_ucf || financierData?.send_to_insurance_broker_email_standalone) && financierData?.send_to_insurance_broker_email_renewal ? ", " : ""}${financierData?.send_to_insurance_broker_email_renewal ? "Renewal" : ""})`
                                                        : ''
                                                }
                                            </span><br />
                                            <span>Whatsapp : {financierData?.send_to_insurance_broker_wa}
                                                {
                                                    (financierData?.send_to_insurance_broker_wa_ucf || financierData?.send_to_insurance_broker_wa_standalone || financierData?.send_to_insurance_broker_wa_renewal)
                                                        ?
                                                        ` (${financierData?.send_to_insurance_broker_wa_ucf ? "With UCF" : ""}${financierData?.send_to_insurance_broker_wa_ucf && financierData?.send_to_insurance_broker_wa_standalone ? ", " : ""}${financierData?.send_to_insurance_broker_wa_standalone ? "Standalone" : ""}${(financierData?.send_to_insurance_broker_wa_ucf || financierData?.send_to_insurance_broker_wa_standalone) && financierData?.send_to_insurance_broker_wa_renewal ? ", " : ""}${financierData?.send_to_insurance_broker_wa_renewal ? "Renewal" : ""})`
                                                        : ''
                                                }
                                            </span>
                                        </td>
                                    }

                                    {HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_policy'], accessType: 'isVisible' }) &&
                                        <td>
                                            <span>Email : {financierData?.insurance_policy_shared_email}
                                                {
                                                    (financierData?.insurance_policy_shared_email_ucf || financierData?.insurance_policy_shared_email_standalone  || financierData?.insurance_policy_shared_email_renewal)
                                                        ?
                                                        ` (${financierData?.insurance_policy_shared_email_ucf ? "With UCF" : ""}${financierData?.insurance_policy_shared_email_ucf && financierData?.insurance_policy_shared_email_standalone ? ", " : ""}${financierData?.insurance_policy_shared_email_standalone ? "Standalone" : ""}${(financierData?.insurance_policy_shared_email_ucf || financierData?.insurance_policy_shared_email_standalone) && financierData?.insurance_policy_shared_email_renewal ? ", " : ""}${financierData?.insurance_policy_shared_email_renewal ? "Renewal" : ""})`
                                                        : ''
                                                }
                                            </span><br />
                                            <span>Whatsapp : {financierData?.insurance_policy_shared_wa}
                                                {
                                                    (financierData?.insurance_policy_shared_wa_ucf || financierData?.insurance_policy_shared_wa_standalone || financierData?.insurance_policy_shared_wa_renewal)
                                                        ?
                                                        ` (${financierData?.insurance_policy_shared_wa_ucf ? "With UCF" : ""}${financierData?.insurance_policy_shared_wa_ucf && financierData?.insurance_policy_shared_wa_standalone ? ", " : ""}${financierData?.insurance_policy_shared_wa_standalone ? "Standalone" : ""}
                                                        ${(financierData?.insurance_policy_shared_wa_ucf || financierData?.insurance_policy_shared_wa_standalone) && financierData?.insurance_policy_shared_wa_renewal ? ", " : ""}${financierData?.insurance_policy_shared_wa_renewal ? "Renewal" : ""})`
                                                        : ''
                                                }
                                            </span>
                                        </td>
                                    }

                                    <td>
                                        <span
                                            className={`status-${financierData?.all_status?.split(",")?.filter((status: any) => +status).length ? "active" : "inactive"
                                                }`}
                                        >
                                            {
                                            // financierData?.all_status?.split(",")?.filter((status: any) => +status).length ? "Active" : "InActive"
                                            financierData?.all_status?.split(",")?.includes("1") ? "Active" : "InActive"
                                            }
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="pagination-outer">
                        {(paginationData && paginationData['total'] > 10) && <Pagination
                            paginateData={{
                                page,
                                loading: false,
                                totalLengthWithoutPagination: paginationData.total,
                                paginationData,
                                filteredData: {}
                            }}
                            sendRequestDataToParent={handleRequestData}
                        />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SendToFinancierTemplateListing;