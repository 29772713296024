import { useSelector } from "react-redux"
import ReactSelect from '@common/ReactSelect';
import { ADD_ONS_TYPE } from 'config/constant';
import * as HELPER from "../../../../config/helper";
import { useSectionList } from "@src/hooks/useSectionList";
import InsuranceService from "@src/services/InsuranceService";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { APP_TYPE_AGENCY, INS_TYPE_COMPREHENSIVE, REGEX_DECIMAL_VALIDATION } from "@src/config/constant";

// type Props = {
//     setLoading: Function,
//     setAddonList: Function,
//     page: number,
//     addonList: Array<any>
// }

export const Addons = forwardRef((props: any, ref) => {
    let { setLoading } = props
    const { fetchSectionItem, fetchItem } = useSectionList({})
    const [addonDetail, setAddonDetail] = useState<Array<any>>([])
    const [refresh, setRefresh] = useState<boolean>(false)
    const vehicleDetails = fetchSectionItem('vehicle_details', 'vehicle_info')
    const [value, setValue] = useState(3)

    const insurance = useSelector((state: any) => state.insurance)

    let { rule_engine, insurance_type, addon_detail, seating_capacity_autofill } = insurance?.createInsuranceAgency || {}

    const isComprensive = insurance_type === INS_TYPE_COMPREHENSIVE ? true : false

    const fetchVehicleItem = (key: string) => {
        const field = fetchItem(key, vehicleDetails?.fields || [])
        return (field?.value && typeof field?.value === 'object') ? field?.value?.value : (field?.value || null)
    }

    const fetchAddons = async () => {
        setLoading(true)
        let vehicleTypeItem = fetchVehicleItem('vehicle_type')
        let insuranceTypeItem = fetchVehicleItem('insurance_type')
        let makeYearItem = fetchVehicleItem('make_year')


        if (vehicleTypeItem && insuranceTypeItem) {
            let params = {
                rule_engine: rule_engine,
                vehicle_type: vehicleTypeItem,
                apptype: APP_TYPE_AGENCY,
                insurance_type: insuranceTypeItem,
                make_year: makeYearItem
            }
            let result = await InsuranceService.getAddonList(params)

            if (result?.data?.data) {
                let originalList = result.data.data
                let carfinanceTypeItem = fetchVehicleItem('car_under_finance')

                let is_required = (carfinanceTypeItem && +carfinanceTypeItem === 1) ? true : false
                if (originalList.length > 0) {
                    originalList = originalList.map((ob: any) => {
                        const prevSelectedItem = addon_detail?.find((ele: any) => +ele.id === +ob.id) || {}
                        if (ob.type === 'pa') {
                            if (ob.is_selected) getVersionSeat(ob)
                            if (prevSelectedItem['extra_seat_capacity']) {
                                ob['extra_seat_value'] = prevSelectedItem['extra_seat_capacity']
                            }
                        }
                        return {
                            ...ob, ...prevSelectedItem, coverage_amt: prevSelectedItem?.['coverage_amount'] || ob['coverage_amt'],
                            is_required: (ob.id === 6) ? is_required : ob.is_required,
                            is_selected: prevSelectedItem?.['id'] ? true : (addon_detail?.length ? false : ob.is_selected)
                        }
                    })
                }
                setAddonDetail(originalList)
            }
        }
        setLoading(false)
    }

    const getVersionSeat = (item: any) => {
        let seatCapacity = seating_capacity_autofill ? fetchVehicleItem('seat_capacity') : null //only fill seat capacity in pa if seating capacity is fetched from mmv
        if (seatCapacity) {
            item['seat_capacity'] = +seatCapacity
        }
    }

    const onChangeCheckbox = (addonItem: any, event: any) => {
        if (addonItem.is_required) {
            addonItem.is_selected = true
        } else {
            addonItem['is_selected'] = !addonItem.is_selected
        }
        setRefresh(!refresh)
    }

    useEffect(() => {
        fetchAddons()
        // eslint-disable-next-line
    }, [])

    const onValueSelect = (addonItem: any, event: any) => {
        addonItem['coverage_amt'] = event.value
        setValue(value + 1)
    }

    const getSeatingCapacityList = (item: any) => {
        let versionSeat = item.max_seat_capacity
        versionSeat = +versionSeat
        var tempList = []
        for (var index = 1; index <= versionSeat; index++) {
            const optionParam = {
                "value": index,
                "label": index
            }
            tempList.push(optionParam)
        }
        return tempList
    }

    const getExtraSeatingCapacityList = (item: any) => {
        let versionSeat = item.max_seat_capacity

        if (versionSeat) {
            versionSeat = +versionSeat
            var tempList = []
            for (var index = 1; index <= versionSeat; index++) {
                const optionParam = {
                    "value": index,
                    "label": index
                }
                tempList.push(optionParam)
            }
            return tempList
        }
        return []
    }

    const saveExtraSeatingValue = (item: any, event: any) => {
        item.extra_seat_value = event.value
        setValue(value + 1)
    }

    const saveSeatingValue = (item: any, event: any) => {
        item.seat_capacity = event.value
        setValue(value + 1)
    }

    const getPremiumTitle = (item: any, key: string) => {
        if (ADD_ONS_TYPE.PERSONAL_ACCIDENT === item.type && isComprensive && isRuleEngine()) {
            return ''
        }
        else if (ADD_ONS_TYPE.ACTS_OF_NATURE === item.type || ADD_ONS_TYPE.RIOTS_STRIKES_CIVIL === item.type || ADD_ONS_TYPE.TOWING_ROWDSIDE === item.type) {
            return ''
        } else if (isComprensive && isRuleEngine()) {
            if (key === "title") {
                return "Premium Amount :"
            } else {
                return HELPER.addCurrencyFormatting(getPremiumAmount(item))
            }
        }
        return ''
    }

    const getPremiumAmount = (item: any) => {
        if (isComprensive && isRuleEngine()) {
            if (ADD_ONS_TYPE.PERSONAL_ACCIDENT === item.type) {
                return item.premium_amount || ''
            }
            let filterItem = item?.coverage_list?.filter((option: any) => option.value === item.coverage_amt)?.[0]
            if (filterItem)
                return filterItem?.premium_amount || ''
            else
                return 0
        }
        else {
            return item.premium_amount ? item.premium_amount : ''
        }
    }

    const isRuleEngine = () => {
        if (rule_engine === 1) return true
        else return false
    }

    const isShowPremiumInputBox = (addonItem: any) => {
        if (((!isComprensive && !isRuleEngine()) || (!isRuleEngine() && isComprensive)) && ADD_ONS_TYPE.RIOTS_STRIKES_CIVIL !== addonItem.type && ADD_ONS_TYPE.TOWING_ROWDSIDE !== addonItem.type) {
            return true
        }
        else {
            if(addonItem.is_editable && !isRuleEngine()){ //ONLY IN CASE OF TRA WITHOUT RULE ENGINE
                return true
            }else{
                //FOR OONA & MERCENTILE IF COVERAGE AMT IS MATCHED FROM API THEN SHOW INPUT BOX
                if(addonItem.type === ADD_ONS_TYPE.PERSONAL_ACCIDENT && ["oona", "milestone"].includes(insurance?.createInsuranceAgency?.partner_slug)){
                    if(addonItem.coverage_amt === addonItem.oona_coverage_amount_limit || addonItem.coverage_amt === addonItem.milestone_coverage_amount_limit){
                        addonItem.premium_amount = ''
                        return false
                    }else{
                        return (addonItem.coverage_amt) ? true : false //MANUAL PREMIUM INPUT ALLOW 
                    }

                }else{
                    return false
                } 
            }
        }
    }

    const onChangePremiumAmount = (addonItem: any, text: any) => {
        // validate two decimal digit value
        // Validate input field of allow two decimal value 
        if (REGEX_DECIMAL_VALIDATION.test(text) || text === '') {
            if (!isComprensive && isRuleEngine()) {
                addonItem.coverage_amt = text ? text : ''
            }
            else {
                addonItem.premium_amount = text ? text : ''
            }
            setValue(value + 1)
        }
    }

    useImperativeHandle(ref, () => ({
        getData: () => addonDetail
    }))

    return (
        <div className="row">
            {
                addonDetail.length
                    ?
                    addonDetail.map((addonItem: any, idx: number) => {
                        return (
                            <React.Fragment key={addonItem.id + (" " + idx + 1)}>
                                <div className="col-md-12">
                                    <div className="">

                                        <fieldset className="form-filed common-application-checkbox">
                                            <div className="custom-control custom-checkbox ">
                                                <input checked={addonItem.is_selected} id="addon_id" type="checkbox" className="custom-control-input " name="addon_id" onChange={(event) => {
                                                    onChangeCheckbox(addonItem, event)
                                                }} />
                                                <label className="custom-control-label checkbox-label-txt"><span className="addon-list-heading">{addonItem.name}</span></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>


                                {
                                    addonItem?.is_selected &&
                                    <>
                                        {
                                            addonItem.coverage_list?.length > 0 &&
                                            <ReactSelect
                                                options={addonItem.coverage_list || []}
                                                value={addonItem.coverage_amt ? { label: addonItem.coverage_amt, value: addonItem.coverage_amt } : null}
                                                handleChange={(e: any, fieldKey: string) => onValueSelect(addonItem, e)}
                                                placeholder="Choose Coverage Amount"
                                                fieldKey=""
                                                fieldSetClassName="col-md-4 p-lg-b"
                                                isDisabled={!isComprensive ? true : false}
                                            />
                                        }

                                        {
                                            addonItem.type === 'pa' && <>
                                                <ReactSelect
                                                    options={getSeatingCapacityList(addonItem)}
                                                    placeholder="Seating Capacity"
                                                    isDisabled={(props.versionSeat || (addonDetail?.find((e: any) => e.id === 3 && e.seat_capacity && seating_capacity_autofill)))}
                                                    value={props.versionSeat ? { label: props.versionSeat, value: props.versionSeat } : addonItem.seat_capacity ? { label: addonItem.seat_capacity, value: addonItem.seat_capacity } : null}
                                                    handleChange={(e: any) => saveSeatingValue(addonItem, e)} fieldSetClassName="col-md-4 p-lg-b"
                                                />

                                                <ReactSelect
                                                    options={getExtraSeatingCapacityList(addonItem)}
                                                    placeholder="Extra Seating Capacity"
                                                    value={addonItem?.extra_seat_value ? { label: addonItem.extra_seat_value, value: addonItem.extra_seat_value } : null}
                                                    handleChange={(e: any) => saveExtraSeatingValue(addonItem, e)} fieldSetClassName="col-md-4 p-lg-b"
                                                />
                                            </>
                                        }

                                        {
                                            getPremiumTitle(addonItem, 'title')?.length > 0 &&
                                            <>
                                                <fieldset className="form-filed">
                                                    <span>{getPremiumTitle(addonItem, 'title')}</span>
                                                    <span>{getPremiumTitle(addonItem, 'value')}</span>
                                                </fieldset>
                                            </>
                                        }
                                        {
                                            isShowPremiumInputBox(addonItem) && <fieldset className="form-filed col-md-4 p-lg-b">
                                                <div className="material">
                                                    <input id="" type="text" placeholder="" name="name" className="form-input" value={getPremiumAmount(addonItem)} onChange={(event) => {
                                                        onChangePremiumAmount(addonItem, event.target.value);
                                                    }} />
                                                    <label data-label={"Premium Amount"} className="form-label"></label>
                                                </div>
                                            </fieldset>
                                        }

                                    </>
                                }
                            </React.Fragment>

                        )
                    })
                    : undefined
            }
        </div >
    )
})